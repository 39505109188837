import OpenPosition from '../components/OpenPosition';

function PositionCtoPage() {
  return (
    <OpenPosition
      positionTitle='Chief Technical Officer'
      boxTitle='CTO'
      positionDescription={[
        'Az nDRU új csapattagot keres nemzetközi mobil applikáció fejlesztési projektjébe.',
        'Csapatunk azon dolgozik, hogy a járműtulajdonosok és a szolgáltatók az nDRU app segítségével könnyen egymásra találjanak. Az app segítségével minden autóhoz kapcsolódó feladat elvégezhető, mint például a szerviz időpontfoglalás, parkolás és egyéb autóhoz kapcsolódó szolgáltatások megrendelése.',
        'Ha szeretnél egy jelentős nemzetközi növekedés előtt álló startuphoz csatlakozni és része lenni ennek a kalandnak akkor most CTO-ként várunk! Munkád eredményét várhatóan több millió autótulajdonos fogja élvezni!',
      ]}
      positionTasksTitle='CTO-ként az alábbi feladataid lennének:'
      positionTasks={[
        'Ios és Android mobilfejlesztés',
        'nDRU szerviz és flotta frontend és backend fejlesztése és fejlesztés koordinálása',
      ]}
      positionOtherThingsTitle='Ezzel a technológiai stack-el dolgozunk:'
      positionOtherThings={[
        'Mobil app: Angular 11, Ionic 5',
        'Szerviz felület: Angular 9 és szintén Ionic 5',
        'Backend: .NET 5',
        'Controllok: DevExtreme csomag',
      ]}
      whatWeOfferTitle='Amit kínálunk:'
      whatWeOffer={[
        'Baráti csapat',
        'Fejlődési lehetőség',
        'Külföldre jutás',
        'Neves inkubátor programokban való részvétel',
        'Autóipar forradalmasítása',
        'Tinder előfizetés',
        'HBO, Netflix hozzáférés',
      ]}
      clausesTitle='Kérünk NE jelentkezz az állásra:'
      clauses={[
        'Ha földönkívüli civilizáció küldöttje vagy (ebben az esetben azért beszéljünk, de nem biztos hogy a fejlesztési vezető pozíció neked szól)',
      ]}
      endpoint='careers/cto'
    />
  );
}
export default PositionCtoPage;
