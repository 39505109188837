import React from 'react';

import Page from '../components/Page';

function NdruPrivacyPolicyHu() {
  return (
    <Page
      title='Adatkezelési Tájékoztató'
      endpoint='NDRU-privacy-policy/hu'
    >
      <div className='container adatkezelesi-tajekoztato py-5'>
        <h1
          className='s1'
          style={{
            paddingTop: '3pt',
            textIndent: '0pt',
            textAlign: 'center',
            fontSize: '22px',
            margin: '2rem auto',
          }}
        >
          Adatkezelési Tájékoztató
        </h1>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '10pt',
            textIndent: '0pt',
            textAlign: 'justify',
          }}
        >
          Jelen Adatkezelési Tájékoztató (a továbbiakban: „
          <b>Adatkezelési Tájékoztató</b>”) arról tartalmaz
          információkat, hogy az NDRU 1.0 Kft. (a
          továbbiakban: „<b>NDRU</b>
          <a
            href='http://www.drivendru.com/'
            className='a'
            target='_blank'
          >
            ”) a{' '}
          </a>
          www.drivendru.com weboldalon, mobilweboldalon és
          az NDRU APP mobilapplikáción (a továbbiakban: „
          <b>Platform</b>”) keresztül nyújtott
          szolgáltatásokkal (a továbbiakban: „
          <b>Szolgáltatás”</b>) kapcsolatban miként kezeli
          az Ön személyes adatait. A sütik (cookie-k)
          kezelésével kapcsolatos részletes tájékoztatást
          jelen Adatkezelési Tájékoztató nem tartalmazza. A
          sütik (cookie-k) kezelésével kapcsolatos
          tájékoztatás itt érhető el.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '11pt',
            textIndent: '0pt',
            lineHeight: '113%',
            textAlign: 'justify',
          }}
        >
          <a
            href='mailto:info@drivendru.com'
            className='a'
            target='_blank'
          >
            Adatkezelő adatai: NDRU 1.0 Kft.,
            cégjegyzékszám: 01-09-374640, adószám:
            28802839-2-43, székhely: 1221 Budapest, Hajó
            utca 1., e-mail:{' '}
          </a>
          info@drivendru.com (a továbbiakban: “<b>NDRU</b>
          ”, vagy “<b>Adatkezelő</b>
          ”).
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '11pt',
            textIndent: '0pt',
            textAlign: 'justify',
          }}
        >
          Kérjük vegye figyelembe, hogy a kezelt adatok
          vonatkozásában adatvédelmi tisztviselő
          kinevezésére nem
        </p>
        <p
          style={{
            paddingTop: '1pt',
            paddingLeft: '11pt',
            textIndent: '0pt',
            textAlign: 'justify',
          }}
        >
          került sor.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '114%',
            textAlign: 'justify',
          }}
        >
          Amennyiben az NDRU <b>jogos érdek </b>jogalapon
          kezeli a személyes adatit{' '}
          <b>
            Önnek joga van tiltakozni az adatkezelés ellen
          </b>
          . Amennyiben Ön gyakorolná ezt a jogát kérjük
          lépjen velünk kapcsolatba e-mailen, vagy egyéb
          módon.
        </p>
        <p
          style={{
            paddingLeft: '5pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        />
        <p
          className='s1'
          style={{
            paddingTop: '8pt',
            paddingLeft: '11pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          Adatkezelési Tájékoztatóban használt fogalmak
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '11pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          Jelen Adatkezelési Tájékoztatóban használt
          fogalmak jelentését az alábbi bekezdés
          tartalmazza.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          className='s1'
          style={{
            paddingLeft: '10pt',
            textIndent: '0pt',
            lineHeight: '113%',
            textAlign: 'justify',
          }}
        >
          Adatfeldolgozó
          <span className='p'>
            : az a természetes vagy jogi személy, közhatalmi
            szerv, ügynökség vagy bármely egyéb szerv, amely
            a személyes adatokat az NDRU nevében
            (megbízásából) kezeli.
          </span>
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          className='s1'
          style={{
            paddingLeft: '11pt',
            textIndent: '0pt',
            lineHeight: '114%',
            textAlign: 'justify',
          }}
        >
          Adatkezelés
          <span className='p'>
            : személyes adatokon, vagy adatállományokon
            automatizált vagy nem automatizált módon végzett
            bármely művelet vagy műveletek összessége, így a
            gyűjtés, rögzítés, rendszerezés, tagolás,
            tárolás, átalakítás vagy megváltoztatás,
            lekérdezés, betekintés, felhasználás, közlés,
            továbbítás, terjesztés vagy egyéb módon történő
            hozzáférhetővé tétel útján, összehangolás vagy
            összekapcsolás, korlátozás, törlés, illetve
            megsemmisítés;
          </span>
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          className='s1'
          style={{
            paddingLeft: '11pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          Ön
          <span className='p'>
            : az NDRU által a Platformon keresztül nyújtott
            szolgáltatások felhasználója.
          </span>
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          className='s1'
          style={{
            paddingLeft: '11pt',
            textIndent: '0pt',
            lineHeight: '114%',
            textAlign: 'justify',
          }}
        >
          Személyes Adat
          <span className='p'>
            : bármely Önre vonatkozó információt, amely
            alapján Önt közvetlen vagy közvetett módon
            azonosítani tudjuk. Személyes Adat a név,
            azonosítószám, helymeghatározó adat, online
            azonosító vagy az Ön testi, fiziológiai,
            genetikai, szellemi, gazdasági, kulturális vagy
            szociális tulajdonságára vonatkozó egy vagy több
            tényező.
          </span>
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          className='s1'
          style={{
            paddingLeft: '11pt',
            textIndent: '0pt',
            lineHeight: '114%',
            textAlign: 'justify',
          }}
        >
          Szolgáltatás:{' '}
          <span className='p'>
            az NDRU által az Ön részére nyújtott
            szolgáltatások összessége a Platform
            üzemeltetése keretében, azaz a Platform
            üzemeltetése, a Platform használatának
            biztosítása (beleértve a regisztrációt, a
            partneri szolgáltatások elérhetővé tételét, a
            böngészést, a megrendelések kezelését,
            helymeghatározások beazonosítását, fizetést, az
            e-szervízkönyv biztosítását és a
            panaszkezelést).
          </span>
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <ol id='l1'>
          <li data-list-text={1}>
            <p
              className='s1'
              style={{
                paddingLeft: '21pt',
                textIndent: '-10pt',
                textAlign: 'left',
              }}
            >
              Adatkezelési Irányelvek
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <ol id='l2'>
              <li data-list-text='a)'>
                <p
                  style={{
                    paddingLeft: '47pt',
                    textIndent: '-18pt',
                    textAlign: 'left',
                  }}
                >
                  Az Ön Személyes Adatait a jogszabályi
                  előírásoknak megfelelően, méltányosan és
                  átlátható módon
                </p>
                <p
                  style={{
                    paddingTop: '1pt',
                    paddingLeft: '47pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  kezeljük.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
              </li>
              <li data-list-text='b)'>
                <p
                  style={{
                    paddingLeft: '47pt',
                    textIndent: '-18pt',
                    textAlign: 'left',
                  }}
                >
                  Az Ön Személyes Adatait kizárólag jelen
                  Adatkezelési Tájékoztatóban meghatározott,
                  jogszerű
                </p>
                <p
                  style={{
                    paddingTop: '1pt',
                    paddingLeft: '47pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  célból kezeljük.
                </p>
              </li>
              <li data-list-text='c)'>
                <p
                  style={{
                    paddingTop: '4pt',
                    paddingLeft: '46pt',
                    textIndent: '-18pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Nem kezeljük olyan Személyes Adatait,
                  amelyek nem alkalmasak, relevánsak vagy
                  szükségesek az Adatkezelési Tájékoztatóban
                  rögzített adatkezelési célok eléréséhez.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
              </li>
              <li data-list-text='d)'>
                <p
                  style={{
                    paddingLeft: '46pt',
                    textIndent: '-18pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Mindent megteszünk, hogy Személyes Adatai
                  pontosak és naprakészek legyenek. Minden
                  észszerű lépést megteszünk annak
                  érdekében, hogy a pontatlan Személyes
                  Adatokat töröljük vagy helyesbítsük.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
              </li>
              <li data-list-text='e)'>
                <p
                  style={{
                    paddingLeft: '46pt',
                    textIndent: '-18pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Az Ön Személyes Adatait olyan formában
                  tároljuk, amely lehetővé teszi, hogy Önt
                  csak a Személyes Adatok kezelése céljából
                  és csak a szükséges ideig tudjuk
                  beazonosítani.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
              </li>
              <li data-list-text='f)'>
                <p
                  style={{
                    paddingLeft: '46pt',
                    textIndent: '-18pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Az Ön Személyes Adatait olyan módon
                  kezeljük, amely biztosítja a Személyes
                  Adatok kezelésének megfelelő biztonságát,
                  az adatok jogosulatlan vagy jogellenes
                  kezelésével, véletlen elvesztésével,
                  megsemmisítésével vagy károsodásával
                  szembeni védelmet is ideértve.
                </p>
              </li>
            </ol>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
          </li>
          <li data-list-text={2}>
            <p
              className='s1'
              style={{
                paddingLeft: '25pt',
                textIndent: '-14pt',
                textAlign: 'left',
              }}
            >
              Miért és hogyan kezeljük Személyes Adatait?
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '10pt',
                textIndent: '0pt',
                lineHeight: '113%',
                textAlign: 'justify',
              }}
            >
              Legtöbb esetben a Személyes Adatait annak
              érdekében kezeljük, hogy szolgáltatásainkat
              nyújtsuk Önnek. Azonban az adatok kezelésének
              más célja is lehet (pl.: törvényben foglalt
              kötelezettségeink teljesítése vagy jogi
              igényünk érvényesítése). Részletes
              tájékoztatás az adatkezelési célokról és a
              kezelés egyéb, vonatkozó körülményeiről az
              alábbiakban célok szerint csoportosítva
              található.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '10pt',
                textIndent: '0pt',
                lineHeight: '112%',
                textAlign: 'justify',
              }}
            >
              A Személyes Adatok kezelése során automatizált
              döntéshozatalt és profilalkotást nem végzünk,
              ellenkező esetben Önt erről közvetlenül
              tájékoztatjuk.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <ol id='l3'>
              <li data-list-text='2.1'>
                <p
                  className='s1'
                  style={{
                    paddingLeft: '27pt',
                    textIndent: '-16pt',
                    textAlign: 'left',
                  }}
                >
                  Regisztráció, szolgáltatáshoz való
                  hozzáférés biztosítása, felhasználói fiók
                  kezelése
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    lineHeight: '113%',
                    textAlign: 'justify',
                  }}
                >
                  Személyes Adat kezelésének célja
                  <span className='s1'>: </span>
                  <span className='p'>
                    Regisztráció nyomán az Ön felhasználói
                    NDRU fiókjának létrehozatala, kezelése
                    és így a Szolgáltatásokhoz való
                    hozzáférés biztosítása.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Adatkezelés jogalapja
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az Adatkezelés az Ön és az NDRU között
                    létrejött szerződés teljesítéséhez
                  </span>
                </h1>
                <p
                  style={{
                    paddingTop: '1pt',
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  szükséges.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Kezelt Személyes Adatok kategóriái
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az Ön által regisztrációkor megadott
                    Személyes Adatok: e-mail cím, jelszó,
                    ügyfélkód, keresztnév, vezetéknév,
                    szállítási cím, telefonszám, vállalkozás
                    esetén név, székhely, adószám,
                    cégjegyzékszám, fénykép, gépjármű
                    típusa, rendszám, alvázszám, motorszám,
                    forgalmi száma, törzskönyv száma,
                    számlázási adatok (név, adószám, cím),
                    szerviztörténet, márka, típus, gyártási
                    év, hengerűrtartam, teljesítmény,
                    üzemanyag, gumiméret, km állás. Facebook
                    fiókkal vagy apple ID-val való
                    bejelentkezés esetén az alábbi további
                    személyes adatok: E-mail cím.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    lineHeight: '113%',
                    textAlign: 'justify',
                  }}
                >
                  Személyes adatok tárolásának időtartama
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az NDRU a felhasználói fiók törlésééig
                    kezeli a Személyes Adatokat.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Személyes Adatok címzettjei,
                  adatfeldolgozók
                  <span className='s1'>:</span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <table
                  style={{
                    borderCollapse: 'collapse',
                    marginLeft: '13.9pt',
                  }}
                  cellSpacing={0}
                >
                  <tbody>
                    <tr style={{ height: '91pt' }}>
                      <td style={{ width: '157pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '2pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'left',
                          }}
                        >
                          Név/kategória
                        </p>
                        <p
                          style={{
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          <br />
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '2pt',
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          Microsoft Ireland Operations
                          limited,
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '2pt',
                            paddingRight: '3pt',
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          One Microsoft Place, South County
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '2pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'left',
                          }}
                        >
                          Business Park, Leopardstown,
                          Dublin
                        </p>
                      </td>
                      <td style={{ width: '159pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'left',
                          }}
                        >
                          Leírás
                        </p>
                        <p
                          style={{
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          <br />
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          Microsoft Azure Szerver,
                          tárhelyszolgáltatás
                        </p>
                      </td>
                      <td style={{ width: '157pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'left',
                          }}
                        >
                          Státusz
                        </p>
                        <p
                          style={{
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          <br />
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          Adatfeldolgozó
                        </p>
                        <p
                          style={{
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          <br />
                        </p>
                        <p
                          style={{
                            paddingLeft: '5pt',
                            paddingRight: '2pt',
                            textIndent: '0pt',
                            lineHeight: '113%',
                            textAlign: 'justify',
                          }}
                        >
                          <span
                            className='s4'
                            style={{
                              backgroundColor: '#0FF',
                            }}
                          >
                            NDRU garanciák EGT-n kívülre
                          </span>
                          <span className='s3'> </span>
                          <span
                            className='s4'
                            style={{
                              backgroundColor: '#0FF',
                            }}
                          >
                            történő adattovábbítás esetén:
                          </span>
                          <span className='s3'> </span>
                          <span
                            className='s4'
                            style={{
                              backgroundColor: '#0FF',
                            }}
                          >
                            általános adatvédelmi kikötések
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: '91pt' }}>
                      <td style={{ width: '157pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '2pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'left',
                          }}
                        >
                          Név/kategória
                        </p>
                        <p
                          style={{
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          <br />
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '2pt',
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          NDRU által működtetett szerver
                        </p>
                      </td>
                      <td style={{ width: '159pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'left',
                          }}
                        >
                          Leírás
                        </p>
                        <p
                          style={{
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          <br />
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          Microsoft Azure Szerver,
                          tárhelyszolgáltatás
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          NDRU platform funkcionalitására biztosított szerver.
                          Elérhető a https://server.drivendru.com/ címen.
                        </p>
                      </td>
                      <td style={{ width: '157pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'left',
                          }}
                        >
                          Státusz
                        </p>
                        <p
                          style={{
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          <br />
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          Adatfeldolgozó
                        </p>
                        <p
                          style={{
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          <br />
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  style={{
                    paddingTop: '4pt',
                    paddingLeft: '16pt',
                    textIndent: '0pt',
                    textAlign: 'justify',
                  }}
                >
                  18 D18 P521
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
              </li>
              <li data-list-text='2.2'>
                <p
                  className='s1'
                  style={{
                    paddingTop: '9pt',
                    paddingLeft: '29pt',
                    textIndent: '-18pt',
                    textAlign: 'left',
                  }}
                >
                  Szolgáltatások nyújtása
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Személyes Adat kezelésének célja
                  <span className='s1'>: </span>
                  <span className='p'>
                    Szervízszolgáltatásokat nyújtó és
                    termékvásárlási lehetőséget biztosító
                    partneri szolgáltatások, futár
                    szolgáltatás, addicionális partneri
                    szolgáltatások (pl. biztosítás, azonnali
                    segítségnyújtás, parkolási
                    szolgáltatások) Platformon keresztül
                    történő elérhetővé tétele, megrendelések
                    kezelése és feldolgozása, Önnel
                    folytatott kommunikáció, e-szervízkönyv
                    biztosítása, helymeghatározások
                    beazonosítása.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    lineHeight: '113%',
                    textAlign: 'justify',
                  }}
                >
                  Adatkezelés jogalapja
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az adatkezelés alapvetően az Ön és az
                    NDRU között létrejött szerződés
                    teljesítéséhez szükséges. Illetve az Ön
                    Személyes Adatait az NDRU a jogos érdeke
                    alapján kezeli. Az NDRU jogos érdeke,
                    hogy az Ön kéréseinek megfelelő
                    szolgáltatást nyújtsa.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Kezelt Személyes Adatok kategóriái
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az Ön által regisztrációkor megadott
                    adatok (ld. 2.1 pont), helymeghatározási
                    adatok, gépjárműre vonatkozó adatok,
                    gépjármű típusa, rendszám, alvázszám,
                    motorszám, forgalmi száma, törzskönyv
                    száma, számlázási adatok (név, adószám,
                    cím), szerviztörténet, márka, típus,
                    gyártási év, hengerűrtartam,
                    teljesítmény, üzemanyag, gumiméret, km
                    állás, szervízelési adatok,
                    szervízelőzményekre vonatkozó adatok,
                    alkatrészek mennyisége, értéke,
                    munkadíjjak, egyéb költségek, autó
                    meglévő hibái, autó elhárított hibái, km
                    óra állás, alvázszám, motorszám, ccm,
                    kw, típus, márka.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    lineHeight: '113%',
                    textAlign: 'justify',
                  }}
                >
                  Személyes adatok tárolásának időtartama
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az NDRU az adatkezelési cél eléréséhez
                    szükséges ideig legfeljebb a
                    felhasználói fiók törlésééig kezeli a
                    Személyes Adatokat.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    textAlign: 'justify',
                  }}
                >
                  Személyes Adatok címzettjei,
                  adatfeldolgozók
                  <span className='s1'>:</span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <table
                  style={{
                    borderCollapse: 'collapse',
                    marginLeft: '13.9pt',
                  }}
                  cellSpacing={0}
                >
                  <tbody>
                    <tr style={{ height: '129pt' }}>
                      <td style={{ width: '157pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '2pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'left',
                          }}
                        >
                          Név/kategória
                        </p>
                        <p
                          style={{
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          <br />
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '2pt',
                            paddingRight: '5pt',
                            textIndent: '0pt',
                            lineHeight: '113%',
                            textAlign: 'justify',
                          }}
                        >
                          Szervízszolgáltatásokat nyújtó és
                          termékvásárlási lehetőséget
                          biztosító partnerek
                        </p>
                        <p
                          style={{
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          <br />
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '2pt',
                            paddingRight: '5pt',
                            textIndent: '0pt',
                            lineHeight: '113%',
                            textAlign: 'justify',
                          }}
                        >
                          Addicionális partneri
                          szolgáltatásokat (pl. biztosítás,
                          azonnali segítségnyújtás) nyújtó
                          partnerek
                        </p>
                      </td>
                      <td style={{ width: '159pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'left',
                          }}
                        >
                          Leírás
                        </p>
                        <p
                          style={{
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          <br />
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '5pt',
                            paddingRight: '5pt',
                            textIndent: '0pt',
                            lineHeight: '113%',
                            textAlign: 'justify',
                          }}
                        >
                          A megrendelt szolgáltatások
                          teljesítése érdekében a szükséges
                          adatok átadásra kerülnek
                        </p>
                        <p
                          style={{
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          <br />
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '5pt',
                            paddingRight: '5pt',
                            textIndent: '0pt',
                            lineHeight: '113%',
                            textAlign: 'justify',
                          }}
                        >
                          A megrendelt szolgáltatások
                          teljesítése érdekében a szükséges
                          adatok átadásra kerülnek
                        </p>
                      </td>
                      <td style={{ width: '74pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'left',
                          }}
                        >
                          Státusz
                        </p>
                        <p
                          style={{
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          <br />
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          Adatfeldolgozó
                        </p>
                        <p
                          style={{
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          <br />
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingTop: '9pt',
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          Adatfeldolgozó
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: '44pt' }}>
                      <td style={{ width: '157pt' }}>
                        <p
                          className='s3'
                          style={{
                            paddingTop: '6pt',
                            paddingLeft: '2pt',
                            textIndent: '0pt',
                            lineHeight: '112%',
                            textAlign: 'left',
                          }}
                        >
                          Futár szolgáltatást nyújtó
                          partnerek
                        </p>
                      </td>
                      <td style={{ width: '159pt' }}>
                        <p
                          className='s3'
                          style={{
                            paddingTop: '3pt',
                            paddingLeft: '5pt',
                            paddingRight: '5pt',
                            textIndent: '0pt',
                            lineHeight: '13pt',
                            textAlign: 'justify',
                          }}
                        >
                          A megrendelt szolgáltatások
                          teljesítése érdekében a szükséges
                          adatok átadásra kerülnek
                        </p>
                      </td>
                      <td style={{ width: '74pt' }}>
                        <p
                          className='s3'
                          style={{
                            paddingTop: '5pt',
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          Adatfeldolgozó
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
              </li>
              <li data-list-text='2.3'>
                <p
                  className='s1'
                  style={{
                    paddingLeft: '29pt',
                    textIndent: '-18pt',
                    textAlign: 'left',
                  }}
                >
                  Ügyfélszolgálat, panaszkezelés
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Személyes Adat kezelésének célja
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az Ön az esetlegesen felmerülő
                    panaszainak, reklamációinak vagy más
                    kéréseinek a kezelése az NDRU
                    ügyfélszolgálatán keresztül, ideértve a
                    postai címen, e-mail címen, telefonon és
                    chat funkción keresztül biztosított
                    ügyfélszolgálatot is.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Adatkezelés jogalapja
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az Ön személyes Adatait az NDRU a jogos
                    érdeke alapján kezeli. Az NDRU jogos
                    érdeke, hogy a szolgáltatás hibáit,
                    illetve az Ön panaszait, kéréseit
                    hatékonyan tudja orvosolni, illetve
                  </span>
                </h1>
                <p
                  style={{
                    paddingTop: '4pt',
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  kezelni. Bizonyos esetekben az adatkezelés
                  jogi kötelezettség teljesítéséhez
                  szükséges (pl. rögzített
                </p>
                <p
                  style={{
                    paddingTop: '1pt',
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  hangfelvétel megőrzése).
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Kezelt Személyes Adatok kategóriái
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az Ön által regisztrációkor, vagy a
                    Szolgáltatás használatakor megadott
                    adatok (ld. 2.1 és 2.2. pont), valamint
                    az Ön által küldött üzenetek, illetve
                    panaszok, kérések előterjesztésével,
                    illetve azok kezelésével kapcsolatos
                    adatok.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Személyes adatok tárolásának időtartama{' '}
                  <span className='p'>
                    Az NDRU az Ön panaszaival kapcsolatos
                    Személyes Adatokat a panasz
                    beérkezésétől számított 5 évig őrizi
                    meg. Az NDRU a panasz kezelése és
                    dokumentálása céljából az Ön telefonon
                    keresztül megfogalmazott panaszát
                    rögzíti, a rögzített hangfelvételt a
                    rögzítéstől számított 5 évig megőrzi.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Személyes Adatok címzettjei,
                  adatfeldolgozók
                  <span className='s1'>:</span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <table
                  style={{
                    borderCollapse: 'collapse',
                    marginLeft: '8.48008pt',
                  }}
                  cellSpacing={0}
                >
                  <tbody>
                    <tr style={{ height: '12pt' }}>
                      <td style={{ width: '114pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '7pt',
                            textIndent: '0pt',
                            lineHeight: '10pt',
                            textAlign: 'left',
                          }}
                        >
                          Név/kategória
                        </p>
                      </td>
                      <td style={{ width: '171pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '53pt',
                            textIndent: '0pt',
                            lineHeight: '10pt',
                            textAlign: 'left',
                          }}
                        >
                          Leírás
                        </p>
                      </td>
                      <td style={{ width: '120pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '42pt',
                            textIndent: '0pt',
                            lineHeight: '10pt',
                            textAlign: 'left',
                          }}
                        >
                          Státusz
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: '17pt' }}>
                      <td style={{ width: '114pt' }}>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '2pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'left',
                          }}
                        >
                          Érintett szerviz
                        </p>
                      </td>
                      <td style={{ width: '171pt' }}>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '32pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'left',
                          }}
                        >
                          Panaszok kivizsgálása
                        </p>
                      </td>
                      <td style={{ width: '120pt' }}>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '41pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'left',
                          }}
                        >
                          Közös adatkezelő
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: '17pt' }}>
                      <td style={{ width: '114pt' }}>
                        <p
                          className='s3'
                          style={{
                            paddingTop: '4pt',
                            paddingLeft: '2pt',
                            textIndent: '0pt',
                            lineHeight: '10pt',
                            textAlign: 'left',
                          }}
                        >
                          Futár szolgáltatók
                        </p>
                      </td>
                      <td style={{ width: '171pt' }}>
                        <p
                          className='s3'
                          style={{
                            paddingTop: '4pt',
                            paddingLeft: '32pt',
                            textIndent: '0pt',
                            lineHeight: '10pt',
                            textAlign: 'left',
                          }}
                        >
                          Panaszok kivizsgálása
                        </p>
                      </td>
                      <td style={{ width: '120pt' }}>
                        <p
                          className='s3'
                          style={{
                            paddingTop: '4pt',
                            paddingLeft: '41pt',
                            textIndent: '0pt',
                            lineHeight: '10pt',
                            textAlign: 'left',
                          }}
                        >
                          Közös adatkezelő
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Addicionális partneri szolgáltatók
                  Panaszok kivizsgálása Közös adatkezelő
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  (pl. biztosítás, azonnali segítségnyújtás)
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
              </li>
              <li data-list-text='2.4'>
                <p
                  className='s1'
                  style={{
                    paddingLeft: '29pt',
                    textIndent: '-18pt',
                    textAlign: 'left',
                  }}
                >
                  Díjak beszedése
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Személyes Adat kezelésének célja
                  <span className='s1'>: </span>
                  <span className='p'>
                    A megrendelés fizetési szolgáltatón
                    keresztül történő bankkártyás,
                  </span>
                </h1>
                <p
                  style={{
                    paddingTop: '1pt',
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  banki átutalás vagy Apple Pay fizetési
                  eszköz útján történő kifizetésének
                  biztosítása.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    lineHeight: '113%',
                    textAlign: 'justify',
                  }}
                >
                  Adatkezelés jogalapja
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az Ön személyes Adatait az NDRU a jogos
                    érdeke alapján kezeli. Az NDRU jogos
                    érdeke, hogy Szolgáltatás ellenében a
                    díjak megfizetését biztosítása, a
                    díjakat beszedje.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Kezelt Személyes Adatok kategóriái
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az Ön által regisztrációkor megadott,
                    vagy a Szolgáltatás használatakor
                    megadott adatok (ld. 2.1 és 2.2. pont),
                    fizetési módra vonatkozó adatok. Az NDRU
                    az Ön bankkártyaadatait rögzíti, de nem
                    tárolja. A bankkártyaadatokhoz az NDRU
                    nem fér hozzá, azokat kezelni kizárólag
                    a fizetési szolgáltatók jogosultak.
                    Önnek lehetősége van elmentenie a
                    bankkártyájának az adatait (bankkártya
                    száma, bankkártyára írt név, lejárat
                    dátuma, CVC szám) annak érdekében, hogy
                    a jövőben kezdeményezett Megrendelések
                    alkalmával ne kelljen újra megadnia ezen
                    adatokat. Mentés esetén minden adat ún.
                    ”tokenként” (vissza nem fejthető
                    kódként) kerül tárolásra, a későbbi
                    rendeléskor csak a token kerül
                    felhasználásra és továbbításra.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    lineHeight: '113%',
                    textAlign: 'justify',
                  }}
                >
                  Személyes adatok tárolásának időtartama
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az NDRU az adatkezelési cél eléréséhez
                    szükséges ideig legfeljebb a
                    felhasználói fiók törlésééig kezeli a
                    Személyes Adatokat.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Személyes Adatok címzettjei,
                  adatfeldolgozók
                  <span className='s1'>:</span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <table
                  style={{
                    borderCollapse: 'collapse',
                    marginLeft: '13.9pt',
                  }}
                  cellSpacing={0}
                >
                  <tbody>
                    <tr style={{ height: '103pt' }}>
                      <td style={{ width: '153pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '2pt',
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          Név/kategória
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '2pt',
                            textIndent: '0pt',
                            lineHeight: '114%',
                            textAlign: 'left',
                          }}
                        >
                          Stripe Inc., nyilvántartási szám:
                          114218795 , székhely: 510 Townsend
                          Street San Francisco, CA 94103
                          United States
                        </p>
                      </td>
                      <td style={{ width: '163pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '9pt',
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          Leírás
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '9pt',
                            paddingRight: '5pt',
                            textIndent: '0pt',
                            textAlign: 'justify',
                          }}
                        >
                          Stripe fizetési rendszer
                          használata esetén a bankkártya
                          adatok beírására és mentésére az
                          NDRU felületén kerül sor
                          átirányítás nélkül, azonban a
                          bankkártyaadatok fokozott
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '9pt',
                            paddingRight: '5pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'justify',
                          }}
                        >
                          biztonságú ügyféloldali
                          titkosítási technológiának (CSE)
                        </p>
                      </td>
                      <td style={{ width: '157pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'left',
                          }}
                        >
                          Státusz
                        </p>
                        <p
                          style={{
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          <br />
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          Adatfeldolgozó
                        </p>
                        <p
                          style={{
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          <br />
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '5pt',
                            paddingRight: '2pt',
                            textIndent: '0pt',
                            lineHeight: '113%',
                            textAlign: 'justify',
                          }}
                        >
                          NDRU garanciák EGT-n kívülre
                          történő adattovábbítás esetén:
                          általános adatvédelmi kikötések
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  style={{
                    paddingTop: '4pt',
                    paddingLeft: '176pt',
                    textIndent: '0pt',
                    textAlign: 'justify',
                  }}
                >
                  köszönhetően a megrendeléssel titkosításra
                  kerülnek, mely titkosítási kulcshoz csak a
                  fizetési szolgáltató fér hozzá.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
              </li>
              <li data-list-text='2.5'>
                <p
                  className='s1'
                  style={{
                    paddingLeft: '29pt',
                    textIndent: '-18pt',
                    textAlign: 'left',
                  }}
                >
                  Hírlevelek és direkt marketing üzenetek
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    lineHeight: '113%',
                    textAlign: 'justify',
                  }}
                >
                  Személyes Adat kezelésének célja
                  <span className='s1'>: </span>
                  <span className='p'>
                    Direkt marketing megkeresés formájában
                    küldött marketing üzenetek küldése (pl.
                    hírlevél, push üzenet).
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Adatkezelés jogalapja
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az Ön hozzájárulása.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Kezelt Személyes Adatok kategóriái
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az Ön által a regisztráció során
                    megadott adatok (ld. 2.1 és 2.2. pont),
                    gépjárműre vonatkozó adatok,
                    szervízelési adatok/szerviz státusza,
                    szervízelőzményekre vonatkozó adatok,
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Személyes adatok tárolásának időtartama
                  <span className='s1'>: </span>
                  <span className='p'>
                    A vonatkozó Személyes Adatokat a
                    hozzájárulás visszavonásáig (pl.:
                    hírlevélről leiratkozás), de legfeljebb
                    a hozzájárulás megadásától számított 10
                    évig kezeljük.
                  </span>
                </h1>
                <h1
                  style={{
                    paddingTop: '9pt',
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Személyes Adatok címzettjei,
                  adatfeldolgozók
                  <span className='s1'>:</span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <table
                  style={{
                    borderCollapse: 'collapse',
                    marginLeft: '13.9pt',
                  }}
                  cellSpacing={0}
                >
                  <tbody>
                    <tr style={{ height: '12pt' }}>
                      <td style={{ width: '151pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '2pt',
                            textIndent: '0pt',
                            lineHeight: '10pt',
                            textAlign: 'left',
                          }}
                        >
                          Név/kategória
                        </p>
                      </td>
                      <td style={{ width: '165pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '11pt',
                            textIndent: '0pt',
                            lineHeight: '10pt',
                            textAlign: 'left',
                          }}
                        >
                          Leírás
                        </p>
                      </td>
                      <td style={{ width: '157pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            lineHeight: '10pt',
                            textAlign: 'left',
                          }}
                        >
                          Státusz
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: '103pt' }}>
                      <td style={{ width: '151pt' }}>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '2pt',
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          MailChimp c/o The Rocket Science
                          Group, LLC., Székhely:{' '}
                          <span className='s5'>
                            675 Ponce De Leon Ave NE Suite
                            5000 Atlanta, GA 30308 USA
                          </span>
                        </p>
                        <p
                          style={{
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          <br />
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '2pt',
                            paddingRight: '35pt',
                            textIndent: '0pt',
                            textAlign: 'justify',
                          }}
                        >
                          Buffer Inc., Székhely: San
                          Francisco, CA, USA 2243 Fillmore
                          St #380-7163
                        </p>
                      </td>
                      <td style={{ width: '165pt' }}>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '11pt',
                            paddingRight: '5pt',
                            textIndent: '0pt',
                            textAlign: 'justify',
                          }}
                        >
                          E-Mail címek és egyéb
                          kapcsolattartási adatok átadása
                          hírlevél küldése céljából
                        </p>
                        <p
                          style={{
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          <br />
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '11pt',
                            paddingRight: '5pt',
                            textIndent: '0pt',
                            textAlign: 'justify',
                          }}
                        >
                          E-Mail címek és egyéb
                          kapcsolattartási adatok átadása
                          hírlevél küldése céljából
                        </p>
                      </td>
                      <td style={{ width: '157pt' }}>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'left',
                          }}
                        >
                          Adatfeldolgozó
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '5pt',
                            paddingRight: '2pt',
                            textIndent: '0pt',
                            textAlign: 'justify',
                          }}
                        >
                          NDRU garanciák EGT-n kívülre
                          történő adattovábbítás esetén:
                          általános adatvédelmi kikötések
                        </p>
                        <p
                          style={{
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          <br />
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          Adatfeldolgozó
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'left',
                          }}
                        >
                          NDRU garanciák EGT-n kívülre
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'left',
                          }}
                        >
                          történő adattovábbítás esetén:
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            lineHeight: '10pt',
                            textAlign: 'left',
                          }}
                        >
                          általános adatvédelmi kikötések
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <span />
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
              </li>
              <li data-list-text='2.6'>
                <p
                  className='s1'
                  style={{
                    paddingLeft: '29pt',
                    textIndent: '-18pt',
                    textAlign: 'left',
                  }}
                >
                  Értékelés (rating)
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Személyes Adat kezelésének célja
                  <span className='s1'>: </span>
                  <span className='p'>
                    A Szolgáltatás minőségére vonatkozó
                    információk alapján a
                  </span>
                </h1>
                <p
                  style={{
                    paddingTop: '1pt',
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Szolgáltatás fejlesztése, felhasználói
                  elégedettség mérése, illetve növelése.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Adatkezelés jogalapja
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az Ön Személyes Adatait az NDRU a jogos
                    érdeke alapján kezeli. Az NDRU jogos
                  </span>
                </h1>
                <p
                  style={{
                    paddingTop: '1pt',
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  érdeke, hogy a magas minőségű
                  szolgáltatást nyújtson.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    lineHeight: '113%',
                    textAlign: 'justify',
                  }}
                >
                  Kezelt Személyes Adatok kategóriái
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az Ön által a regisztráció során
                    megadott adatok (ld. 2.1 és 2.2. pont),
                    értékelés, megjegyzések, teljesítési
                    idő, elégedettségre vonatkozó adatok,
                    gépjárműre vonatkozó adatok, igénybe
                    vett vagy megrendelt szolgáltatás.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Személyes adatok tárolásának időtartama
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az NDRU az Ön értékelésével kapcsolatos
                    Személyes
                  </span>
                </h1>
                <p
                  style={{
                    paddingTop: '1pt',
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Adatait az értékelés beérkezésétől
                  számított 5 évig őrizi meg.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Személyes Adatok címzettjei,
                  adatfeldolgozók:
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <table
                  style={{
                    borderCollapse: 'collapse',
                    marginLeft: '13.9pt',
                  }}
                  cellSpacing={0}
                >
                  <tbody>
                    <tr style={{ height: '12pt' }}>
                      <td style={{ width: '119pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '2pt',
                            textIndent: '0pt',
                            lineHeight: '10pt',
                            textAlign: 'left',
                          }}
                        >
                          Név/kategória
                        </p>
                      </td>
                      <td style={{ width: '139pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '43pt',
                            textIndent: '0pt',
                            lineHeight: '10pt',
                            textAlign: 'left',
                          }}
                        >
                          Leírás
                        </p>
                      </td>
                      <td style={{ width: '104pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '63pt',
                            textIndent: '0pt',
                            lineHeight: '10pt',
                            textAlign: 'left',
                          }}
                        >
                          Státusz
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Érintett szerviz Szolgáltatások értékelése
                  Közös adatkezelő
                </p>
                <p
                  style={{
                    paddingTop: '4pt',
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Futár szolgáltatók Szolgáltatások
                  értékelése Közös adatkezelő
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Addicionális partneri szolgáltatók
                  Szolgáltatások értékelése Közös adatkezelő
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
              </li>
              <li data-list-text='2.7'>
                <p
                  className='s1'
                  style={{
                    paddingTop: '7pt',
                    paddingLeft: '28pt',
                    textIndent: '-17pt',
                    textAlign: 'left',
                  }}
                >
                  Jogi igények érvényesítése
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Személyes Adat kezelésének célja
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az NDRU jogi igényeinek az
                    előterjesztése, érvényesítése és
                  </span>
                </h1>
                <p
                  style={{
                    paddingTop: '1pt',
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  védelme.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    lineHeight: '112%',
                    textAlign: 'justify',
                  }}
                >
                  Adatkezelés jogalapja
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az Ön személyes Adatait az NDRU a jogos
                    érdeke alapján kezeli. Az NDRU jogos
                    érdeke, hogy az NDRU jogi igényeit
                    érvényesíteni tudja.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    lineHeight: '113%',
                    textAlign: 'justify',
                  }}
                >
                  Kezelt Személyes Adatok kategóriái
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az Ön által a regisztráció során
                    megadott adatok (ld. 2.1 és 2.2. pont),
                    gépjárműre vonatkozó adatok,
                    szervízelési adatok, szervízelőzményekre
                    vonatkozó adatok, az Ön panaszaival,
                    azok kezelésével kapcsolatos adatok, a
                    díjak számlázásával, valamint a díjak
                    beszedésével kapcsolatos adatok, egyéb a
                    jogi igény érvényesítéséhez szükséges
                    Személyes Adatok.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    lineHeight: '112%',
                    textAlign: 'justify',
                  }}
                >
                  Személyes adatok tárolásának időtartama
                  <span className='s1'>: </span>
                  <span className='p'>
                    A vonatkozó Személyes Adatokat a
                    felhasználói fiók törlését követő 5 évig
                    kezeljük.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '10pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Személyes Adatok címzettjei,
                  adatfeldolgozók
                  <span className='s1'>:</span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <table
                  style={{
                    borderCollapse: 'collapse',
                    marginLeft: '13.9pt',
                  }}
                  cellSpacing={0}
                >
                  <tbody>
                    <tr style={{ height: '12pt' }}>
                      <td style={{ width: '157pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '2pt',
                            textIndent: '0pt',
                            lineHeight: '10pt',
                            textAlign: 'left',
                          }}
                        >
                          Név/kategória
                        </p>
                      </td>
                      <td style={{ width: '159pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            lineHeight: '10pt',
                            textAlign: 'left',
                          }}
                        >
                          Leírás
                        </p>
                      </td>
                      <td style={{ width: '62pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            lineHeight: '10pt',
                            textAlign: 'left',
                          }}
                        >
                          Státusz
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: '35pt' }}>
                      <td style={{ width: '157pt' }}>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '2pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'left',
                          }}
                        >
                          Jogi képviselők, tanácsadók,
                        </p>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '2pt',
                            textIndent: '0pt',
                            textAlign: 'left',
                          }}
                        >
                          hatóságok, bíróságok
                        </p>
                      </td>
                      <td style={{ width: '159pt' }}>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '5pt',
                            paddingRight: '5pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'justify',
                          }}
                        >
                          Jogi igény érvényesítésével
                          kapcsolatban az adatok
                          továbbításra kerülnek
                        </p>
                      </td>
                      <td style={{ width: '62pt' }}>
                        <p
                          className='s3'
                          style={{
                            paddingLeft: '5pt',
                            textIndent: '0pt',
                            lineHeight: '11pt',
                            textAlign: 'left',
                          }}
                        >
                          Adatkezelők
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
              </li>
              <li data-list-text='2.8'>
                <p
                  className='s1'
                  style={{
                    paddingLeft: '29pt',
                    textIndent: '-18pt',
                    textAlign: 'left',
                  }}
                >
                  Üzleti teljesítmény mérése, menedzsment
                  döntések meghozatala
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Személyes Adat kezelésének célja
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az NDRU statisztikákat vezet arra
                    vonatkozóan, hogy Ön milyen szervizben
                    volt, hányszor, milyen összeget fizetett
                    (munkadíj, illetve alkatrész díja és
                    mennyi volt abból az NDRU jutaléka),
                    milyen alkatrészeket cseréletek, milyen
                    alkatrészek nem lettek kicserélve annak
                    érdekében, hogy az üzleti teljesítményét
                    pontosan mérje és ebből kiindulva
                    megfelelő menedzsment döntéseket hozzon,
                    és így minél magasabb minőségű
                    szolgáltatást nyújtson.
                  </span>
                </h1>
                <h1
                  style={{
                    paddingTop: '9pt',
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Adatkezelés jogalapja
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az Ön Személyes Adatait az NDRU a jogos
                    érdeke alapján kezeli. Az NDRU jogos
                    érdeke, hogy az üzleti teljesítményét
                    pontosan mérje és ebből kiindulva
                    megfelelő menedzsment döntéseket hozzon,
                    és így minél magasabb minőségű
                    szolgáltatást nyújtson.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Kezelt Személyes Adatok kategóriái
                  <span className='s1'>: </span>
                  <span className='p'>
                    Arra vonatkozó adatok, hogy Ön milyen
                    szervizben volt, hányszor, milyen
                    összeget fizetett (munkadíj, illetve
                    alkatrész díja és mennyi volt abból az
                    NDRU jutaléka), milyen alkatrészeket
                    cseréletek, milyen alkatrészek nem
                    lettek kicserélve.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    lineHeight: '113%',
                    textAlign: 'justify',
                  }}
                >
                  Személyes adatok tárolásának időtartama
                  <span className='s1'>: </span>
                  <span className='p'>
                    Az NDRU a fenti statisztikákkal
                    kapcsolatos Személyes Adatait az adott
                    szervizelés teljesítésétől számított 3
                    évig őrizi meg.
                  </span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <h1
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Személyes Adatok címzettjei,
                  adatfeldolgozók
                  <span className='s1'>:</span>
                </h1>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <table
                  style={{
                    borderCollapse: 'collapse',
                    marginLeft: '13.9pt',
                  }}
                  cellSpacing={0}
                >
                  <tbody>
                    <tr style={{ height: '12pt' }}>
                      <td style={{ width: '119pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '2pt',
                            textIndent: '0pt',
                            lineHeight: '10pt',
                            textAlign: 'left',
                          }}
                        >
                          Név/kategória
                        </p>
                      </td>
                      <td style={{ width: '139pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '43pt',
                            textIndent: '0pt',
                            lineHeight: '10pt',
                            textAlign: 'left',
                          }}
                        >
                          Leírás
                        </p>
                      </td>
                      <td style={{ width: '104pt' }}>
                        <p
                          className='s2'
                          style={{
                            paddingLeft: '63pt',
                            textIndent: '0pt',
                            lineHeight: '10pt',
                            textAlign: 'left',
                          }}
                        >
                          Státusz
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  style={{
                    paddingLeft: '11pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <span
                    className='s6'
                    style={{ backgroundColor: '#FF0' }}
                  >
                    [*]
                  </span>
                  <span className='s1'> </span>
                  <span
                    className='s6'
                    style={{ backgroundColor: '#FF0' }}
                  >
                    [*]
                  </span>
                  <span className='s1'> </span>
                  <span
                    className='s6'
                    style={{ backgroundColor: '#FF0' }}
                  >
                    [*]
                  </span>
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
              </li>
            </ol>
          </li>
          <li data-list-text={3}>
            <p
              className='s1'
              style={{
                paddingLeft: '25pt',
                textIndent: '-14pt',
                textAlign: 'left',
              }}
            >
              Az NDRU általi adatkezeléssel kapcsolatban Önt
              megillető jogok
            </p>
            <p
              style={{
                paddingTop: '3pt',
                paddingLeft: '11pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Az NDRU által végzett adatkezelési tevékenység
              vonatkozásában Önt az alábbi jogok illetik
              meg:
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <ul id='l4'>
              <li data-list-text='●'>
                <p
                  className='s7'
                  style={{
                    paddingLeft: '46pt',
                    textIndent: '-18pt',
                    textAlign: 'left',
                  }}
                >
                  A hozzáférés joga
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '46pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Ön hozzáférést kérhet az NDRU-tól az NDRU
                  által kezelt Személyes Adatai
                  tekintetében, valamint tájékoztatást
                  kérhet az adatkezelés céljáról, a kezelt
                  adatok kategóriáiról, az adattovábbítás
                  címzettjeiről, továbbá az adatkezelés
                  időtartamáról, az Önt megillető jogokról a
                  Személyes Adatok NDRU által történő
                  kezelése tekintetében, illetve az
                  adatvédelmi hatósághoz forduláshoz fűződő
                  jogáról. Továbbá, abban az esetben, ha a
                  Személyes Adatait nem Öntől, hanem más
                  forrásból gyűjti az NDRU, joga van
                  tájékoztatást kapni az adatok forrásáról,
                  valamint arról, hogy az NDRU alkalmaz-e
                  automatikus döntéshozatali vagy hasonló
                  eljárásokat, ideértve azok működésének
                  logikáját, valamint az ilyen formában
                  történő adatkezelés jelentőségét,
                  valamint, hogy ez Önre nézve milyen
                  várható következményekkel jár.
                  Tájékoztatást kérhet továbbá arról, hogy
                  az NDRU továbbítja-e, és milyen
                  feltételekkel a Személyes Adatait az EGT-n
                  kívülre.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '46pt',
                    textIndent: '0pt',
                    textAlign: 'justify',
                  }}
                >
                  Amennyiben ezt kéri, az NDRU által kezelt
                  Személyes Adatairól másolatot biztosítunk
                  Önnek.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
              </li>
              <li data-list-text='●'>
                <p
                  className='s7'
                  style={{
                    paddingLeft: '46pt',
                    textIndent: '-18pt',
                    textAlign: 'left',
                  }}
                >
                  helyesbítéshez való jog
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '46pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Ön jogosult arra, hogy kérésére az NDRU
                  helyesbítse az Önre vonatkozó pontatlan
                  személyes adatokat. Jogosult arra, hogy
                  kérje a hiányos személyes adatok
                  kiegészítő nyilatkozat útján történő
                  kiegészítését.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
              </li>
              <li data-list-text='●'>
                <p
                  className='s7'
                  style={{
                    paddingLeft: '46pt',
                    textIndent: '-18pt',
                    textAlign: 'left',
                  }}
                >
                  törléshez való jog
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '46pt',
                    textIndent: '0pt',
                    lineHeight: '113%',
                    textAlign: 'justify',
                  }}
                >
                  Kérésére Személyes Adatait az NDRU
                  haladéktalanul töröli, ha az alábbi
                  indokok valamelyike fennáll:
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <ol id='l5'>
                  <li data-list-text='a)'>
                    <p
                      style={{
                        paddingLeft: '118pt',
                        textIndent: '-18pt',
                        textAlign: 'left',
                      }}
                    >
                      a személyes adatokra már nincs szükség
                      abból a célból, amelyből azokat
                      gyűjtötték;
                    </p>
                  </li>
                  <li data-list-text='b)'>
                    <p
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '118pt',
                        textIndent: '-18pt',
                        textAlign: 'left',
                      }}
                    >
                      Ön visszavonja az adatkezelés alapját
                      képező hozzájárulását, és az
                      adatkezelésnek
                    </p>
                    <p
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '118pt',
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      nincs más jogalapja;
                    </p>
                  </li>
                  <li data-list-text='c)'>
                    <p
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '118pt',
                        textIndent: '-18pt',
                        textAlign: 'left',
                      }}
                    >
                      Ön tiltakozik az adatkezelés ellen, és
                      nincs elsőbbséget élvező jogszerű ok
                      az
                    </p>
                    <p
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '118pt',
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      adatkezelésre;
                    </p>
                  </li>
                  <li data-list-text='d)'>
                    <p
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '118pt',
                        textIndent: '-18pt',
                        textAlign: 'left',
                      }}
                    >
                      a Személyes Adatainak kezelése
                      jogellenes;
                    </p>
                  </li>
                  <li data-list-text='e)'>
                    <p
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '118pt',
                        textIndent: '-18pt',
                        textAlign: 'left',
                      }}
                    >
                      a Személyes Adatait az alkalmazandó
                      jog alapján törölni kell.
                    </p>
                    <p
                      style={{
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      <br />
                    </p>
                    <p
                      style={{
                        paddingLeft: '46pt',
                        textIndent: '0pt',
                        lineHeight: '113%',
                        textAlign: 'justify',
                      }}
                    >
                      Kérjük, vegye figyelembe, hogy az NDRU
                      jogosult megtagadni a Személyes Adatai
                      törlését, amennyiben azok szükségesek
                      – többek között – a
                      véleménynyilvánítás és a tájékozódás
                      szabadságának biztosításához, jogi
                      kötelezettség teljesítéséhez vagy jogi
                      igények előterjesztése, érvényesítése,
                      illetve védelme céljából.
                    </p>
                    <p
                      style={{
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      <br />
                    </p>
                  </li>
                </ol>
              </li>
              <li data-list-text='●'>
                <p
                  className='s7'
                  style={{
                    paddingLeft: '46pt',
                    textIndent: '-18pt',
                    textAlign: 'left',
                  }}
                >
                  Az adatkezelés korlátozásához való jog
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '46pt',
                    textIndent: '0pt',
                    textAlign: 'justify',
                  }}
                >
                  Ön jogosult arra, hogy az adatkezelés
                  korlátozását kérje, ha az alábbi
                  feltételek valamelyike teljesül:
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <ol id='l6'>
                  <li data-list-text='a)'>
                    <p
                      style={{
                        paddingLeft: '82pt',
                        textIndent: '-18pt',
                        lineHeight: '114%',
                        textAlign: 'left',
                      }}
                    >
                      Ön vitatja a személyes adatok
                      pontosságát, ez esetben a korlátozás
                      arra az időtartamra vonatkozik, amely
                      lehetővé teszi a személyes adatok
                      pontosságának ellenőrzését;
                    </p>
                  </li>
                  <li data-list-text='b)'>
                    <p
                      style={{
                        paddingLeft: '82pt',
                        textIndent: '-18pt',
                        lineHeight: '113%',
                        textAlign: 'left',
                      }}
                    >
                      az adatkezelés jogellenes és Ön
                      ellenzi az adatok törlését, és e
                      helyett kéri azok felhasználásának
                      korlátozását;
                    </p>
                  </li>
                  <li data-list-text='c)'>
                    <p
                      style={{
                        paddingLeft: '82pt',
                        textIndent: '-18pt',
                        textAlign: 'left',
                      }}
                    >
                      már nincs szüksége az NDRU-nak a
                      Személyes Adatokra az adatkezelés
                      céljából, de Ön
                    </p>
                    <p
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '82pt',
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      igényli azokat jogi igények
                      előterjesztéséhez, érvényesítéséhez
                      vagy védelméhez;
                    </p>
                  </li>
                  <li data-list-text='d)'>
                    <p
                      style={{
                        paddingTop: '4pt',
                        paddingLeft: '82pt',
                        textIndent: '-18pt',
                        lineHeight: '114%',
                        textAlign: 'justify',
                      }}
                    >
                      ha Ön tiltakozott az adatkezelés
                      ellen; ez esetben a korlátozás arra az
                      időtartamra vonatkozik, amíg
                      megállapításra nem kerül, hogy az NDRU
                      jogos indokai elsőbbséget élveznek-e
                      az Ön jogos indokaival szemben.
                    </p>
                    <p
                      style={{
                        textIndent: '0pt',
                        textAlign: 'left',
                      }}
                    >
                      <br />
                    </p>
                    <p
                      style={{
                        paddingLeft: '46pt',
                        textIndent: '0pt',
                        lineHeight: '114%',
                        textAlign: 'justify',
                      }}
                    >
                      Ha az adatkezelés korlátozás alá esik,
                      az ilyen személyes adatokat a tárolás
                      kivételével csak az Ön
                      hozzájárulásával, vagy jogi igények
                      előterjesztéséhez, érvényesítéséhez
                      vagy védelméhez, vagy más természetes
                      vagy jogi személy jogainak védelme
                      érdekében, vagy az Európai Unió,
                      illetve valamely tagállam fontos
                      közérdekéből lehet kezelni.
                    </p>
                  </li>
                </ol>
              </li>
              <li data-list-text='●'>
                <p
                  className='s7'
                  style={{
                    paddingTop: '9pt',
                    paddingLeft: '46pt',
                    textIndent: '-18pt',
                    textAlign: 'left',
                  }}
                >
                  tiltakozáshoz való jog
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '46pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Amennyiben az NDRU a Személyes Adatait
                  közvetlen üzletszerzés céljából kezeli,
                  Önnek jogában áll az adatok ilyen célú
                  kezelésével szemben tiltakozni, mely
                  esetben az NDRU a Személyes Adatait a
                  továbbiakban e célból nem kezelheti.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '46pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Önnek ezen felül általános joga az
                  adatkezeléssel szemben tiltakozni,
                  amennyiben az adatkezelési tevékenység
                  jogalapja az NDRU jogos érdeke vagy
                  harmadik fél jogos érdeke. Ebben az
                  esetben az NDRU a Személyes Adatokat nem
                  kezelheti tovább, kivéve, ha az NDRU
                  bizonyítja, hogy az adatkezelést olyan
                  kényszerítő erejű jogos okok indokolják,
                  amelyek elsőbbséget élveznek az Ön
                  érdekeivel, jogaival és szabadságaival
                  szemben, vagy amelyek jogi igények
                  előterjesztéséhez, érvényesítéséhez vagy
                  védelméhez kapcsolódnak.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
              </li>
              <li data-list-text='●'>
                <p
                  className='s7'
                  style={{
                    paddingLeft: '46pt',
                    textIndent: '-18pt',
                    textAlign: 'left',
                  }}
                >
                  adathordozhatósághoz való jog
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '46pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'justify',
                  }}
                >
                  Jogosult arra, hogy az Ön által az NDRU
                  rendelkezésére bocsátott Személyes
                  Adatokat tagolt, széles körben használt,
                  géppel olvasható formátumban megkapja,
                  továbbá jogosult arra, hogy ezeket az
                  adatokat az NDRU közvetlenül egy másik
                  adatkezelőnek továbbítsa, ha az alábbi
                  feltételek teljesülnek:
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <ul id='l7'>
                  <li data-list-text='-'>
                    <p
                      style={{
                        paddingLeft: '64pt',
                        textIndent: '-18pt',
                        lineHeight: '114%',
                        textAlign: 'justify',
                      }}
                    >
                      az adatkezelés hozzájáruláson alapul,
                      vagy olyan szerződés teljesítéséhez
                      szükséges, amelyben Ön az egyik fél,
                      vagy az a szerződés megkötését
                      megelőzően kérésére történő lépések
                      megtételéhez szükséges; és
                    </p>
                  </li>
                  <li data-list-text='-'>
                    <p
                      style={{
                        paddingLeft: '64pt',
                        textIndent: '-18pt',
                        lineHeight: '11pt',
                        textAlign: 'justify',
                      }}
                    >
                      az adatkezelés automatizált módon
                      történik.
                    </p>
                  </li>
                </ul>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
              </li>
              <li data-list-text='●'>
                <p
                  className='s7'
                  style={{
                    paddingLeft: '46pt',
                    textIndent: '-18pt',
                    textAlign: 'left',
                  }}
                >
                  hozzájárulás visszavonásának joga
                </p>
              </li>
            </ul>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '46pt',
                textIndent: '0pt',
                lineHeight: '113%',
                textAlign: 'justify',
              }}
            >
              Önnek joga van bármely időpontban visszavonni
              a hozzájárulását, amely nem érinti a
              hozzájáruláson alapuló, a visszavonás előtti
              adatkezelés jogszerűségét.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '10pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'justify',
              }}
            >
              A fenti jogai gyakorlásával kapcsolatos
              intézkedéseket az NDRU késedelem nélkül, a
              kérelem beérkezésétől számított egy hónapon
              belül megteszi. Szükség esetén, figyelembe
              véve a kérelem összetettségét és a kérelmek
              számát, ez a határidő további két hónappal
              meghosszabbítható, amelyről Önt az okok
              feltüntetésével értesíti az NDRU.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '10pt',
                textIndent: '0pt',
                textAlign: 'justify',
              }}
            >
              Abban az esetben, ha ilyen kérelmet
              elektronikus úton nyújtja be, az NDRU
              lehetőség szerint elektronikus
            </p>
            <p
              style={{
                paddingTop: '1pt',
                paddingLeft: '10pt',
                textIndent: '0pt',
                textAlign: 'justify',
              }}
            >
              úton tájékoztatja Önt, kivéve ha ezt más
              formában kéri.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '10pt',
                textIndent: '0pt',
                lineHeight: '113%',
                textAlign: 'justify',
              }}
            >
              Abban az esetben, ha az NDRU nem teszi meg a
              szükséges intézkedéseket a kérelmére
              vonatkozóan, tájékoztatja Önt az okokról a
              kérelem kézhezvételétől számított egy hónapon
              belül.
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '11pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'justify',
              }}
            >
              Amennyiben az NDRU-nak megalapozott kétségei
              vannak a kérelmet benyújtó természetes személy
              kilétével kapcsolatban, további, az érintett
              személyazonosságának megerősítéséhez szükséges
              információk nyújtását kérheti.
            </p>
          </li>
          <li data-list-text={4}>
            <p
              className='s1'
              style={{
                paddingTop: '9pt',
                paddingLeft: '25pt',
                textIndent: '-14pt',
                textAlign: 'left',
              }}
            >
              Jogorvoslatok
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              className='s9'
              style={{
                paddingLeft: '10pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'justify',
              }}
            >
              <a
                href='http://naih.hu/'
                className='a'
                target='_blank'
              >
                Amennyiben nem ért egyet az NDRU válaszával
                vagy intézkedésével, vagy úgy érzi, hogy
                jogai sérültek, panasszal élhet a Nemzeti
                Adatvédelmi és Információszabadság
                Hatóságnál (cím: H-1055 Budapest, Falk Miksa
                utca 9-11, web:{' '}
              </a>
              http://naih.hu/,{' '}
              <a
                href='mailto:ugyfelszolgalat@naih.hu'
                className='a'
                target='_blank'
              >
                postacím: 1363 Budapest, Pf. 9.; telefon:
                +36-1-391-1400; e-mail:{' '}
              </a>
              <span className='p'>
                ugyfelszolgalat@naih.hu). Személyiségi
                jogainak sérelme esetén a Fővárosi
                Törvényszékhez, vagy az Ön lakóhelye szerint
                illetékes törvényszékhez fordulhat (további
                információ: https://birosag.hu/birosag-
                kereso).
              </span>
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
          </li>
          <li data-list-text={5}>
            <p
              className='s1'
              style={{
                paddingLeft: '25pt',
                textIndent: '-14pt',
                textAlign: 'left',
              }}
            >
              Vegyes rendelkezések
            </p>
          </li>
        </ol>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '11pt',
            textIndent: '0pt',
            lineHeight: '114%',
            textAlign: 'justify',
          }}
        >
          Kérjük, vegye figyelembe, hogy az NDRU a jelen
          Adatkezelési Tájékoztatót rendszeresen
          felülvizsgálhatja, és szükség szerint
          módosíthatja. Abban az esetben, ha az NDRU
          módosítja az Adatkezelési Tájékoztatót, azt a
          szokásos módon bejelenti és közzéteszi (például
          e-mailen vagy a weboldalon). Kérjük, alkalmanként
          tekintse át az NDRU Adatkezelési Tájékoztatóját.
        </p>
      </div>
    </Page>
  );
}

export default NdruPrivacyPolicyHu;
