import { useEffect, useRef, useState } from 'react';
import downArrow from '../assets/images/icons/down-arrow.png';
import downArrowWhite from '../assets/images/icons/down-arrow-white.png';

function Dropdown({
  onChange,
  actState,
  white = false,
  closeSelect,
  usedLanguage,
}) {
  const topics =
    usedLanguage === 'en'
      ? [
          {
            name: 'Repair service provider',
            value: 'Repair service provider',
            id: 'repair-service-provider',
          },
          {
            name: 'Fleet service provider',
            value: 'Fleet service provider',
            id: 'fleet-service-provider',
          },
          {
            name: 'Car wash',
            value: 'Car wash',
            id: 'car-wash',
          },
          {
            name: 'Other service provider: Insurance, assistance, other...',
            value:
              'Other service provider: Insurance, assistance, other...',
            id: 'other',
          },
          {
            name: 'Car owner',
            value: 'Car owner',
            id: 'car-owner',
          },
          {
            name: 'Investor relations',
            value: 'Investor relations',
            id: 'investor-relations',
          },
          {
            name: 'Career',
            value: 'Career ',
            id: 'career',
          },
        ]
      : [
          {
            name: 'Szakszerviz',
            value: 'Repair service provider',
            id: 'repair-service-provider',
          },
          {
            name: 'Flottakezelő',
            value: 'Fleet service provider',
            id: 'fleet-service-provider',
          },
          {
            name: 'Autómosó',
            value: 'Car wash',
            id: 'car-wash',
          },
          {
            name: 'Egyéb szolgáltató (pl. biztosítás, assistance)',
            value:
              'Other service provider: Insurance, assistance, other...',
            id: 'other',
          },
          {
            name: 'Autótulajdonoos',
            value: 'Car owner',
            id: 'car-owner',
          },
          {
            name: 'Befektetői kapcsolatok',
            value: 'Investor relations',
            id: 'investor-relations',
          },
          {
            name: 'Karrier',
            value: 'Career ',
            id: 'career',
          },
        ];
  const select = useRef();
  const topicType = useRef();

  const toggleDropdown = () => {
    select.current.classList.toggle('show');
  };

  useEffect(() => {
    select.current.classList.remove('show');
  }, [closeSelect]);

  const [checkedState, setCheckedState] = useState(
    new Array(topics.length).fill(false)
  );

  if (actState.reset) {
    setCheckedState(new Array(topics.length).fill(false));
  }

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map(
      (item, index) => (index === position ? !item : item)
    );

    select.current.classList.remove('error');

    setCheckedState(updatedCheckedState);

    let selectedTopics = [];
    updatedCheckedState.forEach((item, index) => {
      if (item) {
        selectedTopics.push(topics[index].name);
      }
    });
    onChange(selectedTopics);
  };

  if (actState.hasErrors) {
    // select.current.classList.add('show');
    select.current.classList.add('error');
  }

  return (
    <div ref={select} className='select-topic-type'>
      <div
        className={`errorMessage ${
          actState.hasErrors ? 'show' : ''
        }`}
      >
        {actState.message}
      </div>
      <p
        ref={topicType}
        onClick={toggleDropdown}
        className='topic-type'
      >
        <span>
          {white
            ? usedLanguage === 'en'
              ? 'Topic'
              : 'Szolgáltatástípus'
            : usedLanguage === 'en'
            ? 'Select topic type'
            : 'Válassz a típusok közül:'}
        </span>
        <img
          src={white ? downArrowWhite : downArrow}
          alt=''
        />
      </p>

      {topics.map(({ name, value, id }, index) => {
        return (
          <div key={id} className='checkbox-container'>
            <input
              type='checkbox'
              name='topictype'
              value={value}
              id={id}
              checked={checkedState[index]}
              onChange={() => handleOnChange(index)}
            />
            <label htmlFor={id}>
              <span>{name}</span>
            </label>
          </div>
        );
      })}
    </div>
  );
}
export default Dropdown;
