import React from 'react';

import Page from '../components/Page';

function NdruTermsOfServicesHu() {
  return (
    <Page
      title='Általános Szerződési Feltételek'
      endpoint='NDRU-terms-of-service/hu'
    >
      <div className='container py-5 altalanos-szerzodesi-feltetelek'>
        <h1
          style={{
            paddingTop: '3pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'justify',
          }}
        >
          Általános Szerződési Feltételek
        </h1>
        <h2
          style={{
            paddingTop: '21pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          AZ NDRU SZOLGÁLTATÁSOKAT IGÉNYBE VEVŐ FELHASZNÁLÓK
          RÉSZÉRE
        </h2>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'justify',
          }}
        >
          Hatályos: 2022.05.30 napjától
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingTop: '10pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          Jelen Általános Szerződési Feltételek (<b>ÁSZF</b>
          ) a ndru 1.0. Kft. (<b>NDRU</b>
          <a
            href='http://www.drivendru.com/'
            className='s1'
            target='_blank'
          >
            ) által a{' '}
          </a>
          www.drivendru.com weboldalon, mobilweboldalon, az
          NDRU APP mobil applikáción (<b>Mobil Platform</b>)
          és NDRU Szervíz (<b>Szervíz Platform</b>) és NDRU
          Flotta (<b>Flotta Platform</b>) (együttesen:{' '}
          <b>NDRU Platformok</b>) keresztül nyújtott
          elektronikus kereskedelmi szolgáltatásokat igénybe
          vevő személyek jogait és kötelezettségeit
          tartalmazza. Az NDRU weboldalán, mobilweboldalán
          vagy applikációjában történő regisztráció, és így
          felhasználói fiók létesítése kizárólag akkor
          lehetséges, ha a jelen ÁSZF-et a Felhasználó a
          regisztráció során kifejezetten elfogadja. A
          regisztráció során a jelen ÁSZF elfogadásával a
          Felhasználó és az NDRU között szerződés jön létre
          ÁSZF-ben rögzített tartalommal. Az ÁSZF elfogadó
          nyilatkozatot az NDRU tárolja, a regisztráció az
          ÁSZF elfogadása hiányában nem lehetséges. Az
          ÁSZF-et letölthető formában hozzáférhetővé teszi
          az NDRU a regisztrációs felületen keresztül.
        </p>
        <p
          style={{
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '12pt',
            textAlign: 'justify',
          }}
        >
          Az NDRU adatai:
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <table
          style={{
            borderCollapse: 'collapse',
            marginLeft: '9.5pt',
          }}
          cellSpacing={0}
        >
          <tbody>
            <tr style={{ height: '24pt' }}>
              <td style={{ width: '97pt' }}>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '2pt',
                    textIndent: '0pt',
                    lineHeight: '12pt',
                    textAlign: 'left',
                  }}
                >
                  Cégnév:
                </p>
              </td>
              <td style={{ width: '177pt' }}>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '8pt',
                    textIndent: '0pt',
                    lineHeight: '12pt',
                    textAlign: 'left',
                  }}
                >
                  NDRU 1.0 Kft.
                </p>
              </td>
            </tr>
            <tr style={{ height: '36pt' }}>
              <td style={{ width: '97pt' }}>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '2pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Cégjegyzékszám:
                </p>
              </td>
              <td style={{ width: '177pt' }}>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '8pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Cg. 01-09-374640
                </p>
              </td>
            </tr>
            <tr style={{ height: '36pt' }}>
              <td style={{ width: '97pt' }}>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '2pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Nyilvántartja:
                </p>
              </td>
              <td style={{ width: '177pt' }}>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '8pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Fővárosi Törvényszék Cégbírósága
                </p>
              </td>
            </tr>
            <tr style={{ height: '36pt' }}>
              <td style={{ width: '97pt' }}>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '2pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Székhelye:
                </p>
              </td>
              <td style={{ width: '177pt' }}>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '8pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <a
                    href='https://www.google.hu/maps/place/1221Budapest%2BHaj%C3%B3%2Butca%2B1.%2B'
                    className='s3'
                  >
                    1221 Budapest, Hajó utca 1.
                  </a>
                </p>
              </td>
            </tr>
            <tr style={{ height: '36pt' }}>
              <td style={{ width: '97pt' }}>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '2pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Adószáma:
                </p>
              </td>
              <td style={{ width: '177pt' }}>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '8pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  28802839-2-43
                </p>
              </td>
            </tr>
            <tr style={{ height: '36pt' }}>
              <td style={{ width: '97pt' }}>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '2pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Bankszámlaszám:
                </p>
              </td>
              <td style={{ width: '177pt' }}>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '8pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  12012204-01743883-00100005
                </p>
              </td>
            </tr>
            <tr style={{ height: '24pt' }}>
              <td style={{ width: '97pt' }}>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '2pt',
                    textIndent: '0pt',
                    lineHeight: '11pt',
                    textAlign: 'left',
                  }}
                >
                  Postacíme:
                </p>
              </td>
              <td style={{ width: '177pt' }}>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '8pt',
                    textIndent: '0pt',
                    lineHeight: '11pt',
                    textAlign: 'left',
                  }}
                >
                  <a
                    href='https://www.google.hu/maps/place/1221Budapest%2BHaj%C3%B3%2Butca%2B1.%2B'
                    className='s3'
                  >
                    1221 Budapest, Hajó utca 1.
                  </a>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <table
          style={{
            borderCollapse: 'collapse',
            marginLeft: '9.5pt',
          }}
          cellSpacing={0}
        >
          <tbody>
            <tr style={{ height: '18pt' }}>
              <td style={{ width: '76pt' }}>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '2pt',
                    textIndent: '0pt',
                    lineHeight: '12pt',
                    textAlign: 'left',
                  }}
                >
                  E-mail:
                </p>
              </td>
              <td style={{ width: '126pt' }}>
                <p
                  style={{
                    paddingRight: '2pt',
                    textIndent: '0pt',
                    lineHeight: '12pt',
                    textAlign: 'right',
                  }}
                >
                  <a
                    href='mailto:info@drivendru.com'
                    className='s3'
                  >
                    info@drivendru.com
                  </a>
                </p>
              </td>
            </tr>
            <tr style={{ height: '18pt' }}>
              <td style={{ width: '76pt' }}>
                <p
                  className='s2'
                  style={{
                    paddingTop: '5pt',
                    paddingLeft: '2pt',
                    textIndent: '0pt',
                    lineHeight: '11pt',
                    textAlign: 'left',
                  }}
                >
                  Honlapja:
                </p>
              </td>
              <td style={{ width: '126pt' }}>
                <p
                  style={{
                    paddingTop: '5pt',
                    paddingRight: '4pt',
                    textIndent: '0pt',
                    lineHeight: '11pt',
                    textAlign: 'right',
                  }}
                >
                  <a
                    href='http://www.drivendru.com/'
                    className='s3'
                  >
                    www.drivendru.com
                  </a>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingTop: '4pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          A működés és a panaszkezelés helye a székhelyen
          van.
        </p>
        <ol id='l1'>
          <li data-list-text={1}>
            <h3
              style={{
                paddingTop: '4pt',
                paddingLeft: '17pt',
                textIndent: '-11pt',
                textAlign: 'left',
              }}
            >
              DEFINÍCIÓK
            </h3>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <h3
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Szolgáltató <span className='p'>vagy </span>
              NDRU
              <span className='p'>:</span>
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              NDRU 1.0 Kft. (cégjegyzékszám: 01-09-374640,
              adószám: 28802839-2-43, székhely: 1221
              Budapest, Hajó utca 1.).
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <h3
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Felhasználó<span className='p'>:</span>
            </h3>
            <p
              style={{
                paddingTop: '5pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Az a <b>Mobil Platform</b>-on
              <b>, Szervíz Platform</b>-on
              <b>, Flotta Platform</b>-on regisztrált
              természetes személy és jogi személy, aki
              ezeken a Platformokon keresztül Megrendelést
              adhat le.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <h3
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Fogyasztó:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              A Felhasználók közül azok a természetes
              személyek, akik szakmájuk, önálló
              foglalkozásuk vagy üzleti tevékenységük körén
              kívül járnak el.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <h3
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Felek:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              A Szolgáltató és a Felhasználó együtt.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <h3
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Partner:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Egyéni vállalkozó vagy gazdasági társaság,
              amely üzletszerű gazdasági tevékenysége
              körében kínálja szolgáltatásait az{' '}
              <b>NDRU Platformokon </b>a Szolgáltatóval
              kötött külön szerződése alapján.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <h3
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Szerződés:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              A Szolgáltató és a Felhasználó között az ÁSZF
              és a Felhasználó által megadott adatok
              alapján, a regisztráció útján létrejött
              szerződés.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <h3
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              ÁSZF:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              A jelen Általános Szerződési Feltételek.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <h3
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Közvetített Szerződés:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              A Szolgáltató által a Platform segítségével
              közvetített, a Felhasználó és a Partner között
              létrejövő szerződés, amelynek tartalmát az
              ÁSZF 1. Sz. melléklete rögzíti.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <h3
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Platform:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              <a
                href='http://www.drivendru.com/'
                className='s1'
                target='_blank'
              >
                A Szolgáltató által üzemeltetett NDRU
                elnevezésű online megrendelés-közvetítő
                weboldal{' '}
              </a>
              www.drivendru.com, (<b>Weboldal</b>), az NDRU
              APP mobilapplikáció (<b>Mobilapplikáció </b>
              vagy <b>NDRU APP</b>
              <a
                href='http://www.service.drivendru.com/'
                className='s1'
                target='_blank'
              >
                ), a{' '}
              </a>
              <span
                style={{
                  color: '#1154CC',
                  fontFamily: 'Arial, sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  textDecoration: 'underline',
                  fontSize: '10.5pt',
                }}
              >
                www.service.drivendru.com
              </span>{' '}
              (<b>Szervíz Platform</b>
              <a
                href='http://www.fleet.drivendru.com/'
                className='s1'
                target='_blank'
              >
                ),{' '}
              </a>
              <span
                style={{
                  color: '#1154CC',
                  fontFamily: 'Arial, sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  textDecoration: 'underline',
                  fontSize: '10.5pt',
                }}
              >
                www.fleet.drivendru.com
              </span>{' '}
              (<b>Flotta Platform</b>)
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              valamint az ezeket kiszolgáló szerveroldali
              alkalmazások együttesen, amely a Partnerek
              által nyújtott szolgáltatások listáját,
              valamint a Felhasználó NDRU Fiókján belül az
              egyéni felhasználói tartalmakat tartalmazza és
              amelyen keresztül a Felhasználó leadhatja a
              Megrendeléseit.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <h3
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'justify',
              }}
            >
              NDRU Fiók:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Felhasználó által a Platformon történő
              regisztráció során létrehozott fiók, amelynek
              segítségével a Felhasználó a rendszer felé
              azonosíthatja magát a Platformon a Szolgáltató
              felé, személyes, egyénre szabott tartalmakat
              tárolhat, Megrendelést adhat le, továbbá
              amelyben a Szolgáltatás igénybevételéhez
              szükséges alapvető információkat tárolhatja.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <h3
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'justify',
              }}
            >
              Szolgáltatási Egység vagy Egység:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Partner legkisebb önálló gazdasági egysége,
              ahol vagy ahonnan a Partner által nyújtott
              szolgáltatás teljesítésre kerül.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <h3
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'justify',
              }}
            >
              Partneri Szolgáltatás:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Felhasználó által a Platformon keresztül
              leadott Megrendelés útján a Partnertől
              igénybevehető olyan szolgáltatás, mely a
              Platformon az igényelhető szolgáltatások
              között feltüntetésre kerül, illetve a Partneri
              Szolgáltatásokhoz kapcsolódó termékek
              értékesítése a Partnerek által a Platformon
              keresztül a Felhasználók részére.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <h3
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Kosár:
            </h3>
            <p
              style={{
                paddingTop: '5pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              A Felhasználó által összeállított Partneri
              Szolgáltatások listája a Szolgáltatások árának
              feltüntetésével és a Szolgáltatáshoz
              kapcsolódó díjakkal, kiegészítő feltételekkel,
              amelyre a Megrendelés irányul.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <h3
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Megrendelés:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Felhasználóknak a Platformon kiválasztott
              Szolgáltatás igénybevételére illetve
              megvásárlására irányuló szerződéskötési
              ajánlata, amelyet a Szolgáltató közvetít a
              Partner felé a Szolgáltató és a Partner között
              létrejött külön közvetítői szerződés alapján.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <h3
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'justify',
              }}
            >
              NDRU Szolgáltatás:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Szolgáltató által a Felhasználó részére
              nyújtott szolgáltatások összessége a Platform
              üzemeltetése keretében, azaz a Platform
              üzemeltetése, a Platform használatának
              biztosítása (beleértve a regisztrációt, a
              böngészést, a Megrendelések kezelését, az
              e-szervízkönyv biztosítását és a
              panaszkezelést). A Szolgáltató az NDRU
              Szolgáltatásokért külön díjat nem számol fel,
              azonban a Felhasználó által a Platformon
              keresztül megrendelt Partneri Szolgáltatások
              után a Szolgáltató díjat számít fel.
            </p>
            <h3
              style={{
                paddingTop: '4pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'justify',
              }}
            >
              Futár Szolgáltatás:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Felhasználó felhatalmazásából a Partner
              által megkeresett és kiválasztott Futárral a
              Felhasználó részére nyújtott visszterhes
              szolgáltatás, amely során a Partner által
              kiválasztott Futár a Felhasználó és a Futár
              között létrejövő, Felhasználó által elfogadott
              feltételek szerinti kiszállítási szerződés
              alapján a Felhasználó által a Platformon
              megrendelt Partneri Szolgáltatás nyújtása
              végett a Felhasználó gépjárművét a
              Felhasználótól a Partnerig, illetve a Partneri
              Szolgáltatás teljesítését követően a
              Partnertől a Felhasználóhoz szállítják.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <h3
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Futár:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Partner független üzleti partnere, aki
              rendelkezésre áll arra, hogy Futár
              Szolgáltatás keretében a Szolgáltató és a
              Partner közreműködésével a Felhasználóval
              közvetlenül megkötött, Felhasználó által
              elfogadott feltételei szerinti szerződése
              alapján a Felhasználó által a Platformon
              megrendelt Partneri Szolgáltatás nyújtása
              végett a Felhasználó gépjárművét a
              Felhasználótól a Partnerig, illetve a Partneri
              Szolgáltatás teljesítését követően a
              Partnertől a Felhasználóhoz szállítsa.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <h3
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Online fizetési mód:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Megrendelés fizetési szolgáltatón keresztül
              történő bankkártyás fizetési eszköz útján
              történő kifizetése.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
          </li>
          <li data-list-text={2}>
            <h3
              style={{
                paddingLeft: '17pt',
                textIndent: '-11pt',
                textAlign: 'left',
              }}
            >
              A Platform üzemeltetése, az NDRU Szolgáltatás
              tartalma
            </h3>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              2.1.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Szolgáltató a tulajdonosa és üzemeltetője az{' '}
              <b>NDRU Platformok-</b>
              nak. A Szolgáltató a{' '}
              <b>NDRU Platformok-on </b>a Partnerek Partneri
              Szolgáltatás kínálatát gyűjti össze és teszi
              elérhetővé a Felhasználók számára, abból a
              célból, hogy a Felhasználók a Partnerek
              Partneri Szolgáltatásait online
              megrendelhessék, a nyújtott Partneri
              Szolgáltatást kifizethessék, illetve igény
              esetén gépjárművüket Futár igénybevételével a
              Partner Egységébe illetve onnan
              visszajuttassák. A Szolgáltató célja az, hogy
              a Felhasználó igényeire, jogaira és jogos
              érdekeire tekintettel a lehető legszélesebb
              körben tegye elérhetővé és láthatóvá a
              Partnereket és Szolgáltatásaikat, miközben
              lehetővé teszi a Partnereknél az ügyfélkörük
              növekedését.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              2.2.
            </p>
            <p
              style={{
                paddingTop: '5pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Az NDRU lehetővé teszi Felhasználóinak
              gépjármű szervízeket és egyéb gépjárművekhez
              kapcsolódó szolgáltatatásokat nyújtó Partnerek
              megtalálását, Partneri Szolgáltatásaik
              áttekintését,
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              időpont foglalást ezen Partnerekhez, valamint
              a Partneri Szolgáltatások elkészültével a
              Felhasználók az <b>NDRU Platformok </b>
              keresztül Online Fizetési Módon keresztül
              kifizethetik ezeket a Partneri
              Szolgáltatásokat és azokhoz kapcsolódó
              alkatrészeket. A Platform segítségével a
              Felhasználók rögzíthetik gépjárműveiket, azok
              azonosításához szükséges adatokat, és egyéb
              adatokat például: futási kilométert. Ezen túl
              az <b>NDRU Platformok-on </b>keresztül végzett
              szervíz foglalások és fizetés során
              automatikusan rögzítésre kerülnek az{' '}
              <b>NDRU Platform-okon </b>a Felhasználó NDRU
              Fiókjában a gépjárművekhez kapcsolódó a
              szervízelési adatok, szervízelőzmények.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              2.3.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A <b>NDRU Platform-okon</b>keresztül a
              Felhasználók a szervízszolgáltatást nyújtó
              Partneri Szolgáltatásokon felül addicionális
              Partneri Szolgáltatásokat rendelhetnek meg és
              vehetnek igénybe, például: biztosítás,
              azonnali segítségnyújtás, parkolási
              szolgáltatás.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              2.4.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Az <b>NDRU Platform-okon </b>keresztül,
              szerviz Partnertől függően lehetőség van Futár
              Szolgáltatás igénybevételére is, ennek során a
              szervíz Partner által a Felhasználóhoz küldött
              Futár veszi át a Felhasználó gépjárművét és
              viszi be a szervíz Partner Egységébe. Nem
              minden szerviz Partner biztosít Futár
              Szolgáltatást. Futár Szolgáltatást a
              Felhasználó csak abban az esetben tud igénybe
              venni, ha azt az adott Partneri Szolgáltatást
              nyújtó szerviz Partner lehetővé teszi és a
              Platformon addicionális szolgáltatásként az a
              kiválasztott Partneri Szolgáltatás mellet
              opcionálisan megrendelhető.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              2.5.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Platformon keresztül megrendelt Partneri
              Szolgáltatásokról és megvásárolt termékekről
              automatikus számla kerül kiállításra a Partner
              nevében a Partner teljesítési segédeként
              eljáró Szolgáltató közreműködésével. Ez
              kizárólag abban az esetben történik, ha a
              partner az NDRU által integrált számlázási
              szolgáltatást igénybe veszi.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              2.6.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Platformon keresztül elérhető és ott
              feltüntetett Partneri Szolgáltatások díjai úgy
              kerültek a Platform által megállapításra és a
              Platformon feltüntetésre, hogy azok már
              magukban foglalják az NDRU Szolgáltatási Díjat
              is. Az e-mailcím megfelelő megadása a
              Felhasználó kötelzettsége. A Szolgáltató nem
              felel a hibásan/pontatlanul megadott vagy
              valótlan e-mail cím miatti károkért vagy
              problémákért.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              2.7.
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Az NDRU a Partnertől független, önálló
              szolgáltatási tevékenységet folytat. Az NDRU a
              Szolgáltatások illetve a Partneri
              Szolgáltatások nyújtása során a Felhasználók
              illetve a Fogyasztók képviseletében nem jár
              el.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              2.8.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Az NDRU online közvetítő kereskedői
              tevékenységet folytat, amelynek keretében a
              Partnerek megbízásából a Felhasználók
              Megrendeléseit fogadja a Platformokon
              keresztül, majd feldolgozza és továbbítja
              azokat a Partnerek számára.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              2.9.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Platformokon található tartalom, adatok és
              információk az NDRU Szolgáltatás jellegére
              tekintettel folyamatosan változnak. A
              Szolgáltató kifejezetten törekszik arra, hogy
              mindig aktuális tartalmat és ajánlatokat
              jelenítsen meg a Felhasználó számára.
              Tekintettel arra, hogy a Platformokon
              megjelenő információk, Partneri Szolgáltatások
              listája, Partneri Szolgáltatások és a
              kapcsolódó termékek leírása és azok árai a
              Partnertől származnak, a Szolgáltató ezek
              tartalmáért, aktualitásáért, elérhetőségéért
              és minőségéért felelősséget nem vállal és
              kártérítésre nem kötelezhető.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              2.10.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Szolgáltató rögzíti, hogy a Partner
              Platformokon megjelenő Partneri Szolgáltatás
              és kapcsolódó termékkínálata eltérhet a
              Partner Szolgáltatási Egységének
              üzlethelyiségében található, vagy a saját
              online rendelési csatornáin megjelenő
              szolgáltatás- és/vagy termékkínálatától. A
              Partner Szolgáltatási Egységében, vagy saját
              online értékesítési csatornáin alkalmazott
              szolgáltatásnyújtási és/vagy
              termékértékesítési feltételek – így különösen
              a szolgáltatások és termékek ára, esetlegesen
              alkalmazott akciók, szállítási díjak –
              eltérhetnek a Platformon található Partneri
              Szolgáltatások nyújtásának és értékesítésének
              feltételektől. Ugyanazon Partner több
              Szolgáltatási Egységének üzemeltetése esetén a
              Partneri Szolgáltatások és kapcsolódó termékek
              kínálata és a szolgáltatásnyújtásnak és
              ékesítésnek a feltételei eltérhetnek az
              Egységek között.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              2.11.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Szolgáltató tájékoztatja a Felhasználót,
              hogy az 1.5. pontban foglaltak mellett a
              Szolgáltató korlátozhatja a Partnerek azon
              jogát, hogy a Partnerek a saját online
              rendelési csatornáikon (saját weboldal,
              applikáció) eltérő rendelési feltételeket –
              különösen eltérő árakat – alkalmazzanak a
              Platformon szereplő Partneri Szolgáltatások
              megrendelési feltételeitől. A korlátozás a
              Szolgáltató üzleti modelljének fenntartását
              célozza annak érdekében, hogy a Felhasználók
              továbbra is részesüljenek a Platform és az
              NDRU Szolgáltatás által biztosított
              előnyökből, mely felhasználói előnyök a
              Platform működéséből adódó keresési idő és
              költségek csökkentéséből, a nagy
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              választékból, a magas színvonalú
              szolgáltatásokból (különösen a rendkívül gyors
              ügyintézés a Futár Szolgáltatás és a Partnerek
              aktuális rendelkezésre állásának megismerése
              esetén), a Felhasználó Platformon keresztül
              lebonyolított szervízelőzményeinek precíz
              dokumentációjából tevődnek össze. A
              Szolgáltató folyamatosan fejleszti Platformját
              és NDRU Szolgáltatásait, a rendelői kör és új
              Felhasználók szerzése érdekében folyamatosan
              invesztál a Platformok és az NDRU
              Szolgáltatások üzemeltetésébe, mely egyben a
              Partnerek érdeke is. A Partnert érintő
              korlátozások segítik a Szolgáltatót abban,
              hogy megakadályozza azt, hogy a Partnerek a
              Platform működéséből származó előnyöket
              kihasználva kizárólag saját marketing célokra
              és saját ügyfelek toborzására (átcsábítására)
              használja a Platformot. Ebből kifolyólag a
              korlátozás a Szolgáltató azon jogos üzleti és
              gazdasági érdekét szolgálja, hogy egyensúlyba
              hozza a Partnereknek a Platformon való
              megjelenésből és az NDRU Szolgáltatásokból
              eredő előnyeit a Szolgáltató azon
              erőfeszítéseivel és kiadásaival, amelyeket a
              Platform fejlesztése és a szolgáltatásai
              színvonalának fenntartása és emelése érdekében
              vállal. A Szolgáltató a félreértések
              elkerülése érdekében rögzíti, hogy a
              korlátozás nem érinti a Partner azon jogát,
              hogy a saját nem online, a Platformtól
              független, a Szolgáltatói Egységeiből történő
              helyszíni megrendelés esetén eltérő
              kondíciókat alkalmazzon.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              2.12.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Platformokon szereplő minden Partneri
              Szolgáltatáshoz kapcsolódó idő, így többek
              között az adott szervízszolgáltatáshoz
              kapcsolódó munkaóra és kapcsolódó
              termékbeszerzés időtartamának, illetve a
              szolgáltatás teljesítésének határidejének
              meghatározása a Partner becslésén alapul, ezek
              pusztán tájékoztató jellegűek. A tényleges
              teljesítési határidőre és a
              szervízszolgáltatáshoz kapcsolódó munkaórákra
              egyéb külső, a Szolgáltatótól és a Partnertől
              egyaránt független, előre nem látható tényezők
              is hathatnak.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              2.13.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Szolgáltató fenntartja a jogot arra, hogy a
              Platformokon megjelenő tartalmakat bármikor
              módosítsa, elérhetőségét megváltoztathassa,
              illetve a Platformok üzemeltetését
              felfüggessze, vagy véglegesen megszüntesse.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              2.14.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              A Szolgáltató fenntartja a jogot arra, hogy a
              Platformok tartalmát és az ajánlatokat
              részlegesen vagy
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              teljesen az összes Felhasználó, vagy a
              Felhasználók egy csoportja számára korlátozza
              vagy letiltsa.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
          </li>
          <li data-list-text={3}>
            <h3
              style={{
                paddingTop: '10pt',
                paddingLeft: '17pt',
                textIndent: '-11pt',
                textAlign: 'left',
              }}
            >
              A Platformok használata
            </h3>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              3.1.
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              A Felhasználó számára a Platformok használata
              ingyenes.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              3.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Platformokat bárki jogosult használni,
              amennyiben érvényesen és sikeresen regisztrált
              és magára nézve kötelezőnek ismeri el a jelen
              ÁSZF-ben foglaltakat és megismerte és
              elfogadta a természetes személy Felhasználók
              személyes adatainak kezelésére vonatkozó
              Adatkezelési Tájékoztatót. A Platform
              használata az <b>NDRU Fiókon </b>keresztül
              történik.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              3.3.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Platformokon kizárólag 18. életévüket
              betöltött természetes személyek
              regisztrálhatnak, akik vagy saját maguk javára
              vagy egy vállalkozás képviseletében járnak el
              és használják a Platformokat. A Fogyasztó a
              jelen ÁSZF elfogadásával kijelenti, hogy 18.
              életévét betöltötte. A vállalkozás
              képviseletében eljáró természetes személy a
              jelen ÁSZF elfogadásával kijelenti, hogy a
              vállalkozás képviseletére önállóan jogosult,
              eljárása nem korlátozott. A Szolgáltató nem
              köteles ellenőrizni sem a Fogyasztó életkorát
              sem a vállalkozás képviseletében eljáró
              természetes személy képviseleti jogosultságát,
              azonban jogosult felhívni a Fogyasztót arra,
              hogy életkorát hitelt érdemlő módon igazolja,
              úgyszintén a vállalkozás képviseletében eljáró
              természetes személyt, hogy képviseleti
              jogosultságát hitelt érdemlő módon igazolja.
              Amennyiben a Fogyasztó nem töltötte be a 18.
              életévét vagy a Szolgáltató felhívására nem
              igazolta életkorát, úgyszintén, ha a
              vállalkozás képviseletében eljáró természetes
              személy a vállalkozás képviseletére nem
              jogosult vagy képviseleti jogosultságát a
              Szolgáltató felhívására nem igazolta, úgy a
              Szolgáltató azonnali hatállyal megszünteti a
              Szerződést és ezzel együtt az NDRU Fiókot. A
              Fogyasztó 18 éven aluli személy részére nem
              rendelhet meg a Platformon keresztül olyan
              Partneri Szolgáltatást vagy olyan kapcsolódó
              terméket, amelyet 18 éven aluli személy saját
              magának nem vásárolhat. A Felhasználó
              kizárólagosan felelős azért, hogy az NDRU
              Fiókjához 18 éven aluli személy illetve más
              illetéktelen személy ne férhessen hozzá.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              3.4.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Azon természetes személy, aki jogi személy
              illetve vállalkozás mint Felhasználó
              képviseletében jár el, számlát kérhet a
              Platformokon keresztüli megrendeléséről. A
              jogi személy illetve vállalkozás mint
              Felhasználó képviseletében eljáró természetes
              személy a jelen ÁSZF elfogadásával, valamint a
              számlaigény leadásával kijelenti, hogy az
              adott jogi személy illetve vállalkozás
              képviseletében eljárhat.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              3.5.
            </p>
            <p
              style={{
                paddingTop: '5pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              A Felhasználó köteles betartani minden
              jogszabályt, előírást és kötelezettséget a
              Platformok használata és a Szolgáltatások
              igénybevétele során.
            </p>
          </li>
          <li data-list-text={4}>
            <h3
              style={{
                paddingTop: '4pt',
                paddingLeft: '17pt',
                textIndent: '-11pt',
                textAlign: 'left',
              }}
            >
              A regisztráció folyamata
            </h3>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              4.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Platformon kizárólag regisztrált és ennek
              eredményeként NDRU Fiókkal rendelkező
              Felhasználó adhat le Megrendelést. A
              regisztráció során, a sikeres regisztráció
              eredményeként jön létre a Felhasználó{' '}
              <b>NDRU Fiók</b>ja. Az <b>NDRU Fiók </b>
              mobilapplikációban regisztrálható.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              4.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A regisztrációhoz a Felhasználó természetes
              személy, illetve a Felhasználó képviseletében
              eljáró természetes személy személyes
              adatainak, illetve a vállalkozás az adatainak
              megadása szükséges. A Fogyasztó kizárólag a
              saját személyes adataival regisztrálhat a
              Platformokon, azaz Fogyasztók számára a más
              nevében történő regisztráció tilos.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              4.3.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Az NDRU mobil applikációban történő
              regisztráció létrehozásához továbbá az NDRU
              APP mobiltelefonos applikáció ingyenes
              letöltése szükséges, mely megtalálható Apple
              App Store-on, Play Áruházban áruházakban.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              4.4.
            </p>
            <p
              style={{
                paddingTop: '5pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              E-mail címmel történő regisztráció során a
              Felhasználó által beírt e-mail címet a
              rendszer ellenőrzi, hogy az e-mail címhez
              tartozik-e már korábban létrehozott NDRU Fiók,
              ezt követően a Felhasználónak szükséges
              megadnia a következő személyes adatokat:
              e-mail cím, jelszó, keresztnév, vezetéknév,
              szállítási cím, telefonszám, vállalkozás
              esetén cégnév, székhely, adószám,
              cégjegyzékszám; gépjármű adatok: rendszám,
              gépjármű típusa, gépjármű modellje, évjárat,
              üzemanyagtípus, térfogat, teljesítmény,
              műszaki vizsga érvényességi ideje, alvázszám,
              motorszám, kerékméretek, kilométeróra állás,
              utolsó szervíz időpontja, forgalmi engedély
              száma, törzskönyv szám, forgalomba helyezés
              dátuma, gépjármű színe, kötelező biztosítási
              kötvényszám, casco biztosítási kötvényszám,
              ezek összegei és érvényessége, egyéb
              gépjárműhöz kapcsolódó adatok.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              4.5.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A sikeres regisztrációhoz a Felhasználónak el
              kell fogadnia a jelen ÁSZF-et és nyilatkoznia
              kell az Adatkezelési tájékoztató
              megismeréséről, az abban foglaltak tudomásul
              vételéről és nyilatkoznia kell, hogy személyes
              adatainak kezeléséhez a Adatkezelési
              tájékoztatóban foglaltak szerint hozzájárul-e.
              Az adott mezők bepipálásával a Felhasználó
              kijelenti, hogy a jelen ÁSZF valamennyi
              rendelkezését
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              elolvasta, megértette, elfogadja és azokat
              maradéktalanul betartja, valamint annak
              valamennyi rendelkezését magára nézve
              kötelezőnek ismeri el. Az Adatkezelési
              tájékoztató vonatkozásában az adott mező
              bepipálásával a Felhasználó nyilatkozik, hogy
              az abban foglaltakat megismerte, megértette és
              tudomásul vette, a személyes adatainak
              kezeléséhez az Adatkezelési tájékoztatóban
              foglaltak szerinti kezeléséhez hozzájárul. A
              Felhasználónak lehetősége van az adott mező
              bepipálásával feliratkozni a Szolgáltató által
              direkt marketing megkeresés formájában küldött
              marketing üzenetek küldésére (hírlevélre), a
              feliratkozás azonban nem feltétele a sikeres
              regisztrációnak.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              4.6.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A sikeres regisztráció a „Fiók létrehozása”
              gomb kiválasztásával kezdődik, majd a
              Felhasználó által a megadott e-mail címére
              küldött visszaigazoló üzenetben található link
              megadásával, azaz a regisztráció Felhasználó
              általi hitelesítésével történik meg. A
              Szolgáltató által küldött visszaigazoló üzenet
              segítségével tudja visszaigazolna a
              Felhasználó az email címét. Ezt követően a
              Felhasználónak a regisztrációs felületre
              visszatérve lehetősége lesz ellenőrizni és
              szükség esetén javítani a már megadott
              személyes és gépjármű adatait. A visszaigazoló
              üzenetben található linkre kattintva a
              Felhasználó megerősíti regisztrációs
              szándékát, ezzel létrejön a Felhasználó NDRU
              Fiókja és a Platform átirányítja a
              Felhasználót a kezdőoldalra.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              4.7.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A regisztráció és a Szolgáltatás igénybevétele
              során megadott személyes adatok pontosságáért,
              aktualitásáért és valóságtartalmáért kizárólag
              Felhasználó felel. A Felhasználó elfelejtett
              jelszavából, vagy annak illetéktelenek számára
              való hozzáféréséből eredő károkért a
              Szolgáltatót semmilyen felelősség nem terheli.
              A Felhasználó köteles a Szolgáltatót
              haladéktalanul értesíteni, ha annak gyanúja
              merül fel, hogy az NDRU Fiókjához illetéktelen
              személyek hozzáférést szereztek.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingTop: '8pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              4.8.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              A Felhasználó a sikeres Regisztrációt és email
              megerősítését követően jogosult
              Megrendeléseket leadni a Platformon.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
          </li>
          <li data-list-text={5}>
            <h3
              style={{
                paddingLeft: '17pt',
                textIndent: '-11pt',
                textAlign: 'left',
              }}
            >
              A Megrendelés folyamata
            </h3>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.1.
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Megrendelés leadása kizárólag elektronikus
              úton, a Platformon keresztül lehetséges. A
              Szolgáltató a Platformon keresztül
              Megrendelést kizárólag regisztrált
              Felhasználóktól és csak akkor fogad, ha a
              Felhasználó a Megrendeléshez szükséges
              valamennyi adatot maradéktalanul közli.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Szolgáltató kizár mindenfajta felelősséget,
              amely a Platformon megadott adatok
              helytelenségéből, elírásából vagy nem a valós
              adatok megadásával összefüggésében merül fel.
              A Szolgáltatót nem terheli felelősség sem a
              Szerződés sem a Felhasználó és a Partner
              illetve Futár között létrejött szerződés
              késedelmes vagy hibás teljesítéséért, illetve
              egyéb problémáért, hibáért.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.3.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Felhasználó az NDRU főoldalra belépve
              választhat, hogy a felugró ablakon engedélyezi
              a Felhasználó telefonja által biztosított
              helymeghatározási szolgáltatásait, amely
              esetben a Felhasználó aktuális pozíciója
              automatikusan betöltődik. A Szolgáltató a
              Felhasználó telefonja által biztosított
              helymeghatározási szolgáltatásainak megfelelő
              működésével, a helymeghatározás
              pontatlanságával kapcsolatos károkért
              semmiféle felelősséget nem vállal. A Platform
              az aktuális földrajzi helyzet, illetve a Futár
              Szolgáltatáshoz szükséges cím meghatározását
              alkalmazza, új helyzetmeghatározás hiányában a
              Futár szolgáltatás nem köteles teljesíteni.
              NDRU mobil applikáció használata esetén a
              Felhasználó mobiltelefonjának
              Helymeghatározási szolgáltatásainak
              engedélyezése esetén az aktuális helyadatai
              automatikusan betöltődnek, de lehetősége van
              új címét a cím beírásáva megadni.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.4.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Felhasználó a szállítási cím meghatározását
              követően a „Futár” gombra történő kattintással
              választhat, hogy a gépjárművét Futár
              Szolgáltatás igénybevételével szeretné-e e a
              Partner Egységébe juttatni és/vagy onnan a
              Partneri Szolgáltatás Teljesítését követően
              szállítási címére visszajuttatni. A
              Felhasználó szabadon dönthet úgy, hogy a
              gépjárművet saját maga viszi a Partnerhez
              illetve veszi át a Partneri Szolgáltatás
              teljesítését követően. Nem minden Partner
              biztosít lehetőséget Futár Szolgáltatás
              igénybevételére.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.5.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A felhasználó által megrendelni kívánt
              Partneri Szolgáltatás és opcionálisan az
              aktuális földrajzi hely meghatározását
              követően a Platform megjeleníti az elérhető
              Partnerek listáját (<b>Partnerlista</b>
              ). A Felhasználónak lehetősége van választania
              a Partnerlista rendezési sorrendje
              tekintetében (kiválasztott Partneri
              Szolgáltatás típusa szerinti lista,
              Felhasználó által megadott helymeghatározás
              esetén földrajzi közelség, stb.) Felhasználó
              tudomásul veszi, hogy egyebekben a Partnerek
              sorrendjét
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              a Szolgáltató jogosult meghatározni, egyes
              Partnerek a Szolgáltató és a Partner közötti
              megállapodás alapján kiemelt helyen
              szerepelhetnek (úszósáv, Partnerlista első
              pozíciói).
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.6.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Partnerek Platformon történő elérhetősége
              (nyitva tartása) Partnerenként változhat. Az
              adott Partnerhez feltüntetett nyitvatartási
              idő tájékoztató jellegű, a Partnerek és a
              Szolgáltató fenntartják a Partnerek
              elérhetőségének változtatásának jogát. A
              Partner Platformon történő elérhetősége
              eltérhet az adott Egység helyszíni
              nyitvatartási idejétől.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.7.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Felhasználó a Platformon történő böngészés
              során szabadon állíthatja össze a Kosarat. A
              Platformon szereplő árak és díjak Forintban
              vannak meghatározva, bruttó értékben. Az „i”
              gombra kattintva elérhető a Partner
              nyitvatartási információi, a Partner címe és
              térképes elhelyezkedése. Kedvezményes ár
              esetén a Platformon megjelenik az eredeti ár,
              a kedvezményes ár, valamint a kedvezmény
              mértéke is. Úgyszintén megjelenik a Futár
              Szolgáltatás igénybevételének lehetősége.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.8.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Szolgáltató rögzíti, hogy az adott Partner
              mellett a Platformon feltüntetett teljesítési
              határidő és várható munkaóra becsült adat,
              ennélfogva tájékoztató jellegű. Ez vonatkozik
              minden határidőt, munkaórát tartalmazó
              felületre, így különösen a Megrendelést
              követően megjelenő rendelés követésére
              szolgáltató felületre, továbbá a Megrendelést
              visszaigazoló emailre. A Szolgáltatót a
              kijelzett határidőtől vagy becsült munkaórától
              eltérő esetleges korábbi, vagy késedelmes
              teljesítésből, illetve munkadíjtöbbletből
              eredően semmilyen felelősség nem terheli és
              kártérítésre nem kötelezhető.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.9.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              A Partner határozza meg a minimális
              Megrendelési értéket, amely nem lehet
              magasabb, mint 1.000.- forint. A minimális
              Megrendelési érték Partnerenként eltérhet.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.10.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Kosár összeállítását követően a „Tovább a
              megrendeléshez” gombra kattintva a
              Felhasználót a Platform automatikusan
              átirányítja az adatok ellenőrzésének célját
              szolgáló Megrendelést összegző oldalra. A
              Felhasználó itt ellenőrzi és véglegesíti a
              Megrendelés teljesítéséhez szükséges adatokat
              (Partneri Szolgáltatás, annak díja, szállítási
              cím, szállítási, vagy átvételi időpont,
              személyes adatok és telefonszám, továbbá
              megjegyzést tehet a Futárnak). A Megrendelés
              összegző oldalon a Felhasználó látja azt is,
              hogy a Rendelés leadásával a Futár
              Szolgáltatást is megrendeli-e.
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.11.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.12.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Kosár tartalma abban az esetben törlődik, ha
              a Megrendelést a Felhasználó 2 órán belül nem
              véglegesíti. Az NDRU Fiókból történő
              kijelentkezés a Megrendelés véglegesítése
              hiányában a Platformon a Kosár törlését vonja
              maga után.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.13.
            </p>
            <p
              className='s6'
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Megrendelés folyamata:
            </p>
            <ol id='l2'>
              <li data-list-text={1}>
                <p
                  className='s6'
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '17pt',
                    textIndent: '-11pt',
                    textAlign: 'left',
                  }}
                >
                  Felhasználó kiválasztja a Partnert
                </p>
              </li>
              <li data-list-text={2}>
                <p
                  className='s6'
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '17pt',
                    textIndent: '-11pt',
                    textAlign: 'left',
                  }}
                >
                  Felhasználó a Partner szolgáltatásai közül
                  kiválasztja, amelyeket meg szeretne
                  megrendelni
                </p>
              </li>
              <li data-list-text={3}>
                <p
                  className='s6'
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'left',
                  }}
                >
                  Felhasználó kiválasztja az időpontot, hogy
                  mikor vinné be az autót/mikor kéri a
                  futárt, amennyiben erre lehetőséget
                  biztosít a Partner
                </p>
              </li>
              <li data-list-text={4}>
                <p
                  className='s6'
                  style={{
                    paddingLeft: '17pt',
                    textIndent: '-11pt',
                    textAlign: 'left',
                  }}
                >
                  Felhasználó elküldi a Megrendelést és a
                  foglalási időpontot, azzal, hogy
                </p>
                <ul id='l3'>
                  <li data-list-text='-'>
                    <p
                      className='s7'
                      style={{
                        paddingTop: '6pt',
                        paddingLeft: '60pt',
                        textIndent: '-18pt',
                        lineHeight: '150%',
                        textAlign: 'justify',
                      }}
                    >
                      {' '}
                      <span className='p'>
                        amennyiben a Partner rendelkezik
                        saját általános szerződési
                        feltételekkel, nyilatkozik a
                        Felhasználó arról, hogy a Partner
                        Megrendeléshez kapcsolódó általános
                        szerződési feltételeit megismerte és
                        elfogadta,
                      </span>
                    </p>
                  </li>
                  <li data-list-text='-'>
                    <p
                      style={{
                        paddingLeft: '59pt',
                        textIndent: '-17pt',
                        textAlign: 'justify',
                      }}
                    >
                      a „Megrendelem” gomb lenyomásával a
                      Felhasználó véglegesíti a Megrendelést
                    </p>
                  </li>
                  <li data-list-text='-'>
                    <p
                      style={{
                        paddingTop: '6pt',
                        paddingLeft: '60pt',
                        textIndent: '-18pt',
                        lineHeight: '150%',
                        textAlign: 'justify',
                      }}
                    >
                      Partneri Szolgáltatás megrendelésekor
                      Fogyasztó esetében nyilatkozni, hogy
                      kéri-e, hogy a Partner a Fogyasztónak
                      a Fogyasztó és vállalkozás közötti
                      szerződések részletes szabályairól
                      szóló 45/2014. (II. 6.) Korm. rendelet
                      (<b>Fvkr.</b>) 20. §-ában biztosított
                      határidő lejárta előtt már megkezdje a
                      Közvetített Szerződés teljesítését.
                    </p>
                  </li>
                </ul>
              </li>
              <li data-list-text={5}>
                <p
                  className='s6'
                  style={{
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  Partner megkapja az időpont foglalást. Az
                  időpont foglalást követően a Partnernek
                  három lehetősége van: 1. Elfogadja az
                  időpontot; 2. Új időpontot javasol; 3.
                  Elutasítja.
                </p>
              </li>
              <li data-list-text={6}>
                <p
                  className='s6'
                  style={{
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  Ha új időpontot javasol, akkor a
                  Felhasználó az új időpontról értesítést
                  kap a Platformon keresztül és az új
                  időpontot elfogadhatja, vagy
                  elutasíthatja.
                </p>
              </li>
              <li data-list-text={7}>
                <p
                  className='s6'
                  style={{
                    paddingLeft: '17pt',
                    textIndent: '-11pt',
                    textAlign: 'justify',
                  }}
                >
                  A Felhasználó a megerősített időpontot 24
                  órával a foglalt időpont előtt mondhatja
                  le ingyenesen.
                </p>
              </li>
              <li data-list-text={8}>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  A Felhasználó a foglalt időpontban átadja
                  az autót a Partnernek vagy a megrendelt
                  Futár átveszi az autót a Felhasználótól a
                  Felhasználó által jelzett helyszínen
                </p>
              </li>
              <li data-list-text={9}>
                <p
                  className='s6'
                  style={{
                    paddingLeft: '17pt',
                    textIndent: '-11pt',
                    textAlign: 'justify',
                  }}
                >
                  Gépjármű átadását követően a Felhasználó a
                  szerviz folyamat lépésről kap
                  tájékoztatást.
                </p>
              </li>
              <li data-list-text={10}>
                <p
                  className='s6'
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  A Felhasználó értesítést kap a Platformon
                  keresztül, hogy a Partner milyen
                  Megrendelésen túli szolgáltatási
                  javaslatokat és alkatrész beszerzési
                  javaslatokat tesz. Ezek közül a
                  Felhasználó a Platformon keresztül
                  eldöntheti melyiket fogadja el azzal, hogy
                </p>
                <ul id='l4'>
                  <li data-list-text='-'>
                    <p
                      style={{
                        paddingLeft: '78pt',
                        textIndent: '-18pt',
                        lineHeight: '150%',
                        textAlign: 'justify',
                      }}
                    >
                      A „Megrendelem” gomb lenyomásával a
                      Felhasználó kiválasztja a Partner
                      által javasolt javításokat és
                      megrendeli azokat;
                    </p>
                  </li>
                  <li data-list-text='-'>
                    <p
                      style={{
                        paddingTop: '4pt',
                        paddingLeft: '78pt',
                        textIndent: '-18pt',
                        lineHeight: '150%',
                        textAlign: 'left',
                      }}
                    >
                      A javasolt munkák megrendelése esetén,
                      ha a Felhasználó a Megrendelést
                      lemondja akkor köteles a Partner által
                      meghatározott költségeket, illetve
                      díjatmegfizetni.
                    </p>
                  </li>
                </ul>
              </li>
              <li data-list-text={11}>
                <p
                  style={{
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'left',
                  }}
                >
                  A Partner értesíti a Felhasználót a
                  gépjármű szerviz folyamatainak
                  előrehaladásáról: Alkatrész rendelése,
                  szervizelés folyamatban, autó elkészült,
                  valamint egyéb egyedi státuszokat is
                  jelezhet.
                </p>
              </li>
              <li data-list-text={12}>
                <p
                  style={{
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'left',
                  }}
                >
                  A Felhasználó az autó átvétele előtt a
                  Platformon keresztül megfizeti a
                  megrendelt szolgáltatásokat a Partnernek.
                </p>
              </li>
              <li data-list-text={13}>
                <p
                  style={{
                    paddingLeft: '26pt',
                    textIndent: '-20pt',
                    textAlign: 'left',
                  }}
                >
                  A Felhasználó az autó elkészülését jelző
                  értesítést követően átveheti az autóját a
                  Partnernél.
                </p>
              </li>
            </ol>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.14.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Közvetített Szerződés akkor jön létre,
              amikor az elektronikus visszaigazolás a
              Partnertől a Megrendelés befogadásáról
              megérkezik a Szolgáltatóhoz. A Közvetített
              Szerződés tartalmát az ÁSZF melléklete
              tartalmazza. Ebben az esetben a Partner
              általános szerződéseinek Felhasználó általi
              elfogadása a Közvetített Szerződés
              létrejöttének feltétele.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.15.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              A Felhasználó által megrendelt Partneri
              Szolgáltatásért minden elemében kizárólag a
              Partner felelős, a Futár Szolgáltatásért pedig
              a Futár.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.16.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Megrendelés állapota a Platformon a
              Megrendelés révén is nyomon követhető. A
              Megrendelés nyomon követő oldalon található
              teljesítési határidő folyamatosan változhat, a
              teljesítési határidő tájékoztató jellegű, a
              Szolgáltató az adatok helyességéért semmiféle
              felelősséget nem vállal. A Megrendelés
              állapota nyomon követhető a Platform által
              küldött automatikus push notification
              üzenettel, illetve a Megrendelés esetleges
              törlése esetén szintén automatikus push
              notification-t küld a Platform.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.17.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Partneri Szolgáltatásról és a Futár
              Szolgáltatásról a Partner állítja ki a számlát
              a Partner illetve a Futár megbízásából a
              Partner illetve a Futár nevében és helyett. Az
              így kiállított számlát a Partner emailen
              megküldi a Felhasználónak. A Felhasználó
              Tudomásul veszi, hogy a Partneri Szolgáltatás
              illetve a Futár Szolgáltatás díjáról
              kiállított számla a Partner illetve a Futár
              megbízásából kerül kiállításra.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
          </li>
          <li data-list-text={6}>
            <h3
              style={{
                paddingLeft: '17pt',
                textIndent: '-11pt',
                textAlign: 'left',
              }}
            >
              Megrendelés visszavonása, módosítás, elállás,
              rendelés törlése
            </h3>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              6.1.
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Felhasználót a Megrendelés véglegesítésétől
              ajánlati kötöttség terheli. Az ajánlati
              kötöttség megszűnik, ha a Szolgáltató nem
              teljesíti a megrendelést 30 napon belül.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              6.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Felhasználónak a Megrendelés visszavonására
              a foglalt időpont előtt 24 órával van
              lehetősége. Fogyasztók a Megrendelést a
              Közvetített Szerződés létrejöttéig mondhatják
              fel, azaz mindaddig, amíg a Megrendelés
              befogadásáról szóló visszaigazolás még nem
              érkezett meg a Partnertől a Szolgáltatóhoz.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              6.3.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Azon Partneri Szolgáltatások vonatkozásában,
              amelyek esetében Fvkr. 20. §-ában biztosított
              elállási joga van, a Közvetített Szerződést a
              Fogyasztó a mobil applikáción keresztül
              felmondhatja. Amennyiben a Közvetített
              Szerződés szolgáltatásnyújtásra irányul, és a
              Megrendelés véglegesítésekor a Fogyasztó
              kifejezetten kérte a Közvetített Szerződés
              teljesítésének megkezdését a Partnertől az
              Fvkr. 20. §-ában biztosított határidő letelte
              előtt, a Fogyasztót felmondási jog illeti meg
              a Közvetítői Szerződés megkötésétől a Partneri
              Szolgáltatás elvégzéséig. Ez utóbbi esetben a
              Fogyasztó felmondási jogának gyakorlása esetén
              a Közvetített Szerződés a felmondását a
              szolgáltatás megrendelésének leállításával a
              platfomon keresztül kezdeményezheti, azonban
              az ezen időpontig a Partner által teljesített
              Partneri Szolgáltatás díját a Fogyasztó
              köteles megfizetni, illetve a Fogyasztó a már
              esetlegesen teljesített szolgáltatási díjból
              csak olyan mértékű visszatérítésre jogosult,
              amilyen mértékű a Partneri Szolgáltatásból még
              nem teljesített szolgáltatásrész. Amennyiben a
              Megrendelt szolgáltatás nyújtására irányuló
              Partneri Szolgáltatást a Partner teljes
              egészében teljesítette tekintettel a Fogyasztó
              kifejezett nyilatkozatára (megrendelés
              megerősítésére), hogy a Partner a Közvetített
              Szerződés Fvkr. 20. §-ában foglalt határidő
              előtt kezdje meg, a Fogyasztó felmondási jogát
              elveszíti. A Fogyasztó az elállási illetve
              felmondási jogát a platformon keresztüli
              szolgáltatás megrendelés lemondással
              gyakorolhatja. A Közvetített Szerződéstől
              történő elállása vagy a Közvetítői Szerződés
              felmondása a Fogyasztónak a Fogyasztó
              járulékos szerződéseit is megszünteti (pl.
              Futár Szolgáltatásra irányuló szerződés). A
              Fogyasztót terheli annak bizonyítása, hogy az
              elállási jogát illetve felmondási jogát a
              Fvkr. 22. §-ában foglaltakkal összhangban
              gyakorolta. A Fogyasztó nem gyakorolhatja az
              Fvkr. 20. §-ában foglalt elállási vagy
              felmondási jogát a Fvkr. 29.
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'justify',
              }}
            >
              §-ában meghatározott esetekben
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              6.4.
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Felhasználó a Megrendelésén módosítani csak
              addig jogosult, ameddig a Partner a
              Megrendelést a Szolgáltató részére még nem
              igazolta vissza. A Megrendelés kiegészítése
              abban az esetben lehetséges, ha ezt a
              megrendelt Partneri Szolgáltatáshoz
              kapcsolódóan a Szolgáltató kifejezetten
              felajánlja a Felhasználónak. Minden egyéb
              esetben, amennyiben a Felhasználó további
              Partneri Szolgáltatásokat is meg kíván
              rendelni az adott Partnertől, akkor azt
              kizárólag új Rendelés leadásával teheti meg.
              Amennyiben a Partner visszaigazolta a
              Megrendelést, és a Felhasználó kéri az eredeti
              Megrendelést törlését, a Felhasználó nem
              mentesül a fizetési kötelezettsége alól, a
              Megrendelés ellenértékének kiegyenlítésére
              továbbra is köteles, online fizetés esetén a
              már kiegyenlített ellenértéket pedig nem
              követelheti vissza. Ez alól kivételt jelent,
              ha a Fogyasztó az Fvkr. 20. §-a szerint
              elállással vagy felmondással élt, miként azt
              részletesen szabályozza a fenti 6.3 pont.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              6.5.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Szolgáltató jogosult arra, hogy a törölje
              annak a Felhasználónak a Megrendelését, és
              vagy törölje az NDRU Fiókját, akinél már
              előfordult az, hogy a leadott Megrendeléshez
              kapcsolódó díjat nem fizette meg vagy
              szerződésszegő módon szüntette meg a
              Közvetített Szerződést illetve egyéb
              szerződésszegést követett el akár a
              Közvetített Szerződés akár a jelen ÁSZF
              vonatkozásában.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
          </li>
          <li data-list-text={7}>
            <h3
              style={{
                paddingLeft: '17pt',
                textIndent: '-11pt',
                textAlign: 'left',
              }}
            >
              Fizetés
            </h3>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              7.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Felhasználó a Megrendelés során a következő
              fizetési eszközök közül választhat:
            </p>
            <ul id='l5'>
              <li data-list-text='-'>
                <p
                  style={{
                    paddingTop: '5pt',
                    paddingLeft: '59pt',
                    textIndent: '-17pt',
                    textAlign: 'left',
                  }}
                >
                  Online fizetési mód: bankkártyás fizetés
                  (Mastercard, Maestro, VISA)
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingTop: '10pt',
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  7.2.
                </p>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  Szolgáltató jogosult saját mérlegelési
                  jogkörében bármikor további fizetési módot
                  bevezetni vagy megszüntetni. Szolgáltató
                  bármikor- így különösen technikai
                  probléma, átmeneti működésképtelenség
                  esetén - jogosult bármelyik fizetési mód
                  alkalmazását felfüggeszteni időszakos
                  jelleggel.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  7.3.
                </p>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  Amennyiben a Felhasználó által megrendelt
                  Partneri Szolgáltatás kizárólag
                  termékvásárlásra irányul, a Felhasználó
                  köteles a Megrendelt termék átvételekor
                  megfizetni a szolgáltatási díjat. A
                  Szolgáltató csak akkor igazolja vissza a
                  Megrendelés véglegesítését, ha a
                  Megrendelés véglegesítésekor az Online
                  fizetési módon keresztül a fizetés sikeres
                  volt. Amennyiben a Felhasználó
                  szolgáltatásnyújtásra irányuló Partneri
                  Szolgáltatást rendel meg, választása
                  szerint a Megrendelés véglegesítésekor
                  vagy a Partneri Szolgáltatás teljesítését
                  követően, de még a Felhasználó
                </p>
                <p
                  style={{
                    paddingTop: '4pt',
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  gépjárművének Partnertől való átvételét
                  megelőzően köteles teljesíteni a fizetési
                  kötelezettségét. Az Online fizetéskor, ha
                  a tranzakció sikeres, a fizetési
                  szolgáltató azonnal zárolja a Felhasználó
                  által megadott kártyán lévő összeget és
                  automatikusan értesíti a Szolgáltatót a
                  fizetés megtörténtéről, így a Platformon
                  információt kap arról, hogy a Megrendelés
                  ellenértékének teljesítése sikeres.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  7.4.
                </p>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  Online fizetéskor a Szolgáltató a Partner
                  és a Szolgáltató közötti külön szerződés
                  alapján jogosult és köteles a Közvetített
                  Szerződés Felhasználó részéről történő
                  teljesítésének (díjfizetés) elfogadására.
                  A teljesítés elfogadására oly módon kerül
                  sor, hogy a Felhasználó a Szolgáltatóval
                  szerződött fizetési szolgáltatón keresztül
                  fizeti meg a Rendelés szerinti teljes
                  összeget.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  7.5.
                </p>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  A Szolgáltató a Felhasználó
                  bankkártyaadatait rögzíti és nem tárolja.
                  Az adatbiztonsági követelményeknek
                  megfelelően a kártyaadatokhoz a
                  Szolgáltató nem fér hozzá, azokat kezelni
                  kizárólag a fizetési szolgáltatók
                  jogosultak.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  7.6.
                </p>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  A bankkártyás fizetés a Platformtól
                  teljesen független, a Szolgáltatóval
                  szerződött alábbi fizetési
                </p>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  szolgáltatók által üzemeltetett
                  elektronikus rendszereken keresztül
                  valósul meg:
                </p>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '42pt',
                    textIndent: '-18pt',
                    lineHeight: '113%',
                    textAlign: 'left',
                  }}
                >
                  <span className='s8'>-</span>Stripe Inc.,
                  nyilvántartási szám:{' '}
                  <span className='s9'>114218795</span>,
                  székhely: 510 Townsend Street San
                  Francisco, CA 94103 United States Stripe
                  fizetési rendszeren keresztül
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingTop: '8pt',
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  7.7.
                </p>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  Szolgáltató jogosult a saját mérlegelési
                  jogkörében bármikor váltani az
                  elsődlegesen használt és a biztonsági
                  fizetési szolgáltató rendszere között,
                  továbbá bármikor – így különösen technikai
                  probléma, átmeneti működésképtelenség
                  esetén – jogosult bármelyik fizetési
                  szolgáltató alkalmazását felfüggeszteni
                  időszakos jelleggel.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  7.8.
                </p>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  Stripe fizetési rendszer használata esetén
                  a bankkártya adatok beírására és mentésére
                  a Szolgáltató felületén kerül sor
                  átirányítás nélkül, azonban a
                  bankkártyaadatok fokozott biztonságú
                  ügyféloldali titkosítási technológiának
                  (CSE) köszönhetően a Megrendeléssel
                  titkosításra kerülnek, mely titkosítási
                  kulcshoz csak a fizetési szolgáltató fér
                  hozzá.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  7.9.
                </p>
                <p
                  style={{
                    paddingTop: '4pt',
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  Amennyiben sikertelen az Online fizetés,
                  úgy Szolgálató egyértelműen jelzi azt a
                  Platformon, ezt követően a fizetéshez
                  szükséges adatok módosításával a
                  Megrendelés újra leadható. Ha a
                  Felhasználó a külső elektronikus
                  felületről nem tér vissza a Platformra,
                  akkor a tranzakció sikertelennek minősül.
                  Ugyancsak sikertelennek minősül a
                  tranzakció, ha a külső fizetési felületen
                  a Felhasználó a böngésző „Vissza/Back”
                  vagy a „Frissítés/Refresh” gombjára
                  kattint.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  7.10.
                </p>
                <p
                  style={{
                    paddingTop: '5pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  A Felhasználónak lehetősége van elmentenie
                  a bankkártyájának az adatait (bankkártya
                  száma, bankkártyára írt név, lejárat
                  dátuma, CVC szám) annak érdekében, hogy a
                  jövőben kezdeményezett Megrendelések
                  alkalmával ne kelljen újra megadnia ezen
                  adatokat és így könnyebb, egyszerűbb
                  legyen a Megrendelés folyamata. Mentés
                  esetén minden adat ún. ”tokenként” (vissza
                  nem fejthető kódként) kerül tárolásra, a
                  későbbi rendeléskor csak a token kerül
                  felhasználásra és továbbításra. A
                  bankkártyaadatok tárolását a fizetési
                  szolgáltatók végzik.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  7.11.
                </p>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  Az Online fizetési módra a fizetési
                  szolgáltató, az érintett kártyatársaság,
                  továbbá a kártya kibocsátó és az összeget
                  jóváíró pénzügyi intézmény
                  üzletszabályzata irányadóak. Az Online
                  fizetéshez a böngészőnek támogatnia kell
                  az SSL titkosítást.
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text={8}>
            <h3
              style={{
                paddingTop: '8pt',
                paddingLeft: '17pt',
                textIndent: '-11pt',
                textAlign: 'left',
              }}
            >
              Futár Szolgáltatásra vonatkozó különös
              rendelkezések
            </h3>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              8.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Futár Szolgáltatás választása esetén a
              Felhasználó kifejezetten megrendeli gépjármű
              szállítási szolgáltatás nyújtását, és
              kifejezetten felhatalmazza a Partneri
              Szolgáltatást nyújtó Partnert, hogy a
              Felhasználó részére Futárt keressen és
              válasszon, illetve kifejezetten felhatalmazza
              a Felhasználó a Partnert, hogy a nevében közte
              és a Futár között a szállítás vonatkozásában
              szerződést kössön a Felhasználó által
              elfogadott feltételek szerint, a Futár pedig a
              szállítási felhívás elfogadásával hozzájárul
              ahhoz, hogy a Felhasználó részére a köztük
              lévő szerződés alapján szállítási
              szolgáltatást nyújtson.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              8.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Felhasználó elfogadja, hogy a közte és a Futár
              között létrejövő Futár Szolgáltatási szerződés
              feltételeit, így különösen határidejét
              (szállítási idő) és területei korlátait
              (szállítási terület), és a szállítás díját a
              Partneri Szolgáltatást nyújtó Partner
              egyoldalúan állapítja meg és bármikor jogosult
              azokat egyoldalúan megváltoztatni, akár
              ideiglenesen is.
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              8.3.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A szállítási díj összege az adott Szolgáltatói
              Egységtől való távolság, a Megrendelés
              időpontja, valamint az adott zónában elérhető
              Futárok függvényében változhat, azaz Partner
              jogosult a Felhasználó ugyanarra a címére,
              ugyanattól a Partnertől különböző időpontokban
              leadott Megrendelése során eltérő összegű
              Futár Szolgáltatási díjakat megállapítani.
              Felhasználó tudomásul veszi, hogy ugyanattól a
              Partnertől más címre, vagy más időpontban
              történő Megrendelés esetén az alkalmazott
              Futár Szolgáltatási díjak eltérőek lehetnek.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              8.4.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A várható teljesítési idő, a szállítási díj a
              Megrendelés leadása során a Platformon
              bármikor megismerhető, nyomon követhető.
              Felhasználó tudomásul veszi, hogy amennyiben
              Platformon megváltoztatja a szállítási címet,
              úgy ezzel párhuzamosan, mind a szállítási idő,
              mind a szállítási díj összege megváltozhat,
              illetve amennyiben az adott szállítási címre a
              választott Partner esetében a Futár
              Szolgáltatás már nem elérhető, úgy a
              Felhasználó a Futár Szolgáltatást nem fogja
              tudni igénybe venni és magának kell
              gondoskodnia a gépjárművének a Partner
              Egységébe illetve onnan visszaszállításáról.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              8.5.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Futár Szolgáltatás Megrendelésével a
              Felhasználó kötelezettséget vállal arra, hogy
              a megadott helyszínen a megadott időpontban a
              gépjármű átadása és átvétele végett elérhető
              lesz. Amennyiben a megadott időpont +15 percen
              belül a Felhasználó nem érhető el a
              helyszínen, a Futár nem köteles tovább várni a
              Felhasználóra, Futár Szolgáltatás
              teljesítettnek minősül, a Felhasználó a Futár
              Szolgáltatás díjának megfizetésére azonban
              köteles. Amennyiben a gépjármú
              visszaszállítása során nem érhető el a
              Felhasználó a megadott helyszínen, a megadott
              időpontban +15 perc, a Futár a gépjárművet
              visszaszállítja a Partnerhez, és ilyen esetben
              a Felhasználó a Futár Szolgáltatás díját a
              visszaszállítás költségével kiegészítetten
              köteles megfizetni.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              8.6.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Szolgáltatót nem terheli felelősség a Futár
              Szolgáltatás késedelmes vagy hibás
              teljesítéséért. Sem a Szolgáltatót, sem a
              Partnert sem pedig a Futárt nem terheli
              felelősség olyan problémáért, hibáért, amely
              abból ered, hogy a Felhasználó tévesen és/vagy
              pontatlanul adta meg a Futár Szolgáltatás
              teljesítéséhez szükséges adatokat.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
          </li>
          <li data-list-text={9}>
            <h3
              style={{
                paddingLeft: '17pt',
                textIndent: '-11pt',
                textAlign: 'left',
              }}
            >
              Egyéb rendelkezések
            </h3>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              9.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Felek kötelesek a Megrendelést a Platformon
              feltüntetettek szerint a jelen ÁSZF-ben
              foglalt feltételek és a jogszabályoknak
              megfelelően jóhiszeműen teljesíteni, a
              Megrendelés teljesítése során együttműködni
              egymással.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              9.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Amennyiben a Közvetített Szerződés és/vagy
              Futár Szolgáltatási Szerződés teljesítése a
              Felhasználó oldalán felmerülő okból hiúsult
              meg (ide nem értve a Fogyasztót jogszabály
              alapján megillető elállási jog vagy felmondási
              jog gyakorlásának esetét), úgy a Felhasználó a
              már kifizetett Közvetített Szerződés illetve
              Futár Szolgáltatási szerződés szolgáltatási
              díját, vételárát már nem követelheti vissza a
              Szolgáltatótól.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Felek rögzítik, hogy a Felhasználó oldalán
              felmerülő okból hiúsul meg a Futár
              Szolgáltatásra vonatkozó szerződés különösen,
              de nem kizárólag az alábbi esetekben:
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <ul id='l6'>
              <li data-list-text='-'>
                <p
                  className='s7'
                  style={{
                    paddingLeft: '60pt',
                    textIndent: '-18pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  {' '}
                  <span className='p'>
                    A Felhasználó rossz szállítási címet
                    választott, vagy téves címet ütött be,
                    miután a Felhasználó 15 perc után sem
                    elérhető a szállítási címre érkezéstől
                    számítva és legalább kétszer kereste
                    telefonon a Futár a Felhasználót.
                  </span>
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
              </li>
              <li data-list-text='-'>
                <p
                  className='s7'
                  style={{
                    paddingLeft: '60pt',
                    textIndent: '-18pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  {' '}
                  <span className='p'>
                    A Futár nem találja, vagy nem éri el a
                    Felhasználót, miután a Felhasználó 15
                    perc után sem elérhető a szállítási
                    címre érkezéstől számítva és legalább
                    kétszer kereste telefonon a Futár a
                    Felhasználót.
                  </span>
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
              </li>
              <li data-list-text='-'>
                <p
                  className='s7'
                  style={{
                    paddingLeft: '60pt',
                    textIndent: '-18pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  {' '}
                  <span className='p'>
                    A Felhasználó nem adja át a Futárnak a
                    gépjármű üzemeltetéséhez szükséges
                    eszközöket, illetve jogszabály által a
                    közúti használathoz szükséges okmányokat
                    (pl. forgalmi engedély).
                  </span>
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '6pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  Felek rögzítik, hogy a Felhasználó oldalán
                  felmerülő okból hiúsul meg a Közvetített
                  Szolgáltatásra vonatkozó szerződés
                  különösen, de nem kizárólag az alábbi
                  esetekben:
                </p>
                <p
                  style={{
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <br />
                </p>
              </li>
              <li data-list-text='-'>
                <p
                  style={{
                    paddingLeft: '60pt',
                    textIndent: '-18pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  A Felhasználó a Megrendelés leadását
                  követően módosítani kívánt a
                  Megrendelésen, de csak a Partner
                  visszaigazolása (Közvetített szerződés
                  létrejötte) után kéri a Megrendelés
                  törlését (ide nem értve a Fogyasztót
                  jogszabály alapján megillető elállási jog
                  vagy felmondási jog gyakorlásának esetét).
                </p>
              </li>
              <li data-list-text='-'>
                <p
                  className='s7'
                  style={{
                    paddingLeft: '60pt',
                    textIndent: '-18pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  {' '}
                  <span className='p'>
                    A Futár Szolgáltatási szerződés
                    bármilyen okból megszűnik és a
                    Felhasználó nem gondoskodik a
                    gépjárműnek a Partner Egységébe történő
                    szállításáról.
                  </span>
                </p>
              </li>
            </ul>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              9.3.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Felhasználó tudomásul veszi, hogy Szolgáltató,
              amennyiben a Partner nem képes teljesíteni a
              Megrendelést, úgy törölheti a Megrendelést,
              ezáltal jogosult megszüntetni a Partner és a
              Felhasználó között létrejött Közvetített
              Szerződést. Szolgáltató a Megrendelés
              törléséről tájékoztatja a Feleket, a
              Felhasználó részére push notification
              üzenetben, továbbá Online fizetési mód esetén
              intézkedik a Felhasználó által fizetett összeg
              visszautalásáról, vagy visszahelyeztetéséről.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              9.4.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Szolgáltató a Megrendelés meghiúsulásáért,
              törlésért a Közvetítői Szerződés megszűnéséért
              felelősséggel nem tartozik, így a Megrendelés
              törlése esetén vagy a Partner hibás
              teljesítése vagy a Futár hibás teljesítése
              esetén sem köteles a Felhasználó kárainak –
              vagyoni kár, költség, elmaradt haszon –
              megtérítésére.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
          </li>
          <li data-list-text={10}>
            <h3
              style={{
                paddingLeft: '23pt',
                textIndent: '-17pt',
                textAlign: 'left',
              }}
            >
              Panaszkezelés
            </h3>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              10.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              A Felhasználó az esetlegesen felmerülő panasz,
              reklamáció esetén a Szolgáltató
              ügyfélszolgálatával az alábbi elérhetőségek
              valamelyikén jogosult a kapcsolatot felvenni:
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Az NDRU weboldalon lenyíló menü
              „Ügyfélszolgálat” menüpontra kattintva,
              applikációban az
            </p>
            <p
              style={{
                paddingTop: '5pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              „Ügyfélszolgálat menüpontban
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Postázási cím: 1221 Budapest, Hajó utca 1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <a
                href='mailto:info@drivendru.com'
                className='s1'
                target='_blank'
              >
                E-mail cím:{' '}
              </a>
              <a
                href='mailto:info@drivendru.com'
                target='_blank'
              >
                info@drivendru.com
              </a>
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              10.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              A Szolgáltató panaszkezelése minden esetben
              térítésmentes.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              10.3.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              A Szolgáltató az azonnali megoldást igénylő
              problémát haladéktalanul megvizsgálja és
              lehetőség szerint azonnal orvosolja, minden
              más esetben pedig 24 napon belül válaszol a
              felmerült panaszra.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              10.4.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Amennyiben a probléma kezelése más módon nem
              oldható meg, úgy Szolgáltató kezdeményezheti a
              Felhasználó telefonos megkeresését. Ha a
              Szolgáltató a Felhasználót panasszal
              kapcsolatban keresi, úgy Szolgáltató a panasz
              kezelése és dokumentálása céljából a
              Felhasználó telefonon keresztül
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              megfogalmazott panaszát rögzíti, a rögzített
              hangfelvételt a rögzítéstől számított 5 évig
              megőrzi. Amennyiben a Felhasználó nem járul
              hozzá, hogy a telefonbeszélgetést Adatkezelő
              az Adatkezelési tájékoztatóban foglaltaknak
              megfelelően rögzítse, postai úton (1221
              Budapest, Hajó utca 1) vagy elektronikus
              levelezés (
              <span style={{ color: '#000' }}>
                info@drivendru.com
              </span>
              ) útján benyújtania a panaszát. A
              Felhasználónak lehetősége van másolatot kérni
              a telefonbeszélgetésről készült
              hangfelvételről, amely kérést Szolgáltató a
              vonatkozó kérelem beérkezésétől számított 25
              napon belül köteles teljesíteni. A Felhasználó
              a hangfelvétel kiadása iránti kérelmét postai
              úton (1221 Budapest, Hajó utca 1) vagy
              elektronikus levelezés (
              <span style={{ color: '#000' }}>
                info@drivendru.com
              </span>
              ) útján nyújthatja be. Az Adatkezelő
              díjmentesen rendelkezésre bocsátja (postai
              úton) a hangfelvétel másolatát.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              10.5.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              A Felhasználó a panasz kivizsgálása során
              köteles a Szolgáltatóval együttműködni, a
              szükséges információkat, dokumentumokat
              rendelkezésre bocsátani.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              10.6.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Amennyiben a Fogyasztó panasza teljesen vagy
              részlegesen elutasításra kerül, vagy a panasz
              kivizsgálására fent megszabott határidő
              eredménytelenül eltelt, úgy a Fogyasztó
              lakóhelye szerint illetékes hatóságokhoz
              fordulhat:
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Fogyasztóvédelmi hatóságok elérhetősége:{' '}
              <span style={{ color: '#000' }}>
                https://fogyasztovedelem.kormany.hu/#/fogyasztovedelmi_hatosag
              </span>
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Budapesten a Fővárosi Kormányhivatal V.
              Kerületi Hivatalának részeként működő
              Fogyasztóvédelmi Főosztálya:
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Cím: 1051 Budapest, Sas u. 19. III. em.
              Telefon: +36 (1) 450-2598
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <a
                href='mailto:fogyved_kmf_budapest@bfkh.gov.hu'
                className='s1'
                target='_blank'
              >
                E-mail cím:
              </a>
              <a
                href='mailto:fogyved_kmf_budapest@bfkh.gov.hu'
                target='_blank'
              >
                fogyved_kmf_budapest@bfkh.gov.hu
              </a>
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Békéltető Testületek elérhetősége területi
              illetékesség szerint:{' '}
              <a
                href='http://www.bekeltetes.hu/index.php?id=testuletek'
                className='a'
                target='_blank'
              >
                https://
              </a>
              <a
                href='http://www.bekeltetes.hu/index.php?id=testuletek'
                target='_blank'
              >
                www.bekeltetes.hu/index.php?id=testuletek
              </a>
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Budapesti Békéltető Testület elérhetősége:
              Cím: 1016 Budapest, Krisztina krt. 99.
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Telefonszám: +36 (1) 488-2131
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Fax szám: +36 (1) 488-2186
            </p>
            <p
              style={{
                paddingTop: '5pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <a
                href='mailto:bekelteto.testulet@bkik.hu'
                className='s1'
                target='_blank'
              >
                E-mail: bekelteto.testulet@bkik.hu
              </a>
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              10.7.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              A Fogyasztó jogosult a panasszal kapcsolatos
              jogvita esetén bírósághoz fordulni.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
          </li>
          <li data-list-text={11}>
            <h3
              style={{
                paddingTop: '10pt',
                paddingLeft: '23pt',
                textIndent: '-17pt',
                textAlign: 'left',
              }}
            >
              Személyes adatok kezelése
            </h3>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              11.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '149%',
                textAlign: 'left',
              }}
            >
              A Felhasználó személyes adatainak kezelésére
              vonatkozó részletes szabályokat a{' '}
              <span style={{ color: '#000' }}>
                Szolgáltató adatkezelési tájékoztatója{' '}
              </span>
              tartalmazza.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
          </li>
          <li data-list-text={12}>
            <h3
              style={{
                paddingLeft: '23pt',
                textIndent: '-17pt',
                textAlign: 'left',
              }}
            >
              A Platformmal kapcsolatos szellemi
              alkotásokhoz fűződő jogok
            </h3>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              12.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Platformon elhelyezett valamennyi anyag,
              annak teljes szövege, grafikai, illetve egyéb
              tartalma, a Platform szerkezeti felépítése,
              forráskódja, valamint bármely egyéb szellemi
              termék szerzői jogi, illetve egyéb jogi
              védelem alatt áll. A szerzői jogok, illetve
              egyéb szellemi alkotáshoz kapcsolódó jogok
              kizárólagos jogosultja a Szolgáltató. A
              Platform tartalmának, illetve a Szolgáltató
              egyéb szellemi alkotásának bármely
              felhasználása kizárólag a Szolgáltató előzetes
              írásbeli engedélyével lehetséges.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              12.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              A Platform tartalmának jogosulatlan
              felhasználása védjegyjogi, szerzői jogi és
              polgári jogi igényeken túlmenően a büntetőjogi
              jogszabályokban meghatározott szankciókat is
              maguk után vonhatnak.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
          </li>
          <li data-list-text={13}>
            <h3
              style={{
                paddingLeft: '23pt',
                textIndent: '-17pt',
                textAlign: 'left',
              }}
            >
              A Szerződés létrejötte és megszűnése
            </h3>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              13.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              A Szolgáltató és a Felhasználó közötti
              Szerződés a Felhasználó regisztrációjakor jön
              létre.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              13.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Felhasználó és a Szolgáltató között a
              Szerződés elektronikus úton megkötött
              szerződésnek minősül, amelyre a Ptk. és az
              Elkertv. elektronikus kereskedelmi
              szolgáltatásokra vonatkozó előírásai
              alkalmazandóak. A Közvetített Szerződés
              írásban foglaltnak minősül és írásban tárolt
              adatai megegyeznek a Megrendelés adataival. A
              Platformon keresztül megkötött Közvetített
              Szerződések adatai megtekinthetők
              visszamenőleg is a „Szerviztörténet” menüpont
              alatt. A szerződéskötés és a kapcsolattartás
              nyelve a magyar.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              13.3.
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Közvetített Szerződés akkor jön létre,
              amikor a Partnertől a Szolgáltatóhoz a
              Megrendelésről szóló elektronikus
              visszaigazolás megérkezik, feltéve, hogy addig
              a Felhasználó illetve a Fogyasztó nem vonta
              vissza a Megrendelést.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
          </li>
          <li data-list-text={14}>
            <h3
              style={{
                paddingLeft: '23pt',
                textIndent: '-17pt',
                textAlign: 'left',
              }}
            >
              Az ÁSZF tárgyi és időbeli hatálya
            </h3>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              14.1.
            </p>
            <p
              style={{
                paddingTop: '5pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              A jelen ÁSZF hatálya a Szolgáltató által
              Magyarország területén a Felhasználók részére
              nyújtott Szolgáltatásra terjed ki.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              14.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              A jelen ÁSZF irányadó a Szolgáltatások minden
              olyan felhasználási módjára (mobil weboldal,
              mobil alkalmazások, stb.), amelyeken keresztül
              a Platform elérhető.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              14.3.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Az ÁSZF a fent rögzített naptól hatályos és
              határozatlan időre szól.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
          </li>
          <li data-list-text={15}>
            <h3
              style={{
                paddingTop: '10pt',
                paddingLeft: '23pt',
                textIndent: '-17pt',
                textAlign: 'left',
              }}
            >
              Az ÁSZF módosítása és elérhetősége
            </h3>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              15.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A Szolgáltató bármikor jogosult a jelen ÁSZF
              feltételeit egyoldalúan módosítani. Ez a
              jogosultság kifejezetten kiterjed a jelen ÁSZF
              mellékleteire is. Az esetleges módosítás a
              Platformon való megjelenéssel egyidejűleg lép
              hatályba.
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
            <p
              style={{
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              15.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '6pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <a
                href='http://www.drivendru.com/aszf'
                className='s1'
                target='_blank'
              >
                Az ÁSZF és annak korábban hatályos verziói
                itt érhetőek el: www.drivendru.com/aszf
              </a>
            </p>
            <p
              style={{
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <br />
            </p>
          </li>
          <li data-list-text={16}>
            <h3
              style={{
                paddingTop: '10pt',
                paddingLeft: '23pt',
                textIndent: '-17pt',
                textAlign: 'left',
              }}
            >
              Záró rendelkezések
            </h3>
          </li>
        </ol>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingTop: '10pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          16.1.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          A Szolgáltató által üzemeltetett Platform
          biztonsági foka megfelelő, azonban a Felhasználó
          számára javasolt megtenni az alábbi
          óvintézkedéseket: friss adatbázissal rendelkező
          vírus és spyware védelmi szoftverek használata, az
          operációs rendszer biztonsági frissítéseinek
          telepítése. A Platform használata feltételezi a
          Felhasználó részéről az internet technikai és
          műszaki korlátainak ismeretét és a technológiával
          együtt járó hibalehetőségek elfogadását.
        </p>
        <p
          style={{
            paddingTop: '4pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          16.2.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          A Szolgáltató a Platformról ugrópontként elérhető
          külső honlapok tartalmáért, adat- és
          információvédelmi gyakorlatáért nem vállal
          felelősséget. Amennyiben a Szolgáltató tudomására
          jut, hogy az általa linkelt oldal vagy a linkelés
          harmadik személyek jogait vagy a hatályos
          jogszabályokat sérti, a linket haladéktalanul
          eltávolítja a Platformról.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          16.3.
        </p>
        <p
          style={{
            paddingTop: '5pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'left',
          }}
        >
          A Szolgáltató nem felelős semmilyen kárért, amely
          a Platformra való csatlakozás miatt következett
          be. A Felhasználót terheli a számítógépe, illetve
          az azon található adatok védelmének
          kötelezettsége.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          16.4.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'left',
          }}
        >
          Kifejezetten tilos a Platformon törvényileg nem
          engedélyezett tartalmak továbbítása, közlése,
          megosztása. A Szolgáltató fenntartja a jogot a
          Felhasználók által feltöltött tartalmak törlésére.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          16.5.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          A Szerződés mind a Felhasználó, mind a Szolgáltató
          részéről indokolás nélkül a Felhasználónak,
          illetve az Ügyfélszolgálatnak megküldött e-mail
          útján felmondható, továbbá a Felhasználónak
          lehetősége van az NDRU Fiókjának törlésére a
          Profil menüpont „Fiók törlése” gombra kattintva,
          mely esetben a Szolgáltató és a Felhasználó
          közötti szerződés a Fiók és a hozzá tartozó
          személyes adatok törlésével egyidejűleg megszűnik.
          A Felhasználó tudomásul veszi, hogy a Szerződés
          felmondásával, illetve az NDRU Fiókjának
          törlésével valamennyi olyan aat, melyet az NDRU
          Fiók keretében volt tárolva véglegesen és
          visszahozhatatlanul törlésre kerül, így például
          törlésre kerülnek a Felhasználó szervízelőzményei.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          16.6.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          A Szolgáltató jogosult a Szolgáltatásokból eredő
          bármely jogát vagy kötelezettségét a Felhasználó
          hozzájárulása nélkül részben vagy egészben
          átengedni jogutódjának, kapcsolt vállalkozásának,
          vagy a Szolgáltató vagyonát és vagyonelemeit
          megszerző más gazdasági társaságnak.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          16.7.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          A Szolgáltató nem veti alá magát semmilyen
          magatartási kódex rendelkezéseinek.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingTop: '10pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          16.8.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          A Szerződésre a magyar jog rendelkezései az
          irányadók.
        </p>
        <p
          style={{
            paddingTop: '4pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'left',
          }}
        >
          Az ÁSZF-ben hivatkozott jogszabályok, továbbá a
          Szerződésre és a Szolgáltatásra egyébként
          vonatkozó jogszabályok:
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <ul id='l7'>
          <li data-list-text='-'>
            <p
              style={{
                paddingLeft: '59pt',
                textIndent: '-17pt',
                textAlign: 'left',
              }}
            >
              A Polgári Törvénykönyvről szóló 2013. évi V.
              törvény (<b>Ptk.</b>)
            </p>
          </li>
          <li data-list-text='-'>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '59pt',
                textIndent: '-17pt',
                textAlign: 'left',
              }}
            >
              Az általános adatvédelmi rendelet (2016/679/EU
              rendelet; <b>GDPR</b>)
            </p>
          </li>
          <li data-list-text='-'>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '60pt',
                textIndent: '-18pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Az információs önrendelkezési jogról és az
              információszabadságról szóló 2011. évi CXII.
              törvény (<b>Infotv.</b>)
            </p>
          </li>
          <li data-list-text='-'>
            <p
              style={{
                paddingLeft: '60pt',
                textIndent: '-18pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              <span className='s7'> </span>Az elektronikus
              kereskedelmi szolgáltatások, valamint az
              információs társadalommal összefüggő
              szolgáltatások egyes kérdéseiről szóló 2001.
              évi CVIII. törvény (<b>Elkertv.</b>)
            </p>
          </li>
          <li data-list-text='-'>
            <p
              style={{
                paddingLeft: '62pt',
                textIndent: '-20pt',
                textAlign: 'left',
              }}
            >
              A fogyasztókkal szembeni tisztességtelen
              kereskedelmi gyakorlat tilalmáról szóló 2008.
            </p>
            <p
              style={{
                paddingTop: '5pt',
                paddingLeft: '60pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              évi XLVII. törvény (<b>Fttv.</b>)
            </p>
          </li>
          <li data-list-text='-'>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '59pt',
                textIndent: '-17pt',
                textAlign: 'left',
              }}
            >
              A fogyasztóvédelemről szóló 1997. évi XCL.
              törvény (<b>Fvtv.</b>)
            </p>
          </li>
          <li data-list-text='-'>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '60pt',
                textIndent: '-18pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              A fogyasztó és a vállalkozás közötti
              szerződések részletes szabályairól szóló
              45/2014. (II. 26.) Kormányrendelet (
              <b>Fvkr.</b>)
            </p>
          </li>
          <li data-list-text='-'>
            <p
              style={{
                paddingLeft: '60pt',
                textIndent: '-18pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              A tisztességtelen piaci magatartás és a
              versenykorlátozás tilalmáról szóló 1996. évi
              LVII. törvény (<b>Tpvt.</b>)
            </p>
          </li>
          <li data-list-text='-'>
            <p
              style={{
                paddingLeft: '59pt',
                textIndent: '-17pt',
                textAlign: 'left',
              }}
            >
              A védjegyek és a földrajzi árujelzők
              oltalmáról szóló 1997. évi XI. törvény (
              <b>Vtv.</b>)
            </p>
          </li>
        </ul>
        <h3
          style={{
            paddingTop: '4pt',
            paddingLeft: '116pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          1. sz. Melléklet – A Közvetített Szerződés
          tartalma
        </h3>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingTop: '10pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          1.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          A Közvetített Szerződés akkor jön létre, amikor a
          Megrendelés leadását követően az elektronikus
          visszaigazolás megérkezik a Szolgáltatóhoz. A
          Közvetített Szerződés tartalmát a jelen Melléklet
          határozza meg.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          2.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          A Közvetített Szerződés elektronikus úton
          megkötött szerződésnek minősül, amelyre a Ptk. és
          az Elkertv. elektronikus kereskedelmi
          szolgáltatásokra vonatkozó előírásai
          alkalmazandóak. A Közvetített Szerződés írásban
          foglaltnak minősül és írásban tárolt adatai
          megegyeznek a Megrendelés adataival. A Közvetített
          Szerződés és Rendelés adatai megtekinthetők
          visszamenőleg is a „Rendeléseim” menüpont alatt. A
          szerződéskötés és a kapcsolattartás nyelve a
          magyar.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          3.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          A Platformon szereplő aktuális tartalommal, a
          megjelenített ajánlatokkal, ezek elérhetőségével
          és minőségével kapcsolatos minden felelősség a
          Partnert terheli. A Felhasználó a Partneri
          Szolgáltatással kapcsolatos minőségi kifogás
          esetén a Partnerhez fordulhat, ezzel kapcsolatos
          igényét csak a Partnerrel szemben tudja
          érvényesíteni.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          4.
        </p>
        <p
          style={{
            paddingTop: '5pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          A Partner felel a Termékekre, Partneri
          Szolgáltatásokra vonatkozó jogszabályban rögzített
          tájékoztatás megtörténtéért és megfelelő
          tartalmáért. A Szolgáltató csak Megrendeléseket
          közvetít, így a Partneri Szolgáltatás nyújtásában,
          a kapcsolódó termékek összekészítésében,
          beszerzésében, előállításában, beépítésében nem
          működik közre, arra sem hatása, sem rálátása
          nincs, így az e pont szerinti tájékoztatási
          kötelezettségek nem terhelik. A Szolgáltató
          azonban a Platform megfelelő kialakításával
          megteremti a szükséges feltételeit annak, hogy a
          Partner teljeskörűen megfelelhessen a számára
          előírt fenti jogszabályi rendelkezéseknek. A
          Szolgáltató felelőssége kizárólag a Partner által
          megfelelő formában megküldött adatok pontos
          megjelenítésére terjed ki. A Partner köteles a
          hatályos jogszabályoknak megfelelő adatokat
          közölni a Szolgáltatóval a Platformon való
          megjelenést megelőzően, továbbá változás esetében
          haladéktalanul köteles értesíteni a Szolgáltatót.
          Az adatközlés jogszerűségéért, teljességéért és
          valóságáért kizárólag a Partner felel.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          5.
        </p>
        <p
          style={{
            paddingTop: '5pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'left',
          }}
        >
          A következők feltüntetésével a vonatkozó hazai és
          uniós jogszabályoknak megfelelően minden
          felelősség a Partnert terheli a Fogyasztóval
          szemben: kötelező jótállási idők,
          termékszavatosság,
        </p>
        <p
          style={{
            paddingTop: '4pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          kellékszavatosság, minőségbiztosítási szabványok,
          biztonsági előírások, használati módok
          ismertetése.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          6.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          A hatályos jogszabályok szerint minden esetben
          kötelező tájékoztatáson felül, a Fogyasztó
          kifejezett kérésére az érintett termékeket
          forgalmazó Partnernek kötelezően tájékoztatnia
          kell a Fogyasztót az adott termékre vonatkozó
          jótállási, kellék- és termékszavatossági
          feltételekről, illetve a Partneri Szolgáltatás
          kellékszavatossági feltételeiről. Ezt a
          kötelezettséget a Partner közvetlenül teljesíti, a
          Szolgáltató ezeket az adatokat nem gyűjti, tárolja
          és adja ki. A Szolgáltató vállalja, hogy a
          Fogyasztó ilyen irányú kéréseit továbbítja a
          Partner felé.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          7.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'left',
          }}
        >
          A Fogyasztók panaszainak kezelése a Partner
          felelőssége, azzal, hogy abban a Szolgáltató is
          közreműködik (ld. ÁSZF panaszkezelésre vonatkozó
          rendelkezései).
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          8.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'left',
          }}
        >
          A szállítás, amennyiben Futár Szolgáltatás
          lehetőségét a Partner biztosítja a Felhasználó
          számára, kizárólag a Partner kötelezettsége.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          9.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          A Közvetített Szerződés nem, vagy nem megfelelő
          teljesítése esetén a jelen ÁSZF 9. fejezetében
          foglaltak alkalmazandóak, amennyiben azok a
          Partner és a Felhasználó közötti Közvetített
          Szerződés vonatkozásában értelemszerűen
          alkalmazhatóak.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          10.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          Partner felelős a Megrendelés nem, vagy nem
          megfelelő teljesítésért, és ő köteles a
          Felhasználó felé helytállni különösen, de nem
          kizárólag az alábbi esetekben: Partner Egységének
          zárva tartása, termékhiány, technikai probléma,
          Platformon feltüntetett téves információ, vagy
          ajánlat, Partneri Szolgáltatás késedelmes
          teljesítése.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          11.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          Amennyiben a Partner olyan terméket forgalmaz
          illetve olyan Partneri Szolgáltatást nyújt,
          amelyet illetően a Fogyasztónak az Fvkr. 20.
          §-ában biztosított elállási illetve felmondási
          joga van, úgy a Partner köteles megteremteni annak
          a feltételeit, hogy azt a Fogyasztó megfelelően
          érvényesíthesse ezen jogait. A Fogyasztó
          elállásáról, felmondásáról a Partner köteles
          haladéktalanul írásban értesíteni a Szolgáltatót.
        </p>
        <p
          style={{
            paddingTop: '4pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          12.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          A Fogyasztó nem gyakorolhatja az Fvkr. 20. §-ában
          foglalt elállási vagy felmondási jogát a Fvkr. 29.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          §-ában meghatározott esetekben.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingTop: '10pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          13.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '149%',
            textAlign: 'left',
          }}
        >
          A Fogyasztó az elállási jogát az arra vonatkozó
          egyértelmű nyilatkozatnak a Szolgáltatóhoz mobil
          applikáción keresztül tudja gyakorolni.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          14.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          Szolgáltató az elállási jog, felmondási jog
          gyakorlásának jogszerűségét, illetve a kapcsolódó
          díj- visszatérítés jogosságát, vagy annak
          esetleges elutasítását nem vizsgálja. Szolgáltató
          a visszatérítést a Fogyasztó által igénybe vett
          fizetési móddal megegyező módon teljesíti a
          Fogyasztó részére.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: '6pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          15.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '6pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'left',
          }}
        >
          Amennyiben a Szolgáltató már elszámolt a
          Partnerrel a Rendelés értékével, úgy a
          visszatérítés a Partner kötelezettsége.
        </p>
      </div>
    </Page>
  );
}

export default NdruTermsOfServicesHu;
