import { useContext } from 'react';
import Page from '../components/Page';
import MobileAppsSection from '../components/MobileAppsSection';
import ContactUsSection from '../components/ContactUsSection';
import Context from '../context/Context';

function ContactUs() {
  const { usedLanguage } = useContext(Context);
  return (
    <Page
      title={
        usedLanguage === 'en' ? 'Contact us' : 'Vedd fel velünk a kapcsolatot!'
      }
      endpoint='contact-us'
    >
      <ContactUsSection />
      <MobileAppsSection />
    </Page>
  );
}
export default ContactUs;
