export const categoriesTexts = {
  en: {
    categories: [
      ['Repair', 'shops'],
      ['Car', 'cleaning'],
      ['Tire', 'change'],
    ],
  },
  hu: {
    categories: [
      ['Szakszervizek', ''],
      ['Autómosók', ''],
      ['Gumiszervizek', ''],
    ],
  },
};
