import szbdImg from '../assets/images/our-mission-page/team/szbd.jpg';
import kkImg from '../assets/images/our-mission-page/team/kk.jpg';
import hmImg from '../assets/images/our-mission-page/team/hm.jpg';
import fpImg from '../assets/images/our-mission-page/team/fp.jpg';
import cssImg from '../assets/images/our-mission-page/team/css.jpg';


export const teamSectionTexts = {
  en: {
    h2: 'Team',
    teamMembers: [
      {
        position: 'Marketing',
        name: 'Bence Domonkos Szabó',
        img: szbdImg,
      },
      {
        position: 'CEO',
        name: 'Krisztián Kövesdán',
        img: kkImg,
      },
      {
        position: 'CoFounder',
        name: 'Máté Hegedűs',
        img: hmImg,
      },
      {
        position: 'Head of Marketing',
        name: 'Fruzsina Peti',
        img: fpImg,
      },
      {
        position: 'Lead Developer',
        name: 'Csongor Simon',
        img: cssImg,
      },
    ],
  },
  hu: {
    h2: 'Csapat',
    teamMembers: [
      {
        position: 'Marketing',
        name: 'Szabó Bence Domonkos',
        img: szbdImg,
      },
      {
        position: 'Vezérigazgató',
        name: 'Kövesdán Krisztián',
        img: kkImg,
      },
      {
        position: 'Társalapító',
        name: 'Hegedűs Máté',
        img: hmImg,
      },
      {
        position: 'Marketing vezető',
        name: 'Peti Fruzsina',
        img: fpImg,
      },
      {
        position: 'Vezető fejlesztő',
        name: 'Simon Csongor',
        img: cssImg,
      },
    ],
  },
};
