import { Link } from 'react-router-dom';

const truncateString = (text) => {
  return text.substring(0, 226) + '...';
};

function BlogCard({ image, alt = '', title, text, link, date }) {
  return (
    <Link to={link} className='blog-card'>
      <div className='blog-card__img-container'>
        <img src={image} alt={alt} className='blog-card__img' />
      </div>
      <div className='blog-card__body'>
        <small className='blog-card__header'>Product update</small>

        <div className='blog-card__main-content'>
          <h5 className='blog-card__title'>{title}</h5>
          <p className='blog-card__text'>{truncateString(text)}</p>
        </div>

        <small className='blog-card__date'>{date}</small>
      </div>
    </Link>
  );
}
export default BlogCard;
