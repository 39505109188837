export const pricinfTexts = {
  en: {
    h1: `Free management platform for professional
    car service providers`,
    pageTitle: 'Pricing',
    p: `Join nDRU and enjoy the subscription-free
    revolutionary solution`,
    btns: ['Create your account now', 'Log in'],
  },
  hu: {
    h1: `Emeld új szintre vállalkozásod hatékonyságát és szerezz új ügyfeleket`,
    pageTitle: 'Áraink',
    p: `Az első 100 szolgáltatónak díjmentes az nDRU bevezetése és használata.`,
    btns: ['Csatlakozom', 'Bejelentkezés'],
  },
};
