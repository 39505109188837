import { useContext } from 'react';

import appStoreSvg from '../assets/images/icons/app-store.svg';
import googlePlaySvg from '../assets/images/icons/google-play.svg';
import phoneImg from '../assets/images/page1/phone/img_hero_driver@3x.png';

import Page from '../components/Page';

import { mobileAppLinks } from '../config/mobileAppLinks';
import Context from '../context/Context';

import { landingDriversTexts } from '../texts/landingDriversTexts';
import Categories from '../components/Categories';
import FeaturedLocations from '../components/FeaturedLocations';
import MoreThan from '../components/MoreThan';
import BoostUpYourRepairShop from '../components/BoostUpYourRepairShop';

function LandingDrivers() {
  const { usedLanguage } = useContext(Context);

  const {
    h1,
    categoriesH2,
    dtrStart,
    dtrTexts,
    dtrEnd,
    sections,
    moreThanText,
  } = landingDriversTexts[usedLanguage];

  const { appStore, googlePlay } = mobileAppLinks;

  return (
    <Page title='Landing drivers'>
      {/* Hero  */}
      <section className='section section--hero1'>
        <div className='container'>
          <div>
            <h1 className='d-none'>{h1}</h1>

            <div className='dtr'>
              <span className='dtr__start'>{dtrStart}</span>
              <div className='dtr__texts'>
                <ul>
                  {dtrTexts.map((text, index) => (
                    <li key={text + index}>{text}</li>
                  ))}
                </ul>
              </div>
              <span className='dtr__end'>{dtrEnd}</span>
            </div>

            <div className='hero1__badges'>
              <a
                href={appStore}
                target='_blank'
                className='badge app-store'
              >
                <img
                  src={appStoreSvg}
                  alt='app-store badge'
                />
              </a>
              <a
                href={googlePlay}
                target='_blank'
                className='badge play-store'
              >
                <img
                  src={googlePlaySvg}
                  alt='google-play badge'
                />
              </a>
            </div>

            <div className='hero1__image'>
              <div className='phone'>
                <img src={phoneImg} alt='Phone' />

                <div className='circle-1'>
                  <svg
                    viewBox='0 0 261 264'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <ellipse
                      cx='130.5'
                      cy='132'
                      rx='130.5'
                      ry='132'
                      fill='#72D66F'
                    />
                  </svg>
                </div>
                <div className='circle-2'>
                  <svg
                    viewBox='0 0 149 150'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <ellipse
                      cx='74.5'
                      cy='75'
                      rx='74.5'
                      ry='75'
                      fill='#2B2B2B'
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Categories  */}
      <section className='section section--extra-padding-bottom'>
        <div className='container'>
          <h2 className='section__title'>{categoriesH2}</h2>
          <Categories />
        </div>
      </section>

      {/* Featured locations in Hungary  */}
      <FeaturedLocations />

      {/* Sections  */}
      {sections.map((section, index) => {
        if (index === sections.length - 1) {
          return (
            <section
              key={section.h2 + index}
              className='section--border-bottom-from-md section--no-padding-bottom mb-5'
            >
              <div className='container'>
                <div className='row align-items-center'>
                  <div className='col-12 col-md-6 mb-3 mb-md-0 section__col--border-bottom-until-md'>
                    <picture className='section__image section__image--left'>
                      <img
                        srcSet={
                          section.imgs.length > 1
                            ? `${section.imgs[0]} 654w,
                            ${section.imgs[1]} 981w`
                            : section.imgs[0]
                        }
                        alt={section.alt}
                      />
                    </picture>
                  </div>
                  <div className='col-12 col-md-6 section__text-content--right'>
                    <h2 className='section__title section__title--text-align-left section__title--margin-bottom-sm'>
                      {section.h2}
                    </h2>
                    <p className='section__paragraph'>
                      {section.p}
                    </p>
                  </div>
                </div>
              </div>
            </section>
          );
        }

        if (index % 2 === 0) {
          return (
            <section
              key={section.h2 + index}
              className='section'
            >
              <div className='container'>
                <div className='row align-items-center'>
                  <div className='col-12 col-md-6 mb-4 mb-md-0'>
                    <picture className='section__image section__image--left'>
                      <img
                        sizes='570px'
                        srcSet={
                          section.imgs.length > 1
                            ? `${section.imgs[0]} 654w,
                          ${section.imgs[1]} 981w`
                            : section.imgs[0]
                        }
                        alt={section.alt}
                        loading='lazy'
                      />
                    </picture>
                  </div>
                  <div className='col-12 col-md-6 section__text-content--right'>
                    <h2 className='section__title section__title--text-align-left section__title--margin-bottom-sm'>
                      {section.h2}
                    </h2>
                    <p className='section__paragraph'>
                      {section.p}
                    </p>
                  </div>
                </div>
              </div>
            </section>
          );
        } else {
          return (
            <section
              key={section.h2 + index}
              className='section'
            >
              <div className='container'>
                <div className='row align-items-center'>
                  <div className='col-12 col-md-6 order-2 order-md-1 section__text-content--left'>
                    <h2 className='section__title section__title--text-align-left section__title--margin-bottom-sm'>
                      {section.h2}
                    </h2>
                    <p className='section__paragraph'>
                      {section.p}
                    </p>
                  </div>
                  <div className='col-12 col-md-6 order-1 order-md-2 mb-4 mb-md-0'>
                    <picture className='section__image section__image--right'>
                      <img
                        sizes='570px'
                        srcSet={
                          section.imgs.length > 1
                            ? `${section.imgs[0]} 654w,
                           ${section.imgs[1]} 981w`
                            : section.imgs[0]
                        }
                        alt={section.alt}
                        loading='lazy'
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </section>
          );
        }
      })}

      {/* More than */}
      <section className='section section--extra-padding-bottom-sm my-lg-3'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-4'>
              <h2 className='section__title section__title--text-align-left section__title--margin-bottom-sm section__title--more-than mb-4 mb-md-0'>
                {moreThanText}
              </h2>
            </div>
            <MoreThan />
          </div>
        </div>
      </section>

      {/* Boost up your repair */}
      <BoostUpYourRepairShop />
    </Page>
  );
}
export default LandingDrivers;
