import { useContext, useEffect } from 'react';

import Page from '../components/Page';
import SliderSection from '../components/SliderSection';
import HubSpotForm from '../components/HubSpotFormSection';


import laptopSvg from '../assets/images/page2/page2-hero/img_laptop_shop-lg.svg';
import laptopPng from '../assets/images/page2/page2-hero/img_laptop_shop2.png';
import laptopSvgSm from '../assets/images/page2/page2-hero/img_laptop_shop-sm.svg';

import { serviceDrivendruLinks } from '../config/serviceDrivendruLinks';
import Context from '../context/Context';
import { repairShopsLandingTexts } from '../texts/repairShopsLandingTexts';

import Categories from '../components/Categories';
import MoreThan from '../components/MoreThan';

function RepairShops() {
  const { usedLanguage } = useContext(Context);

  const {
    h1,
    categoriesH2,
    dtr2Start,
    dtr2Texts,
    dtr2End,
    btns,
    sections,
    moreThanText,
  } = repairShopsLandingTexts[usedLanguage];

  const { createAccount, login } = serviceDrivendruLinks;

  return (
    <Page
      title={
        usedLanguage === 'en' ? 'Repair shops' : 'Szervizek'
      }
      endpoint='repair-shops'
    >
      <section className='section section--hero2'>
        <div className='container'>
          <div className='hero2__row'>
            <div className='hero2__column1'>
              <h1 className='d-none'>{h1}</h1>

              <div className='dtr2'>
                <span className='dtr2__start'>
                  {dtr2Start[0]}
                  <br />
                  {dtr2Start[1]}
                </span>
                <div className='dtr2__texts'>
                  <ul>
                    {dtr2Texts.map((text, index) => (
                      <li key={text + index}>{text}</li>
                    ))}
                  </ul>
                </div>
                <span className='dtr2__end'>{dtr2End}</span>
              </div>

              <div className='row'>
                <div className='col-12 col-sm-10 offset-sm-1 offset-md-0 mb-3 mb-md-1 mb-lg-0 col-lg-8 pr-lg-2'>
                  <a
                    href={createAccount}
                    className='btn btn--green'
                  >
                    {btns[0]}
                  </a>
                </div>
                <div className='col-12 col-sm-10 offset-sm-1 offset-md-0 col-lg-4 pl-lg-0'>
                  <a
                    href={login}
                    className='btn btn--login'
                  >
                    {btns[1]}
                  </a>
                </div>
              </div>
            </div>
            <div className='hero2__column2'>
              <picture className='hero2__image'>
                <source
                  srcSet={laptopSvg}
                  media='(min-width: 1400px)'
                />
                <source
                  srcSet={laptopPng}
                  media='(min-width: 768px)'
                />
                <img
                  srcSet={laptopSvgSm}
                  alt='Manage your repair shop easily'
                />
              </picture>
            </div>
          </div>
        </div>
      </section>

      {/* Categories */}
      <section className='section section--extra-padding-bottom'>
        <div className='container'>
          <h2
            className='section__title'
            style={{
              maxWidth: '679px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            {categoriesH2}
          </h2>
          <Categories />
        </div>
      </section>

      {/* More than */}
      <section className='section section--extra-padding-bottom-sm my-lg-3'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-4'>
              <h2 className='section__title section__title--text-align-left section__title--margin-bottom-sm section__title--more-than mb-4 mb-md-0'>
                {moreThanText}
              </h2>
            </div>
            <MoreThan />
          </div>
        </div>
      </section>

      {/* Sections  */}
      {sections.map((section, index) => {
        if (index % 2 === 0) {
          return (
            <section
              key={section.h2 + index}
              className='section'
            >
              <div className='container'>
                <div className='row align-items-center'>
                  <div className='col-12 col-md-6 mb-4 mb-md-0'>
                    <picture className='section__image section__image--left'>
                      <img
                        sizes='570px'
                        srcSet={
                          section.imgs.length > 1
                            ? `${section.imgs[0]} 654w,
                          ${section.imgs[1]} 981w`
                            : section.imgs[0]
                        }
                        alt={section.alt}
                        loading='lazy'
                      />
                    </picture>
                  </div>
                  <div className='col-12 col-md-6 section__text-content--right'>
                    <h2 className='section__title section__title--text-align-left section__title--margin-bottom-sm'>
                      {section.h2}
                    </h2>
                    <p className='section__paragraph'>
                      {section.p}
                    </p>
                    { (index == 2) ? 
                      <a
                      href={createAccount}
                      className='btn btn--green col-4 mt-4'
                      >
                        {btns[0]}
                      </a>
                     : ''}
                  </div>
                </div>
              </div>
            </section>
          );
        } else {
          return (
            <section key={section.h2} className='section'>
              <div className='container'>
                <div className='row align-items-center'>
                  <div className='col-12 col-md-6 order-2 order-md-1 section__text-content--left'>
                    <h2 className='section__title section__title--text-align-left section__title--margin-bottom-sm'>
                      {section.h2}
                    </h2>
                    <p className='section__paragraph'>
                      {section.p}
                    </p>
                  </div>
                  <div className='col-12 col-md-6 order-1 order-md-2 mb-4 mb-md-0'>
                    <picture className='section__image section__image--right'>
                      <img
                        sizes='570px'
                        srcSet={
                          section.imgs.length > 1
                            ? `${section.imgs[0]} 654w,
                           ${section.imgs[1]} 981w`
                            : section.imgs[0]
                        }
                        alt={section.alt}
                        loading='lazy'
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </section>
          );
        }
      })}

      {/* Slider */}
      <SliderSection />

      {/* Get in touch */}
      {/* <GetInTouch />*/}
      <HubSpotForm />
      
    </Page>
  );
}
export default RepairShops;
