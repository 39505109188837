import Rating from './Rating';

function Location({ rating, img, address, title }) {
  return (
    <div className='location'>
      <div className='img-container'>
        <img loading='lazy' src={img} alt={title} className='img-fluid' />
      </div>
      <h5>{title}</h5>
      <p className='location__address'>{address}</p>
      <Rating rating={rating} />
    </div>
  );
}
export default Location;
