import React, { useContext } from 'react';
import Context from '../context/Context';
import { ndruIsGrowingSection } from '../texts/ndruIsGrowingSectionTexts';

function NdruIsGrowingSection() {
  const { usedLanguage } = useContext(Context);
  const { h2, h3, p, h3Right, lis } =
    ndruIsGrowingSection[usedLanguage];

  return (
    <section className='ndru-is-growing'>
      <div className='container--special'>
        <h2 className='ndru-is-growing__title'>{h2}</h2>

        <div className='row'>
          <div className='col-md-6'>
            <h3 className='ndru-is-growing__subtitle'>
              {h3}
            </h3>
            <p className='ndru-is-growing__paragraph'>
              {p}
            </p>
          </div>
          <div className='col-md-6'>
            <h3 className='ndru-is-growing__list-title'>
              {h3Right}
            </h3>
            <ul className='ndru-is-growing__list'>
              {lis.map((li) => (
                <li key={li}>
                  <span>{li}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NdruIsGrowingSection;
