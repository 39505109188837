import Page from "../components/Page";
import { useEffect } from 'react';

function QuestionnairePage() {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = '//embed.typeform.com/next/embed.js';

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    });

  return (<Page title='autozas-felmeres-2024' endpoint='autozas-felmeres-2024'>
    <div data-tf-live='01HZ4D21Q0CPQAV5D1DBHDN1ZQ'></div>
  </Page>);
}

export default QuestionnairePage;
