import { useContext } from 'react';
import Context from '../context/Context';
import { keyResultsTexts } from '../texts/keyResultsTexts';

const KeyResults = () => {
  const { usedLanguage } = useContext(Context);

  const { h2, p, results } = keyResultsTexts[usedLanguage];

  return (
    <section className='key-results section'>
      <div className='container'>
        <div className='key-results__intro'>
          <h2>{h2}</h2>
          <p>{p}</p>
        </div>

        <div className='key-results__grid'>
          {results.map((result, index) => (
            <div
              key={result.h5 + index}
              className='key-results__item'
            >
              <div className='key-results__percentage'>
                {result.percentage}
              </div>
              <div className='key-results__texts'>
                <h5> {result.h5}</h5>
                <p>{result.p}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default KeyResults;
