import Page from "../components/Page";
import { useContext } from "react";
import Context from '../context/Context';

function DeleteAccountPage() {
  const { usedLanguage } = useContext(Context);

  return (
    <Page title="Delete account" endpoint="DeleteAccount">
      {usedLanguage === "en" ? (
        <>
          <h1 className="delete_account__page-title">Account deletion</h1>
          <div className="container delete_account__page-content">
            <section>
              To delete your account:
              <ul>
                <li>
                  open the nDRU app on your mobile phone then navigate to the
                  profile settings page with the bottom navigation bar
                </li>
                <li>Here select the "Your account" menu option</li>
                <li>
                  Scroll down to the bottom of the page and press the "Delete
                  account" button
                </li>
                <li>
                  Press the "Delete" button on the next page to confirm the
                  deletion
                </li>
              </ul>
            </section>
            <section>
              With the deletion of your account your stored personal information
              (name, email, phone number, address) will be deleted, along with
              your payment information. Your stored cars and their information
              will be also deleted.
            </section>
            <section>
              If you have any other concerns, please contact us via the{" "}
              <a href="mailto:info@drivendru.com">info@drivendru.com</a> email address.
            </section>
          </div>
        </>
      ) : (
        <>
          <h1 className="delete_account__page-title">Felhasználói fiók törlése</h1>
          <div className="container delete_account__page-content">
            <section>
              A felhasználói fiók törléséhez:
              <ul>
                <li>
                  Nyissa meg az nDRU applikációt a mobil telefonján és navigáljon el a profil beállítások oldalra az alsó navigációs menüt használva.
                </li>
                <li>Itt válassza ki a  "Személyes adatok módosítása" menüpontot</li>
                <li>
                  Görgessen le az oldal aljára és nyomjon a "Fiók törlése" gombra
                </li>
                <li>
                  A megjelenő oldalon nyomjon a "Törlés" gombra, hogy megerősítse a felhasználói fiók törlését
                </li>
              </ul>
            </section>
            <section>
                A felhasználói fiók törlésével a fiókban tárolt személyes adatok (név, email, telefonszám, számlázási cím) törlésre kerülnek.
                A felvett fizetési információk is törlésre kerülnek a fiók törlése során.
                Minden felvett autó és azok adatai is törlésre kerülnek a fiók törlése során.
            </section>
            <section>
              Ha bármi más kérdés merülne még fel, kérjük vegye fel velünk a kapcsolatot az{" "}
              <a href="mailto:info@drivendru.com">info@drivendru.com</a> email címen.
            </section>
          </div>
        </>
      )}
    </Page>
  );
}
export default DeleteAccountPage;
