import BlogPostPageComponent from '../components/BlogPostPageComponent';
import Page from '../components/Page';

function BlogPostPageNdruHasLaunched() {
  return (
    <Page title='nDRU has launched' endpoint='blog/nDRU-has-launched'>
      <BlogPostPageComponent
        title='nDRU has launched – the app simplifying life with
  a car'
        meta={{
          author: 'Krisztián Kövesdán',
          year: '2022',
          date: 'on 12th November',
        }}
        introduction='Our team has launched three digital tools for an elevated car experience: a
      mobile app for car owners to easily book automotive services, a cloud-based
      service platform for repair shops and other service providers to improve their
      processes and efficiency, and a fleet platform for car fleet managers to
      streamline their operations.'
        paragraphs={[
          {
            title: 'Digital solutions for an analog automotive industry',
            texts: [
              'After more than 2 years of planning, design, development, and testing, then going through this cycle all over again, the nDRU team is proud to present its solutions that enable car owners and automotive service providers to finally enter the digital age. We are incredibly excited to finally reach this milestone and share what we’ve been tirelessly working on.',
              'Our aim was to create an application that does all things us motorists might need, and does better than any other options currently on the market, whether digital or not. Some websites might already offer the option to book appointments for a car wash or a repair, and some apps already provide insurance products or highway passes digitally, but these are just fregments to what this single app will cover with such great efficiency! With the launch of nDRU, a new, digital era begins for car owners.',
              'What’s more, nDRU also provides a digital platform for automotive service providers, where they can manage their operations with high effectiveness. With three repair shops and many users from Hungary already on board at launch, the team is excited to finally share nDRU’s three main products with the public.',
            ],
          },
          {
            title: 'The only mobile app car owners will ever need',
            texts: [
              'Owning a car should be a great experience, yet maintaining it can cause a few headaches. You have this fabulous piece of machinery, developed by great engineers, just waiting for you to hop in. Once you’re inside, your music is playing, driving is great fun, and your loyal four-wheeled steed takes you to your destination without question. However, there are some strings attached. Starting with loads of administration when lets say you are trying to get an insurance for your vehicle, or even just bringing it for a yearly check-up can cause a few hassle, not to mention any bigger repairs. All these caveats can ruin the car experience. nDRU makes owning a car as easy as it can be. Users can add their car’s details and repair history, then quickly book any services, be it roadside assistance, a new insurance offer, or an appointment at the repair shop. In the near future, it will also be possible to get highway passes and pay for parking right in this one app, which contains everything us motorists might need for our cars. Our aim is to make life as a car owner easier and more transparent than ever.',
            ],
          },
          {
            title: 'A platform made by a repair shop for repair shops',
            texts: [
              'Yet we didn’t stop there. Instead, we set out to create a superior experience for the service providers of the automotive industry as well. While some have already started to improve their operations with digital solutions, we are certain that we will soon provide a product that has no match on the market. Our subscription-free services can be used so efficiently by managers, employees, and mechanics alike. nDRU comes with an innovative ERP system for repair shops, car washes, and tire change shops. As a service provider, you can use this cloud-based system to keep track of everything going on at the repair shop and communicate effortlessly with clients. Powerful process management features, coupled with the client-facing application ensures that your enterprise will be much more efficient than ever before. And the best is yet to come: the nDRU management platform is completely free to use, furthermore providers are listed in the nDRU app, ensuring more client bookings to their services.',
            ],
          },
          {
            title: 'Car fleet management simplified',
            texts: [
              "This is still not everything though. The nDRU mobile app is great for managing a few personal vehicles, but you can only do so much on a small screen. For those that need more powerful features that enable to keep track of an entire car fleet: nDRU has got you covered. The fleet platform allows managers of car fleets to get a high-level overview of their cars and to book, track and manage their repairs. With all data stored in the cloud, it becomes easier to identify inefficiencies in the vehicle management process and track the spendings of the repair process for each car of the entire fleet. Managers can get a complete overview of their cars' repair history and costs in a single platform and streamline their spendings – all free of charge.",
            ],
          },
        ]}
      />
    </Page>
  );
}
export default BlogPostPageNdruHasLaunched;
