import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import logo from '../assets/images/icons/ndru_logo.svg';
import appStoreSvg from '../assets/images/icons/app-store.svg';
import googlePlaySvg from '../assets/images/icons/google-play.svg';
import { footerLinks } from '../config/footerLinks';
import { mobileAppLinks } from '../config/mobileAppLinks';

import Context from '../context/Context';

function Footer() {
  const location = useLocation();

  const { usedLanguage } = useContext(Context);

  const { about, business, legal, social } = footerLinks;
  const { appStore, googlePlay } = mobileAppLinks;

  const toTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className='pt-5 site-footer'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-3 col-lg-4'>
            <div className='row'>
              <div className='col-6 col-md-12'>
                <Link
                  to='/'
                  onClick={
                    location.pathname === '/'
                      ? toTop
                      : undefined
                  }
                  className='site-footer__logo'
                >
                  <img
                    loading='lazy'
                    src={logo}
                    alt='logo'
                  />
                  <strong>nDRU</strong>
                </Link>
              </div>
              <div className='col-6 col-md-12'>
                <div className='site-footer__social'>
                  <a href={social.facebook} target='_blank'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <title>Facebook</title>
                      <g opacity='0.32'>
                        <path
                          d='M17 1.88965H14C12.6739 1.88965 11.4021 2.41419 10.4645 3.34789C9.52678 4.28158 9 5.54795 9 6.86839V9.85564H6V13.8386H9V21.8046H13V13.8386H16L17 9.85564H13V6.86839C13 6.6043 13.1054 6.35103 13.2929 6.16429C13.4804 5.97755 13.7348 5.87264 14 5.87264H17V1.88965Z'
                          stroke='#1F2125'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </g>
                    </svg>
                  </a>
                  <a href={social.instagram} target='_blank'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <title>Instagram</title>
                      <g opacity='0.32'>
                        <path
                          d='M17 1.88965H7C4.23858 1.88965 2 4.11871 2 6.86839V16.8259C2 19.5756 4.23858 21.8046 7 21.8046H17C19.7614 21.8046 22 19.5756 22 16.8259V6.86839C22 4.11871 19.7614 1.88965 17 1.88965Z'
                          stroke='#1F2125'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M15.9999 11.22C16.1234 12.0488 15.9812 12.8951 15.5937 13.6387C15.2062 14.3824 14.5931 14.9854 13.8416 15.362C13.0901 15.7387 12.2384 15.8698 11.4077 15.7367C10.5771 15.6036 9.80971 15.2131 9.21479 14.6207C8.61987 14.0283 8.22768 13.2642 8.09402 12.4371C7.96035 11.6099 8.09202 10.7619 8.47028 10.0136C8.84854 9.26525 9.45414 8.65474 10.2009 8.26889C10.9477 7.88304 11.7977 7.74149 12.6299 7.86438C13.4789 7.98972 14.2648 8.38362 14.8716 8.98788C15.4785 9.59215 15.8741 10.3747 15.9999 11.22Z'
                          stroke='#1F2125'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M17.5 6.37061H17.51'
                          stroke='#1F2125'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </g>
                    </svg>
                  </a>
                  <a href={social.linkedin} target='_blank'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <title>LinkedIn</title>
                      <g opacity='0.32'>
                        <path
                          d='M16 8C17.5913 8 19.1174 8.63214 20.2426 9.75736C21.3679 10.8826 22 12.4087 22 14V21H18V14C18 13.4696 17.7893 12.9609 17.4142 12.5858C17.0391 12.2107 16.5304 12 16 12C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V21H10V14C10 12.4087 10.6321 10.8826 11.7574 9.75736C12.8826 8.63214 14.4087 8 16 8V8Z'
                          stroke='#1F2125'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M6 9H2V21H6V9Z'
                          stroke='#1F2125'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z'
                          stroke='#1F2125'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-9 col-lg-8'>
            <div className='row'>
              <div className='col-6 col-md-3'>
                <div className='site-footer__block'>
                  <h3>
                    {usedLanguage === 'en'
                      ? 'About'
                      : 'Rólunk'}
                  </h3>
                  <nav>
                    <ul>
                      {about[usedLanguage].map((item) => (
                        <li key={item.linkText}>
                          {item.isLinkTag ? (
                            <Link
                              to={item.to}
                              onClick={
                                location.pathname ===
                                item.to
                                  ? toTop
                                  : undefined
                              }
                            >
                              {item.linkText}
                            </Link>
                          ) : (
                            <a href={item.to}>
                              {item.linkText}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
              <div className='col-6 col-md-3'>
                <div className='site-footer__block'>
                  <h3>
                    {usedLanguage === 'en'
                      ? 'Business'
                      : 'Vállalatoknak'}
                  </h3>
                  <nav>
                    <ul>
                      {business[usedLanguage].map(
                        (item) => (
                          <li key={item.linkText}>
                            <Link
                              to={item.to}
                              onClick={
                                location.pathname ===
                                item.to
                                  ? toTop
                                  : undefined
                              }
                            >
                              {item.linkText}
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
              <div className='col-6 col-md-3'>
                <div className='site-footer__block'>
                  <h3>
                    {usedLanguage === 'en'
                      ? 'Legal'
                      : 'Jogi tájékoztatás'}
                  </h3>
                  <nav>
                    <ul>
                      {legal[usedLanguage].map((item) => (
                        <li key={item.linkText}>
                          <Link
                            to={item.to}
                            onClick={
                              location.pathname === item.to
                                ? toTop
                                : undefined
                            }
                          >
                            {item.linkText}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
              <div className='col-6 col-md-3'>
                <div className='site-footer__block site-footer__block--badge'>
                  <h3>
                    {usedLanguage === 'en'
                      ? 'Mobile app'
                      : 'Mobilalkalmazás'}
                  </h3>
                  <nav>
                    <ul>
                      <li>
                        <a
                          href={appStore}
                          target='_blank'
                          className='badge app-store'
                        >
                          <img
                            loading='lazy'
                            src={appStoreSvg}
                            alt='app-store'
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href={googlePlay}
                          target='_blank'
                          className='badge play-store'
                        >
                          <img
                            loading='lazy'
                            src={googlePlaySvg}
                            alt='google-play'
                          />
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className='site-footer__hr' />
      <div className='container'>
        <p className='site-footer__copyright py-4'>
          &copy; Copyright NDRU 1.0 Ltd. -{' '}
          <Link
            to={legal[usedLanguage][0].to}
            onClick={
              location.pathname ===
              legal[usedLanguage][0].to
                ? toTop
                : undefined
            }
          >
            Privacy Policy
          </Link>
        </p>
      </div>
    </footer>
  );
}
export default Footer;
