import { useContext } from 'react';
import Context from '../context/Context';
import { useCasesSectionTexts } from '../texts/useCasesSectionTexts';

const UseCasesSection = () => {
  const { usedLanguage } = useContext(Context);

  const { h2, useCases } =
    useCasesSectionTexts[usedLanguage];

  return (
    <section className='section py-lg-5 my-lg-5'>
      <div className='container-fluid container--large'>
        <h2 className='section__title'>{h2}</h2>

        <div className='row'>
          {useCases.map((useCase, index) => (
            <div
              key={useCase.title[0] + index}
              className='col-12 col-md-4 mb-4 mb-md-0 h-100'
            >
              <div className='cases__item'>
                <img
                  className='cases__icon'
                  src={useCase.img}
                  alt=''
                />
                <div className='cases__title'>
                  {useCase.title[0]}&nbsp;
                  <br className='d-none d-md-block d-lg-none' />
                  {useCase.title[1]}
                </div>
                <div className='cases__text'>
                  {useCase.text}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default UseCasesSection;
