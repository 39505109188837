function Loader({ show }) {
  return (
    <div className={`loader ${show ? 'show' : ''}`}>
      <div className='circle'></div>
      <div className='circle'></div>
      <div className='circle'></div>
    </div>
  );
}
export default Loader;
