import trackProcess2x from '../assets/images/page2/track-all-repair-processes/img_track_process@2x.png';
import trackProcess3x from '../assets/images/page2/track-all-repair-processes/img_track_process@3x.png';

import communicate2x from '../assets/images/page2/communicate-with-your-clients/img_communicate@2x.png';
import communicate3x from '../assets/images/page2/communicate-with-your-clients/img_communicate@3x.png';

import worksheets2x from '../assets/images/page2/detailed-worksheets/img_worksheets@2x.png';
import worksheets3x from '../assets/images/page2/detailed-worksheets/img_worksheets@3x.png';

export const repairShopsLandingTexts = {
  en: {
    h1: `Manage your repair shop easily and stay in
    touch with your clients`,
    categoriesH2: 'Pick a business type to get started',
    dtr2Start: [`Manage your repair shop `, `easily and`],
    dtr2Texts: [
      'stay in touch with',
      'send messages to',
      'offer new services for',
      'send invoices to',
      'stay in touch with',
    ],
    dtr2End: 'your clients',
    btns: ['Create your account now', 'Log in'],
    sections: [
      {
        imgs: [trackProcess2x, trackProcess3x],
        alt: 'Track all repair processes',
        h2: `Track all repair processes`,
        p: `NDRU car repair shop management platform
        will assist you in managing your entire
        shop. It allows you to schedule
        appointments, track repairs by status and
        easily update statuses to our customers.
        With our kan-ban view your will be able to
        have a great overview of all current repair
        processes.`,
      },
      {
        imgs: [communicate2x, communicate3x],
        alt: 'Communicate with your clients',
        h2: `Communicate with your clients`,
        p: `With NDRU service platform you can manage
        your type of services, customers, cars and
        provide price quotes. The online solution
        allows you to communicate with your
        customers to accept bookings, repairs,
        quotes and in doing so updating the them
        about the status.`,
      },
      {
        imgs: [worksheets2x, worksheets3x],
        alt: 'Detailed worksheets',
        h2: `Detailed worksheets`,
        p: `NDRU is an all encompassing application for
        smartphones allowing you to view incoming
        cars on the go and update their status
        accordingly. Paired with our platform, you
        can create quotes, receive payments by
        credit card and automatically create and
        send out invoices for your clients.`,
      },
    ],
    moreThanText: 'nDRU in numbers',
  },
  hu: {
    h1: `Lásd át a folyamatokat és kezeld foglalásaid könnyedén`,
    categoriesH2:
      'Válassz egy szolgáltatástípust a kezdéshez',
    dtr2Start: [`Lásd át a folyamatokat `, `és kezeld`],
    dtr2Texts: [
      'foglalásaid',
      'munkalapjaidat',
      'új ügyfeleidet',
      'a számlázást',
      'foglalásaid',
    ],
    dtr2End: 'könnyedén',
    btns: ['Csatlakozom', 'Bejelentkezés'],
    sections: [
      {
        imgs: [trackProcess2x, trackProcess3x],
        alt: 'Tedd hatékonnyabbá szervizfolyamataidat',
        h2: `Tedd hatékonnyabbá szervizfolyamataidat`,
        p: `Az nDRU segít szervized könnyű irányításában: kezeld a foglalásokat egy helyen, kövesd a javítások státuszát és tedd hatékonnyá folyamataidat!`,
      },
      {
        imgs: [communicate2x, communicate3x],
        alt: 'Egyszerűsítsd le a kommunikációt',
        h2: `Egyszerűsítsd le a kommunikációt`,
        p: `A platformon keresztül ügyfeleidnek ajánlatokat küldhetsz, árakat javasolhatsz és értesítheted őket az elkészült munkáról, ezzel radikálisan csökkentve a telefonos kommunikációt.`,
      },
      {
        imgs: [worksheets2x, worksheets3x],
        alt: 'Lásd át a folyamatokat részletes munkalapokkal',
        h2: `Lásd át a folyamatokat részletes munkalapokkal`,
        p: `Az nDRU-n keresztül átláthatod a szervizbe érkező összes autó munkalapját és azok státuszát, hogy vállalkozásod teljesen online irányíthasd - bárhonnan, bármikor!`,
      },
    ],
    moreThanText: 'Az nDRU számokban',
  },
};
