import { Link } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import headerArrow from '../assets/images/icons/header-arrow.svg';
import logo200 from '../assets/images/icons/ndru_logo.svg';
import { GB, HU } from 'country-flag-icons/react/3x2';
import { useHeader } from '../hooks/useHeader';

function Header() {
  const {
    siteHeader,
    menuContent,
    menuToggler,
    menuLinks,
    mainMenuLink,
    appStore,
    googlePlay,
    usedLanguage,
    toggleUsedLanguage,
    toggleMenu,
  } = useHeader();

  return (
    <header ref={siteHeader} id='home' className='site-header'>
      <div className='container-fluid container--large'>
        <Link to='/' className='site-header__logo'>
          <picture>
            {/* <source media='(min-width: 500px)' srcSet={logo100} /> */}
            <img src={logo200} alt='logo' />
          </picture>
        </Link>

        <MobileView>
          <div
            ref={menuToggler}
            className='site-header__toggler'
            onClick={toggleMenu}
          >
            <span className='toggler__label'> Menu </span>

            <div className='toggler__icon'>
              <div className='middle'></div>
            </div>
          </div>

          <div ref={menuContent} className='site-header__menu-content'>
            <div className='container'>
              <h2 className='menu-content__header'>Menu</h2>
              <nav className='primary-nav'>
                <ul>
                  {menuLinks.map((link, index) => (
                    <li key={index}>
                      {['Download the app', 'nDRU Letöltés'].includes(link.title) ? (
                        <details className='header-details'>
                          <summary>
                            <span className='summary-title summary-title--font-weight-normal'>
                              {link.title}
                            </span>
                            <div className='summary-chevron-up'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='feather feather-chevron-down'
                              >
                                <polyline points='6 9 12 15 18 9'></polyline>
                              </svg>
                            </div>
                          </summary>

                          <div className='summary-content'>
                            <a className='link app-store' href={appStore}>
                              {usedLanguage === 'en' ? 'for iOS' : 'iOS rendszerhez'}
                            </a>
                            <a className='link play-store' href={googlePlay}>
                              {usedLanguage === 'en'
                                ? 'for Android'
                                : 'Android rendszerhez'}
                            </a>
                          </div>
                          <div className='summary-chevron-down'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className='feather feather-chevron-up'
                            >
                              <polyline points='18 15 12 9 6 15'></polyline>
                            </svg>
                          </div>
                        </details>
                      ) : link.isLinkTag ? (
                        <Link to={link.to}>
                          {link.title}
                          <img src={headerArrow} alt='arrow' />
                        </Link>
                      ) : (
                        <a href={link.to}>
                          {link.title}
                          <img src={headerArrow} alt='arrow' />
                        </a>
                      )}
                    </li>
                  ))}

                  <li onClick={toggleUsedLanguage}>
                    {usedLanguage === 'en' ? (
                      <span className='language-switcher'>
                        <HU title='Hungarian' className='country-flag' />
                        <span className='language-text'>Hungarian</span>
                      </span>
                    ) : (
                      <span className='language-switcher'>
                        <GB title='English' className='country-flag' />
                        <span className='language-text'>English</span>
                      </span>
                    )}
                  </li>

                  <hr className='site-header__hr' />

                  <li>
                    {['For Business', 'Vállalatoknak'].includes(
                      mainMenuLink.title
                    ) ? (
                      <details className='header-details'>
                        <summary>
                          <span className='summary-title'>{mainMenuLink.title}</span>
                          <div className='summary-chevron-up'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className='feather feather-chevron-down'
                            >
                              <polyline points='6 9 12 15 18 9'></polyline>
                            </svg>
                          </div>
                        </summary>

                        <div className='summary-content'>
                          <Link
                            className='link'
                            to={
                              usedLanguage === 'en'
                                ? '/repair-shops'
                                : '/szervizek-partnerek'
                            }
                          >
                            {usedLanguage === 'en' ? 'Repair shops' : 'Szervizek'}
                          </Link>
                          <Link
                            className='link'
                            to={
                              usedLanguage === 'en'
                                ? '/service-providers'
                                : '/szolgaltatok'
                            }
                          >
                            {usedLanguage === 'en'
                              ? 'Service providers'
                              : 'Szolgáltatók'}
                          </Link>
                          <Link
                            className='link'
                            to={usedLanguage === 'en' ? '/fleet' : '/flotta'}
                          >
                            {usedLanguage === 'en'
                              ? 'Fleet managers'
                              : 'Flottakezelők'}
                          </Link>
                        </div>
                        <div className='summary-chevron-down'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className='feather feather-chevron-up'
                          >
                            <polyline points='18 15 12 9 6 15'></polyline>
                          </svg>
                        </div>
                      </details>
                    ) : (
                      <a href={mainMenuLink.to} className='primary-nav__link--last'>
                        {mainMenuLink.title}
                        <img src={headerArrow} alt='arrow' />
                      </a>
                    )}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </MobileView>

        <BrowserView>
          <div className='container'>
            <nav className='desktop-nav'>
              <ul>
                {menuLinks.map((link, index) => (
                  <li key={index}>
                    {['Download the app', 'nDRU Letöltés'].includes(link.title) ? (
                      <div className='dropdown-menu'>
                        <a href={link.to}>{link.title}</a>
                        <div className='dropdown-content'>
                          <a className='link app-store' href={appStore}>
                            {usedLanguage === 'en' ? 'for iOS' : 'iOS rendszerhez'}
                          </a>
                          <a className='link play-store' href={googlePlay}>
                            {usedLanguage === 'en'
                              ? 'for Android'
                              : 'Android rendszerhez'}
                          </a>
                        </div>
                      </div>
                    ) : link.isLinkTag ? (
                      <Link to={link.to}>{link.title}</Link>
                    ) : (
                      <a href={link.to}>{link.title}</a>
                    )}
                  </li>
                ))}

                <li>
                  {['For Business', 'Vállalatoknak'].includes(mainMenuLink.title) ? (
                    <div className='dropdown-menu'>
                      <a href={mainMenuLink.to}>{mainMenuLink.title}</a>
                      <div className='dropdown-content'>
                        <Link
                          className='link'
                          to={
                            usedLanguage === 'en'
                              ? '/repair-shops'
                              : '/szervizek-partnerek'
                          }
                        >
                          {usedLanguage === 'en' ? 'Repair shops' : 'Szervizek'}
                        </Link>
                        <Link
                          className='link'
                          to={
                            usedLanguage === 'en'
                              ? '/service-providers'
                              : '/szolgaltatok'
                          }
                        >
                          {usedLanguage === 'en'
                            ? 'Service providers'
                            : 'Szolgáltatók'}
                        </Link>
                        <Link
                          className='link'
                          to={usedLanguage === 'en' ? '/fleet' : '/flotta'}
                        >
                          {usedLanguage === 'en'
                            ? 'Fleet managers'
                            : 'Flottakezelők'}
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <a href={mainMenuLink.to}>{mainMenuLink.title}</a>
                  )}
                </li>

                <li onClick={toggleUsedLanguage}>
                  {usedLanguage === 'en' ? (
                    <span className='language-switcher'>
                      <HU title='Hungarian' className='country-flag' />
                      <span className='language-text'>Hungarian</span>
                    </span>
                  ) : (
                    <span className='language-switcher'>
                      <GB title='English' className='country-flag' />
                      <span className='language-text'>English</span>
                    </span>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        </BrowserView>
      </div>
    </header>
  );
}
export default Header;
