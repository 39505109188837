export const mobileAppsSectionTexts = {
  en: {
    pLeft: 'Mobile app for professionals',
    pRight: 'Mobile app for car owners',
  },
  hu: {
    pLeft: 'Mobil app vállalatoknak',
    pRight: 'Mobil app autósoknak',
  },
};
