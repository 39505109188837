import { useEffect, useRef } from 'react';

import throttle from 'lodash/throttle';

import upArrowSvg from '../assets/images/icons/up-arrow.svg';

function BackToTop() {
  const btn = useRef(null);

  let isVisible = false;

  const toggleVisibility = throttle(() => {
    if (window.scrollY > 500 && !isVisible) {
      btn.current.style.display = 'flex';
      const timeout = setTimeout(() => {
        btn.current.classList.add('back-to-top-btn--visible');
        isVisible = true;
        clearTimeout(timeout);
      }, 500);
    } else if (window.scrollY < 500 && isVisible) {
      btn.current.classList.remove('back-to-top-btn--visible');
      const timeout = setTimeout(() => {
        btn.current.style.display = 'none';
        isVisible = false;
        clearTimeout(timeout);
      }, 550);
    }
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', () => toggleVisibility());
  }, [toggleVisibility]);

  return (
    <a
      ref={btn}
      href='#home'
      id='back-to-top'
      className='back-to-top-btn'
      title='home'
      role='button'
    >
      <img src={upArrowSvg} alt='back to top' />
    </a>
  );
}
export default BackToTop;
