import { useContext } from 'react';

import Page from '../components/Page';
import HubSpotForm from '../components/HubSpotFormSection';

import heroPhoneSvg524 from '../assets/images/service-providers/hero/phone.svg';
import arrowSvg from '../assets/images/service-providers/hero/arrow.svg';
import Context from '../context/Context';
import { serviceProvidersPageTexts } from '../texts/serviceProvidersPageTexts';
import MoreThan from '../components/MoreThan';
import UseCasesSection from '../components/UseCasesSection';
import ProviderFeatures from '../components/ProviderFeatures';

function ServiceProviders() {
  const { usedLanguage } = useContext(Context);

  const { h1, p, btnText, caption, moreThanText } =
    serviceProvidersPageTexts[usedLanguage];

  return (
    <Page
      title={
        usedLanguage === 'en'
          ? 'Service providers'
          : 'Szolgáltatók'
      }
      endpoint='service-providers'
    >
      <section className='section'>
        <div className='container'>
          <div className='hero-service-providers'>
            <div className='hero-service-providers__texts'>
              <h1 className='hero-service-providers__title'>
                {h1}
              </h1>
              <p className='hero-service-providers__subtitle'>
                {p}
              </p>
              <div className='hero-service-providers__buttons'>
                <div className='row'>
                  <div className='col-6 offset-3 col-md-4 offset-md-4 offset-lg-0'>
                    <a
                      href={usedLanguage === 'en' ? '/contact-us' : '/kapcsolat'}
                      className='btn btn--green'
                    >
                      {btnText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='hero-service-providers__images'>
              <div className='left-side'></div>
              <div className='hero-service-providers__image-container'>
                <img
                  className='phone'
                  srcSet={`${heroPhoneSvg524} 524w`}
                  sizes='262px'
                  alt=''
                />

                <div className='caption'>{caption}</div>

                <img
                  className='arrow'
                  srcSet={`${arrowSvg} 60w`}
                  sizes='30px'
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* More than */}
      <section className='section section--extra-padding-bottom-sm my-lg-3'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-4'>
              <h2 className='section__title section__title--text-align-left section__title--margin-bottom-sm section__title--more-than mb-4 mb-md-0'>
                {moreThanText}
              </h2>
            </div>
            <MoreThan />
          </div>
        </div>
      </section>

      {/* Use cases */}
      <UseCasesSection />

      {/* Features */}
      <ProviderFeatures />

      <HubSpotForm />
    </Page>
  );
}
export default ServiceProviders;
