import { useContext } from 'react';
import logo from '../assets/images/icons/ndru_logo.svg';
import logoTransparent from '../assets/images/icons/ndru_logo_transparent.png';
import googlePlaySvg from '../assets/images/icons/google-play.svg';
import appStoreSvg from '../assets/images/icons/app-store.svg';
import phoneLeft from '../assets/images/pricing-page/phones/phone-left.png';
import phoneRight from '../assets/images/pricing-page/phones/phone-right.png';

import { mobileAppLinks } from '../config/mobileAppLinks';
import Context from '../context/Context';
import { mobileAppsSectionTexts } from '../texts/mobileAppsSectionTexts';

function MobileAppsSection() {
  const { usedLanguage } = useContext(Context);

  const { pLeft, pRight } =
    mobileAppsSectionTexts[usedLanguage];

  const { appStore, googlePlay } = mobileAppLinks;

  return (
    <section className='mobile-apps section'>
      <div className='container'>
        <div className='mobile-apps__left'>
          <div className='mobile-apps__text'>
            <div className='mobile-apps__ndru'>
              <img
                loading='lazy'
                src={logo}
                alt='ndru logo'
              />
              <span>nDRU</span>
            </div>
            <p>{pLeft}</p>
            <div className='badges'>
              <a
                href={googlePlay}
                target='_blank'
                className='badge'
              >
                <img
                  loading='lazy'
                  src={googlePlaySvg}
                  alt='google-play'
                />
              </a>
              <a
                href={appStore}
                target='_blank'
                className='badge'
              >
                <img
                  loading='lazy'
                  src={appStoreSvg}
                  alt='app-store'
                />
              </a>
            </div>
          </div>
          <div className='mobile-apps__phone'>
            <img
              loading='lazy'
              src={phoneLeft}
              alt='mobile application'
            />
          </div>
        </div>

        <div className='mobile-apps__right'>
          <div className='mobile-apps__text'>
            <div className='mobile-apps__ndru'>
              <img
                loading='lazy'
                src={logoTransparent}
                style={{ width: '50px' }}
                alt=''
              />
              <span>nDRU</span>
            </div>
            <p>{pRight}</p>
            <div className='badges'>
              <a
                href={googlePlay}
                target='_blank'
                className='badge'
              >
                <img
                  loading='lazy'
                  src={googlePlaySvg}
                  alt='google-play'
                />
              </a>
              <a
                href={appStore}
                target='_blank'
                className='badge'
              >
                <img
                  loading='lazy'
                  src={appStoreSvg}
                  alt='app-store badge'
                />
              </a>
            </div>
          </div>
          <div className='mobile-apps__phone'>
            <img
              loading='lazy'
              src={phoneRight}
              alt='mobile application'
            />
          </div>
        </div>
      </div>
    </section>
  );
}
export default MobileAppsSection;
