export const categoryLinks = {
  en: {
    repairShops: '/repair-shops/list',
    carCleaning: '/repair-shops/list',
    tireChange: '/repair-shops/list'
  },
  hu: {
    repairShops: '/szervizek-partnerek/lista',
    carCleaning: '/szervizek-partnerek/lista',
    tireChange: '/szervizek-partnerek/lista',
  }
};
