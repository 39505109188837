import { Link } from 'react-router-dom';
import Page from '../components/Page';
import GetInTouchSection from '../components/GetInTouchSection';

// images
import settingsIcon from '../assets/images/position-page/settings.svg';
import settingsGreyIcon from '../assets/images/position-page/settings-grey.svg';
import locationIcon from '../assets/images/position-page/location.svg';
import clockIcon from '../assets/images/position-page/clock.svg';
import BenefitsCards from './BenefitsCards';

function OpenPosition({
  positionTitle,
  boxTitle,
  positionDescription,
  positionTasksTitle,
  positionTasks,
  positionOtherThingsTitle,
  positionOtherThings,
  whatWeOfferTitle,
  whatWeOffer,
  clausesTitle,
  clauses,
  endpoint,
}) {
  const title = `Open position: ${positionTitle}`;
  return (
    <Page title={title} endpoint={endpoint}>
      <div className='container position'>
        <div className='row'>
          <div className='col-12 col-md-4'>
            <aside>
              <div className='row'>
                <div className='col-12'>
                  <div className='position-box'>
                    <div className='position-box__header'>
                      <img
                        src={settingsIcon}
                        alt=''
                        className='position-box__icon'
                      />
                      <span className='position-box__position'>
                        Senior
                      </span>
                    </div>
                    <div className='position-box__body'>
                      {boxTitle}
                    </div>
                    <div className='position-box__footer'>
                      <div className='position-box__location'>
                        <img
                          src={locationIcon}
                          alt=''
                          className='icon'
                        />
                        <div className='city'>Budapest</div>
                      </div>
                      <div className='position-box__shift'>
                        <img
                          src={clockIcon}
                          alt=''
                          className='icon'
                        />
                        <div className='hours'>
                          Full-time
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <Link
                    to='/contact-us'
                    className='apply-now-btn'
                  >
                    Apply Now
                  </Link>
                </div>
              </div>
            </aside>
          </div>
          <div className='col-12 col-md-8 position__right'>
            <h1 className='position__title'>
              {positionTitle}
            </h1>
            <div className='position__info-icons'>
              <div className='position__info-icon'>
                <img
                  src={settingsGreyIcon}
                  alt=''
                  className='icon'
                />
                <div className='text'>Senior</div>
              </div>
              <div className='position__info-icon'>
                <img
                  src={locationIcon}
                  alt=''
                  className='icon'
                />
                <div className='text'>Budapest</div>
              </div>
              <div className='position__info-icon'>
                <img
                  src={clockIcon}
                  alt=''
                  className='icon'
                />
                <div className='text'>Full-time</div>
              </div>
            </div>
            <div className='position__descriptions'>
              {positionDescription.map((paragraph) => (
                <p
                  key={paragraph}
                  className='position__description'
                >
                  {paragraph}
                </p>
              ))}
            </div>
            <section className='position__list-section'>
              <p className='intro'>{positionTasksTitle}</p>
              <ul className='position__list'>
                {positionTasks.map((positionTask) => (
                  <li key={positionTask}>
                    <span>{positionTask}</span>
                  </li>
                ))}
              </ul>
            </section>

            <section className='position__list-section'>
              <p className='intro'>
                {positionOtherThingsTitle}
              </p>
              <ul className='position__list'>
                {positionOtherThings.map(
                  (positionOtherThing) => (
                    <li key={positionOtherThing}>
                      <span>{positionOtherThing}</span>
                    </li>
                  )
                )}
              </ul>
            </section>

            <section className='position__list-section'>
              <p className='intro'>{whatWeOfferTitle}</p>
              <ul className='position__list'>
                {whatWeOffer.map((item) => (
                  <li key={item}>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </section>

            <section className='position__list-section '>
              <p className='intro intro--clauses'>
                {clausesTitle}
              </p>
              <ul className='position__list position__list--clauses'>
                {clauses.map((clause) => (
                  <li key={clause}>
                    <span>{clause}</span>
                  </li>
                ))}
              </ul>
            </section>

            <section className='position__ndru'>
              <h2>About NDRU:</h2>
              <p>
                At NDRU, we work as a car lover so that
                vehicle owners and service providers can
                easily connect and use innovative services
                on a daily basis. At NDRU, we work as a car
                lover so that vehicle owners and service
                providers can easily connect and use
                innovative services on a daily basis. At
                NDRU, we work as a car lover so that vehicle
                owners and service providers can easily
                connect and use innovative services on a
                daily basis. At NDRU, we work as a car lover
                so that vehicle owners and service providers
                can easily connect and use innovative
                services on a daily basis.
              </p>
              <Link
                to='/careers'
                className='btn position__careers-btn'
              >
                Careers
              </Link>
            </section>
          </div>
        </div>
      </div>

      <BenefitsCards extraPaddingBottom='4rem' />

      <GetInTouchSection />
    </Page>
  );
}
export default OpenPosition;
