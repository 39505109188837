export const serviceProvidersPageTexts = {
  en: {
    h1: 'Join our store with your service',
    p: `nDRU provides a marketplace to car service
    providers for listing and selling their
    products and services`,
    btnText: 'Join us',
    caption: 'Your service listed in the nDRU app',
    moreThanText: 'nDRU in numbers',
  },
  hu: {
    h1: 'Jelenj meg szolgáltatásoddal az nDRU-n',
    p: `Az nDRU piacteret biztosít az autós szolgáltatóknak termékeik és szolgáltatásaik listázására és értékesítésére`,
    btnText: 'Csatlakozom',
    caption: 'Szolgáltatásod az nDRU appban',
    moreThanText: 'Az nDRU számokban',
  },
};
