import { useContext, useState, useEffect } from 'react';

import { moreThanTexts } from '../texts/moreThanTexts';
import Context from '../context/Context';

const MoreThan = () => {
  const { usedLanguage } = useContext(Context);
  const [services, setServices] = useState([]);

  const [servicesCount, setServicesCount] = useState(0);

  const { boxes } = moreThanTexts[usedLanguage];

  const ndruServicesListUrl =
    'https://corsproxy.io/?https://server.drivendru.com/api/services/info/100/0/aPRh6KcTHTuM7JEjLtp+TgYjpb1kW2yd3OT0oKI5uQ7LYMo4zCxsEc7nhu6LNdYbo2farn2o9fE28L5MMuijQ==';

  const getData = async (url) => {
    const response = await fetch(url);

    if (!response.ok) {
      console.log('fetching failed');
      return [];
    }

    return await response.json();
  };

  useEffect(() => {
    const fetchServices = async () => {
      const services = await getData(ndruServicesListUrl);

      setServices(services);
      setServicesCount(services.length)
    };
    fetchServices();
  }, []);


  return (
    <div className='col-12 col-md-8'>
      <div className='row'>
        {boxes.map((box) => (
          <div
            key={box.h5}
            className='col-6 col-md-4 mb-3 mb-md-0'
          >
            <div className='box'>
              <h5>{box.key === `services` ? servicesCount : box.h5}</h5>
              <p>
                {box.p[0]}{' '}
                <br className='d-none d-md-block' />
                {box.p[1]}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MoreThan;
