export const featuredLocationsTexts = {
  en: {
    h2: `Featured locations in Hungary`,
    locations: [
      {
        rating: 4.2,
        address: '1221 Budapest, Hajó u. 1.',
        title: `Kövesdán Kft.`,
      },
      {
        rating: 4.2,
        address: '1222 Budapest, Háros u. 12.',
        title: `Hollós Szerviz`,
      },
    ],
  },
  hu: {
    h2: `Kiemelt szervizek Budapesten`,
    locations: [
      {
        rating: 4.2,
        address: '1221 Budapest, Hajó u. 1.',
        title: `Kövesdán Kft.`,
      },
      {
        rating: 4.2,
        address: '1222 Budapest, Háros u. 12.',
        title: `Hollós Szerviz`,
      },
    ],
  },
};
