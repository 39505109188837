import repairShopsImg654 from '../assets/images/page1/find-repair-shops/img_find_repair_shops@2x.png';
import repairShopsImg981 from '../assets/images/page1/find-repair-shops/img_find_repair_shops@3x.png';

import followServiceProcessImg654 from '../assets/images/page1/follow-service-process/img_follow_service@2x.png';
import followServiceProcessImg981 from '../assets/images/page1/follow-service-process/img_follow_service@3x.png';

import SubscribeImg from '../assets/images/page1/subscribe-to-exclusive-services/Subscribe.png';

import relatedInfosImg654 from '../assets/images/page1/store-all-repair-related-infos/img_store_info@2x.png';
import relatedInfosImg981 from '../assets/images/page1/store-all-repair-related-infos/img_store_info@3x.png';

import payWithYourPhoneImg654 from '../assets/images/page1/pay-for-repairs-with-your-phone/img_phone@2x.png';
import payWithYourPhoneImg981 from '../assets/images/page1/pay-for-repairs-with-your-phone/img_phone@2x.png';

export const landingDriversTexts = {
  en: {
    h1: `Find, schedule and pay for repair services,
    car cleaning service, tire change shop easily.`,
    categoriesH2: 'Categories',
    dtrStart: `Find, schedule and pay for`,
    dtrTexts: [
      `repair services`,
      `car cleaning service`,
      `tire change shop`,
      `repair services`,
    ],
    dtrEnd: `easily`,
    sections: [
      {
        imgs: [repairShopsImg654, repairShopsImg981],
        alt: 'Find repair shops',
        h2: `Find repair shops`,
        p: `With NDRU you are always just a click away
        from booking your next car repair in the
        comfort of your home or office. The app will
        provide you with a list of car repair
        services based on your vehicle and location.
        You can choose the nearest shop and book an
        appointment right there. It's like having
        your own mechanic on call, everywhere,
        anytime.`,
      },
      {
        imgs: [
          followServiceProcessImg654,
          followServiceProcessImg981,
        ],
        alt: 'Follow service process',
        h2: `Follow service process`,
        p: `No more phone calls! NDRU is going to
        connect you and your mechanic. With NDRU You
        can follow all the necessary steps of the
        car repair process at one place in real
        time. Control your car, choose the works you
        prefer, pay through the app, and get
        notifications if your car is ready for pick
        up.`,
      },
      {
        imgs: [SubscribeImg],
        alt: 'Subscribe to exlusive services for your car',
        h2: `Subscribe to exlusive services for your car`,
        p: `Exclusive offers are available for your
        vehicle, such as insurance, assistance
        services.`,
      },
      {
        imgs: [relatedInfosImg654, relatedInfosImg981],
        alt: 'Store all repair related infos',
        h2: `Store all repair related infos`,
        p: `The nDRU app is revolutionizing the way you
        store car repair and maintenance
        information. The app automatically stores
        all of your car's repair information so you
        are able to access and share them easily.`,
      },
      {
        imgs: [
          payWithYourPhoneImg654,
          payWithYourPhoneImg981,
        ],
        alt: 'Pay for repairs with your phone',
        h2: `Pay for repairs with your phone`,
        p: `With nDRU, you can pay for the performed
        services right from your phone. Add and save
        your bank card information once, so that
        payment is always just a tap away.
        Alternatively, just use Apple Pay or Google
        Pay. (coming soon)`,
      },
    ],
    moreThanText: 'More than',
  },
  hu: {
    h1: `Foglalj, és fizess egyszerűen az autószervizben.`,
    categoriesH2: 'Kategóriák',
    dtrStart: `Foglalj, és fizess egyszerűen`,
    dtrTexts: [
      `az autószervizben`,
      `az autómosóban`,
      `a gumiszervizben`,
      `az autószervizben`,
    ],
    dtrEnd: `telefonodról az nDRU-val`,
    sections: [
      {
        imgs: [repairShopsImg654, repairShopsImg981],
        alt: 'Találd meg a legközelebbi szakszervizt!',
        h2: `Találd meg a legközelebbi szakszervizt!`,
        p: `Az nDRU az autód típusa és a tartózkodási helyed alapján segít lefoglalni a legmegfelelőbb műhelyben az időpontod. Mintha saját autószerelőd lenne, aki bárhol és bármikor készen  áll!`,
      },
      {
        imgs: [
          followServiceProcessImg654,
          followServiceProcessImg981,
        ],
        alt: 'Kövesd a szervizfolyamatot',
        h2: `Kövesd a szervizfolyamatot`,
        p: `Nincs több telefonálgatás! Az nDRU-n keresztül kiválaszthatod az elvégzendő munkákat és valós időben követheted a javítási folyamatot. Amint elkészül az autód, az app azonnal értesít.`,
      },
      {
        imgs: [SubscribeImg],
        alt: 'Exkluzív szolgáltatások autódhoz',
        h2: `Exkluzív szolgáltatások autódhoz`,
        p: `A járművedhez exkluzív előfizetéses ajánlatokat találsz az nDRU-ban, mint a biztosítási vagy segítségnyújtási szolgáltatások`,
      },
      {
        imgs: [relatedInfosImg654, relatedInfosImg981],
        alt: 'Tárold autód szervizadatait',
        h2: `Tárold autód szervizadatait`,
        p: `Az nDRU alkalmazás automatikusan elmenti autód összes javítási információját, így bármikor könnyen hozzáférhetsz és megoszthatod azokat.`,
      },
      {
        imgs: [
          payWithYourPhoneImg654,
          payWithYourPhoneImg981,
        ],
        alt: 'Fizess a javításokért egyszerűen a telefonoddal',
        h2: `Fizess a javításokért egyszerűen a telefonoddal`,
        p: `Az nDRU segítségével közvetlenül a telefonodról fizethetsz a szolgáltatásokért, egyetlen kattintással. Add hozzá a kártyád és már kész is vagy!`,
      },
    ],
    moreThanText: 'Több mint',
  },
};
