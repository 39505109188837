import { Link } from 'react-router-dom';
import Page from '../components/Page';

function NotFound() {
  return (
    <Page title='404'>
      <div className='.container'>
        <div className='not-found'>
          <h1>404</h1>
          <h2>Not Found</h2>
          <h3>This page is missing</h3>
          <Link to='/' className='btn btn--not-found'>
            GO HOME
          </Link>
        </div>
      </div>
    </Page>
  );
}
export default NotFound;
