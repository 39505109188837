import Page from '../components/Page';

function NdruTermsOfService() {
  return (
    <Page title='NDRU-terms-of-service' endpoint='NDRU-terms-of-service'>
      <div className='container terms-of-service'>
        <h1
          style={{
            paddingTop: '3pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            fontSize: '1.6rem',
          }}
        >
          General Terms and Conditions
        </h1>

        <h2 style={{ textAlign: 'center', fontSize: '1rem', marginTop: '1.5rem' }}>
          FOR USERS OF NDRU SERVICES
        </h2>
        <p style={{ paddingLeft: '5pt' }}>
          Last updated: 11/08/2022 (version 1.0.1)
        </p>

        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'justify' }}>
          Effective: from 30.05.2022
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingTop: '10pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          These Terms and Conditions (<b>T&amp;Cs) </b>set out the rights and
          obligations of persons using the e- commerce services provided by ndru 1.0.
          Kft (<b>NDRU</b>
          <a href='http://www.drivendru.com/' className='s1' target='_blank'>
            ) through the
          </a>{' '}
          www.drivendru.com website, mobile website, NDRU APP mobile application (
          <b>Mobile Platform</b>) and NDRU Service (<b>Service Platform</b>) and NDRU
          Fleet (<b>Fleet Platform</b>) (collectively, the <b>NDRU Platforms</b>).
          Registration on the NDRU website, mobile website or application, and thus
          the creation of a user account, is only possible if the User expressly
          accepts these GTC during registration. By accepting these GTC during the
          registration, a contract is concluded between the User and NDRU with the
          contents set out in the GTC. The acceptance of the GTC is stored by the
          NDRU and registration is not possible without acceptance of the GTC. The
          GTC will be made available in downloadable form by the NDRU through the
          registration interface.
        </p>
        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'justify' }}>
          Data from the NDRU:
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <table
          style={{ borderCollapse: 'collapse', marginLeft: '8.524pt' }}
          cellSpacing={0}
        >
          <tbody>
            <tr style={{ height: '24pt' }}>
              <td style={{ width: '92pt' }}>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '2pt',
                    textIndent: '0pt',
                    lineHeight: '12pt',
                    textAlign: 'left',
                  }}
                >
                  Company name:
                </p>
              </td>
              <td style={{ width: '174pt' }}>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '12pt',
                    textIndent: '0pt',
                    lineHeight: '12pt',
                    textAlign: 'left',
                  }}
                >
                  NDRU 1.0 Ltd.
                </p>
              </td>
            </tr>
            <tr style={{ height: '55pt' }}>
              <td style={{ width: '92pt' }}>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '2pt',
                    paddingRight: '2pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Company registration number:
                </p>
              </td>
              <td style={{ width: '174pt' }}>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '12pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Cg. 01-09-374640
                </p>
              </td>
            </tr>
            <tr style={{ height: '37pt' }}>
              <td style={{ width: '92pt' }}>
                <p
                  className='s2'
                  style={{
                    paddingTop: '5pt',
                    paddingLeft: '2pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  It records:
                </p>
              </td>
              <td style={{ width: '174pt' }}>
                <p
                  className='s2'
                  style={{
                    paddingTop: '5pt',
                    paddingLeft: '12pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Court of Justice of the Capital City Court
                </p>
              </td>
            </tr>
            <tr style={{ height: '31pt' }}>
              <td style={{ width: '92pt' }}>
                <p
                  className='s2'
                  style={{
                    paddingTop: '5pt',
                    paddingLeft: '2pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Headquarters:
                </p>
              </td>
              <td style={{ width: '174pt' }}>
                <p
                  style={{
                    paddingTop: '5pt',
                    paddingLeft: '12pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <a
                    href='https://www.google.hu/maps/place/1221Budapest%2BHaj%C3%B3%2Butca%2B1.%2B'
                    className='s3'
                  >
                    1221 Budapest, Hajó utca 1.
                  </a>
                </p>
              </td>
            </tr>
            <tr style={{ height: '36pt' }}>
              <td style={{ width: '92pt' }}>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '2pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Tax number:
                </p>
              </td>
              <td style={{ width: '174pt' }}>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '12pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  28802839-2-43
                </p>
              </td>
            </tr>
            <tr style={{ height: '43pt' }}>
              <td style={{ width: '92pt' }}>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '2pt',
                    paddingRight: '26pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Bank account number:
                </p>
              </td>
              <td style={{ width: '174pt' }}>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '12pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  12012204-01743883-00100005
                </p>
              </td>
            </tr>
            <tr style={{ height: '31pt' }}>
              <td style={{ width: '92pt' }}>
                <p
                  className='s2'
                  style={{
                    paddingTop: '5pt',
                    paddingLeft: '2pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  Postacíme:
                </p>
              </td>
              <td style={{ width: '174pt' }}>
                <p
                  style={{
                    paddingTop: '5pt',
                    paddingLeft: '12pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  <a
                    href='https://www.google.hu/maps/place/1221Budapest%2BHaj%C3%B3%2Butca%2B1.%2B'
                    className='s3'
                  >
                    1221 Budapest, Hajó utca 1.
                  </a>
                </p>
              </td>
            </tr>
            <tr style={{ height: '24pt' }}>
              <td style={{ width: '92pt' }}>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '2pt',
                    textIndent: '0pt',
                    lineHeight: '11pt',
                    textAlign: 'left',
                  }}
                >
                  E-mail:
                </p>
              </td>
              <td style={{ width: '174pt' }}>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '12pt',
                    textIndent: '0pt',
                    lineHeight: '11pt',
                    textAlign: 'left',
                  }}
                >
                  <a href='mailto:info@drivendru.com' className='s3'>
                    info@drivendru.com
                  </a>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <table
          style={{ borderCollapse: 'collapse', marginLeft: '8.524pt' }}
          cellSpacing={0}
        >
          <tbody>
            <tr style={{ height: '12pt' }}>
              <td style={{ width: '74pt' }}>
                <p
                  className='s2'
                  style={{
                    paddingLeft: '2pt',
                    textIndent: '0pt',
                    lineHeight: '11pt',
                    textAlign: 'left',
                  }}
                >
                  Website:
                </p>
              </td>
              <td style={{ width: '126pt' }}>
                <p
                  style={{
                    paddingLeft: '30pt',
                    textIndent: '0pt',
                    lineHeight: '11pt',
                    textAlign: 'left',
                  }}
                >
                  <a href='http://www.drivendru.com/' className='s3'>
                    www.drivendru.com
                  </a>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingTop: '4pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          The place of operation and complaints handling is at the headquarters.
        </p>
        <ol id='l1'>
          <li data-list-text={1}>
            <h3
              style={{
                paddingTop: '4pt',
                paddingLeft: '16pt',
                textIndent: '-11pt',
                textAlign: 'left',
              }}
            >
              DEFINITIONS
            </h3>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <h3
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Provider <span className='p'>or </span>NDRU<span className='p'>:</span>
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              NDRU 1.0 Kft. (company registration number: 01-09-374640, tax number:
              28802839-2-43, registered office: 1221 Budapest, Hajó utca 1.).
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <h3 style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              User<span className='p'>:</span>
            </h3>
            <p
              style={{
                paddingTop: '5pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              A natural person or legal entity registered{'{'}' '{'}'}
              <b>on the Mobile Platform, Service Platform, Fleet Platform</b>
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              who can place an Order through these Platforms.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <h3
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Consumers:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Users are natural persons acting outside the scope of their profession,
              self-employment, or business activity.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <h3 style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              Parties:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              The Service Provider and the User together.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <h3
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Partner:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              An individual entrepreneur or a company that offers its services on the
              {'{'}' '{'}'}
              <b>NDRU Platforms </b>in the course of its commercial economic activity
              on the basis of a separate contract with the Service Provider.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <h3 style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              Contract:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              A contract between the Service Provider and the User, based on the GTC
              and the data provided by the User, concluded through registration.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <h3 style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              GTC:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              These Terms and Conditions.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <h3
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Intermediated Contract:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              The contract between the User and the Partner, mediated by the Service
              Provider through the Platform, the content of which is set out in Annex
              1, Section 1 of the GTC.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <h3 style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              Platform:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              <a href='http://www.drivendru.com/' className='s1' target='_blank'>
                The online ordering website{'{'}' '{'}'}
              </a>
              www.drivendru.com, (<b>Website</b>), the NDRU APP mobile application (
              <b>Mobile Application </b>or <b>NDRU APP</b>
              <a
                href='http://www.service.drivendru.com/'
                className='s1'
                target='_blank'
              >
                ), the{'{'}' '{'}'}
              </a>
              <span
                style={{
                  color: '#1154CC',
                  fontFamily: 'Arial, sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  textDecoration: 'underline',
                  fontSize: '10.5pt',
                }}
              >
                www.service.drivendru.com
              </span>
              {'{'}' '{'}'}(<b>Service Platform</b>
              <a
                href='http://www.fleet.drivendru.com/'
                className='s1'
                target='_blank'
              >
                ),{'{'}' '{'}'}
              </a>
              <span
                style={{
                  color: '#1154CC',
                  fontFamily: 'Arial, sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  textDecoration: 'underline',
                  fontSize: '10.5pt',
                }}
              >
                www.fleet.drivendru.com
              </span>
              {'{'}' '{'}'}(<b>Fleet Platform</b>) and the server-side applications
              serving them, which
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              contains the list of services provided by the Partners and the
              individual user content within the User's NDRU Account and through
              which the User can place his/her Orders.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <h3
              style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'justify' }}
            >
              NDRU Account:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The account created by the User during registration on the Platform,
              which allows the User to identify himself/herself to the Service
              Provider on the Platform, to store personal, personalized content, to
              place an Order and to store basic information necessary for using the
              Service.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <h3
              style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'justify' }}
            >
              Service Unit or Unit:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The smallest independent economic unit of the Partner where or from
              which the service provided by the Partner is performed.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <h3
              style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'justify' }}
            >
              Partner Service:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Services that may be requested from the Partner through an Order placed
              by the User through the Platform, which are listed on the Platform as
              available services, and the sale of products related to Partner
              Services by Partners to Users through the Platform.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <h3 style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              Basket:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              A list of the Partner Services compiled by the User, with the price of
              the Services and the fees and additional terms related to the Service
              for which the Order is placed.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <h3 style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              Order:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              An offer by Users to conclude a contract for the use or purchase of a
              Service selected on the Platform, which is mediated by the Service
              Provider to the Partner on the basis of a separate mediation agreement
              between the Service Provider and the Partner.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <h3 style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              NDRU Service:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              All the services provided by the Service Provider to the User in the
              context of the operation of the Platform, i.e. the operation of the
              Platform, the provision of the use of the Platform (including
              registration, browsing, management of Orders, provision of the
              e-service book and complaint management). The Service Provider does not
              charge a separate fee for the NDRU Services, however, the Service
              Provider charges a fee for the Partner Services ordered by the User
              through the Platform.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <h3 style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              Courier Service:
            </h3>
            <p
              style={{
                paddingTop: '5pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              A return service provided to the User by a courier contacted and
              selected by the Partner on the User's behalf, whereby the courier
              selected by the Partner delivers the User's vehicle from the User to
              the
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Partner or from the Partner to the User after the completion of the
              Partner Service, on the basis of a delivery contract between the User
              and the courier, under terms and conditions agreed by the User, for the
              provision of the Partner Service ordered by the User on the Platform.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <h3 style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              Courier:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The Partner is an independent business partner who is available to
              transport the User's vehicle from the User to the Partner within the
              framework of the Courier Service, in order to provide the Partner
              Service ordered by the User on the Platform, on the basis of a contract
              concluded directly with the User and accepted by the User, and in
              accordance with the terms and conditions of the contract concluded by
              the Service Provider and the Partner, or from the Partner to the User
              after the completion of the Partner Service.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <h3 style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              Online payment method:
            </h3>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Payment of the Order through a payment service provider by means of a
              credit card payment instrument.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={2}>
            <h3
              style={{ paddingLeft: '16pt', textIndent: '-11pt', textAlign: 'left' }}
            >
              Operation of the Platform, content of the NDRU Service
            </h3>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              2.1.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The Service Provider is the owner and operator of the{'{'}' '{'}'}
              <b>NDRU Platforms</b>. On the <b>NDRU Platforms, </b>the Service
              Provider collects and makes available to the Users the Partner Services
              offered by the Partners, in order to enable the Users to order the
              Partner Services of the Partners online, to pay for the provided
              Partner Services and, if required, to return their vehicle to the
              Partner's Unit by courier. The Service Provider's aim is to make the
              Partners and their Services as widely available and visible as
              possible, taking into account the User's needs, rights and legitimate
              interests, while allowing the Partners to grow their customer base.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              2.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              NDRU allows Users to find Partners providing car repair and other
              vehicle related services, review their Partner Services, book
              appointments with these Partners and, once the Partner Services are
              completed, Users can pay for these Partner Services and related parts
              through the <b>NDRU Platforms </b>via the Online Payment Method. The
              Platform allows Users to record their vehicles, their identification
              data and other data such as mileage. In addition, when making service
              reservations and payments through the <b>NDRU Platforms, </b>the
              service data and service history related to the vehicles are
              automatically recorded on the <b>NDRU Platforms in </b>the User's NDRU
              Account.
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              2.3.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Through the <b>NDRU Platforms</b>, Users can order and use additional
              Partner Services in addition to the Partner Services that provide the
              service, such as: insurance, immediate assistance, parking services.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              2.4.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Through the <b>NDRU Platforms</b>, depending on the Service Partner, it
              is also possible to use the Courier Service, whereby a courier sent by
              the Service Partner to the User picks up the User's vehicle and brings
              it to the Service Partner's Unit. Not all Service Partners provide
              Courier Services. The User can only use the Courier Service if the
              Service Partner providing the given Partner Service allows it and it
              can be optionally ordered on the Platform as an additional service to
              the selected Partner Service.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              2.5.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              An automatic invoice will be issued for Partner Services ordered and
              products purchased through the Platform, with the Service Provider
              acting as a fulfillment agent on behalf of the Partner. This will only
              happen if the Partner uses the NDRU integrated invoicing service.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              2.6.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The fees for the Partner Services available and displayed on the
              Platform have been set by the Platform and are displayed on the
              Platform in such a way that they include the NDRU Service Fee. It is
              the User's responsibility to provide the correct e-mail address. The
              Service Provider shall not be liable for any damages or problems caused
              by an incorrect/inaccurate or untrue e-mail address.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              2.7.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              The NDRU is an independent service provider independent of the Partner.
              NDRU does not act on behalf of Users or Consumers in the provision of
              the Services or the Partner Services.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              2.8.
            </p>
            <p
              style={{
                paddingTop: '5pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              NDRU is an online intermediary merchant, which receives Orders from
              Users through the Platforms on behalf of Partners and processes and
              transmits them to Partners.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              2.9.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The content, data and information on the Platforms are subject to
              change due to the nature of the NDRU Service. The Service Provider
              makes every effort to ensure that the content and offers presented to
              the User are always up to date. Considering that the information, the
              list of Partner
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Services, the description of Partner Services and related products and
              their prices on the Platforms originate from the Partner, the Service
              Provider shall not be liable for their content, timeliness,
              availability and quality and shall not be obliged to pay any
              compensation.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              2.10.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The Service Provider acknowledges that the Partner Service and related
              product offerings displayed on the Partner Platforms may differ from
              the service and/or product offerings of the Partner's Service Unit
              located in the Partner's premises or displayed on its own online
              ordering channels. The conditions of provision of services and/or sale
              of products at the Partner's Service Unit or on its own online sales
              channels, including in particular the prices of services and products,
              any promotions applied, delivery charges, may differ from the
              conditions of provision and sale of the Partner Services on the
              Platform. Where the same Partner operates several Service Units of the
              same Partner, the offer of Partner Services and related products and
              the terms and conditions of provision and sale of services and wedges
              may differ between the Units.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              2.11.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The Service Provider informs the User that, in addition to the
              provisions of clause 1.5, the Service Provider may restrict the right
              of the Partners to apply different ordering conditions - in particular
              different prices - from the ordering conditions of the Partner Services
              on the Platform through their own online ordering channels (own
              website, application). This restriction is intended to maintain the
              Service Provider's business model in order to ensure that Users
              continue to benefit from the advantages offered by the Platform and the
              NDRU Service, which consist of reduced search time and costs due to the
              operation of the Platform, a large choice, high quality services (in
              particular, extremely fast administration of the Courier Service and
              the current availability of the Partners), accurate documentation of
              the User's service history through the Platform. The Service Provider
              is continuously improving its Platform and NDRU Services, investing in
              the operation of the Platforms and NDRU Services in order to increase
              the number of its practices and new Users, which is also in the
              interest of the Partners. The Partner restrictions help the Service
              Provider to prevent Partners from taking advantage of the Platform's
              operation to use the Platform solely for their own marketing purposes
              and to recruit (entice) their own customers. Consequently, the
              restriction serves the legitimate business and economic interest of the
              Service Provider to balance the benefits of the Partners' presence on
              the Platform and the NDRU Services with the efforts and expenses that
              the Service Provider undertakes to develop the Platform and to maintain
              and raise the level of its services. For the avoidance of doubt, the
              Service Provider stipulates that this restriction does not affect the
              Partner's right to apply different terms and conditions for its own
              non-online orders from its Service Provider Units, independent of the
              Platform.
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              2.12.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The time associated with any Partner Service on the Platforms,
              including but not limited to the hours of work and related product
              procurement associated with the Service, and the time to complete the
              Service, are based on the Partner's estimates and are provided for
              information purposes only. The actual completion time and hours
              associated with the Service may be affected by other external
              unforeseen factors, independent of both the Service Provider and the
              Partner.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              2.13.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              The Service Provider reserves the right to modify the content displayed
              on the Platforms at any time, to change its availability, or to suspend
              or permanently terminate the operation of the Platforms.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              2.14.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              The Service Provider reserves the right to restrict or disable the
              content of the Platforms and the offers partially or completely for all
              Users or a group of Users.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={3}>
            <h3
              style={{ paddingLeft: '16pt', textIndent: '-11pt', textAlign: 'left' }}
            >
              Using the Platforms
            </h3>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              3.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              The use of the Platforms is free of charge for the User.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              3.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Anyone is entitled to use the Platforms, provided that he/she has
              validly and successfully registered and acknowledges the terms of these
              GTC and has read and accepted the Privacy Policy for the processing of
              personal data of natural person Users. The Platform is used through the
              {'{'}' '{'}'}
              <b>NDRU Account.</b>
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              3.3.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Only natural persons over the age of 18 who are acting either for
              themselves or on behalf of a business may register and use the
              Platforms. By accepting these GTC, the Consumer declares that he/she is
              over 18 years of age. A natural person acting on behalf of a business
              declares, by accepting these GTC, that he/she is entitled to represent
              the business independently and that his/her actions are not restricted.
              The Service Provider shall not be obliged to verify the age of the
              Consumer or the right of representation of the natural person
              representing the undertaking but shall be entitled to require the
              Consumer to provide proof of age and the natural person representing
              the undertaking to provide proof of his right of representation. If the
              Consumer has not reached the age of 18 or has not provided proof
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              of age at the request of the Service Provider, or if the natural person
              representing the business is not entitled to represent the business or
              has not provided proof of entitlement to representation at the request
              of the Service Provider, the Service Provider shall terminate the
              Contract and the NDRU Account with immediate effect. The Consumer may
              not order through the Platform for a person under the age of 18 any
              Partner Service or related product that a person under the age of 18
              may not purchase for himself/herself. The Consumer is solely
              responsible for ensuring that his/her NDRU Account is not accessible to
              persons under 18 years of age or any other unauthorized person.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              3.4.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              A natural person acting on behalf of a legal entity or a business as a
              User may request an invoice for orders placed through the Platforms. By
              accepting these GTC and by submitting an invoice request, the natural
              person acting on behalf of a legal person or an enterprise as User
              declares that he/she is acting on behalf of that legal person or
              enterprise.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              3.5.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              The User shall comply with all laws, regulations and obligations when
              using the Platforms and Services.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={4}>
            <h3
              style={{ paddingLeft: '16pt', textIndent: '-11pt', textAlign: 'left' }}
            >
              The registration process
            </h3>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              4.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Only registered Users who have an NDRU Account as a result may place an
              Order on the Platform. During registration, the User's{'{'}' '{'}'}
              <b>NDRU Account </b>is created as a result of successful registration.
              The <b>NDRU Account </b>can be registered in the mobile application.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              4.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Registration requires the provision of the personal data of the natural
              person of the User or of the natural person acting on behalf of the
              User, or of the company. Consumers may register on the Platforms only
              with their own personal data, i.e., Consumers are not allowed to
              register on behalf of another person.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              4.3.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '149%',
                textAlign: 'left',
              }}
            >
              To register in the NDRU mobile app, you also need to download the NDRU
              APP mobile app, which can be found in Apple App Store, Play Store, and
              other stores.
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              4.4.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              When registering with an e-mail address, the e-mail address entered by
              the User is checked by the system to see whether the e-mail address is
              associated with a previously created NDRU Account, after which the User
              is required to provide the following personal data: e-mail address,
              password, first name, surname, delivery address, telephone number, in
              case of a company, company name, registered office, tax number, company
              registration number; vehicle data: registration number, vehicle type,
              vehicle model, year, fuel type, engine capacity, power, date of expiry
              of MOT, chassis number, engine number, wheel sizes, odometer reading,
              date of last service, registration number, registration number, date of
              registration, vehicle colour, compulsory insurance policy number, casco
              insurance policy number, their amounts and validity, other
              vehicle-related data.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              4.5.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              In order to successfully register, the User must accept these GTC and
              declare that he/she has read and accepted the Privacy Policy and that
              he/she consents to the processing of his/her personal data as described
              in the Privacy Policy. By ticking the relevant boxes, the User declares
              that he/she has read, understood, accepted and fully complied with all
              the provisions of these GTC and that he/she accepts to be bound by all
              the provisions thereof. By ticking the relevant box in relation to the
              Privacy Policy, the User declares that he/she has read, understood and
              acknowledged the terms and conditions of the Privacy Policy and
              consents to the processing of his/her personal data in accordance with
              the Privacy Policy. By ticking the relevant box, the User has the
              possibility to subscribe to the sending of marketing messages
              (newsletter) by the Service Provider in the form of direct marketing
              enquiries, however, the subscription is not a condition of successful
              registration.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              4.6.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Successful registration starts by selecting the "Create Account" button
              and then entering the link in the confirmation message sent by the User
              to the e-mail address provided, i.e. the registration is authenticated
              by the User. The confirmation message sent by the Service Provider will
              allow the User to confirm his email address. The User will then be able
              to check and, if necessary, correct the personal and vehicle data
              already provided, by returning to the registration interface. By
              clicking on the link in the confirmation message, the User confirms
              his/her registration, the User's NDRU Account is created and the
              Platform redirects the User to the home page.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              4.7.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The User is solely responsible for the accuracy, timeliness and
              truthfulness of the personal data provided during registration and use
              of the Service. The Service Provider shall not be liable for any
              damages resulting from the User's forgotten password or from its access
              to unauthorized persons.
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              The User shall notify the Service Provider immediately if he/she
              suspects that unauthorized persons have gained access to his/her NDRU
              Account.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '8pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              4.8.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              After successful registration and email confirmation, the User is
              entitled to place Orders on the Platform.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={5}>
            <h3
              style={{ paddingLeft: '16pt', textIndent: '-11pt', textAlign: 'left' }}
            >
              The Order Process
            </h3>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Orders can only be submitted electronically via the Platform. The
              Service Provider accepts Orders via the Platform only from registered
              Users and only if the User provides all the data required for the Order
              in full.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              5.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The Service Provider excludes any liability arising from the
              incorrectness, typographical error or failure to provide true data in
              connection with the data provided on the Platform. The Service Provider
              shall not be liable for any delay or defect in the performance of the
              Contract or the contract concluded between the User and the Partner or
              the Courier, or for any other problem or error.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              5.3.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              When entering the NDRU main page, the User can choose to enable the
              location services provided by the User's phone in the pop-up window, in
              which case the User's current position will be automatically loaded.
              The Service Provider shall not be liable for any damages related to the
              proper functioning of the location services provided by the User's
              phone, nor for any inaccuracy of the location. The Platform will use
              the current geographic position and address determination for the
              Courier Service, in the absence of a new positioning, the Courier
              Service shall not be obliged to perform. When using the NDRU mobile
              application, if the Location Services are enabled on the User's mobile
              phone, his current location data will be automatically loaded, but he
              will have the possibility to enter his new address by entering the
              address.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              5.4.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              After specifying the delivery address, the User can choose to have the
              vehicle delivered to the Partner's Unit by using the Courier Service
              and/or to have it returned to the Partner's delivery address
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              after the Partner Service has been completed by clicking on the
              "Courier" button. The User is free to choose to deliver the vehicle to
              the Partner himself/herself or to collect it after the Partner Service
              has been completed. Not all Partners offer the possibility to use the
              Courier Service.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              5.5.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              After specifying the Partner Service the User wishes to order and,
              optionally, the current geographical location, the Platform displays
              the list of available Partners (<b>Partner List</b>). The User has the
              possibility to choose the order of the Partner List (list by type of
              Partner Service selected, geographical proximity in case of location
              specified by the User, etc.) The User acknowledges that the Service
              Provider is entitled to determine the order of the Partners, certain
              Partners may be given priority (swim lane, first positions in the
              Partner List) by agreement between the Service Provider and the
              Partner.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              5.6.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The availability (opening hours) of Partners on the Platform may vary
              from Partner to Partner. The opening hours indicated for a given
              Partner are indicative and the Partners and the Service Provider
              reserve the right to change the availability of the Partners. The
              availability of a Partner on the Platform may differ from the on-site
              opening hours of the relevant Unit.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              5.7.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              While browsing the Platform, the User is free to configure the Cart.
              The prices and fees on the Platform are in HUF, gross value. By
              clicking on the "i" button you can access the Partner's opening hours,
              address and map location. In the case of a discounted price, the
              Platform also displays the original price, the discounted price and the
              discount rate. You will also see the option to use the Courier Service.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              5.8.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The Service Provider acknowledges that the completion date and expected
              working hours indicated on the Platform for the given Partner are
              estimates and therefore indicative. This applies to all interfaces
              containing deadlines and working hours, in particular to the interface
              for tracking the order that appears after the Order, as well as to the
              email confirming the Order. The Service Provider shall not be liable
              for any early or late performance or any overrun of the work resulting
              from any failure to meet the stated deadline or estimated working hours
              and shall not be liable for any compensation.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              5.9.
            </p>
            <p
              style={{
                paddingTop: '5pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              The Partner shall determine the minimum Order value, which shall not be
              higher than HUF 1.000. The minimum Order value may vary from Partner to
              Partner.
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.10.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              After the shopping cart has been created, the User is automatically
              redirected to the Order Summary page for data verification by clicking
              on the "Proceed to Order" button. Here, the User checks and finalises
              the data required to complete the Order (Partner Service, its price,
              delivery address, delivery or pick-up date, personal data and telephone
              number, and can also make a comment to the Courier). On the Order
              Summary page, the User can also see whether the Courier Service is also
              ordered by placing the Order.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              5.11.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              5.12.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The contents of the Cart will be deleted if the User does not finalize
              the Order within 2 hours. Logging out of the NDRU Account without
              finalizing the Order on the Platform will result in the deletion of the
              Cart.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              5.13.
            </p>
            <p
              className='s6'
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'justify',
              }}
            >
              Order process:
            </p>
            <ol id='l2'>
              <li data-list-text={1}>
                <p
                  className='s6'
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '16pt',
                    textIndent: '-11pt',
                    textAlign: 'left',
                  }}
                >
                  User selects the Partner
                </p>
              </li>
              <li data-list-text={2}>
                <p
                  className='s6'
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '16pt',
                    textIndent: '-11pt',
                    textAlign: 'left',
                  }}
                >
                  User selects from the Partner's services that he/she would like to
                  order
                </p>
              </li>
              <li data-list-text={3}>
                <p
                  className='s6'
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '156%',
                    textAlign: 'left',
                  }}
                >
                  The User chooses the time when he/she would like to pick up the
                  car/when he/she would like the courier to arrive, if the Partner
                  provides this option
                </p>
              </li>
              <li data-list-text={4}>
                <p
                  className='s6'
                  style={{
                    paddingLeft: '16pt',
                    textIndent: '-11pt',
                    lineHeight: '12pt',
                    textAlign: 'left',
                  }}
                >
                  The User sends the Order and the booking date, stating that
                </p>
                <ul id='l3'>
                  <li data-list-text='-'>
                    <p
                      style={{
                        paddingTop: '6pt',
                        paddingLeft: '59pt',
                        textIndent: '-18pt',
                        lineHeight: '150%',
                        textAlign: 'justify',
                      }}
                    >
                      where the Partner has its own general terms and conditions, the
                      User declares that he/she has read and accepted the Partner's
                      general terms and conditions relating to the Order,
                    </p>
                  </li>
                  <li data-list-text='-'>
                    <p
                      style={{
                        paddingLeft: '47pt',
                        textIndent: '-6pt',
                        textAlign: 'justify',
                      }}
                    >
                      by pressing the "Place Order" button, the User finalizes the
                      Order
                    </p>
                  </li>
                  <li data-list-text='-'>
                    <p
                      style={{
                        paddingTop: '6pt',
                        paddingLeft: '59pt',
                        textIndent: '-18pt',
                        lineHeight: '150%',
                        textAlign: 'justify',
                      }}
                    >
                      When ordering Partner Services, the Consumer must declare
                      whether he/she requests the Partner to start the performance of
                      the Intermediated Contract before the expiry of the deadline
                      provided for the Consumer in Article 20 of Government Decree
                      No. 45/2014 (II. 6.) on the detailed rules of contracts between
                      the Consumer and the business (<b>Fvkr.)</b>.
                    </p>
                  </li>
                </ul>
              </li>
              <li data-list-text={5}>
                <p
                  className='s6'
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '156%',
                    textAlign: 'left',
                  }}
                >
                  Partner receives the appointment booking. Once the appointment is
                  booked, the Partner has three options.
                </p>
              </li>
              <li data-list-text={6}>
                <p
                  className='s6'
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '156%',
                    textAlign: 'left',
                  }}
                >
                  If a new appointment is proposed, the User will be notified of the
                  new appointment via the Platform and may accept or reject the new
                  appointment.
                </p>
              </li>
              <li data-list-text={7}>
                <p
                  className='s6'
                  style={{
                    paddingTop: '4pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '156%',
                    textAlign: 'justify',
                  }}
                >
                  The User may cancel the confirmed appointment 24 hours before the
                  booked appointment free of charge.
                </p>
              </li>
              <li data-list-text={8}>
                <p
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'left',
                  }}
                >
                  The User delivers the car to the Partner at the time booked or the
                  courier picks up the car from the User at the location indicated by
                  the User.
                </p>
              </li>
              <li data-list-text={9}>
                <p
                  className='s6'
                  style={{
                    paddingLeft: '16pt',
                    textIndent: '-11pt',
                    textAlign: 'justify',
                  }}
                >
                  After the delivery of the vehicle, the User will be informed about
                  the steps of the service process.
                </p>
              </li>
              <li data-list-text={10}>
                <p
                  className='s6'
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '155%',
                    textAlign: 'justify',
                  }}
                >
                  The User will be notified via the Platform of any service proposals
                  and spare parts procurement proposals made by the Partner beyond
                  the scope of the Order. The User may decide via the Platform which
                  of these to accept by
                </p>
                <ul id='l4'>
                  <li data-list-text='-'>
                    <p
                      style={{
                        paddingLeft: '77pt',
                        textIndent: '-18pt',
                        lineHeight: '150%',
                        textAlign: 'left',
                      }}
                    >
                      By pressing the "Order" button, the User selects the repairs
                      proposed by the Partner and orders them.
                    </p>
                  </li>
                  <li data-list-text='-'>
                    <p
                      style={{
                        paddingLeft: '77pt',
                        textIndent: '-18pt',
                        lineHeight: '150%',
                        textAlign: 'left',
                      }}
                    >
                      In case of ordering the proposed works, if the User cancels the
                      Order, the User shall pay the costs and fees set by the
                      Partner.
                    </p>
                  </li>
                </ul>
              </li>
              <li data-list-text={11}>
                <p
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'left',
                  }}
                >
                  The Partner shall notify the User of the progress of the vehicle
                  service: parts order, service in progress, car completed, and other
                  specific statuses.
                </p>
              </li>
              <li data-list-text={12}>
                <p
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '149%',
                    textAlign: 'left',
                  }}
                >
                  The User shall pay the Partners for the ordered services via the
                  Platform before taking delivery of the car.
                </p>
              </li>
              <li data-list-text={13}>
                <p
                  style={{
                    paddingLeft: '22pt',
                    textIndent: '-17pt',
                    textAlign: 'left',
                  }}
                >
                  The User may collect the car from the Partner after the
                  notification of the completion of the car.
                </p>
              </li>
            </ol>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.14.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The Intermediated Agreement is concluded when the electronic
              confirmation of the acceptance of the Order is received by the Service
              Provider from the Partner. The content of the Intermediated Agreement
              is set out in the Annex to the GTC. In this case, the User's acceptance
              of the Partner's general terms and conditions is a condition for the
              conclusion of the Intermediated Agreement.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              5.15.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              The Partner is solely responsible for all aspects of the Partner
              Service ordered by the User, and the Courier is solely responsible for
              the Courier Service.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              5.16.
            </p>
            <p
              style={{
                paddingTop: '5pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The status of the Order can also be tracked on the Platform through the
              Order. The delivery date on the Order tracking page may change
              continuously, the delivery date is indicative, and the Service Provider
              does not assume any responsibility for the correctness of the data. The
              status of the Order can be tracked by means of an automatic push
              notification message sent by the Platform, and in the event of
              cancellation of the Order, the Platform will also send an automatic
              push notification.
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              5.17.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The Partner issues the invoice for the Partner Service and the Courier
              Service on behalf of the Partner and the Courier in the name of and on
              behalf of the Partner and the Courier. The invoice issued in this way
              shall be sent by the Partner to the User by email. The User
              acknowledges that the invoice for the Partner Service or the Courier
              Service is issued on behalf of the Partner or the Courier.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={6}>
            <h3
              style={{ paddingLeft: '16pt', textIndent: '-11pt', textAlign: 'left' }}
            >
              Cancellation, modification, withdrawal, cancellation of order
            </h3>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              6.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              The User is bound by the offer from the moment the Order is finalized.
              The offer shall be terminated if the Service Provider does not fulfill
              the Order within 30 days.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              6.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The User has the possibility to cancel the Order 24 hours before the
              booked time. Consumers may cancel the Order until the conclusion of the
              Intermediated Contract, i.e., until the confirmation of acceptance of
              the Order has been received by the Service Provider from the Partner.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              6.3.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              In relation to Partner Services for which the right of withdrawal is
              provided for in Article 20 of the Consumer Contract Act, the Consumer
              may terminate the Intermediated Contract via the mobile application. If
              the Intermediated Contract is for the provision of a Service and, when
              finalising the Order, the Consumer has expressly requested the Partner
              to start the performance of the Intermediated Contract before the
              expiry of the time limit provided for in Article 20 of the Fvkr. In the
              latter case, if the Consumer exercises his right of termination, the
              Intermediary Agreement may initiate its termination by stopping the
              ordering of the Service through my platform, but the Consumer shall be
              liable to pay the fee for the Partner Service performed by the Partner
              until that date, and the Consumer shall be entitled to a refund of the
              service fee already paid, if any, only to the extent of the portion of
              the Partner Service not yet performed. If the Partner Service for the
              provision of the Ordered Service has been fully performed by the
              Partner in view of the Consumer's express declaration (order
              confirmation) that the Partner shall commence the Intermediary
              Agreement before the deadline set out in Article 20 of the Intermediary
              Agreement, the Consumer shall lose his right of termination. The
              Consumer may exercise his right of withdrawal or termination by
              cancelling the order for the service through the Platform. The
              Consumer's withdrawal from the Intermediated Contract or the
              termination of the Intermediated Contract shall also terminate the
              Consumer's ancillary contracts (e.g., a contract for Courier Services).
              The Consumer shall bear the burden of proving that he/she exercised
              his/her right of withdrawal or termination in accordance with the
              provisions of Article 22 of the Fvkr. The
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              Consumer may not exercise his right of withdrawal or termination under
              Article 20 of the Consumer Contract Act in the cases set out in Article
              29 of the Consumer Contract Act
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              6.4.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The User is entitled to modify the Order only until the Partner has
              confirmed the Order to the Service Provider. The Order may be amended
              if the Service Provider expressly offers the User to do so in
              connection with the Partner Service ordered. In all other cases, if the
              User wishes to order additional Partner Services from the given
              Partner, he may do so only by placing a new Order. If the Partner has
              confirmed the Order and the User requests the cancellation of the
              original Order, the User shall not be released from his payment
              obligation, shall remain obliged to pay the amount of the Order and, in
              case of online payment, shall not be entitled to claim back the amount
              already paid. An exception to this is if the Consumer has exercised
              withdrawal or termination pursuant to Article 20 of the Fvkr. as
              regulated in detail in Section 6.3 above.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              6.5.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The Service Provider shall be entitled to cancel the Order of the User
              and to cancel the NDRU Account of the User who has already failed to
              pay the fee related to the submitted Order or has terminated the
              Intermediated Agreement in a breach of contract or has committed any
              other breach of contract either in relation to the Intermediated
              Agreement or these GTC.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={7}>
            <h3
              style={{ paddingLeft: '16pt', textIndent: '-11pt', textAlign: 'left' }}
            >
              Payment
            </h3>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              7.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              The User may choose from the following payment methods when placing an
              Order:
            </p>
            <ul id='l5'>
              <li data-list-text='-'>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '47pt',
                    textIndent: '-6pt',
                    textAlign: 'left',
                  }}
                >
                  Online payment method: payment by credit card (Mastercard, Maestro,
                  VISA)
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingTop: '10pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  7.2.
                </p>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  The Service Provider is entitled to introduce or discontinue an
                  additional payment method at any time at its sole discretion. The
                  Service Provider shall be entitled to suspend the use of any
                  payment method at any time, in particular in the event of technical
                  problems or temporary inoperability.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  7.3.
                </p>
                <p
                  style={{
                    paddingTop: '5pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'left',
                  }}
                >
                  If the Affiliate Service ordered by the User is solely for the
                  purchase of products, the User shall pay the service fee upon
                  receipt of the ordered product. The Service Provider shall only
                  confirm the
                </p>
                <p
                  style={{
                    paddingTop: '4pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  finalisation of the Order if the payment via the Online Payment
                  Method was successful at the time of finalising the Order. If the
                  User orders a Partner Service for the provision of a Service, the
                  User shall, at its option, be obliged to make the payment upon
                  finalisation of the Order or after the Partner Service has been
                  completed, but before the User's vehicle is taken over from the
                  Partner. When the online payment is made, if the transaction is
                  successful, the payment service provider will immediately block the
                  amount on the card provided by the User and will automatically
                  notify the Service Provider that the payment has been made, so that
                  the Platform will be informed that the payment of the Order has
                  been successfully completed.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  7.4.
                </p>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  In the case of online payment, the Service Provider is entitled and
                  obliged to accept the fulfilment (payment of the fee) of the
                  Intermediated Agreement by the User on the basis of a separate
                  contract between the Partner and the Service Provider. The
                  acceptance of fulfilment is made by the User paying the total
                  amount of the Order through the payment service provider contracted
                  with the Service Provider.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  7.5.
                </p>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  The Service Provider records and does not store the User's credit
                  card data. In accordance with data security requirements, the
                  Service Provider does not have access to the card data, which may
                  only be processed by payment service providers.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  7.6.
                </p>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'left',
                  }}
                >
                  Payment by credit card is made through electronic systems operated
                  by the following payment service providers contracted with the
                  Service Provider, which are completely independent of the Platform:
                </p>
                <p
                  style={{
                    paddingLeft: '41pt',
                    textIndent: '-18pt',
                    lineHeight: '114%',
                    textAlign: 'left',
                  }}
                >
                  <span className='s7'>- </span>Stripe Inc., registration number:
                  {'{'}' '{'}'}
                  <span className='s8'>114218795</span>, headquarters: 510 Townsend
                  Street San Francisco, CA 94103 United States via Stripe payment
                  system
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingTop: '8pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  7.7.
                </p>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  The Service Provider shall be entitled to switch between the
                  primary and the backup payment service provider at any time at its
                  own discretion and shall be entitled to suspend the use of any
                  payment service provider at any time, in particular in the event of
                  technical problems or temporary inoperability.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  7.8.
                </p>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'left',
                  }}
                >
                  When using the Stripe payment system, the credit card data is
                  entered and saved on the Service Provider's interface without
                  redirection, but the credit card data is encrypted with the Order
                  thanks to
                </p>
                <p
                  style={{
                    paddingTop: '4pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  the use of highly secure client-side encryption (CSE) technology,
                  to which only the payment service provider has access.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  7.9.
                </p>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  If the Online Payment fails, the Server will clearly indicate this
                  on the Platform, after which the Order can be placed again by
                  modifying the payment details. If the User does not return to the
                  Platform from the external electronic interface, the transaction
                  will be deemed unsuccessful. The transaction shall also be deemed
                  unsuccessful if the User clicks on the "Back/Back" or "Refresh"
                  button of the browser on the external payment interface.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  7.10.
                </p>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  The User has the possibility to save his/her credit card details
                  (credit card number, name written on the credit card, expiry date,
                  CVC number) in order to avoid having to re-enter these details in
                  future Orders and thus to make the Order process easier and
                  simpler. When saved, all data is stored as a "token"
                  (non-reversible code), and only the token is used and transmitted
                  for future orders. Credit card data is stored by the payment
                  service providers.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    textAlign: 'left',
                  }}
                >
                  7.11.
                </p>
                <p
                  style={{
                    paddingTop: '6pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  Online payment is governed by the business rules of the payment
                  service provider, the card company concerned and the financial
                  institution issuing the card and crediting the amount. For Online
                  Payment, the browser must support SSL encryption.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text={8}>
            <h3
              style={{
                paddingTop: '8pt',
                paddingLeft: '16pt',
                textIndent: '-11pt',
                textAlign: 'left',
              }}
            >
              Special provisions for the Courier Service
            </h3>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              8.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              In case of choosing the Courier Service, the User expressly orders the
              provision of a car delivery service and expressly authorizes the
              Partner providing the Partner Service to search for and select a
              Courier for the User, and the User expressly authorizes the Partner to
              enter into a contract between the User and the Courier for the delivery
              on its behalf, according to the terms and conditions accepted by the
              User, and the Courier, by accepting the delivery request, agrees to
              provide the User with a delivery service on the basis of the contract
              between them.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              8.2.
            </p>
            <p
              style={{
                paddingTop: '5pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              The User accepts that the terms and conditions of the Courier Service
              contract between the User and the Courier, in particular the delivery
              time, the territorial limits (delivery area) and the delivery fee, are
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              unilaterally determined by the Partner providing the Partner Service
              and may be unilaterally changed, even temporarily, at any time.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              8.3.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The amount of the delivery fee may vary depending on the distance from
              the given Service Unit, the time of the Order and the Couriers
              available in the given zone, i.e. Partner is entitled to set different
              Courier Service fees for Orders placed by the User to the same address
              from the same Partner at different times. The User acknowledges that
              the Courier Service Charges applied may differ for Orders placed from
              the same Partner to different addresses or at different times.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              8.4.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The expected delivery time and the delivery fee can be viewed and
              tracked at any time when placing the Order on the Platform. The User
              acknowledges that if the User changes the delivery address on the
              Platform, both the delivery time and the amount of the delivery fee may
              change, and if the Courier Service is no longer available for the
              delivery address of the selected Partner, the User will not be able to
              use the Courier Service and will have to arrange for the return of the
              vehicle to and from the Partner's Unit.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              8.5.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              By ordering the Courier Service, the User undertakes to be available to
              deliver and collect the vehicle at the specified location at the
              specified time. If the User is not available at the location within +15
              minutes of the specified time, the Courier is not obliged to wait for
              the User any longer, the Courier Service is deemed to be completed, but
              the User is obliged to pay the Courier Service fee. If the User is not
              available at the specified location at the specified time + 15 minutes,
              the Courier shall return the vehicle to the Partner and in such case
              the User shall pay the Courier Service fee plus the cost of returning
              the vehicle.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              8.6.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The Service Provider shall not be liable for any delay or failure in
              the performance of the Courier Service. Neither the Service Provider,
              nor the Partner, nor the Courier shall be liable for any problems or
              errors resulting from the fact that the User has provided incorrect
              and/or inaccurate data required for the performance of the Courier
              Service.
            </p>
          </li>
          <li data-list-text={9}>
            <h3
              style={{
                paddingTop: '4pt',
                paddingLeft: '16pt',
                textIndent: '-11pt',
                textAlign: 'left',
              }}
            >
              Other provisions
            </h3>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              9.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The Parties shall perform the Order as indicated on the Platform in
              good faith in accordance with the terms and conditions of these GTC and
              the law, and shall cooperate with each other in the performance of the
              Order.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              9.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              If the performance of the Intermediated Contract and/or Courier Service
              Contract has failed for reasons on the part of the User (not including
              the case of the exercise of the right of withdrawal or termination to
              which the Consumer is entitled by law), the User may not claim back
              from the Service Provider the service fee or purchase price of the
              Intermediated Contract or Courier Service Contract already paid.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The Parties stipulate that the contract for the Courier Service shall
              be terminated for reasons on the part of the User, in particular, but
              not exclusively, in the following cases:
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <ul id='l6'>
              <li data-list-text='-'>
                <p
                  style={{
                    paddingLeft: '59pt',
                    textIndent: '-18pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  The User has chosen the wrong delivery address or has entered the
                  wrong address, after the User is not available after 15 minutes
                  from the time of arrival at the delivery address and the Courier
                  has called the User at least twice.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
              </li>
              <li data-list-text='-'>
                <p
                  style={{
                    paddingLeft: '59pt',
                    textIndent: '-18pt',
                    lineHeight: '149%',
                    textAlign: 'justify',
                  }}
                >
                  The Courier cannot find or reach the User after the User is
                  unavailable after 15 minutes from the time of arrival at the
                  delivery address and the Courier has called the User at least
                  twice.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
              </li>
              <li data-list-text='-'>
                <p
                  style={{
                    paddingLeft: '59pt',
                    textIndent: '-18pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  The User does not provide the Courier with the tools necessary for
                  the operation of the vehicle or the documents required by law for
                  use on the road (e.g. registration licence).
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
                <p
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  The Parties stipulate that the contract for the Intermediated
                  Service shall be terminated for reasons on the part of the User, in
                  particular, but not exclusively, in the following cases:
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}>
                  <br />
                </p>
              </li>
              <li data-list-text='-'>
                <p
                  style={{
                    paddingLeft: '59pt',
                    textIndent: '-18pt',
                    lineHeight: '150%',
                    textAlign: 'justify',
                  }}
                >
                  The User has requested to modify the Order after placing the Order,
                  but only after the confirmation of the Partner (conclusion of the
                  Intermediated Contract), the User requests the cancellation of the
                  Order (not including the case of the exercise of the right of
                  withdrawal or termination to which the Consumer is entitled by
                  law).
                </p>
              </li>
              <li data-list-text='-'>
                <p
                  style={{
                    paddingLeft: '59pt',
                    textIndent: '-18pt',
                    lineHeight: '149%',
                    textAlign: 'justify',
                  }}
                >
                  The Courier Service Contract shall be terminated for any reason and
                  the User shall not arrange for the delivery of the vehicle to the
                  Partner's Unit.
                </p>
              </li>
            </ul>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              9.3.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The User acknowledges that if the Partner is unable to fulfil the
              Order, the Service Provider may cancel the Order, thus having the right
              to terminate the Intermediated Agreement between the Partner and the
              User. The Service Provider shall inform the Parties of the cancellation
              of the Order by sending a push notification message to the User and, in
              the case of online payment, shall arrange for the refund or
              reinstatement of the amount paid by the User.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              9.4.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The Service Provider shall not be liable for the failure or
              cancellation of the Order or for the termination of the Intermediary
              Agreement, and shall not be liable for the User's damages - property
              damage, costs, loss of profit - in the event of the cancellation of the
              Order or the Partner's or the Courier's faulty performance.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={10}>
            <h3
              style={{ paddingLeft: '22pt', textIndent: '-17pt', textAlign: 'left' }}
            >
              Handling complaints
            </h3>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              10.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              In the event of a complaint or claim, the User is entitled to contact
              the Service Provider's customer service at one of the following contact
              details:
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Click on "Customer Service" in the drop-down menu on the NDRU website,
              "Customer Service" in the app
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              <a href='mailto:info@drivendru.com' className='s1' target='_blank'>
                Postal address: 1221 Budapest, Hajó utca 1. E-mail address:{'{'}' '
                {'}'}
              </a>
              <a href='mailto:info@drivendru.com' target='_blank'>
                info@drivendru.com
              </a>
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              10.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              The Service Provider's handling of complaints is always free of charge.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              10.3.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              The Service Provider will immediately investigate and, if possible,
              immediately remedy the problem requiring immediate resolution, and in
              all other cases will respond to the complaint within 24 days.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              10.4.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              If the problem cannot be solved in any other way, the Service Provider
              may contact the User by telephone. If the Service Provider contacts the
              User in connection with a complaint, the Service Provider shall record
              the User's complaint by telephone for the purpose of handling and
              documenting the complaint, and shall keep the recorded voice recording
              for 5 years from the date of recording. If
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              the User does not consent to the recording of the telephone
              conversation by the Data Controller in accordance with the provisions
              of the Data Management Information, the User may submit the complaint
              by post (1221 Budapest, Hajó utca 1) or by e-mail{'{'}' '{'}'}
              <span style={{ color: '#000' }}>(</span>info@drivendru.com). The User
              has the possibility to request a copy of the audio recording of the
              telephone conversation, which the Service Provider shall comply with
              within 25 days from the receipt of the relevant request. The User may
              request the audio recording by post (1221 Budapest, Hajó utca 1) or by
              e-mail <span style={{ color: '#000' }}>(</span>info@drivendru.com). The
              Controller shall provide (by post) a copy of the audio recording free
              of charge.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              10.5.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              During the investigation of the complaint, the User shall cooperate
              with the Service Provider and provide the necessary information and
              documents.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              10.6.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              If the Consumer's complaint is rejected in whole or in part, or if the
              time limit set above for the investigation of the complaint has expired
              without result, the Consumer may refer the matter to the competent
              authorities in his/her place of residence:
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Contact details of consumer protection authorities:{'{'}' '{'}'}
              <span style={{ color: '#000' }}>
                https://fogyasztovedelem.kormany.hu/#/fogyasztovedelmi_hatosag
              </span>
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '149%',
                textAlign: 'left',
              }}
            >
              In Budapest, the Consumer Protection Department of the V. District
              Office of the Metropolitan Government Office:
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Address: 1051 Budapest, Sas u. 19. III. floor. Phone: +36 (1) 450-2598
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              E-mail{'{'}' '{'}'}
              <a
                href='mailto:fogyved_kmf_budapest@bfkh.gov.hu'
                className='a'
                target='_blank'
              >
                cím:{'{'}' '{'}'}
              </a>
              <a href='mailto:fogyved_kmf_budapest@bfkh.gov.hu' target='_blank'>
                fogyved_kmf_budapest@bfkh.gov.hu
              </a>
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Contact details of Conciliation Boards by territorial jurisdiction:
              {'{'}' '{'}'}
              <a
                href='http://www.bekeltetes.hu/index.php?id=testuletek'
                className='a'
                target='_blank'
              >
                https://
              </a>
              <a
                href='http://www.bekeltetes.hu/index.php?id=testuletek'
                target='_blank'
              >
                www.bekeltetes.hu/index.php?id=testuletek
              </a>
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Contact the Budapest Conciliation Board: Address: 1016 Budapest,
              Krisztina krt. 99.
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              Phone number: +36 (1) 488-2131
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              Fax number: +36 (1) 488-2186
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <a
                href='mailto:bekelteto.testulet@bkik.hu'
                className='s1'
                target='_blank'
              >
                E-mail: bekelteto.testulet@bkik.hu
              </a>
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              10.7.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              The Consumer has the right to take the matter to court in the event of
              a dispute regarding the complaint.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={11}>
            <h3
              style={{ paddingLeft: '22pt', textIndent: '-17pt', textAlign: 'left' }}
            >
              Processing of personal data
            </h3>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              11.1.
            </p>
            <p
              style={{
                paddingTop: '5pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              The detailed rules for the processing of the User's personal data are
              set out in{'{'}' '{'}'}
              <span style={{ color: '#000' }}>
                the Service Provider's Privacy Policy
              </span>
              .
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={12}>
            <h3
              style={{ paddingLeft: '22pt', textIndent: '-17pt', textAlign: 'left' }}
            >
              Intellectual property rights in relation to the Platform
            </h3>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              12.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              All material on the Platform, its entire text, graphics and other
              content, the structure of the Platform, the source code and any other
              intellectual property is protected by copyright and other intellectual
              property rights. The Service Provider is the exclusive owner of the
              copyright and other intellectual property rights. Any use of the
              content of the Platform or any other intellectual property of the
              Service Provider is subject to the prior written consent of the Service
              Provider.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              12.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '149%',
                textAlign: 'left',
              }}
            >
              Unauthorised use of the Platform's content may result in sanctions
              under criminal law, in addition to trademark, copyright and civil law
              claims.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={13}>
            <h3
              style={{ paddingLeft: '22pt', textIndent: '-17pt', textAlign: 'left' }}
            >
              Establishment and termination of the Contract
            </h3>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              13.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              The Contract between the Service Provider and the User is concluded
              upon registration of the User.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              13.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The Contract between the User and the Service Provider shall be deemed
              to be a contract concluded by electronic means, to which the provisions
              of the Civil Code and the E-Commerce Act applicable to electronic
              commerce services shall apply. The Intermediated Contract shall be
              deemed to be in writing and its data stored in writing shall be
              identical to the data of the Order. The data of the Intermediated
              Contracts concluded through the Platform can be viewed retrospectively
              under the "Service History" menu. The language of contracting and
              communication is Hungarian.
            </p>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              13.3.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The Intermediated Agreement shall be concluded when the electronic
              confirmation of the Order is received by the Service Provider from the
              Partner, provided that the User or the Consumer has not withdrawn the
              Order by that time.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={14}>
            <h3
              style={{ paddingLeft: '22pt', textIndent: '-17pt', textAlign: 'left' }}
            >
              Scope and duration of the GTC
            </h3>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              14.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              The scope of these GTC shall cover the Service provided by the Service
              Provider to Users in Hungary.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              14.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              These GTC apply to all uses of the Services (mobile website, mobile
              applications, etc.) through which the Platform is accessed.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              14.3.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              The GTC are effective from the date set out above and are valid
              indefinitely.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={15}>
            <h3
              style={{
                paddingTop: '10pt',
                paddingLeft: '22pt',
                textIndent: '-17pt',
                textAlign: 'left',
              }}
            >
              Amendments to the GTC and availability
            </h3>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              style={{
                paddingTop: '10pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              15.1.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '150%',
                textAlign: 'justify',
              }}
            >
              The Service Provider may unilaterally modify the terms and conditions
              of these GTC at any time. This right also expressly extends to the
              annexes to these GTC. Any amendment shall enter into force at the same
              time as it is published on the Platform.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
              15.2.
            </p>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left',
              }}
            >
              <a href='http://www.drivendru.com/aszf' className='s1' target='_blank'>
                The GTC and its previous versions are available here:
                www.drivendru.com/aszf
              </a>
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
          </li>
          <li data-list-text={16}>
            <h3
              style={{
                paddingTop: '10pt',
                paddingLeft: '22pt',
                textIndent: '-17pt',
                textAlign: 'left',
              }}
            >
              Final provisions
            </h3>
          </li>
        </ol>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingTop: '10pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          16.1.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          The level of security of the Platform operated by the Service Provider is
          adequate, however, the User is recommended to take the following
          precautions: use virus and spyware protection software with a fresh
          database, install security updates of the operating system. The use of the
          Platform implies the User's knowledge of the technical and technological
          limitations of the Internet and acceptance of the potential for error
          inherent in the technology.
        </p>
        <p
          style={{
            paddingTop: '4pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          16.2.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          The Service Provider shall not be liable for the content, data and
          information protection practices of external websites accessible from the
          Platform as a steppingstone. If the Service Provider becomes aware that the
          linked page or the linking violates the rights of third parties or the
          applicable laws, it shall immediately remove the link from the Platform.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          16.3.
        </p>
        <p
          style={{
            paddingTop: '5pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'left',
          }}
        >
          The Service Provider shall not be liable for any damage caused by the
          connection to the Platform. The User is responsible for the protection of
          his/her computer and the data on it.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          16.4.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'left',
          }}
        >
          It is expressly prohibited to transmit, publish or share content on the
          Platform that is not legally permitted. The Service Provider reserves the
          right to delete content uploaded by Users.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          16.5.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          The Contract may be terminated by either the User or the Service Provider
          without giving any reason by sending an e-mail to the User or to the
          Customer Service, and the User may delete his/her NDRU Account by clicking
          on the "Delete Account" button in the Profile menu, in which case the
          contract between the Service Provider and the User shall terminate
          simultaneously with the deletion of the Account and the personal data
          associated with it. The User acknowledges that upon termination of the
          Contract or deletion of his/her NDRU Account, all data stored in the NDRU
          Account will be permanently and irretrievably deleted, such as the User's
          service history.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          16.6.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          The Service Provider shall be entitled to assign any rights or obligations
          arising from the Services, in whole or in part, without the consent of the
          User, to its successor, affiliate or other business entity acquiring the
          assets and property of the Service Provider.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          16.7.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          The Service Provider does not subscribe to any code of conduct.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingTop: '10pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          16.8.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          The Contract shall be governed by the provisions of Hungarian law.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingTop: '10pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'left',
          }}
        >
          The legislation referred to in the GTC and the legislation otherwise
          applicable to the Contract and the Service:
        </p>
        <ul id='l7'>
          <li data-list-text='-'>
            <p
              style={{
                paddingTop: '4pt',
                paddingLeft: '47pt',
                textIndent: '-6pt',
                textAlign: 'left',
              }}
            >
              Act V of 2013 on the Civil Code (<b>Civil Code</b>)
            </p>
          </li>
          <li data-list-text='-'>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '47pt',
                textIndent: '-6pt',
                textAlign: 'left',
              }}
            >
              The General Data Protection Regulation (Regulation (EU) 2016/679;{'{'}'
              '{'}'}
              <b>GDPR</b>)
            </p>
          </li>
          <li data-list-text='-'>
            <p
              style={{
                paddingTop: '6pt',
                paddingLeft: '59pt',
                textIndent: '-18pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Act CXII of 2011 on the Right to Informational Self-Determination and
              Freedom of Information (<b>Infotv.</b>)
            </p>
          </li>
          <li data-list-text='-'>
            <p
              style={{
                paddingLeft: '59pt',
                textIndent: '-18pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Act CVIII of 2001 on certain aspects of electronic commerce services
              and information society services (<b>Elkertv. </b>)
            </p>
          </li>
          <li data-list-text='-'>
            <p
              style={{
                paddingLeft: '59pt',
                textIndent: '-18pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Act XLVII of 2008 on the Prohibition of Unfair Commercial Practices
              against Consumers (<b>Fttv. </b>)
            </p>
          </li>
          <li data-list-text='-'>
            <p
              style={{ paddingLeft: '47pt', textIndent: '-6pt', textAlign: 'left' }}
            >
              Act XCL of 1997 on Consumer Protection (<b>Fvtv. </b>)
            </p>
          </li>
          <li data-list-text='-'>
            <p
              style={{
                paddingTop: '5pt',
                paddingLeft: '59pt',
                textIndent: '-18pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Government Decree 45/2014 (II. 26.) on the detailed rules of contracts
              between consumers and businesses (<b>Fvkr. </b>)
            </p>
          </li>
          <li data-list-text='-'>
            <p
              style={{
                paddingLeft: '59pt',
                textIndent: '-18pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Act LVII of 1996 on the Prohibition of Unfair Market Behaviour and
              Restriction of Competition (<b>Act on </b>Unfair <b>Competition</b>)
            </p>
          </li>
          <li data-list-text='-'>
            <p
              style={{
                paddingLeft: '59pt',
                textIndent: '-18pt',
                lineHeight: '150%',
                textAlign: 'left',
              }}
            >
              Act XI of 1997 on the Protection of Trademarks and Geographical
              Indications (<b>Act on </b>the Protection of Trademarks and
              Geographical Indications) (Act XI of 1997)
            </p>
          </li>
        </ul>
        <h3
          style={{
            paddingTop: '2rem',
            textIndent: '0pt',
            textAlign: 'center',
          }}
        >
          Annex 1 - Content of the Intermediated Contract
        </h3>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p
          style={{
            paddingTop: '10pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          1.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          The Intermediated Contract is concluded when the electronic confirmation is
          received by the Service Provider after the Order has been placed. The
          content of the Intermediated Contract is set out in this Annex.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          2.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          An Intermediated Contract is a contract concluded by electronic means, to
          which the provisions of the Civil Code and the E-Commerce Act applicable to
          electronic commercial services apply. The Intermediated Contract shall be
          deemed to be in writing and its data stored in writing shall be identical
          to the data of the Order. The details of the Intermediated Contract and
          Order can be viewed retrospectively under "My Orders". The language of
          contracting and communication is Hungarian.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          3.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          The Partner shall bear all responsibility for the current content on the
          Platform, the offers displayed, their availability and quality. The User
          may contact the Partner in the event of quality complaints regarding the
          Partner Service, and may only assert claims against the Partner.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          4.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          The Partner shall be responsible for the provision and correct content of
          the information required by law on the Products and Partner Services. The
          Service Provider only acts as an intermediary for Orders, and is therefore
          not involved in the provision of the Partner Service, nor in the assembly,
          procurement, production or installation of the related products, and has
          neither influence nor insight into them, and is therefore not subject to
          the information obligations under this clause. However, the Service
          Provider shall, by designing the Platform accordingly, create the necessary
          conditions to enable the Partner to comply fully with the above legal
          provisions imposed on it. The Service Provider's liability shall be limited
          to the accurate presentation of the data provided by the Partner in the
          correct form. The Partner shall be obliged to provide the Service Provider
          with the data in accordance with the legislation in force prior to their
          publication on the Platform and to notify the Service Provider without
          delay in the event of any changes. The Partner shall be solely responsible
          for the legality, completeness and accuracy of the data provided.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          5.
        </p>
        <p
          style={{
            paddingTop: '4pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          The Partner shall be liable to the Consumer for all liability in accordance
          with the applicable national and EU legislation, including mandatory
          warranty periods, product warranty, accessory warranty, quality assurance
          standards, safety regulations, description of methods of use.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          6.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          In any case, in addition to the mandatory information required under the
          legislation in force, the Partner distributing the products concerned must,
          at the express request of the Consumer, inform the Consumer of the
          warranty, accessories and product guarantee conditions applicable to the
          product concerned and of the conditions of the Partner Service's
          accessories guarantee. This obligation is fulfilled directly by the Partner
          and is not collected, stored or disclosed by the Service Provider. The
          Service Provider undertakes to forward any such requests from the Consumer
          to the Partner.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          7.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'left',
          }}
        >
          The Partner is responsible for the handling of complaints from Consumers,
          with the Service Provider's involvement (see the provisions of the GTC on
          complaints handling).
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          8.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'left',
          }}
        >
          The delivery, if the Partner provides the User with the possibility of a
          Courier Service, is the sole responsibility of the Partner.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          9.
        </p>
        <p
          style={{
            paddingTop: '5pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          In the event of non-performance or improper performance of the
          Intermediated Agreement, the provisions of Chapter 9 of these GTC shall
          apply to the extent that they are applicable mutatis mutandis to the
          Intermediated Agreement between the Partner and the User.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          10.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          The Partner is responsible for the non-performance or improper performance
          of the Order and shall be liable to the User in particular, but not
          exclusively, in the following cases: the Partner's Unit being closed,
          product failure, technical problem, incorrect information or offer on the
          Platform, delayed performance of the Partner Service.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          11.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          If the Partner sells a product or provides a Partner Service in respect of
          which the Consumer has the right of withdrawal or termination provided for
          in Article 20 of the Consumer Contract Act, the Partner shall create the
          conditions for the Consumer to exercise these rights properly. The Partner
          shall immediately notify the Service Provider in writing of the Consumer's
          withdrawal or termination.
        </p>
        <p
          style={{
            paddingTop: '4pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            textAlign: 'left',
          }}
        >
          12.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'left',
          }}
        >
          The Consumer may not exercise his right of withdrawal or termination under
          Article 20 of the Consumer Contract Act in the cases specified in Article
          29 of the Consumer Contract Act.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          13.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '149%',
            textAlign: 'left',
          }}
        >
          The Consumer can exercise his right of withdrawal by sending a clear
          declaration to the Service Provider via a mobile application.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          14.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'justify',
          }}
        >
          The Service Provider does not examine the legality of the exercise of the
          right of withdrawal, the right of termination, the legality of the related
          refund or its possible refusal. The Supplier shall make the refund to the
          Consumer in the same way as the payment method used by the Consumer.
        </p>
        <p style={{ textIndent: '0pt', textAlign: 'left' }}>
          <br />
        </p>
        <p style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>
          15.
        </p>
        <p
          style={{
            paddingTop: '6pt',
            paddingLeft: '5pt',
            textIndent: '0pt',
            lineHeight: '150%',
            textAlign: 'left',
          }}
        >
          If the Service Provider has already settled with the Partner the value of
          the Order, the refund is the responsibility of the Partner.
        </p>
      </div>
    </Page>
  );
}
export default NdruTermsOfService;
