import icClock from '../assets/images/pricing-page/icons/ic_clock.svg';
import icBook from '../assets/images/pricing-page/icons/ic_book.svg';
import icCheckmark from '../assets/images/pricing-page/icons/ic_checkmark.svg';
import icCalendar from '../assets/images/pricing-page/icons/ic_calendar.svg';
import icCar from '../assets/images/pricing-page/icons/ic_car.svg';
import icProfile from '../assets/images/pricing-page/icons/ic_profile.svg';

export const subscriptionSectionTexts = {
  en: {
    h2: `No subscription fees`,
    p: `Unlimited usage without monthly fees`,
    sections: [
      {
        img: icClock,
        alt: 'clock',
        h5: 'Unlimited bookings',
        p: `Your customers can book an appointment
        without any restrictions on the working
        days you specify based on the services
        they have ordered.`,
      },
      {
        img: icBook,
        alt: 'book',
        h5: 'Unlimited worksheets',
        p: `There is no limit to how many worksheets you
        start or close.`,
      },
      {
        img: icCheckmark,
        alt: 'checkmark',
        h5: 'Unlimited tasks',
        p: `Each worksheet can have multiple tasks
        depending on what your customers have
        ordered and what suggestions you make.`,
      },
      {
        img: icCalendar,
        alt: 'calendar',
        h5: 'Unlimited payments',
        p: `There are no restrictions on the amount or
        size of payments through the app. Use our
        integrated billing system, which greatly
        simplifies your entire payment process.`,
      },
      {
        img: icCar,
        alt: 'car',
        h5: 'Unlimited discussions',
        p: `Send a message within the app to your
        customers and communicate with your service
        team. Messaging assigned to each worksheet
        simplifies your processes.`,
      },
      {
        img: icProfile,
        alt: 'profile',
        h5: 'Unlimited clients',
        p: `Keep track of your customers and their car
        repair history. Through this, you will be
        constantly updated on their needs.`,
      },
    ],
  },
  hu: {
    h2: `Minden egy helyen, amire a vállalkozásod digitális működéséhez szükséged van`,
    p: `Korlátlan használat havidíjak nélkül`,
    sections: [
      {
        img: icClock,
        alt: 'clock',
        h5: 'Korlátlan foglalás',
        p: `Kezeld naptárad problémamentesen.`,
      },
      {
        img: icBook,
        alt: 'book',
        h5: 'Korlátlan munkalap',
        p: `A teljes adminisztrációd egyszerűsítve.`,
      },
      {
        img: icCheckmark,
        alt: 'checkmark',
        h5: 'Korlátlan feladat',
        p: `Kövesd folyamataid hatékonyan.`,
      },
      {
        img: icCalendar,
        alt: 'calendar',
        h5: 'Korlátlan számlázás',
        p: `A számlák az applikáción keresztül egyenlítődnek ki, számodra extra költségek nélkül.`,
      },
      {
        img: icCar,
        alt: 'car',
        h5: 'Korlátlan beszélgetés',
        p: `Kommunikálj ügyfleiddel hatékonyan.`,
      },
      {
        img: icProfile,
        alt: 'profile',
        h5: 'Korlátlan ügyfél',
        p: `Tartsd kézben ügyfeleid adatait.`,
      },
    ],
  },
};
