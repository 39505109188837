export const ndruIsGrowingSection = {
  en: {
    h2: `NDRU is growing`,
    h3: `Everyone at nDRU is passionate about cars,
    which means we understand what our customers
    need from our product.`,
    p: `Our services are designed to make car owners'
    lives a breeze and to provide a one-stop-shop
    experience for everything car related. Are you
    ready to shape the future of the industry
    undergoing massive transformation with us?
    Check out our open positions and send us your
    application. We can't wait to hear from you!`,
    h3Right: `Check out what makes us special:`,
    lis: [
      `Global innovation`,
      `Disrupting an industry bootstrapped`,
      `A product built by car lovers`,
    ],
  },
  hu: {
    h2: `Az nDRU bővül`,
    h3: `Az nDRU-nál mindenki szenvedélyesen szereti az autókat, ami azt jelenti, hogy tudjuk, mire van szüksége az ügyfeleinknek a termékünktől.`,
    p: `Szolgáltatásaink célja, hogy megkönnyítsük az autótulajdonosok életét azzal, hogy minden autóval kapcsolatos kérdést egy helyen oldhatnak meg. Készen állsz rá, hogy velünk együtt alakítsd (a hatalmas átalakuláson áteső) iparág jövőjét? Tekintsd meg nyitott pozícióinkat, és küldd el nekünk jelentkezésedet. Alig várjuk, hogy halljunk felőled!`,
    h3Right: `Nézd meg, mi tesz minket különlegessé:`,
    lis: [
      `Egy iroda tele különleges Porsche modellekkel`,
      `Megváltoztatunk egy iparágat`,
      `Egy autószerelmesek által alkotott termék`,
    ],
  },
};
