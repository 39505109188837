export const boostUpYourRepairShopTexts = {
  en: {
    h2: [
      'Boost up your repair',
      'shop with',
      'our',
      'management platform',
    ],
    p: [
      `Easily follow the status of your repairs,
      get`,
      `quick access to all`,
      `history data and schedule`,
      `upcoming repairs in minutes.`,
    ],
    btnText: 'Learn more',
  },
  hu: {
    h2: [
      'Autoszervizként',
      'csatlakoznál',
      'az nDRU',
      'app-hoz?',
    ],
    p: [
      `Emeld új szintre szervized`,
      `a vállalatirányítási-platformunkkal:`,
      `növeld szervized hatékonyságát és`,
      `szerezz új vásárlókat!`,
    ],
    btnText: `Lépj kapcsolatba`,
  },
};
