import { useContext } from 'react';
import locationImgKovesdan from '../assets/images/locations/kovesdan-kft.png';
import locationImgHollos from '../assets/images/locations/hollos-szerviz-logo.png';

import Location from './Location';
import { featuredLocationsTexts } from '../texts/featuredLocationsTexts';
import Context from '../context/Context';

const FeaturedLocations = () => {
  const { usedLanguage } = useContext(Context);

  const { h2, locations } =
    featuredLocationsTexts[usedLanguage];

  const imgsArray = [
    locationImgKovesdan,
    locationImgHollos,
  ];

  return (
    <section className='section'>
      <div className='container'>
        <h2 className='section__title section__title--location section__title--align-left-from-md'>
          {h2}
        </h2>

        <div className='locations'>
          <div className='row'>
            {locations.map((location, index) => (
              <div
                key={index}
                className={`col-6 col-lg-5 ${
                  index === 1 ? 'offset-lg-2' : ''
                }`}
              >
                <Location
                  rating={location.rating}
                  img={imgsArray[index]}
                  address={location.address}
                  title={location.title}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedLocations;
