import Page from '../components/Page';
import GetInTouchSection from '../components/GetInTouchSection';
import MobileAppsSection from '../components/MobileAppsSection';
import BlogCard from '../components/BlogCard';

import ManWithFlag from '../assets/images/blog-cards/man-with-flag.jpg';

function BlogPage() {
  return (
    <Page title='Blog' endpoint='blog'>
      <h1 className='blog__page-title'>Blog</h1>
      <h2 className='blog__page-subtitle'>
        Company Updates
      </h2>

      <section className='blog__cards'>
        <BlogCard
          image={ManWithFlag}
          link={'/blog/nDRU-has-launched'}
          title='nDRU has launched – the app simplifying life with a car'
          text='Our team has launched three digital tools for an elevated car experience: a
          mobile app for car owners to easily book automotive services, a cloud-based
          service platform for repair shops and other service providers to improve their
          processes and efficiency, and a fleet platform for car fleet managers to
          streamline their operations.'
          date='12/11/2022'
        />
      </section>

      <MobileAppsSection />
      <GetInTouchSection />
    </Page>
  );
}
export default BlogPage;
