import { useContext } from 'react';

import Context from '../context/Context';
import { originStoryTexts } from '../texts/originStoryTexts';

const OriginStory = () => {
  const { usedLanguage } = useContext(Context);

  const { h2, milestones, lead } =
    originStoryTexts[usedLanguage];

  return (
    <section className='section origin-story'>
      <div className='container-fluid container--large'>
        <h2 className='section__title'>{h2}</h2>

        <div className='origin-story__grid'>
          {milestones.map((milestone, index) => (
            <div
              key={milestone.header + index}
              className='origin-story__item'
            >
              <div className='origin-story__image'>
                <img
                  src={milestone.img}
                  alt={milestone.alt}
                />
              </div>
              <div className='origin-story__description'>
                <p style={{ margin: 0 }}>
                  {milestone.header}
                </p>
                {milestone.text}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='container'>
        <p className='origin-story__intro lead'>{lead}</p>
      </div>
    </section>
  );
};

export default OriginStory;
