import { useContext } from 'react';

import { subscriptionSectionTexts } from '../texts/subscriptionSectionTexts';
import Context from '../context/Context';

const SubscriptionSection = () => {
  const { usedLanguage } = useContext(Context);
  const { h2, p, sections } =
    subscriptionSectionTexts[usedLanguage];

  return (
    <section className='subscription section'>
      <div className='container'>
        <div className='subscription__intro'>
          <h2>{h2}</h2>
          <p>{p}</p>
        </div>

        <div className='subscription__grid'>
          {sections.map((section, index) => (
            <div
              key={section.h5 + index}
              className='subscription__item'
            >
              <div className='subscription__icon'>
                <img src={section.img} alt={section.alt} />
              </div>
              <div className='subscription__texts'>
                <h5>{section.h5}</h5>
                <p>{section.p}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SubscriptionSection;
