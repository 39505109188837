import icClock from '../assets/images/service-providers/cases/ic_clock.svg';
import icClipboard from '../assets/images/service-providers/cases/ic_clipboard.svg';
import icPhone from '../assets/images/service-providers/cases/ic_phone.svg';

export const useCasesSectionTexts = {
  en: {
    h2: 'Use cases',
    useCases: [
      {
        img: icClock,
        title: ['National parking', 'provider'],
        text: `A car parking provider from Hungary has
        partnered with us, so that nDRU users can
        easily find and pay for parking spaces.
        Being the first such provider on the
        platform, they enjoyed a massive headstart
        on the competition with the help of nDRU.`,
      },
      {
        img: icClipboard,
        title: ['Insurance', 'company'],
        text: `An insurance company has decided to sell its
        casco and other liability insurance products
        through the nDRU service store. This way,
        customers can get their vehicle insured in
        mere minutes and pay the monthly fee
        automatically via the app.`,
      },
      {
        img: icPhone,
        title: ['Car assistance', 'provider'],
        text: `If their vehicle ever breaks down on the
        road, nDRU users won't need to worry. A car
        assistance provider offering its national
        and international assistance services
        through the nDRU store has them covered.`,
      },
    ],
  },
  hu: {
    h2: 'Partnereink',
    useCases: [
      {
        img: icClock,
        title: ['Nemzeti parkolási', 'szolgáltató'],
        text: `Egy magyar parkolási szolgáltató a partnerünkké vált, így az nDRU felhasználói könnyen megtalálhatják és fizethetnek parkolóhelyekért és garázsokért a közelükben. Mivel ez volt az első ilyen szolgáltató a platformon, az nDRU segítségével hatalmas előnyre tett szert a versenytársakkal szemben.`,
      },
      {
        img: icClipboard,
        title: ['Biztosítótársaság', ''],
        text: `Egy biztosítótársaság úgy döntött, hogy casco és egyéb felelősségbiztosítási termékeit az nDRU applikáción keresztül értékesíti. Így az ügyfelek percek alatt biztosíthatják járművüket, és a havidíjat automatikusan fizethetik az alkalmazáson keresztül.`,
      },
      {
        img: icPhone,
        title: ['Segélyhívás', 'szolgáltató'],
        text: `Ha járművük lerobban az úton, az nDRU felhasználóknak nem kell aggódniuk. Az nDRU áruházon keresztül országos és nemzetközi segítségnyújtási szolgáltatásait kínáló autósegély-szolgáltató gondoskodik róluk.`,
      },
    ],
  },
};
