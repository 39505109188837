import { useContext } from 'react';

import { Link } from 'react-router-dom';

import Rating from './Rating';
import Context from '../context/Context';

import ImagePlaceholder from '../assets/images/image-placeholder.jpg';

function LocationListItem({ rating, img, address, title, id }) {
  const { usedLanguage } = useContext(Context);

  const imgSource = img ? `data:image/jpeg;base64,${img}` : ImagePlaceholder;

  return (
    <Link to={usedLanguage === 'en' ? `/repair-shops/list/${id}` : `/szervizek-partnerek/lista/${id}`} className='location'>
      <div className='img-container'>
        <img src={imgSource} alt={title} />
      </div>
      <div className='location__body'>
        <h5>{title}</h5>
        <p className='location__address'>{address}</p>
      </div>
      <Rating rating={rating} />
    </Link>
  );
}
export default LocationListItem;
