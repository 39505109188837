import img1992 from '../assets/images/our-mission-page/origin/1992.jpg';
import img1987 from '../assets/images/our-mission-page/origin/1987.jpg';
import img1978 from '../assets/images/our-mission-page/origin/1978.jpg';
import img2011 from '../assets/images/our-mission-page/origin/2011.jpg';
import img1995 from '../assets/images/our-mission-page/origin/1995.jpg';
import imgPorsche from '../assets/images/our-mission-page/origin/porsche.jpg';

export const originStoryTexts = {
  en: {
    h2: 'Origin story',
    milestones: [
      {
        img: img1992,
        alt: '1992',
        header:
          '1992 Hungary Hillclimbing championship Paradsasvár',
        text: `András Kövesdán with M3 E30 BMW
        Touring 1st Place`,
      },
      {
        img: img1987,
        alt: '1987',
        header: '1987 Hungaroring',
        text: `András Kövesdán at Ralt
        Formula 3 RT30`,
      },
      {
        img: img1978,
        alt: '1978',
        header: '1978 Bükk Rally',
        text: `András Kövesdán with a BMW
        1602 1st place`,
      },
      {
        img: img2011,
        alt: '2011',
        header: '2011 Budapest',
        text: `Kövesdán repair shop`,
      },
      {
        img: img1995,
        alt: '1955',
        header: '1995 Budapest',
        text: `Krisztián Kövesdán first try to
        put a racing helmet on`,
      },
      {
        img: imgPorsche,
        alt: 'porsche',
        header: '2011 Budapest',
        text: `Kövesdán repair shop`,
      },
    ],
    lead: `The love of cars runs deep in the nDRU heritage.
    The father of our CEO, András Kövesdán competed
    with great success in various racing championships
    before opening his independent repair shop in
    Budapest in the 1970s. Since then, the name
    Kövesdán has become a symbol of quality,
    expertise, and reliability in the minds of vehicle
    owners. We have set out to bring these values into
    the product we’re creating, which will ease the
    inconveniences that car owners might face, while
    improving the efficiency and productivity of
    repair shops as well.`,
  },
  hu: {
    h2: 'Az ötlet története',
    milestones: [
      {
        img: img1992,
        alt: '1992',
        header:
          '1992 Magyar Hegyiverseny Bajnokság Paradsasvár',
        text: `Kövesdán András az M3 E30 BMW Touring autóval 1. hely`,
      },
      {
        img: img1987,
        alt: '1987',
        header: '1987 Hungaroring',
        text: `Kövesdán András a Ralt Formula 3 RT30-ban`,
      },
      {
        img: img1978,
        alt: '1978',
        header: '1978 Bükk Rally',
        text: `Kövesdán András és a BMW 1602 1. hely`,
      },
      {
        img: img2011,
        alt: '2011',
        header: '2011 Budapest',
        text: `Kövesdán autószerviz`,
      },
      {
        img: img1995,
        alt: '1955',
        header: '1995 Budapest',
        text: `Kövesdán Krisztián először próbált fel egy bukósisakot`,
      },
      {
        img: imgPorsche,
        alt: 'porsche',
        header: '2011 Budapest',
        text: `Kövesdán autószerviz`,
      },
    ],
    lead: `Az autók szeretete mélyen gyökerezik az nDRU örökségében. Társalapítónk édesapja, Kövesdán András nagy sikerrel versenyzett különböző bajnokságokban, mielőtt a ‘70-es években megnyitotta független autószervizét Budapesten. Azóta a Kövesdán név a minőség, a szakértelem és a megbízhatóság szimbólumává vált a járműtulajdonosok szemében. Célunk, hogy ezeket az értékeket átültessük az általunk létrehozott termékbe, amely megkönnyíti az autótulajdonosok számára az esetleges kellemetlenségeket, miközben javítja a szervizek hatékonyságát és termelékenységét is.`,
  },
};
