import { useEffect } from 'react';

function Page({ children, title, endpoint = '' }) {
  useEffect(() => {
    document.title = `nDRU | ${title}`;
    document
      .querySelector('meta[property="og:url"]')
      .setAttribute('content', `http://drivendru.com/${endpoint}`);
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute('content', `nDRU | ${title}`);

    const timeout = setTimeout(function () {
      window.scrollTo(0, 0);
      clearTimeout(timeout);
    }, 100);

    return () => clearTimeout(timeout);
  }, [title, endpoint]);
  return <>{children}</>;
}
export default Page;
