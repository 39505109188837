import { createContext, useState, useEffect } from 'react';

const Context = createContext();

export const ContextProvider = ({ children }) => {
  const [backdropClasses, setBackdropClasses] =
    useState('');
  const [usedLanguage, setUsedLanguage] = useState(null);

  const toggleUsedLanguage = () => {
    let language = 'en';

    if (usedLanguage === 'en') {
      language = 'hu';
    }

    localStorage.setItem('usedLanguage', language);
    setUsedLanguage(language);
  };

  useEffect(() => {
    const userLang =
      navigator.language || navigator.userLanguage;

    const storedUsedLanguage =
      localStorage.getItem('usedLanguage');
    const language = storedUsedLanguage
      ? storedUsedLanguage
      : userLang === 'hu-HU'
      ? 'hu'
      : 'en';
    setUsedLanguage(language);
  }, []);

  return (
    <Context.Provider
      value={{
        backdropClasses,
        setBackdropClasses,
        toggleUsedLanguage,
        usedLanguage,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
