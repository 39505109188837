import { useContext } from 'react';

import icProfile from '../assets/images/our-mission-page/values/ic_profile.svg';
import Context from '../context/Context';
import { ourValuesSectionTexts } from '../texts/ourValuesSectionTexts';

const OurValuesSection = () => {
  const { usedLanguage } = useContext(Context);

  const { h2, values } =
    ourValuesSectionTexts[usedLanguage];

  return (
    <section className='section our-values'>
      <div className='container-fluid container--large'>
        <h2 className='section__title'>{h2}</h2>

        <div className='our-values__grid'>
          {values.map((value, index) => (
            <div
              key={value.title + index}
              className='our-values__item'
            >
              <div className='our-values__header'>
                <span className='our-values__image'>
                  <img src={icProfile} alt='' />
                </span>
                <span className='our-values__title'>
                  {' '}
                  {value.title}{' '}
                </span>
              </div>
              <div className='our-values__description'>
                {value.desc}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurValuesSection;
