export const keyResultsTexts = {
  en: {
    h2: 'Key results',
    p: `With nDRU you will experience significant
    operational results`,
    results: [
      {
        percentage: '20%',
        h5: 'Increased sales',
        p: `With your services listed in the nDRU store
        and the automatic upselling functionality of
        the app, you are going to see a significant
        rise in your revenue`,
      },
      {
        percentage: '70%',
        h5: 'Decreased phone calls',
        p: `The app allows you to communicate with your
        clients in real-time through chat, and shows
        both you and your customers relevant
        information. No need to call for every small
        question`,
      },
      {
        percentage: '65%',
        h5: 'Decreased no-shows',
        p: `Your customers see their appointment right
        within the app, which makes sure they show
        up on time`,
      },
    ],
  },
  hu: {
    h2: 'Pörgesd fel vállalkozásod növekedését',
    p: `Az nDRU-val jelentős eredményeket érhetsz el`,
    results: [
      {
        percentage: '20%',
        h5: 'Növekvő bevétel',
        p: `Az nDRU store-ban közzétett szolgáltatásaiddal és az alkalmazás automatikus upselling funkciójával jelentősen emelkedni fog a bevételed.`,
      },
      {
        percentage: '70%',
        h5: 'Kevesebb telefonhívás',
        p: `Az alkalmazás lehetővé teszi, hogy valós időben kommunikálj ügyfeleiddel, mivel releváns információkat mutat mind szervizednek, mind ügyfeleidnek. Ezentúl nem kell minden apró kérdés esetén telefonálni.`,
      },
      {
        percentage: '65%',
        h5: 'Kevesebb késés',
        p: `Ügyfeleid közvetlenül az alkalmazásban látják az időpontjukat, ami biztosítja, hogy időben megjelenjenek.`,
      },
    ],
  },
};
