import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { siteCookieLinks } from '../config/siteCookieLinks';

function SiteCookies() {
  const [siteCookiesClasses, setSiteCookiesClasses] = useState('');

  const getCookie = (name) => {
    let nameEQ = name + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  useEffect(() => {
    if (!getCookie('ndru')) {
      setSiteCookiesClasses('site-cookies--display-flex');
    }
  }, []);

  const setCookie = (name, value, days) => {
    let expires = '';
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  };

  const handleAcceptCookies = () => {
    setCookie('ndru', 'accepted', '2');
    setSiteCookiesClasses('');
  };

  return (
    <div className={`site-cookies ${siteCookiesClasses}`}>
      <p className='site-cookies__text'>
        We use cookies to improve your experience and monitor website traffic.
        <Link to={siteCookieLinks.readMore} target='_blank'>
          Read more
        </Link>
      </p>
      <button className='site-cookies__button' onClick={handleAcceptCookies}>
        Accept
      </button>
    </div>
  );
}
export default SiteCookies;
