import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

import ImagePlaceholder from '../assets/images/image-placeholder.jpg';

import Map from './Map';
import FacilityCategories from './FacilityCategories/FacilityCategories';
import ServiceList from './ServiceList/ServiceList';

function RepairShopSingleComponent({ shop, categories }) {
  const img = shop.cover
    ? `data:image/jpeg;base64,${shop.cover}`
    : ImagePlaceholder;

  const [openingsAndClosings, setOpeningsAndClosings] =
    useState([
      { day: 'Monday', open: '08:00', close: '17:00' },
    ]);
  const [selectedCategory, setSelectedCategory] =
    useState('Összes szolg.');

  const [filteredFacilities, setFilteredFacilities] =
    useState([]);

  const getOpeningsAndClosings = () => {
    const days = [];

    if (shop.mondayOpenFrom && shop.mondayOpenTo) {
      const openTime = new Date(shop.mondayOpenFrom);
      const closeTime = new Date(shop.mondayOpenTo);
      days.push({
        day: 'Monday',
        open: `${openTime.getHours()}:${
          openTime.getMinutes() < 10
            ? `0${openTime.getMinutes()}`
            : openTime.getMinutes()
        }`,
        close: `${closeTime.getHours()}:${
          closeTime.getMinutes() < 10
            ? `0${closeTime.getMinutes()}`
            : closeTime.getMinutes()
        }`,
      });
    }
    if (shop.tuesdayOpenFrom && shop.tuesdayOpenTo) {
      const openTime = new Date(shop.tuesdayOpenFrom);
      const closeTime = new Date(shop.tuesdayOpenTo);
      days.push({
        day: 'Tuesday',
        open: `${openTime.getHours()}:${
          openTime.getMinutes() < 10
            ? `0${openTime.getMinutes()}`
            : openTime.getMinutes()
        }`,
        close: `${closeTime.getHours()}:${
          closeTime.getMinutes() < 10
            ? `0${closeTime.getMinutes()}`
            : closeTime.getMinutes()
        }`,
      });
    }
    if (shop.wednesdayOpenFrom && shop.wednesdayOpenTo) {
      const openTime = new Date(shop.wednesdayOpenFrom);
      const closeTime = new Date(shop.wednesdayOpenTo);
      days.push({
        day: 'Wednesday',
        open: `${openTime.getHours()}:${
          openTime.getMinutes() < 10
            ? `0${openTime.getMinutes()}`
            : openTime.getMinutes()
        }`,
        close: `${closeTime.getHours()}:${
          closeTime.getMinutes() < 10
            ? `0${closeTime.getMinutes()}`
            : closeTime.getMinutes()
        }`,
      });
    }
    if (shop.thursdayOpenFrom && shop.thursdayOpenTo) {
      const openTime = new Date(shop.thursdayOpenFrom);
      const closeTime = new Date(shop.thursdayOpenTo);
      days.push({
        day: 'Thursday',
        open: `${openTime.getHours()}:${
          openTime.getMinutes() < 10
            ? `0${openTime.getMinutes()}`
            : openTime.getMinutes()
        }`,
        close: `${closeTime.getHours()}:${
          closeTime.getMinutes() < 10
            ? `0${closeTime.getMinutes()}`
            : closeTime.getMinutes()
        }`,
      });
    }
    if (shop.fridayOpenFrom && shop.fridayOpenTo) {
      const openTime = new Date(shop.fridayOpenFrom);
      const closeTime = new Date(shop.fridayOpenTo);
      days.push({
        day: 'Friday',
        open: `${openTime.getHours()}:${
          openTime.getMinutes() < 10
            ? `0${openTime.getMinutes()}`
            : openTime.getMinutes()
        }`,
        close: `${closeTime.getHours()}:${
          closeTime.getMinutes() < 10
            ? `0${closeTime.getMinutes()}`
            : closeTime.getMinutes()
        }`,
      });
    }
    if (shop.saturdayOpenFrom && shop.saturdayOpenTo) {
      const openTime = new Date(shop.saturdayOpenFrom);
      const closeTime = new Date(shop.saturdayOpenTo);
      days.push({
        day: 'Saturday',
        open: `${openTime.getHours()}:${
          openTime.getMinutes() < 10
            ? `0${openTime.getMinutes()}`
            : openTime.getMinutes()
        }`,
        close: `${closeTime.getHours()}:${
          closeTime.getMinutes() < 10
            ? `0${closeTime.getMinutes()}`
            : closeTime.getMinutes()
        }`,
      });
    }
    if (shop.sundayOpenFrom && shop.sundayOpenTo) {
      const openTime = new Date(shop.sundayOpenFrom);
      const closeTime = new Date(shop.sundayOpenTo);
      days.push({
        day: 'Sunday',
        open: `${openTime.getHours()}:${
          openTime.getMinutes() < 10
            ? `0${openTime.getMinutes()}`
            : openTime.getMinutes()
        }`,
        close: `${closeTime.getHours()}:${
          closeTime.getMinutes() < 10
            ? `0${closeTime.getMinutes()}`
            : closeTime.getMinutes()
        }`,
      });
    }

    return days;
  };

  useEffect(() => {
    setOpeningsAndClosings(getOpeningsAndClosings());
  }, [shop]);

  useEffect(() => {
    if (selectedCategory === 'Összes szolg.') {
      setFilteredFacilities(shop.facilities);
      console.log(filteredFacilities);
    } else {
      setFilteredFacilities(
        shop.facilities.filter(
          (facility) =>
            facility.type &&
            facility.type.name === selectedCategory
        )
      );
    }
  }, [selectedCategory]);

  return (
    <div className='container py-5'>
      <div className='repair-shop-single__header d-md-flex align-items-center'>
        <div className='row'>
          <div className='col-12 col-md-7 col-lg-5 mb-5 mb-md-0'>
            <div className='shop-details'>
              <h1>{shop.name}</h1>
              <h2>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  className='bi bi-star empty'
                  viewBox='0 0 16 16'
                >
                  <path d='M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z' />
                </svg>
                <span className='rp-rating'>
                  {shop.rating}
                </span>{' '}
                <span className='rp-address'>
                  {shop.zipCode} {shop.city},{' '}
                  {shop.streetName} {shop.streetCategory}{' '}
                  {shop.streetNumber}
                </span>
              </h2>
              <div className='row'>
                <div className='col-10 offset-1 offset-md-0 col-md-8 col-xl-12'>
                  <Link to='/' className='btn btn--green'>
                    Start booking
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-5 col-lg-6 offset-lg-1'>
            <div className='rp-img-container'>
              <img src={img} alt={shop.name} />
            </div>
          </div>
        </div>
      </div>

      <div className='repair-shop-single__introduction mt-3 pt-3  mt-lg-5 pt-lg-5'>
        <div className='row'>
          <h2 className='col-12 rp-intro'>Introduction</h2>

          <div className='rp-left col-lg-5 mb-5 mb-lg-0'>
            <p className='opening-title'>Opening</p>
            {openingsAndClosings.map((oac) => (
              <div key={oac.day} className='opening'>
                <span className='d'>{oac.day}</span>

                <span className='oc'>
                  <span className='o'>{oac.open}</span> -
                  <span className='c'>{oac.close}</span>
                </span>
              </div>
            ))}
          </div>
          <div className='rp-map col-lg-7'>
            <h3 className='rp-location-title'>
              Location:{' '}
              <span className='small'>
                {shop.zipCode} {shop.city},{' '}
                {shop.streetName} {shop.streetCategory}{' '}
                {shop.streetNumber}
              </span>
            </h3>

            {/* shop.latitude, shop.longitude */}

            <Map
              location={{
                lat: shop.latitude,
                lng: shop.longitude,
              }}
              title={shop.name}
            />
          </div>
        </div>
      </div>


      {/*
      {shop.facilities && shop.facilities.length > 0 ? (
        <div className='repair-shop-single__services'>
          <div className='row'>
            <h2 className='col-12 rp-services'>Services</h2>

            <div className='col-12 col-12--no-padding'>
              <FacilityCategories
                setActiveCategory={setSelectedCategory}
                activeCategory={selectedCategory}
                categories={categories}
              />
            </div>

            <div
              className='col-12 mt-3 mt-md-5'
              style={{ overflow: 'auto' }}
            >
              <ServiceList services={filteredFacilities} />
            </div>
          </div>
        </div>
      ) : (
        <div className='mt-5' />
      )}
      */}
    </div>
  );
}
export default RepairShopSingleComponent;

// AIzaSyByMLX0XGfy_7Cla1diLJvt980yJNcKpJY
