import { useContext } from 'react';

import Page from '../components/Page';
import GetInTouchSection from '../components/GetInTouchSection';

import heroSm from '../assets/images/page3/hero/hero-sm.svg';
import heroPng from '../assets/images/page3/hero/hero-lg.png';

import { serviceDrivendruLinks } from '../config/serviceDrivendruLinks';
import Context from '../context/Context';
import { fleetLandingTexts } from '../texts/fleetLandingTexts';
import MoreThan from '../components/MoreThan';

function Fleet() {
  const { usedLanguage } = useContext(Context);
  const {
    h1,
    dtr3Start,
    dtr3Texts,
    dtr3End,
    btns,
    sections,
    moreThanText,
  } = fleetLandingTexts[usedLanguage];

  const { createAccount, login } = serviceDrivendruLinks;

  return (
    <Page
      title={
        usedLanguage === 'en'
          ? 'Fleet managers'
          : 'Flottakezelők'
      }
      endpoint='fleet'
    >
      <section className='section section--hero3'>
        <div className='container'>
          <div className='hero3__row'>
            <div className='hero3__column1'>
              <h1 className='d-none'>{h1}</h1>
              <div className='dtr3'>
                <span className='dtr3__start'>
                  {dtr3Start}
                </span>
                &nbsp;
                <div className='dtr3__texts'>
                  <ul>
                    {dtr3Texts.map((text, index) => (
                      <li key={text + index}>{text}</li>
                    ))}
                  </ul>
                </div>
                <span className='dtr3__end'>{dtr3End}</span>
              </div>

              <div className='row'>
                <div className='col-12 col-sm-10 offset-sm-1 offset-md-0 mb-3 mb-md-1 mb-lg-0 col-lg-8 pr-lg-2'>
                  <a
                    href={createAccount}
                    className='btn btn--green'
                  >
                    {btns[0]}
                  </a>
                </div>
                <div className='col-12 col-sm-10 offset-sm-1 offset-md-0 col-lg-4 pl-lg-0'>
                  <a
                    href={login}
                    className='btn btn--login'
                  >
                    {btns[1]}
                  </a>
                </div>
              </div>
            </div>
            <div className='hero3__column2'>
              <picture className='hero3__image hero3__image--at-lg-png'>
                <source
                  srcSet={heroPng}
                  media='(min-width: 768px)'
                />
                <img srcSet={heroSm} alt={h1} />
              </picture>
            </div>
          </div>
        </div>
      </section>

      {/* More than */}
      <section className='section section--extra-padding-bottom-sm my-lg-3'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-4'>
              <h2 className='section__title section__title--text-align-left section__title--margin-bottom-sm section__title--more-than mb-4 mb-md-0'>
                {moreThanText}
              </h2>
            </div>
            <MoreThan />
          </div>
        </div>
      </section>

      {/* Sections  */}
      {sections.map((section, index) => {
        if (index % 2 === 0) {
          return (
            <section
              key={section.h2 + index}
              className='section'
            >
              <div className='container'>
                <div className='row align-items-center'>
                  <div className='col-12 col-md-6 mb-4 mb-md-0'>
                    <picture className='section__image section__image--left'>
                      <img
                        sizes='570px'
                        srcSet={
                          section.imgs.length > 1
                            ? `${section.imgs[0]} 654w,
                          ${section.imgs[1]} 981w`
                            : section.imgs[0]
                        }
                        alt={section.alt}
                        loading='lazy'
                      />
                    </picture>
                  </div>
                  <div className='col-12 col-md-6 section__text-content--right'>
                    <h2 className='section__title section__title--text-align-left section__title--margin-bottom-sm'>
                      {section.h2}
                    </h2>
                    <p className='section__paragraph'>
                      {section.p}
                    </p>
                  </div>
                </div>
              </div>
            </section>
          );
        } else {
          return (
            <section key={section.h2} className='section'>
              <div className='container'>
                <div className='row align-items-center'>
                  <div className='col-12 col-md-6 order-2 order-md-1 section__text-content--left'>
                    <h2 className='section__title section__title--text-align-left section__title--margin-bottom-sm'>
                      {section.h2}
                    </h2>
                    <p className='section__paragraph'>
                      {section.p}
                    </p>
                  </div>
                  <div className='col-12 col-md-6 order-1 order-md-2 mb-4 mb-md-0'>
                    <picture className='section__image section__image--right'>
                      <img
                        sizes='570px'
                        srcSet={
                          section.imgs.length > 1
                            ? `${section.imgs[0]} 654w,
                           ${section.imgs[1]} 981w`
                            : section.imgs[0]
                        }
                        alt={section.alt}
                        loading='lazy'
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </section>
          );
        }
      })}

      {/* Get in touch */}
      <GetInTouchSection />
    </Page>
  );
}
export default Fleet;
