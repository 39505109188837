import { useEffect, useState, useContext } from 'react';
import { useImmerReducer } from 'use-immer';
import { CSSTransition } from 'react-transition-group';
import emailjs from '@emailjs/browser';
import validator from 'validator';
import Spinner from './Spinner';

import { footerLinks } from '../config/footerLinks';
import Dropdown from './Dropdown';
import Context from '../context/Context';

function GetInTouchSection() {
  const { usedLanguage } = useContext(Context);

  const { legal } = footerLinks;

  const [isLoading, setIsLoading] = useState(false);

  const [msgDelivered, setMsgDelivered] = useState({
    message: '',
    show: false,
    error: false,
  });

  const initialState = {
    topicTypes: {
      value: [],
      hasErrors: false,
      message: '',
      checkCount: 0,
    },
    firstName: {
      value: '',
      hasErrors: false,
      message: '',
      checkCount: 0,
    },
    lastName: {
      value: '',
      hasErrors: false,
      message: '',
      checkCount: 0,
    },
    company: {
      value: '',
      hasErrors: false,
      message: '',
      checkCount: 0,
    },
    phone: {
      value: '',
      hasErrors: false,
      message: '',
      checkCount: 0,
    },

    email: {
      value: '',
      hasErrors: false,
      message: '',
      checkCount: 0,
    },
    title: {
      value: '',
      hasErrors: false,
      message: '',
      checkCount: 0,
    },
    message: {
      value: '',
      hasErrors: false,
      message: '',
      checkCount: 0,
    },
    terms: {
      value: '',
      hasErrors: false,
      message: '',
      checkCount: 0,
    },
    submitCount: 0,
  };

  function formReducer(draft, action) {
    switch (action.type) {
      case 'resetForm':
        draft.topicTypes = {
          value: [],
          hasErrors: false,
          message: '',
          checkCount: 0,
        };
        draft.firstName = {
          value: '',
          hasErrors: false,
          message: '',
          checkCount: 0,
        };
        draft.lastName = {
          value: '',
          hasErrors: false,
          message: '',
          checkCount: 0,
        };
        draft.company = {
          value: '',
          hasErrors: false,
          message: '',
          checkCount: 0,
        };
        draft.phone = {
          value: '',
          hasErrors: false,
          message: '',
          checkCount: 0,
        };

        draft.email = {
          value: '',
          hasErrors: false,
          message: '',
          checkCount: 0,
        };
        draft.title = {
          value: '',
          hasErrors: false,
          message: '',
          checkCount: 0,
        };
        draft.message = {
          value: '',
          hasErrors: false,
          message: '',
          checkCount: 0,
        };
        draft.terms = {
          value: '',
          hasErrors: false,
          message: '',
          checkCount: 0,
        };
        draft.submitCount = 0;
        return;
      case 'firstNameImmediately':
        draft.firstName.hasErrors = false;
        draft.firstName.value = action.value;
        const firstNameTrimmedVal = action.value.trim();
        if (firstNameTrimmedVal.length > 50) {
          draft.firstName.hasErrors = true;
          draft.firstName.message =
            usedLanguage === 'en'
              ? 'First name cannot exceed 50 characters.'
              : 'A keresztnév nem haladhatja meg az 50 karaktert.';
        }
        if (
          firstNameTrimmedVal.value &&
          !/^([A-Za-zÁÉÍÓÖŐÚÜŰáéíóöőúüű\s]+)$/.test(
            firstNameTrimmedVal.value
          )
        ) {
          draft.firstName.hasErrors = true;
          draft.firstName.message =
            usedLanguage === 'en'
              ? 'First name can only contain letters.'
              : 'A keresztnév csak betűket tartalmazhat.';
        }
        return;
      case 'firstNameAfterDelay':
        if (draft.firstName.value.trim().length < 3) {
          draft.firstName.hasErrors = true;
          draft.firstName.message =
            usedLanguage === 'en'
              ? 'First name must be at least 3 characters.'
              : 'A keresztnévnek legalább 3 karakterből kell állnia.';
        }
        if (!draft.firstName.hasErrors && !action.noRequest) {
          draft.firstName.checkCount++;
        }
        return;
      case 'lastNameImmediately':
        draft.lastName.hasErrors = false;
        draft.lastName.value = action.value;
        const lastNameTrimmedVal = action.value.trim();
        if (lastNameTrimmedVal.length > 50) {
          draft.lastName.hasErrors = true;
          draft.lastName.message =
            usedLanguage === 'en'
              ? 'Last name cannot exceed 50 characters.'
              : 'A vezetéknév nem haladhatja meg az 50 karaktert.';
        }
        if (
          lastNameTrimmedVal.value &&
          !/^([A-Za-zÁÉÍÓÖŐÚÜŰáéíóöőúüű\s]+)$/.test(
            lastNameTrimmedVal.value
          )
        ) {
          draft.lastName.hasErrors = true;
          draft.lastName.message =
            usedLanguage === 'en'
              ? 'Last name can only contain letters.'
              : 'A vezetéknév csak betűket tartalmazhat.';
        }
        return;
      case 'lastNameAfterDelay':
        if (draft.lastName.value.trim().length < 3) {
          draft.lastName.hasErrors = true;
          draft.lastName.message =
            usedLanguage === 'en'
              ? 'Last name must be at least 3 characters.'
              : 'A vezetéknév legalább 3 karakterből kell állnia.';
        }
        if (!draft.lastName.hasErrors && !action.noRequest) {
          draft.lastName.checkCount++;
        }
        return;
      case 'topicTypesImmediately':
        draft.topicTypes.hasErrors = false;
        draft.topicTypes.value = action.value;
        return;
      case 'topicTypesAfterDelay':
        if (
          draft.topicTypes.value &&
          draft.topicTypes.value.length === 0
        ) {
          draft.topicTypes.hasErrors = true;
          draft.topicTypes.message =
            usedLanguage === 'en'
              ? 'Please select a topic type.'
              : 'Kérjük, válasszon egy típust.';
        }
        if (
          !draft.topicTypes.hasErrors &&
          !action.noRequest
        ) {
          draft.topicTypes.checkCount++;
        }
        return;
      case 'emailImmediately':
        draft.email.hasErrors = false;
        draft.email.value = action.value;
        return;
      case 'emailAfterDelay':
        if (!validator.isEmail(draft.email.value.trim())) {
          draft.email.hasErrors = true;
          draft.email.message =
            usedLanguage === 'en'
              ? 'You must provide a valid email address.'
              : 'Érvényes e-mail címet kell megadnia.';
        }
        if (!draft.email.hasErrors && !action.noRequest) {
          draft.email.checkCount++;
        }
        return;

      case 'companyImmediately':
        draft.company.hasErrors = false;
        draft.company.value = action.value;
        const compNameTrimmedVal = action.value.trim();
        if (compNameTrimmedVal.length > 80) {
          draft.company.hasErrors = true;
          draft.company.message =
            usedLanguage === 'en'
              ? 'Company name cannot exceed 80 characters.'
              : 'A cégnév nem haladhatja meg a 80 karaktert.';
        }
        if (
          compNameTrimmedVal &&
          !/^([0-9A-Za-zÁÉÍÓÖŐÚÜŰáéíóöőúüű.\s]+)$/.test(
            compNameTrimmedVal
          )
        ) {
          draft.company.hasErrors = true;
          draft.company.message =
            usedLanguage === 'en'
              ? 'Company name can only contain letters and numbers.'
              : 'A cégnév csak betűket és számokat tartalmazhat.';
        }
        return;
      case 'companyAfterDelay':
        if (draft.company.value.trim().length < 3) {
          draft.company.hasErrors = true;
          draft.company.message =
            usedLanguage === 'en'
              ? 'Company name must be at least 3 characters.'
              : 'A cégnévnek legalább 3 karakterből kell állnia.';
        }
        if (!draft.company.hasErrors && !action.noRequest) {
          draft.company.checkCount++;
        }
        return;

      case 'phoneImmediately':
        draft.phone.hasErrors = false;
        draft.phone.value = action.value;
        const phoneTrimmedVal = action.value.trim();
        if (
          phoneTrimmedVal.length < 5 ||
          phoneTrimmedVal.length > 13
        ) {
          draft.phone.hasErrors = true;
          draft.phone.message =
            usedLanguage === 'en'
              ? 'Please enter a valid phone number.'
              : 'Kérjük, adjon meg egy érvényes telefonszámot.';
        }
        return;
      case 'phoneAfterDelay':
        if (
          draft.phone.value.trim() &&
          !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
            draft.phone.value.trim()
          ) &&
          !/^((?:\+?3|0)6)(?:-|\()?(\d{1,2})(?:-|\))?(\d{3})-?(\d{3,4})$/.test(
            draft.phone.value.trim()
          )
        ) {
          draft.phone.hasErrors = true;
          draft.phone.message =
            usedLanguage === 'en'
              ? 'Please enter a valid phone number.'
              : 'Kérjük, adjon meg egy érvényes telefonszámot.';
        }
        if (!draft.phone.hasErrors && !action.noRequest) {
          draft.phone.checkCount++;
        }
        return;

      case 'titleImmediately':
        draft.title.hasErrors = false;
        draft.title.value = action.value;
        if (draft.title.value.trim().length > 50) {
          draft.title.hasErrors = true;
          draft.title.message =
            usedLanguage === 'en'
              ? 'Title cannot exceed 50 characters.'
              : 'A tárgy nem haladhatja meg az 50 karaktert.';
        }
        return;
      case 'titleAfterDelay':
        if (draft.title.value.trim().length < 3) {
          draft.title.hasErrors = true;
          draft.title.message =
            usedLanguage === 'en'
              ? 'Title must be at least 3 characters.'
              : 'A tárgynak legalább 3 karakterből kell állnia.';
        }
        if (!draft.title.hasErrors && !action.noRequest) {
          draft.title.checkCount++;
        }
        return;

      case 'messageImmediately':
        draft.message.hasErrors = false;
        draft.message.value = action.value;
        if (draft.message.value.trim().length > 500) {
          draft.message.hasErrors = true;
          draft.message.message =
            usedLanguage === 'en'
              ? 'Message cannot exceed 500 characters.'
              : 'Az üzenet nem haladhatja meg az 500 karaktert.';
        }
        return;
      case 'messageAfterDelay':
        if (draft.message.value.trim().length < 2) {
          draft.message.hasErrors = true;
          draft.message.message =
            usedLanguage === 'en'
              ? 'Message is required'
              : 'Üzenet kötelező';
        }
        if (!draft.message.hasErrors && !action.noRequest) {
          draft.message.checkCount++;
        }
        return;
      case 'termsImmediately':
        draft.terms.hasErrors = false;
        draft.terms.value = action.value;
        return;
      case 'termsAfterDelay':
        if (!draft.terms.value) {
          draft.terms.hasErrors = true;
          draft.terms.message = '';
        }
        if (!draft.terms.hasErrors && !action.noRequest) {
          draft.terms.checkCount++;
        }
        return;
      case 'submitForm':
        if (
          !draft.firstName.hasErrors &&
          !draft.lastName.hasErrors &&
          !draft.company.hasErrors &&
          !draft.phone.hasErrors &&
          !draft.email.hasErrors &&
          !draft.title.hasErrors &&
          !draft.message.hasErrors &&
          !draft.topicTypes.hasErrors &&
          !draft.terms.hasErrors
        ) {
          draft.submitCount++;
        }
        return;
      default:
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(
    formReducer,
    initialState
  );

  useEffect(() => {
    if (state.topicTypes.checkCount) {
      const delay = setTimeout(
        () => dispatch({ type: 'topicTypesAfterDelay' }),
        800
      );
      return () => clearTimeout(delay);
    }
  }, [state.topicTypes.value]);

  useEffect(() => {
    if (state.firstName.value) {
      const delay = setTimeout(
        () => dispatch({ type: 'firstNameAfterDelay' }),
        800
      );
      return () => clearTimeout(delay);
    }
  }, [state.firstName.value]);

  useEffect(() => {
    if (state.lastName.value) {
      const delay = setTimeout(
        () => dispatch({ type: 'lastNameAfterDelay' }),
        800
      );
      return () => clearTimeout(delay);
    }
  }, [state.lastName.value]);

  useEffect(() => {
    if (state.company.value) {
      const delay = setTimeout(
        () => dispatch({ type: 'companyAfterDelay' }),
        800
      );
      return () => clearTimeout(delay);
    }
  }, [state.company.value]);

  useEffect(() => {
    if (state.phone.value) {
      const delay = setTimeout(
        () => dispatch({ type: 'phoneAfterDelay' }),
        800
      );
      return () => clearTimeout(delay);
    }
  }, [state.phone.value]);

  useEffect(() => {
    if (state.email.value) {
      const delay = setTimeout(
        () => dispatch({ type: 'emailAfterDelay' }),
        800
      );
      return () => clearTimeout(delay);
    }
  }, [state.email.value]);

  useEffect(() => {
    if (state.title.value) {
      const delay = setTimeout(
        () => dispatch({ type: 'titleAfterDelay' }),
        800
      );
      return () => clearTimeout(delay);
    }
  }, [state.title.value]);

  useEffect(() => {
    if (state.message.value) {
      const delay = setTimeout(() => {
        dispatch({ type: 'messageAfterDelay' });
      }, 800);
      return () => clearTimeout(delay);
    }
  }, [state.message.value]);

  useEffect(() => {
    if (state.terms.value) {
      const delay = setTimeout(() => {
        dispatch({ type: 'termsAfterDelay' });
        clearTimeout(delay);
      }, 800);
    }
  }, [state.terms.value]);

  useEffect(() => {
    if (state.submitCount) {
      setIsLoading(true);
      let topics = '';
      state.topicTypes.value.forEach((item, index) => {
        if (index === 0) {
          topics += item;
        } else {
          topics += ', ' + item;
        }
      });

      emailjs
        .send(
          'service_mlh1k0i',
          'template_4doku4j',
          {
            company: state.company.value.trim(),
            phone: state.phone.value.trim(),
            email: state.email.value.trim(),
            title: state.title.value.trim(),
            message: state.message.value.trim(),
            firstName: state.firstName.value.trim(),
            lastName: state.lastName.value.trim(),
            topicTypes: topics,
          },
          'KTxZ-5Y0deMlG9_If'
        )
        .then(
          (result) => {
            setMsgDelivered({
              message:
                usedLanguage === 'en' ?
                'Your message has been sent successfully.' : 
                'Üzenetét sikeresen elküldtük.',
              show: true,
              error: false,
            });
            setIsLoading(false);

            const delay = setTimeout(() => {
              setIsLoading(true);

              setMsgDelivered({
                message: '',
                show: false,
                error: false,
              });
              clearTimeout(delay);
              const delay2 = setTimeout(() => {
                setIsLoading(false);
                clearTimeout(delay2);
              }, 500);
            }, 5000);

            dispatch({ type: 'resetForm' });
          },
          (error) => {
            setMsgDelivered({
              message:
                usedLanguage === 'en'
                ? 'Something went wrong. Please try again later.'
                : 'Üzentét nem sikerült elküldenünk. Kérjük, próbálja meg később újra.',
              show: true,
              error: true,
            });
            setIsLoading(false);

            const delay = setTimeout(() => {
              setMsgDelivered({
                message: '',
                show: false,
                error: false,
              });
              clearTimeout(delay);
            }, 5000);
            console.log(error.text);
          }
        );
    }
  }, [state.submitCount]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: 'firstNameImmediately',
      value: state.firstName.value,
    });
    dispatch({
      type: 'lastNameImmediately',
      value: state.lastName.value,
    });
    dispatch({
      type: 'firstNameAfterDelay',
      value: state.firstName.value,
      noRequest: true,
    });
    dispatch({
      type: 'lastNameAfterDelay',
      value: state.lastName.value,
      noRequest: true,
    });
    dispatch({
      type: 'companyImmediately',
      value: state.company.value,
    });
    dispatch({
      type: 'companyAfterDelay',
      value: state.company.value,
      noRequest: true,
    });
    dispatch({
      type: 'phoneImmediately',
      value: state.phone.value,
    });
    dispatch({
      type: 'phoneAfterDelay',
      value: state.phone.value,
      noRequest: true,
    });

    dispatch({
      type: 'emailImmediately',
      value: state.email.value,
    });
    dispatch({
      type: 'emailAfterDelay',
      value: state.email.value,
      noRequest: true,
    });
    dispatch({
      type: 'titleImmediately',
      value: state.title.value,
    });
    dispatch({
      type: 'titleAfterDelay',
      value: state.title.value,
      noRequest: true,
    });
    dispatch({
      type: 'messageImmediately',
      value: state.message.value,
    });
    dispatch({
      type: 'messageAfterDelay',
      value: state.message.value,
      noRequest: true,
    });
    dispatch({
      type: 'termsImmediately',
      value: state.terms.value,
    });
    dispatch({
      type: 'termsAfterDelay',
      value: state.terms.value,
      noRequest: true,
    });
    dispatch({
      type: 'topicTypesImmediately',
      value: state.topicTypes.value,
    });
    dispatch({
      type: 'topicTypesAfterDelay',
      value: state.topicTypes.value,
      noRequest: true,
    });

    dispatch({ type: 'submitForm' });
  };

  if (!isLoading && msgDelivered.show) {
    return (
      <section className='section get-in-touch-modified'>
        <div className='container'>
          <h2>
            {usedLanguage === 'en'
              ? 'Get in touch with us'
              : 'Kérdésed van? Lépj velünk kapcsolatba!'}
          </h2>
          <div
            className={`message-has-been-sent ${
              msgDelivered.error ? 'error' : ''
            }`}
          >
            {msgDelivered.message}
          </div>
        </div>
      </section>
    );
  }

  if (isLoading) {
    return (
      <section className='section get-in-touch-modified'>
        <div className='container'>
          <h2>
            {usedLanguage === 'en'
              ? 'Get in touch with us'
              : 'Kérdésed van? Lépj velünk kapcsolatba!'}
          </h2>
          <Spinner />
        </div>
      </section>
    );
  }

  return (
    <section className='section get-in-touch-modified'>
      <div className='container'>
        <h2>
          {usedLanguage === 'en'
            ? 'Get in touch with us'
            : 'Kérdésed van? Lépj velünk kapcsolatba!'}
        </h2>

        <form onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-12 col-lg-6'>
              <div className='row'>
                <div className='col-12 col-lg-6'>
                  <div className='form-group'>
                    <input
                      type='text'
                      name='firstName'
                      placeholder={
                        usedLanguage === 'en'
                          ? 'Your first name'
                          : 'Keresztnév'
                      }
                      onChange={(e) =>
                        dispatch({
                          type: 'firstNameImmediately',
                          value: e.target.value,
                        })
                      }
                      value={state.firstName.value}
                      className={
                        state.firstName.hasErrors ? 'error' : ''
                      }
                    />
                    <CSSTransition
                      in={state.firstName.hasErrors}
                      timeout={330}
                      classNames='liveValidateMessage'
                      unmountOnExit
                    >
                      <div className='liveValidateMessage'>
                        {state.firstName.message}
                      </div>
                    </CSSTransition>
                  </div>
                  <div className='form-group'>
                    <input
                      type='text'
                      name='company'
                      placeholder={
                        usedLanguage === 'en'
                          ? 'Company'
                          : 'Vállalat'
                      }
                      onChange={(e) =>
                        dispatch({
                          type: 'companyImmediately',
                          value: e.target.value,
                        })
                      }
                      value={state.company.value}
                      className={
                        state.company.hasErrors
                          ? 'error'
                          : ''
                      }
                    />
                    <CSSTransition
                      in={state.company.hasErrors}
                      timeout={330}
                      classNames='liveValidateMessage'
                      unmountOnExit
                    >
                      <div className='alert alert-danger small liveValidateMessage'>
                        {state.company.message}
                      </div>
                    </CSSTransition>
                  </div>
                  <div className='form-group'>
                    <input
                      type='tel'
                      name='phone'
                      placeholder={
                        usedLanguage === 'en'
                          ? 'Phone'
                          : 'Telefon'
                      }
                      onChange={(e) =>
                        dispatch({
                          type: 'phoneImmediately',
                          value: e.target.value,
                        })
                      }
                      value={state.phone.value}
                      className={
                        state.phone.hasErrors ? 'error' : ''
                      }
                    />
                    <CSSTransition
                      in={state.phone.hasErrors}
                      timeout={330}
                      classNames='liveValidateMessage'
                      unmountOnExit
                    >
                      <div className='alert alert-danger small liveValidateMessage'>
                        {state.phone.message}
                      </div>
                    </CSSTransition>
                  </div>
                </div>
                <div className='col-12 col-lg-6'>
                  <div className='form-group'>
                    <input
                      type='text'
                      name='lastName'
                      placeholder={
                        usedLanguage === 'en'
                          ? 'Your last name'
                          : 'Vezetéknév'
                      }
                      onChange={(e) =>
                        dispatch({
                          type: 'lastNameImmediately',
                          value: e.target.value,
                        })
                      }
                      value={state.lastName.value}
                      className={
                        state.lastName.hasErrors ? 'error' : ''
                      }
                    />
                    <CSSTransition
                      in={state.lastName.hasErrors}
                      timeout={330}
                      classNames='liveValidateMessage'
                      unmountOnExit
                    >
                      <div className='liveValidateMessage'>
                        {state.lastName.message}
                      </div>
                    </CSSTransition>
                  </div>
                  <div className='form-group'>
                    <input
                      type='email'
                      name='email'
                      placeholder={
                        usedLanguage === 'en'
                          ? 'E-mail'
                          : 'E-mail'
                      }
                      onChange={(e) =>
                        dispatch({
                          type: 'emailImmediately',
                          value: e.target.value,
                        })
                      }
                      value={state.email.value}
                      className={
                        state.email.hasErrors ? 'error' : ''
                      }
                    />
                    <CSSTransition
                      in={state.email.hasErrors}
                      timeout={330}
                      classNames='liveValidateMessage'
                      unmountOnExit
                    >
                      <div className='alert alert-danger small liveValidateMessage'>
                        {state.email.message}
                      </div>
                    </CSSTransition>
                  </div>

                  <div className='form-group'>
                    <Dropdown
                      onChange={(val) =>
                        dispatch({
                          type: 'topicTypesImmediately',
                          value: val,
                        })
                      }
                      actState={state.topicTypes}
                      msgDelivered={msgDelivered}
                      white={true}
                      usedLanguage={usedLanguage}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12  col-lg-5 offset-lg-1'>
              <div className='form-group'>
                <input
                  type='text'
                  name='title'
                  placeholder={
                    usedLanguage === 'en'
                      ? 'Message title'
                      : 'Tárgy'
                  }
                  onChange={(e) =>
                    dispatch({
                      type: 'titleImmediately',
                      value: e.target.value,
                    })
                  }
                  value={state.title.value}
                  className={
                    state.title.hasErrors ? 'error' : ''
                  }
                />
                <CSSTransition
                  in={state.title.hasErrors}
                  timeout={330}
                  classNames='liveValidateMessage'
                  unmountOnExit
                >
                  <div className='alert alert-danger small liveValidateMessage'>
                    {state.title.message}
                  </div>
                </CSSTransition>
              </div>
              <div className='form-group'>
                <textarea
                  name='message'
                  placeholder={
                    usedLanguage === 'en'
                      ? 'Your message'
                      : 'Üzeneted'
                  }
                  onChange={(e) =>
                    dispatch({
                      type: 'messageImmediately',
                      value: e.target.value,
                    })
                  }
                  value={state.message.value}
                  className={
                    state.message.hasErrors ? 'error' : ''
                  }
                ></textarea>
                <CSSTransition
                  in={state.message.hasErrors}
                  timeout={330}
                  classNames='liveValidateMessage'
                  unmountOnExit
                >
                  <div className='alert alert-danger small liveValidateMessage'>
                    {state.message.message}
                  </div>
                </CSSTransition>
              </div>
            </div>
            <div className='col-12'>
              <div className='row'>
                <div
                  className={`col-12 ${
                    usedLanguage === 'en'
                      ? 'col-md-6 offset-md-6'
                      : 'col-md-8 offset-md-4'
                  } mb-3 mt-xl-3`}
                >
                  <div
                    className={`form-group form-group--terms ${
                      state.terms.hasErrors ? 'error' : ''
                    }`}
                  >
                    <div className='round'>
                      <input
                        type='checkbox'
                        id='checkbox'
                        onChange={(e) =>
                          dispatch({
                            type: 'termsImmediately',
                            value: e.target.checked,
                          })
                        }
                        checked={state.terms.value}
                      />
                      <label htmlFor='checkbox'></label>
                    </div>

                    {usedLanguage === 'en' ? (
                      <p>
                        I agree to the{' '}
                        <a href={legal[usedLanguage][1].to}>
                          terms
                        </a>{' '}
                        and&nbsp;
                        <a href={legal[usedLanguage][0].to}>
                          privacy polcily
                        </a>
                      </p>
                    ) : (
                      <p>
                        Elfogadom az{' '}
                        <a href={legal[usedLanguage][1].to}>
                          ÁSZF
                        </a>
                        -et és az{' '}
                        <a href={legal[usedLanguage][0].to}>
                          Adatvédelmi Tájékoztatóban
                        </a>{' '}
                        foglaltakat.
                      </p>
                    )}
                  </div>
                </div>
                <div className='col-12 col-md-5 offset-md-7 col-lg-3 offset-lg-9'>
                  <button className='btn btn--green'>
                    {usedLanguage === 'en' ? 'Send message' : 'Beküldés'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
export default GetInTouchSection;
