import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import throttle from 'lodash/throttle';
import { useContext } from 'react';
import Context from '../context/Context';
import { mobileAppLinks } from '../config/mobileAppLinks';

export const useHeader = () => {
  const location = useLocation();
  const { usedLanguage, toggleUsedLanguage } = useContext(Context);

  const [menuLinks, setMenuLinks] = useState([
    {
      title: usedLanguage === 'en' ? 'Download the app' : 'nDRU Letöltés',
      to: '#',
      isLinkTag: false,
    },
  ]);
  const [mainMenuLink, setMainMenuLink] = useState({
    title: usedLanguage === 'en' ? 'For Business' : 'Vállalatoknak',
    to: '#',
  });

  const { appStore, googlePlay } = mobileAppLinks;

  useEffect(() => {
    if (location.pathname === '/fleet' || location.pathname === '/flotta') {
      setMenuLinks([
        {
          title: usedLanguage === 'en' ? 'Create account' : 'Regisztráció',
          to: 'https://service.drivendru.com',
          isLinkTag: false,
        },
        {
          title: usedLanguage === 'en' ? 'Log in' : 'Bejelentkezés',
          to: 'https://service.drivendru.com/login',
          isLinkTag: false,
        },
        {
          title: usedLanguage === 'en' ? 'Download the app' : 'nDRU Letöltés',
          to: '#',
          isLinkTag: false,
        },
      ]);
      setMainMenuLink({
        title: usedLanguage === 'en' ? 'For Customers' : 'Ügyfeleknek',
        to: '/',
        isLinkTag: false,
      });
    } else if (
      location.pathname === '/service-providers' ||
      location.pathname === '/szolgaltatok'
    ) {
      setMainMenuLink({
        title: usedLanguage === 'en' ? 'For Customers' : 'Ügyfeleknek',
        to: '/',
        isLinkTag: true,
      });
    } else if (
      location.pathname === '/repair-shops' ||
      location.pathname === '/szervizek-partnerek'
    ) {
      setMenuLinks([
        {
          title: usedLanguage === 'en' ? 'Create account' : 'Regisztráció',
          to: 'https://service.drivendru.com',
          isLinkTag: false,
        },
        {
          title: usedLanguage === 'en' ? 'Log in' : 'Bejelentkezés',
          to: 'https://service.drivendru.com/login',
          isLinkTag: false,
        },
      ]);
      setMainMenuLink({
        title: usedLanguage === 'en' ? 'For Customers' : 'Ügyfeleknek',
        to: '/',
        isLinkTag: true,
      });
    } else {
      setMenuLinks([
        {
          title: usedLanguage === 'en' ? 'Download the app' : 'nDRU Letöltés',
          to: '#',
          isLinkTag: false,
        },
      ]);
      setMainMenuLink({
        title: usedLanguage === 'en' ? 'For Business' : 'Vállalatoknak',
        to: '#',
      });
    }
  }, [location.pathname, usedLanguage]);

  const { setBackdropClasses } = useContext(Context);

  const menuContent = useRef(null);
  const siteHeader = useRef(null);
  const menuToggler = useRef(null);

  const toggleShadow = throttle(() => {
    if (
      window.scrollY > 200 &&
      siteHeader.current.classList.contains('site-header--is-expanded')
    ) {
      siteHeader.current.classList.add('site-header--shadow');
    } else {
      siteHeader.current.classList.remove('site-header--shadow');
    }
  }, 200);

  // Add shadow to menu hook
  useEffect(() => {
    if (isMobile) {
      window.addEventListener('scroll', toggleShadow);
      document.querySelector('main').addEventListener('click', () => {
        if (
          menuContent.current.classList.contains(
            'site-header__menu-content--is-visible'
          )
        ) {
          toggleMenu();
        }
      });
    }
  }, []);

  const showBackdrop = () => {
    setBackdropClasses('backdrop--display backdrop--z-index-1000');
    setTimeout(() => {
      setBackdropClasses('backdrop--display backdrop--z-index-1000 backdrop--show');
    }, 100);
  };

  const removeBackdrop = () => {
    setBackdropClasses('backdrop--z-index-1000 backdrop--display');
    setTimeout(() => {
      setBackdropClasses('');
    }, 100);
  };

  const showMenuContent = () => {
    menuContent.current.style.display = 'flex';
    setTimeout(() => {
      menuContent.current.classList.add('site-header__menu-content--is-visible');
    }, 100);
  };

  const removeMenuContent = () => {
    menuContent.current.classList.remove('site-header__menu-content--is-visible');
    setTimeout(() => {
      menuContent.current.style.display = 'none';
    }, 100);
  };

  const toggleMenuContent = () => {
    if (
      menuContent.current.classList.contains('site-header__menu-content--is-visible')
    ) {
      removeBackdrop();
      removeMenuContent();
    } else {
      showBackdrop();
      showMenuContent();
    }
  };

  const toggleMenu = () => {
    toggleMenuContent();
    siteHeader.current.classList.toggle('site-header--is-expanded');
    menuToggler.current.classList.toggle('site-header__toggler--close-x');
  };

  return {
    siteHeader,
    menuContent,
    menuToggler,
    menuLinks,
    mainMenuLink,
    usedLanguage,
    appStore,
    googlePlay,
    toggleUsedLanguage,
    toggleMenu,
  };
};
