import { useContext } from 'react';

import Context from '../context/Context';
import { teamSectionTexts } from '../texts/teamSectionTexts';

const TeamSection = () => {
  const { usedLanguage } = useContext(Context);

  const { h2, teamMembers } =
    teamSectionTexts[usedLanguage];

  return (
    <section className='section team'>
      <div className='container'>
        <h2 className='section__title'>{h2}</h2>

        <div className='team__grid'>
          {teamMembers.map((member, index) => (
            <div
              key={member.name + index}
              className='team__item'
            >
              <div className='team__header'>
                {member.position}
              </div>
              <div className='team__image'>
                <img src={member.img} alt={member.name} />
              </div>
              <div className='team__name'>
                {member.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
