export const timelineSectionTexts = {
  en: {
    milestones: [
      {
        date: '2021 January',
        event: 'Development launched',
      },
      {
        date: '2021 September',
        event: 'Pre-beta launch',
      },
      {
        date: '2021 December',
        event: 'Finetuning pre-beta',
      },
      {
        date: '2022 January',
        event: 'Closed beta launch',
      },
      {
        date: '2022 July',
        event: 'Launched NDRU in app stores',
      },
    ],
  },
  hu: {
    milestones: [
      {
        date: '2021 Január',
        event: 'Elkezdődik a fejlesztés',
      },
      {
        date: '2021 Szeptember',
        event: 'Elindul a pre-bétatesztelés',
      },
      {
        date: '2021 December',
        event: 'Finomítások a bétaverzió előtt',
      },
      {
        date: '2022 Január',
        event: 'Elindul a zárt bétatesztelés',
      },
      {
        date: '2022 Július',
        event: 'Az nDRU megjelenik az alkalmazásboltokban',
      },
    ],
  },
};
