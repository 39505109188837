import { useContext } from 'react';

import Page from '../components/Page';
import GetInTouchSection from '../components/GetInTouchSection';

import heroLg1024 from '../assets/images/pricing-page/hero/hero-lg-1024.png';
import heroLg2048 from '../assets/images/pricing-page/hero/hero-lg-2048.png';
import heroSm768 from '../assets/images/pricing-page/hero/hero-sm-768.png';
import heroSm1536 from '../assets/images/pricing-page/hero/hero-sm-1536.png';

import MobileAppsSection from '../components/MobileAppsSection';
import Context from '../context/Context';
import { pricinfTexts } from '../texts/pricingTexts';
import SubscriptionSection from '../components/SubscriptionSection';
import KeyResults from '../components/KeyResults';

function Pricing() {
  const { usedLanguage } = useContext(Context);

  const { h1, pageTitle, p, btns } =
    pricinfTexts[usedLanguage];

  return (
    <Page
      title={usedLanguage === 'en' ? 'Pricing' : 'Áraink'}
      endpoint='pricing'
    >
      <section className='section section--hero-pricing-page'>
        <div className='container'>
          <div className='hero-pricing-page'>
            <div className='hero-pricing-page__texts'>
              <div className='hero-pricing-page__title'>
                {pageTitle}
              </div>
              <h1 className='hero-pricing-page__h1'>
                {h1}
              </h1>
              <p className='hero-pricing-page__subtitle bg-main-green'>
                {p}
              </p>
              <div className='hero-pricing-page__buttons'>
                <div className='row'>
                  <div className='col-12 col-sm-10 offset-sm-1 offset-md-0 mb-3 mb-md-1 mb-lg-0 col-lg-8 pr-lg-2'>
                    <a
                      href='https://service.drivendru.com'
                      className='btn btn--green'
                    >
                      {btns[0]}
                    </a>
                  </div>
                  <div className='col-12 col-sm-10 offset-sm-1 offset-md-0 col-lg-4 pl-lg-0'>
                    <a
                      href='https://service.drivendru.com/login'
                      className='btn btn--login'
                    >
                      {btns[1]}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='hero-pricing-page__image'>
              <picture>
                <source
                  sizes='980px'
                  srcSet={`
                    ${heroLg1024} 1024w,
                    ${heroLg2048} 2048w
                    `}
                  media='(min-width: 768px)'
                />
                <img
                  srcSet={`
                    ${heroSm768}   500w,
                    ${heroSm1536}  1000w
                    `}
                  alt={h1}
                />
              </picture>
            </div>
          </div>
        </div>
      </section>

      {/* Subscription Section */}
      <SubscriptionSection />

      <KeyResults />

      <MobileAppsSection />

      <GetInTouchSection />
    </Page>
  );
}
export default Pricing;
