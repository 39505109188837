import { useEffect, useRef, useContext } from 'react';
import Context from '../context/Context';
import { timelineSectionTexts } from '../texts/timelineSectionTexts';

function TimelineSection() {
  const { usedLanguage } = useContext(Context);
  const { milestones } = timelineSectionTexts[usedLanguage];

  const timeline = useRef();
  let items = [];

  useEffect(() => {
    const timeout = setTimeout(() => {
      items = timeline.current.querySelectorAll('li');
      run();
      setMarginLeft();
      window.addEventListener('resize', run);
      window.addEventListener('scroll', run);
      window.addEventListener('resize', setMarginLeft);

      clearTimeout(timeout);
    }, 1000);
  }, []);

  const isInViewport = (el) => {
    const rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight ||
          document.documentElement.clientHeight) &&
      rect.right <=
        (window.innerWidth ||
          document.documentElement.clientWidth)
    );
  };

  const run = () => {
    items.forEach((item) => {
      if (isInViewport(item)) {
        item.classList.add('show');
      }
    });
  };

  const setMarginLeft = () => {
    const windowInnerWidth = window.innerWidth;
    const navContainer = document.querySelector(
      '.site-header .container-fluid'
    );
    const timeline = document.querySelector('.timeline');

    if (windowInnerWidth >= 768) {
      const marginLeft =
        navContainer.getBoundingClientRect().left;
      const style =
        navContainer.currentStyle ||
        window.getComputedStyle(navContainer);
      const paddingLeft = parseInt(style.paddingLeft);
      timeline.style.marginLeft = `${
        marginLeft + paddingLeft
      }px`;
    } else {
      timeline.style.marginLeft = 'auto';
      timeline.style.marginRight = 'auto';
    }
  };

  return (
    <section
      ref={timeline}
      className='section timeline timeline--at-large'
    >
      <ul>
        {milestones.map((milestone, index) => (
          <li key={milestone.event + index}>
            <div>
              <span>{milestone.date}</span>
              <p>{milestone.event}</p>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
}
export default TimelineSection;
