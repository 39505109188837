export const moreThanTexts = {
  en: {
    boxes: [
      {
        key: `owners`,
        h5: `850`,
        p: [`registered car`, `owners`],
      },
      {
        key: `worksheets`,
        h5: `6000`,
        p: [`worksheets`, ``],
      },
      {
        key: `services`,
        h5: `60`,
        p: [`car service`, `partners`],
      },
    ],
  },
  hu: {
    boxes: [
      {
        key: `owners`,
        h5: `850`,
        p: [`regisztrált`, `autótulajdonos`],
      },
      {
        key: `worksheets`,
        h5: `6000`,
        p: [`munkalap`, ``],
      },
      {
        key: `services`,
        h5: `60`,
        p: [`szervizpartner`, ``],
      },
    ],
  },
};
