import { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from "swiper";
import 'swiper/css';
import Context from '../context/Context';

import { sliderSectionTexts } from '../texts/sliderSectionTexts';

function SliderSection() {
  const { usedLanguage } = useContext(Context);

  const { h2 } = sliderSectionTexts[usedLanguage];

  return (
    <section className='section section--slider'>
      <div className='container container--slider'>
        <h2 className='section__title section__title--text-align-left section__title--slider'>
          {h2}
        </h2>

        <Swiper
          loop={true}
          slidersperview={1}
          spaceBetween={0}
          breakpoints={{
            576: {
              slidesPerView: 2,
              spaceBetween: 24,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
          }}
          navigation
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Navigation]}
        >
          <SwiperSlide>
            <div className='card'>
              <div className='card__stars'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-star-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-star-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-star-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-star-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-star-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                </svg>
              </div>
              <p className='card__testimonial'>
                {usedLanguage === 'en' ? 
                `I run a medium-sized car repair shop where
                we work with several staff and services. The
                nDRU has made the daily workflow very simple
                and speeds up our processes.` : 
                `Egy közepes méretű autószerelő műhelyt vezetek, 
                ahol több munkatárssal és sok céges ügyféllel dolgozunk. 
                Az nDRU nagyon egyszerűvé tette a napi munkát, 
                mivel a foglalások már nem telefonon, hanem az appon keresztül történnek.`}
              </p>

              <div className='card__person'>
                {/* <img src={johnImg} alt='John' /> */}
                <span>
                {usedLanguage === 'en' ? 
                `Peter` : 
                `Péter`}
                </span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='card'>
              <div className='card__stars'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-star-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-star-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-star-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-star-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-star-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                </svg>
              </div>
              <p className='card__testimonial'>
                {usedLanguage === 'en' ? 
                `What I like most about nDRU is the mechanic
                app linked to the service system. Through
                this, we get the worksheets to administer
                tasks and faults found on the cars easily.` : 
                `Az nDRU-ban a szervizünkhöz kapcsolódó szerelői appot szeretem a legjobban. 
                Ezen keresztül kapjuk meg az egyes autókhoz kapcsolódó feladatokat 
                és egyszerűen tudjuk jelezni az átvizsgálás során talált hibákat is.`}
              </p>

              <div className='card__person'>
                {/* <img src={johnImg} alt='John' /> */}
                <span>{usedLanguage === 'en' ? 
                `Thomas` : 
                `Tamás`}</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='card'>
              <div className='card__stars'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-star-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-star-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-star-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-star-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-star-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                </svg>
              </div>
              <p className='card__testimonial'>
                {usedLanguage === 'en' ? 
                `As a repair service manager, the calendar
                and Kan-Ban view helps me the most. It
                allows me to see the tasks of all our
                departments and to keep track of exactly
                which employee is working on which job.` : 
                `Szervízvezetőként a naptár és a Kan-Ban nézet segíti a legjobban a napi munkámat. 
                Ezekkel egyszerűen átlátom, hogy melyik autó milyen státuszban van és azt is, 
                hogy a szerelőink épp mivel foglalkoznak.`}
              </p>

              <div className='card__person'>
                {/* <img src={johnImg} alt='John' /> */}
                <span>{usedLanguage === 'en' ? 
                `Jonah` : 
                `János`}</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='card'>
              <div className='card__stars'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-star-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-star-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-star-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-star-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='currentColor'
                  className='bi bi-star-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
                </svg>
              </div>
              <p className='card__testimonial'>
                {usedLanguage === 'en' ? 
                `By having our customers pay us via the nDRU
                app in the service department without having
                to deal with the terminal or the invoicing,
                the payment process is fully automated.` : 
                `Ügyfeleink az nDRU alkalmazáson keresztül teljesen automatizáltan fizetnek, 
                így nekünk a szervizben nem kell sem a kártyaterminállal, 
                sem pedig a számlázó programmal foglalkoznunk.`}
              </p>

              <div className='card__person'>
                {/* <img src={johnImg} alt='John' /> */}
                <span>{usedLanguage === 'en' ? 
                `Tim` : 
                `Timót`}</span>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
}
export default SliderSection;
