import { useContext, useEffect } from 'react';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import TagManager from 'react-gtm-module'

import Header from './components/Header';
import Backdrop from './components/Backdrop';
import LandingDrivers from './pages/LandingDrivers';
import Footer from './components/Footer';
import BackToTop from './components/BackToTop';
import SiteCookies from './components/SiteCookies';
import RepairShops from './pages/RepairShops';
import Fleet from './pages/Fleet';
import Pricing from './pages/Pricing';
import Introduction from './pages/Introduction';
import ServiceProviders from './pages/ServiceProviders';
import Careers from './pages/Careers';
import ContactUs from './pages/ContactUs';
import NotFound from './pages/NotFound';
import NdruPrivacyPolicy from './pages/NdruPrivacyPolicy';
import NdruTermsOfService from './pages/NdruTermsOfService';

import BlogPage from './pages/BlogPage';

import PositionCtoPage from './pages/PositionCtoPage';
import BlogPostPageNdruHasLaunched from './pages/BlogPostPageNdruHasLaunched';
import RepairShopsList from './pages/RepairShopsList';
import RepairShopSinglePage from './pages/RepairShopSinglePage';
import Loader from './components/Loader';
import Context from './context/Context';
import NdruPrivacyPolicyHu from './pages/NdruPrivacyPolicyHu';
import NdruTermsOfServicesHu from './pages/NdruTermsOfServicesHu';

import DeleteAccountPage from './pages/DeleteAccountPage';
import QuestionnairePage from './pages/QuestionnairePage';

function App() {
  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-N8FJNDW'
    })
  }, [])

  const { usedLanguage } = useContext(Context);
  return (
    <>
      {usedLanguage && (
        <Router>
          <Backdrop />
          <Header />

          <main id='home'>
            <Routes>
              <Route
                exact
                path='/'
                element={<LandingDrivers />}
              />
              <Route
                exact
                path='/repair-shops'
                element={<RepairShops />}
              />
              <Route
                exact
                path='/szervizek-partnerek'
                element={<RepairShops />}
              />

              <Route
                exact
                path='/fleet'
                element={<Fleet />}
              />
              <Route
                exact
                path='/flotta'
                element={<Fleet />}
              />

              <Route
                exact
                path='/pricing'
                element={<Pricing />}
              />
              <Route
                exact
                path='/arazas'
                element={<Pricing />}
              />

              <Route
                exact
                path='/introduction'
                element={<Introduction />}
              />
              <Route
                exact
                path='/bemutatkozas'
                element={<Introduction />}
              />
              <Route
                exact
                path='/service-providers'
                element={<ServiceProviders />}
              />
              <Route
                exact
                path='/szolgaltatok'
                element={<ServiceProviders />}
              />

              <Route
                exact
                path='/careers'
                element={<Careers />}
              />

              <Route
                exact
                path='/karrier'
                element={<Careers />}
              />

              <Route
                exact
                path='/contact-us'
                element={<ContactUs />}
              />
              <Route
                exact
                path='/kapcsolat'
                element={<ContactUs />}
              />

              <Route
                exact
                path='/repair-shops/list'
                element={<RepairShopsList />}
              />

              <Route
                exact
                path='/szervizek-partnerek/lista'
                element={<RepairShopsList />}
              />

              <Route
                exact
                path='/repair-shops/list/:id'
                element={<RepairShopSinglePage />}
              />
              <Route
                exact
                path='/szervizek-partnerek/lista/:id'
                element={<RepairShopSinglePage />}
              />

              <Route
                exact
                path='/blog'
                element={<BlogPage />}
              />
              <Route
                exact
                path='/blog/nDRU-has-launched'
                element={<BlogPostPageNdruHasLaunched />}
              />

              <Route
                exact
                path='/careers/cto'
                element={<PositionCtoPage />}
              />

              <Route
                exact
                path='/NDRU-privacy-policy'
                element={<NdruPrivacyPolicy />}
              />
              <Route
                exact
                path='/adatvedelmi-tajekoztatas'
                element={<NdruPrivacyPolicyHu />}
              />
              <Route
                exact
                path='/NDRU-terms-of-service'
                element={<NdruTermsOfService />}
              />
              <Route
                exact
                path='/felhasznalasi-feltetelek'
                element={<NdruTermsOfServicesHu />}
              />
              <Route
                exact
                path='/delete-account'
                element={<DeleteAccountPage />}
              />
              <Route
                exact
                path='/autozas2024'
                element={<Navigate to="/autozas-felmeres-2024" />}
              />
              <Route
                exact
                path='/autozas-felmeres-2024'
                element={<QuestionnairePage />}
              />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </main>

          <Footer />
          <BackToTop />
          <SiteCookies />
        </Router>
      )}
      {!usedLanguage && <Loader />}
    </>
  );
}

export default App;
