function BlogPostParagraph({ title, texts }) {
  return (
    <div className='blog-post__paragraph'>
      <div className='container' style={{ maxWidth: '867px' }}>
        <h3>{title}</h3>
        {texts.map((text) => (
          <p>{text}</p>
        ))}
      </div>
    </div>
  );
}
export default BlogPostParagraph;
