import { useContext } from 'react';

import { Link } from 'react-router-dom';

import group917 from '../assets/images/icons/Group 917.svg';
import group934 from '../assets/images/icons/Group 934.svg';
import group920 from '../assets/images/icons/Group 920.svg';
import { categoryLinks } from '../config/categoryLinks';
import { categoriesTexts } from '../texts/categoriesTexts';
import Context from '../context/Context';

const Categories = () => {
  const { usedLanguage } = useContext(Context);

  const { categories } = categoriesTexts[usedLanguage];

  const { repairShops, carCleaning, tireChange } =
    categoryLinks[usedLanguage];

  const linksArray = [repairShops, carCleaning, tireChange];
  const imgsArray = [group917, group934, group920];

  return (
    <div className='categories'>
      {categories.map((category, index) => (
        <Link
          key={index}
          to={linksArray[index]}
          className='category'
        >
          <img
            src={imgsArray[index]}
            alt={`${category[0]} ${category[1]}`}
          />
          <span className='label'>
            {category[0]}&nbsp;
            <br />
            {category[1]}
          </span>
        </Link>
      ))}
    </div>
  );
};

export default Categories;
