import servicesPng from '../assets/images/service-providers/features/list-your-services.png';
import customizePng from '../assets/images/service-providers/features/customize.png';
import featuredPng from '../assets/images/service-providers/features/get-featured.png';

export const providerFeaturesTexts = {
  en: {
    h2: 'Features',
    features: [
      {
        img: servicesPng,
        title: 'List your service in the nDRU store',
        text: `The app enables you to reach a vast number of
        potential customers and provides you with a
        stable revenue stream`,
      },
      {
        img: customizePng,
        title: 'Customize your service offering',
        text: `Your service – your terms. Service offerings
        can be customised to fit your business model.
        Offer additional services upon checkout and
        improve your customer lifetime value.`,
      },
      {
        img: featuredPng,
        title: 'Get featured in the store',
        text: `You can launch special promotions to reach
        even more customers through nDRU.`,
      },
    ],
  },
  hu: {
    h2: 'Kiemelt jellemzők',
    features: [
      {
        img: servicesPng,
        title: 'Listázd szolgáltatásod az nDRU-ban',
        text: `Az alkalmazással rengeteg potenciális ügyfelet érhetsz el, így stabil bevételi forrást biztosítva vállalkozásodnak`,
      },
      {
        img: customizePng,
        title: 'Szabd személyre ajánlataid',
        text: `A te szolgáltatásod - a te feltételeid. A szolgáltatási ajánlatok a te üzleti modelledhez igazíthatók. Kínálj további szolgáltatásokat a fizetéskor, és javítsd a vásárlói életút értéket`,
      },
      {
        img: featuredPng,
        title: 'Szerepelj kiemelt helyen a piactéren',
        text: `Különleges promóciókat indíthatsz, hogy még több ügyfelet érj el az nDRU-n keresztül`,
      },
    ],
  },
};
