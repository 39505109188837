export const footerLinks = {
  about: {
    en: [
      {
        linkText: 'Careers',
        to: '/careers',
        isLinkTag: true,
      },
      {
        linkText: 'FAQ',
        to: 'https://ndru.helpcenter.io',
        isLinkTag: false,
      },
      {/*{
        linkText: 'Blog',
        to: '/blog',
        isLinkTag: true,
      }*/},
      {
        linkText: 'About NDRU',
        to: '/introduction',
        isLinkTag: true,
      },
    ],
    hu: [
      {
        linkText: 'Karrier',
        to: '/karrier',
        isLinkTag: true,
      },
      {
        linkText: 'GYIK',
        to: 'https://ndru.helpcenter.io',
        isLinkTag: false,
      },
      {/*{
        linkText: 'Blog',
        to: '/blog',
        isLinkTag: true,
      }*/},
      {
        linkText: 'Az NDRU-ról',
        to: '/bemutatkozas',
        isLinkTag: true,
      },
    ],
  },
  business: {
    en: [
      {
        linkText: 'Repair shops',
        to: '/repair-shops',
      },
      {
        linkText: 'Service providers',
        to: '/service-providers',
      },
      {
        linkText: 'Fleet managers',
        to: '/fleet',
      },
      {
        linkText: 'Pricing',
        to: '/pricing',
      },
      {
        linkText: 'Contact us',
        to: '/contact-us',
      },
    ],
    hu: [
      {
        linkText: 'Szervizek',
        to: '/szervizek-partnerek',
      },
      {
        linkText: 'Szolgáltatók',
        to: '/szolgaltatok',
      },
      {
        linkText: 'Flottakezelők',
        to: '/flotta',
      },
      {
        linkText: 'Áraink',
        to: '/arazas',
      },
      {
        linkText: 'Kapcsolat',
        to: '/kapcsolat',
      },
    ],
  },
  legal: {
    en: [
      {
        linkText: 'Privacy Policy',
        to: '/NDRU-privacy-policy',
      },
      {
        linkText: 'Terms of Service',
        to: '/NDRU-terms-of-service',
      },
    ],
    hu: [
      {
        linkText: 'Adatvédelmi Tájékoztató',
        to: 'adatvedelmi-tajekoztatas',
      },
      {
        linkText: 'Általános Szerződési Feltételek',
        to: 'felhasznalasi-feltetelek',
      },
    ],
  },
  social: {
    facebook: 'https://www.facebook.com/drivendru',
    instagram: 'https://instagram.com/drivendru',
    linkedin: 'https://www.linkedin.com/company/drivendru',
  },
};
