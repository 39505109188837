import { useContext } from 'react';
import { Link } from 'react-router-dom';

import rectangleImg396 from '../assets/images/page1/boost-up-your-repair/Rectangle 396.png';
import trackProcessSvg from '../assets/images/page1/boost-up-your-repair/img_track_process.svg';
import trackProcessSvg2 from '../assets/images/page1/boost-up-your-repair/img_track_process2.png';
import ellipseSvg from '../assets/images/page1/boost-up-your-repair/Ellipse 25.svg';
import Context from '../context/Context';

import { boostUpYourRepairShopTexts } from '../texts/boostUpYourRepairShopTexts';

const BoostUpYourRepairShop = () => {
  const { usedLanguage } = useContext(Context);

  const { h2, p, btnText } =
    boostUpYourRepairShopTexts[usedLanguage];

  return (
    <section className='pt-5 pb-3 mt-5 mt-md-2'>
      <div
        className='container'
        style={{ position: 'relative' }}
      >
        <div className='jumbotron'>
          <h2 className='jumbotron__title'>
            {h2[0]}&nbsp;
            <br className='d-md-none' />
            {h2[1]}&nbsp;
            <br className='d-none d-md-block' />
            {h2[2]}&nbsp;
            <br className='d-md-none' />
            {h2[3]}
          </h2>
          <p className='jumbotron__paragraph'>
            {p[0]}&nbsp;
            <br className='d-md-none' />
            {p[1]}&nbsp;
            <br className='d-none d-md-block' />
            {p[2]}&nbsp;
            <br
              className={
                p[3] === 'percek alatt.'
                  ? 'd-none'
                  : 'd-md-none'
              }
            />
            {p[3]}
          </p>
          <Link
            to={usedLanguage === 'en'
            ? '/repair-shops'
            : '/szervizek-partnerek'}
            className='jumbotron__btn'
          >
            {btnText}
          </Link>
          <img
            src={rectangleImg396}
            className='jumbotron__img'
            loading='lazy'
            alt='learn more'
          />
          <img
            src={trackProcessSvg}
            className='jumbotron__comp d-md-none'
            loading='lazy'
            alt='learn more'
          />
          <img
            src={trackProcessSvg2}
            className='jumbotron__comp2 d-none d-md-block'
            alt='learn more'
          />
        </div>
        <img
          src={ellipseSvg}
          className='jumbotron__ellipse d-none d-lg-block'
          loading='lazy'
          alt='learn more'
        />
      </div>
    </section>
  );
};

export default BoostUpYourRepairShop;
