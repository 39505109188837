export const introductionTexts = {
  en: {
    h1: 'Our mission:',
    p: `At nDRU, we work as car lovers so that
    vehicle owners and service providers can
    easily connect and use innovative services
    on a daily basis.`,
    btn: 'Join us',
  },
  hu: {
    h1: 'Küldetésünk:',
    p: `Az nDRU-nál szeretjük az autókat, így célunk, hogy a járműtulajdonosok és a szolgáltatók könnyen összekapcsolódhassanak és innovatív szolgáltatásokat használhassanak a mindennapokban.`,
    btn: 'Csatlakozz hozzánk',
  },
};
