import { useContext } from 'react';
import Context from '../context/Context';
import { providerFeaturesTexts } from '../texts/providerFeaturesTexts';

const ProviderFeatures = () => {
  const { usedLanguage } = useContext(Context);

  const { h2, features } =
    providerFeaturesTexts[usedLanguage];

  return (
    <section className='section py-lg-5 my-lg-5 service-providers-feature'>
      <div className='container-fluid container--large'>
        <h2 className='section__title'>{h2}</h2>

        {features.map((feature, index) => (
          <div
            key={feature + index}
            className={`row align-items-center service-providers-feature ${
              index % 2 !== 0
                ? 'service-providers-feature--odd'
                : ''
            } py-5`}
          >
            <div
              className={`col-12 col-md-6 mb-4 mb-md-0 ${
                index % 2 !== 0 ? 'order-md-2' : ''
              }`}
            >
              <img
                className='service-providers-feature__img'
                src={feature.img}
                alt=''
              />
            </div>
            <div className='col-12 col-md-6'>
              <h2 className='service-providers-feature__title'>
                {feature.title}
              </h2>
              <p className='service-providers-feature__text'>
                {feature.text}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProviderFeatures;
