import managePng from '../assets/images/page3/manage-your-fleet-easily/manage.png';
import trackPng from '../assets/images/page3/track-car-repair-spendings/track.png';
import storePng from '../assets/images/page3/store-all-your-fleets-data/store.png';

export const fleetLandingTexts = {
  en: {
    h1: 'Easy client and fleet management',
    dtr3Start: 'Easy fleet',
    dtr3Texts: [
      'repair history',
      'spending',
      'carpool',
      'client',
      'service partner',
      'repair history',
    ],
    dtr3End: 'management',
    btns: ['Create your account now', 'Log in'],
    sections: [
      {
        imgs: [managePng],
        alt: 'Manage your fleet easily',
        h2: `Manage your fleet easily`,
        p: `NDRU car fleet management platform is a
        cloud-based solution, which helps you to
        manage your car fleet. With our platform,
        you will be able to track, accept and refuse
        the repair processes of your fleet.`,
      },
      {
        imgs: [trackPng],
        alt: 'Track car repair spendings',
        h2: `Track car repair spendings`,
        p: `You will be able to track car repairs done
        by repair shops of your choice and then
        receive periodical reports on the spending
        of your fleet. The NDRU platform will
        influence your business by helping you
        identify inefficiencies in your vehicle
        management process and improve overall
        efficiency, and hence profitability.`,
      },
      {
        imgs: [storePng],
        alt: 'Store all your fleets data',
        h2: `Store all your fleets data`,
        p: `Whether you are managing car fleet, taxi
        fleet or just your personal car, NDRU will
        help you manage all your cars, store, share
        and export your history so that it's always
        available and easy to understand. Get
        complete overview of your cars' repair
        history and costs in a single platform.`,
      },
    ],
    moreThanText: 'nDRU in numbers',
  },
  hu: {
    h1: 'Egyszerű flottakezelés',
    dtr3Start: 'Egyszerű',
    dtr3Texts: [
      'flotta',
      'ügyfél',
      'szervizelőzmény',
      'szervizpartner',
      'gépjármű',
      'flotta',
    ],
    dtr3End: 'kezelés',
    btns: ['Hozd létre fiókod most', 'Bejelentkezés'],
    sections: [
      {
        imgs: [managePng],
        alt: 'Kezeld flottád egyszerűen',
        h2: `Kezeld flottád egyszerűen`,
        p: `Az nDRU flottakezelő-platform egy felhőalapú szolgáltatás, mely segít a flottakezelésben. A platformon könnyedén követheted, elfogadhatod és visszautasíthatod flottád szervizfolyamatait.`,
      },
      {
        imgs: [trackPng],
        alt: 'Kövesd a szervizkiadásokat',
        h2: `Kövesd a szervizkiadásokat`,
        p: `Követheted az általad kiválasztott szervizek által végzett javításokat és rendszeres jelentéseket kaphatsz a költésekről. Az nDRU platform segíti vállalkozásod, mivel könnyebben észreveheted a folyamatok hiányosságait és javíthatod a hatékonyságot, ezáltal pedig a nyereséget is.`,
      },
      {
        imgs: [storePng],
        alt: 'Tárold flottád összes adatát',
        h2: `Tárold flottád összes adatát`,
        p: `Mindegy, hogy milyen flottát vagy autót kezelsz, az nDRU segít az adatok  tárolásában, így azok mindig elérhetők és könnyen értelmezhetők. Teljes áttekintést kapsz a szervizelőzményekről és a költségekről ezen az egy platformon keresztül.`,
      },
    ],
    moreThanText: 'Az nDRU számokban',
  },
};
