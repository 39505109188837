import teamImg from '../assets/images/careers/benefits/awesome-team.jpg';
import remoteImg from '../assets/images/careers/benefits/remote.jpg';
import familyImg from '../assets/images/careers/benefits/family-leave.jpg';
import hoursImg from '../assets/images/careers/benefits/no-core-hours.jpg';
import productImg from '../assets/images/careers/benefits/exciting-product.jpg';
import socialImg from '../assets/images/careers/benefits/social-activities.jpg';
import mindsetImg from '../assets/images/careers/benefits/global-mindset.jpg';
import learningImg from '../assets/images/careers/benefits/learning-and-development.jpg';

export const benefitsCardsTexts = {
  en: {
    h2: `Benefits`,
    benefits: [
      {
        img: teamImg,
        text: ['Awesome ', 'team'],
      },
      {
        img: remoteImg,
        text: ['Remote'],
      },
      {
        img: familyImg,
        text: ['Family leave'],
      },
      {
        img: hoursImg,
        text: ['No core ', 'hours'],
      },
      {
        img: productImg,
        text: ['Exciting ', 'product'],
      },
      {
        img: socialImg,
        text: ['Social ', 'activities'],
      },
      {
        img: mindsetImg,
        text: ['Global ', 'mindset'],
      },
      {
        img: learningImg,
        text: ['Learning and ', 'development'],
      },
    ],
  },
  hu: {
    h2: `Előnyök`,
    benefits: [
      {
        img: teamImg,
        text: ['Szuper ', 'csapat'],
      },
      {
        img: remoteImg,
        text: ['Home ', 'office'],
      },
      {
        img: familyImg,
        text: ['Családi ', 'szabadság'],
      },
      {
        img: hoursImg,
        text: ['Nincs ', 'törzsidő'],
      },
      {
        img: productImg,
        text: ['Izgalmas ', 'termék'],
      },
      {
        img: socialImg,
        text: ['Közös ', 'kikapcsolódás'],
      },
      {
        img: mindsetImg,
        text: ['Globális ', 'gondolkodás'],
      },
      {
        img: learningImg,
        text: ['Tanulás és ', 'fejlődés'],
      },
    ],
  },
};
