export const ourValuesSectionTexts = {
  en: {
    h2: 'Our values',
    values: [
      {
        title: 'Innovation',
        desc: `We create or find the innovative solutions we
        can add the most value to our customers, while
        we learn and develop ourselves`,
      },
      {
        title: 'Focus on the User',
        desc: `Customer was our focus when we started
        developing NDRU, and we follow this principle
        when preparing each of our new services.`,
      },
      {
        title: 'Team flow',
        desc: `Even talented individuals can only create
        great things in a well-functioning team.
        Strong teams are invincible.`,
      },
      {
        title: 'Open mindset',
        desc: `We are constantly open-minded about new ideas
        and suggestions. This is the driving force
        behind the development of NDRU.`,
      },
      {
        title: 'Ownership',
        desc: `We solve every task with ownership, from the
        first blog character to the last backend
        settings. Ownership comes with great
        responsibility, but it also results in the
        highest esteem.`,
      },
      {
        title: 'Run through walls',
        desc: `We are determined and hard working because
        that is the only way to win the race... and we
        will win!!!`,
      },
    ],
  },
  hu: {
    h2: 'Értékeink',
    values: [
      {
        title: 'Innováció',
        desc: `Digitális szolgáltatásokat hozunk egy eddig nagyrészt analóg iparágba.`,
      },
      {
        title: 'Ügyfélközpontúság',
        desc: `Igyekszünk termékünket mindenki számára könnyen használhatóvá tenni.`,
      },
      {
        title: 'Megbízhatóság',
        desc: `Az nDRU appra mindig számíthatsz  – átvizsgálás vagy nagyszerviz esetén is.`,
      },
      {
        title: 'Minőség',
        desc: `Egy olyan terméket akarunk létrehzoni, ami soha nem hagy cserben.`,
      },
      {
        title: 'Egyszerűség',
        desc: `Az nDRU-nál célunk, hogy egy autó birtoklása gyerekjáték legyen. Termékünk egyaránt egyszerűsíti az autószervizek és az autótulajdonosok életét.`,
      },
      {
        title: 'Szenvedély',
        desc: `Mint autórajongók, mindig keressük a módját, hogy javítsuk az autótulajdonosok élményét.`,
      },
    ],
  },
};
