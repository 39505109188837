import { useContext } from 'react';
import { Link } from 'react-router-dom';

import Page from '../components/Page';
import GetInTouchSection from '../components/GetInTouchSection';
import BenefitsCards from '../components/BenefitsCards';
import Context from '../context/Context';

import { careersPageTexts } from '../texts/careersPageTexts';
import NdruIsGrowingSection from '../components/NdruIsGrowingSection';
import OpenPositionsSection from '../components/OpenPositionsSection';

function Careers() {
  const { usedLanguage } = useContext(Context);
  const { h1, p, btn } = careersPageTexts[usedLanguage];

  return (
    <Page
      title={usedLanguage === 'en' ? 'Careers' : 'Karrier'}
      endpoint='careers'
    >
      <section className='section section--hero-pricing-page'>
        <div className='container'>
          <div className='hero-our-mission'>
            <div className='hero-our-mission__texts'>
              <h1 className='hero-our-mission__title'>
                {h1}
              </h1>
              <p className='hero-our-mission__subtitle'>
                {p}
              </p>
              <div className='hero-our-mission__buttons'>
                <div className='row'>
                  <div className='col-6 offset-3 col-md-4 offset-md-0'>
                    <Link
                      to={usedLanguage === 'en'
                      ? '/contact-us'
                      : '/kapcsolat'}
                      className='btn btn--green'
                    >
                      {btn}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='hero-our-mission__image hero-our-mission__image--careers'></div>
          </div>
        </div>
      </section>

      <NdruIsGrowingSection />

      <BenefitsCards />

      {/*<OpenPositionsSection />*/}

      <GetInTouchSection />
    </Page>
  );
}
export default Careers;
