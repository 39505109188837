import { useContext } from 'react';

import Context from '../context/Context';
import { benefitsCardsTexts } from '../texts/benefitsCardsTexts';

function BenefitsCards({ extraPaddingBottom }) {
  const { usedLanguage } = useContext(Context);

  const { h2, benefits } = benefitsCardsTexts[usedLanguage];

  return (
    <section
      className='benefits'
      style={{ paddingBottom: extraPaddingBottom }}
    >
      <div className='container-fluid container--large'>
        <h2 className='benefits__title'>{h2}</h2>
        <div className='benefits__grid'>
          {benefits.map(({ img, text }, index) => (
            <div
              key={index + text[0]}
              style={{
                backgroundImage: `url(${img})`,
              }}
              className='benefits__item'
            >
              {text.length > 1 ? (
                <>
                  {text[0]}
                  <br />
                  {text[1]}
                </>
              ) : (
                text[0]
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
export default BenefitsCards;
