import { useContext } from 'react';
import { Link } from 'react-router-dom';
import Page from '../components/Page';

import MobileAppsSection from '../components/MobileAppsSection';
import TimelineSection from '../components/TimelineSection';
import Context from '../context/Context';
import { introductionTexts } from '../texts/IntroductionTexts';
import OriginStory from '../components/OriginStory';
import OurValuesSection from '../components/OurValuesSection';
import TeamSection from '../components/TeamSection';
import JoinUsSection from '../components/JoinUsSection';

function Introduction() {
  const { usedLanguage } = useContext(Context);
  const { h1, p, btn } = introductionTexts[usedLanguage];

  return (
    <Page
      title={
        usedLanguage === 'en'
          ? 'Introduction'
          : 'Az NDRU-ról'
      }
      endpoint='introduction'
    >
      <section className='section section--hero-pricing-page'>
        <div className='container'>
          <div className='hero-our-mission'>
            <div className='hero-our-mission__texts'>
              <h1 className='hero-our-mission__title'>
                {h1}
              </h1>
              <p className='hero-our-mission__subtitle'>
                {p}
              </p>
              <div className='hero-our-mission__buttons'>
                <div className='row'>
                  <div className='col-6 offset-3 col-md-4 offset-md-0'>
                    <Link
                      to={usedLanguage === 'en'
                      ? '/contact-us'
                      : '/kapcsolat'}
                      className='btn btn--green'
                    >
                      {btn}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='hero-our-mission__image'></div>
          </div>
        </div>
      </section>

      <TimelineSection />

      <OriginStory />

      <OurValuesSection />

      <TeamSection />

      {/*<JoinUsSection />*/}

      <MobileAppsSection />
    </Page>
  );
}
export default Introduction;
