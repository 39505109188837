import BlogPostParagraph from '../components/BlogPostParagraph';
import GetInTouchSection from '../components/GetInTouchSection';
import MobileAppsSection from '../components/MobileAppsSection';
import Page from '../components/Page';
import { Link } from 'react-router-dom';

import { mobileAppLinks } from '../config/mobileAppLinks';

// images
// import Garage from '../assets/images/blog-posts/Garage.jpg';
import Flag from '../assets/images/blog-posts/Flag.jpg';

function BlogPostPage({ title, introduction, paragraphs, meta }) {
  return (
    <Page title='Blog Post' endpoint='blog/blog-post'>
      <h2 className='blog-post__flag'>Blog</h2>
      <p className='blog-post__category'>Product updates</p>
      <h1 className='blog-post__title'>{title}</h1>
      <div className='blog-post__meta'>
        by <span className='blog-post__author'>{meta.author}</span> {meta.date},{' '}
        {meta.year}
      </div>

      <div
        className='blog-post__hero-img'
        style={{ backgroundImage: `url(${Flag})` }}
      ></div>

      <section className='blog-post__content'>
        <div className='container' style={{ maxWidth: '867px' }}>
          <p className='blog__introduction'>{introduction}</p>
        </div>

        {paragraphs.map((paragraph) => (
          <BlogPostParagraph title={paragraph.title} texts={paragraph.texts} />
        ))}

        <div className='container' style={{ maxWidth: '867px' }}>
          <div className='blog__be-part-of'>
            <h3>Be part of a growing platform from the start</h3>
            <p>
              The nDRU mobile app is available to download for free both on{' '}
              <a href={mobileAppLinks.appStore}>iOS</a> and{' '}
              <a href={mobileAppLinks.googlePlay}>Android</a>, and you can already
              book appointments for your vehicle at various repair shops in Hungary,
              with more countries coming soon. The team is working hard to bring even
              more features to nDRU, so stay tuned for the option to get your highway
              passes, pay for parking, and much more via the app. If you wish to join
              nDRU with your services or your fleet,{' '}
              <Link to={'/contact-us'}>contact us</Link> for details and to start the
              onboarding process. The service platform and the fleet platform require
              no subscription fees and will enable your company to operate with high
              efficiency. If you provide some kind of automotive service that we
              don’t support yet, please reach out to us to discuss a potential
              cooperation. Motorists will thank you for bringing yet another service
              to the ultimate platform for managing their cars.
            </p>
          </div>
        </div>

        <div className='container' style={{ maxWidth: '867px' }}>
          <div className='blog__be-part-of'>
            <h3>About the company</h3>
            <p>
              nDRU 1.0 Ltd., the company behind the nDRU app, the nDRU service
              platform, and the nDRU fleet platform was founded in 2021 with the aim
              of creating a superior digital experience for car owners and automotive
              service providers alike. As a startup company backed by angel investors
              from Hungary, the team is committed to create a product that both
              private and enterprise customers enjoy using and which solves and
              facilitates everyday hurdles in the automotive industry.
            </p>
          </div>
        </div>

        {/* <div className='container'>
          <div
            className='blog-post__img'
            style={{ backgroundImage: `url(${Garage})` }}
          ></div>
        </div> */}
      </section>

      <MobileAppsSection />
      <GetInTouchSection />
    </Page>
  );
}
export default BlogPostPage;
