import Page from '../components/Page';

function NdruPrivacyPolicy() {
  return (
    <Page
      title='Privacy Policy'
      endpoint='NDRU-privacy-policy'
    >
      <div className='container privacy-policy-page'>
        <h1>
          PRIVACY NOTICE AND INFORMATION ON THE PROCESSING
          OF PERSONAL DATA
        </h1>
        <p>Last updated: 13/06/2024 (version 1.0.2)</p>

        <p>
          This Privacy Policy (hereinafter referred to as
          the "<strong>Privacy Policy</strong>") contains
          information about how NDRU 1.0 Ltd (hereinafter
          referred to as "<strong>NDRU</strong>") processes
          your personal data in connection with the services
          provided through the www. drivendru.com website,
          mobile website and the NDRU APP mobile application
          (hereinafter referred to as the "
          <strong>Platform</strong>"){' '}
          <strong>
            (hereinafter referred to as the "Service").
          </strong>{' '}
          Detailed information on the use of cookies is not
          included in this Privacy Notice. Information on
          the management of cookies is available here.
        </p>
        <p>
          Data Controller: NDRU 1.0 Ltd., company
          registration number: 01-09-374640, tax number:
          28802839-2-43, registered office: 1221 Budapest,
          Hajó utca 1., e-mail: info@drivendru.com
          (hereinafter referred to as "<strong>NDRU</strong>
          " or "<strong>Data Controller</strong>").
        </p>
        <p>
          Please note that no DPO has been appointed for the
          data processed.
        </p>
        <p className='border'>
          If NDRU processes your personal data on the basis
          of{' '}
          <strong>
            legitimate interest, you have the right to
            object to the processing.
          </strong>{' '}
          If you wish to exercise this right, please contact
          us by e-mail or other means.
        </p>

        <h2>Terms used in this Privacy Notice</h2>

        <p>
          For the meaning of terms used in this Privacy
          Notice, please refer to the following paragraph.
        </p>

        <p>
          <strong>Data Processor:</strong> a natural or
          legal person, public authority, agency or any
          other body that processes personal data on behalf
          of (on behalf of) NDRU.
        </p>

        <p>
          <strong>Data processing:</strong> any operation or
          set of operations which is performed upon personal
          data or on sets of personal data, whether or not
          by automated means, such as collection, recording,
          organisation, structuring, structuring, storage,
          adaptation or alteration, retrieval, consultation,
          use, disclosure, transmission, dissemination or
          otherwise making available, alignment or
          combination, restriction, erasure or destruction.
        </p>

        <p>
          <strong>You:</strong> are a user of the services
          provided by NDRU through the Platform.
        </p>

        <p>
          <strong>Personal Data:</strong> any information
          about you that allows us to identify you directly
          or indirectly. Personal Data means your name,
          identification number, location data: GPS
          coordinates of the user's location, online
          identifier or one or more factors relating to your
          physical, physiological, genetic, mental,
          economic, cultural, or social characteristics.
        </p>

        <p>
          <strong>Service:</strong> the set of services
          provided by NDRU to you in the context of the
          operation of the Platform, i.e., the operation of
          the Platform, the provision of the use of the
          Platform (including registration, making partner
          services available, browsing, order management,
          location identification, payment, provision of the
          e-service book and complaint handling).
        </p>

        <h2>1. Data Processing Policy</h2>
        <ol>
          <li>
            Your Personal Data will be processed fairly and
            transparently in accordance with the law.
          </li>
          <li>
            We will only process your Personal Data for the
            lawful purposes set out in this Privacy Notice.
          </li>
          <li>
            We will not process Personal Data that is not
            adequate, relevant or necessary for the purposes
            of the processing set out in the Privacy Notice.
          </li>
          <li>
            We do our best to keep your Personal Data
            accurate and up to date. We will take all
            reasonable steps to delete or correct inaccurate
            Personal Data.
          </li>
          <li>
            We store your Personal Data in a format that
            allows us to identify you only for the purposes
            for which we process your Personal Data and for
            the time necessary to do so.
          </li>
          <li>
            We will process your Personal Data in a manner
            that ensures appropriate security for the
            processing of Personal Data, including
            protection against unauthorised or unlawful
            processing, accidental loss, destruction or
            damage.
          </li>
        </ol>

        <h2>
          2. Why and how do we process your Personal Data?
        </h2>
        <p>
          In most cases, we process your Personal Data in
          order to provide you with our services. However,
          we may also process your data for other purposes
          (e.g. to comply with our legal obligations or to
          enforce our legal claims). Detailed information
          about the purposes for which we process your data
          and other relevant processing circumstances are
          set out below, grouped by purpose.
        </p>
        <p>
          We do not carry out automated decision-making and
          profiling when processing Personal Data, otherwise
          we will inform you directly.
        </p>

        <h3>
          2.1 Registration, provision of access to the
          service, user account management
        </h3>

        <p>
          <em>Purpose of processing of Personal Data:</em>{' '}
          to create and manage your NDRU account upon
          registration and thus provide you with access to
          the Services.
        </p>

        <p>
          <em>Legal basis for processing:</em> processing is
          necessary for the performance of a contract
          between you and NDRU.
        </p>

        <p>
          <em>Categories of Personal Data processed:</em>{' '}
          the Personal Data you provide when registering:
          e-mail address, password, customer code, first
          name, surname, delivery address, telephone number,
          name, registered office, tax number, company
          registration number, photograph, vehicle type,
          registration number, chassis number, engine
          number, registration number, registration number,
          billing information (name, tax number, address),
          service history, make, model, year of manufacture,
          engine capacity, power, fuel, tyre size, mileage.
          If logging in with a Facebook account or Apple ID,
          the following additional personal data: e-mail
          address.
        </p>

        <p>
          <em>Duration of storage of Personal Data:</em> the
          NDRU will process Personal Data until the user
          account is deleted.
        </p>

        <p>
          <em>
            Recipients of personal data, data processors:
          </em>
          <table>
            <thead>
              <tr>
                <th>Name/Category</th>
                <th>Description</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Microsoft Ireland Operations limited,{' '}
                  <br /> One Microsoft Place, South County
                  Business Park, Leopardstown, Dublin 18 D18
                  P521
                </td>
                <td>
                  Microsoft Azure Server, hosting service
                </td>
                <td>
                  Data processor <br /> NDRU safeguards for
                  data transfers outside the EEA: general
                  data protection clauses
                </td>
              </tr>
              <tr>
                <td>
                  NDRU operated processing server hosted in Microsoft Azure
                </td>
                <td>
                  Provides functionality for the NDRU platform <br />
                  accessible on https://server.drivendru.com
                </td>
                <td>
                  Data processor 
                </td>
              </tr>
            </tbody>
          </table>
        </p>

        <p>
          <em>
            Recipients of personal data, data processors:
          </em>
          <table>
            <thead>
              <tr>
                <th>Name/Category</th>
                <th>Description</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Partners providing service and product
                  purchase opportunities
                </td>
                <td>
                  The necessary data will be provided to
                  fulfil the services ordered
                </td>
                <td>Data processor</td>
              </tr>
              <tr>
                <td>
                  Partners providing additional partner
                  services (e.g. insurance, immediate
                  assistance)
                </td>
                <td>
                  The necessary data will be provided to
                  fulfil the services ordered
                </td>
                <td>Data processor</td>
              </tr>
              <tr>
                <td>Partners providing courier services</td>
                <td>
                  The necessary data will be provided to
                  fulfil the services ordered
                </td>
                <td>Data processor</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  (e.g. insurance, immediate assistance)
                </td>
              </tr>
            </tfoot>
          </table>
        </p>

        <h3>2.3 Customer service, complaint handling</h3>

        <p>
          <em>Purpose of processing of Personal Data:</em>{' '}
          the processing of any complaints, claims or other
          requests you may have through NDRU's customer
          service, including customer service provided
          through postal address, e-mail address, telephone
          and chat function.
        </p>

        <p>
          <em>Legal basis for processing:</em> NDRU
          processes your personal data on the basis of its
          legitimate interest. NDRU's legitimate interest is
          to be able to effectively remedy and manage
          service failures and your complaints and requests.
          In some cases, processing is necessary to comply
          with a legal obligation (e.g. to preserve a
          recorded voice).
        </p>

        <p>
          <em>Categories of Personal Data Processed:</em>{' '}
          the data you provide when registering or using the
          Service (see 2.1 and 2.2), and data related to the
          submission and handling of messages or complaints
          and requests sent by you.
        </p>

        <p>
          <em>Duration of storage of Personal Data:</em>{' '}
          NDRU will retain Personal Data relating to your
          complaint for 5 years from the date of receipt of
          the complaint. For the purpose of handling and
          documenting the complaint, NDRU will record your
          complaint made over the telephone and will retain
          the recorded audio for 5 years from the date of
          recording.
        </p>

        <p>
          <em>
            Recipients of personal data, data processors:
          </em>
          <table>
            <thead>
              <tr>
                <th>Name/Category</th>
                <th>Description</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Service concerned</td>
                <td>Investigation of complaints</td>
                <td>Joint controller</td>
              </tr>
              <tr>
                <td>Courier service providers</td>
                <td>Investigate complaints</td>
                <td>Shared data controller</td>
              </tr>
              <tr>
                <td>Add-on partner service providers </td>
                <td>Investigate complaints</td>
                <td>Shared data controller</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  (e.g. insurance, immediate assistance)
                </td>
              </tr>
            </tfoot>
          </table>
        </p>

        <h3>2.4 Collection of fees</h3>

        <p>
          <em>Purpose of processing your Personal Data:</em>{' '}
          to ensure payment of your order through a payment
          service provider by credit card, bank transfer or
          Apple Pay.
        </p>
        <p>
          <em>Legal basis for processing:</em> NDRU
          processes your personal data on the basis of its
          legitimate interest. NDRU has a legitimate
          interest in ensuring payment of fees in return for
          the Service, to collect the fees.
        </p>
        <p>
          <em>Categories of Personal Data processed:</em>{' '}
          data provided by you when registering or when
          using the Service (see 2.1 and 2.2), payment
          method data. NDRU records but does not store your
          credit card details. The NDRU does not have access
          to the credit card data, which can only be
          processed by payment service providers. You have
          the possibility to save your credit card details
          (credit card number, name on the credit card,
          expiry date, CVC number) in order to avoid having
          to re-enter these details for future Orders. When
          saved, all data will be stored as a "token"
          (non-reversible code) and only the token will be
          used and transmitted for future orders.
        </p>
        <p>
          <em>Duration of storage of Personal Data:</em> the
          NDRU will process Personal Data for the time
          necessary to achieve the purpose of the
          processing, up to the time of deletion of the user
          account.
        </p>

        <p>
          <em>
            Recipients of personal data, data processors:
          </em>
          <table>
            <thead>
              <tr>
                <th>Name/Category</th>
                <th>Description</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Stripe Inc., registration number:
                  114218795 , registered office: 510
                  Townsend Street San Francisco, CA 94103
                  United States
                </td>
                <td>
                  When using the Stripe payment system,
                  credit card data is entered and saved in
                  the NDRU interface without redirection,
                  but thanks to the use of highly secure
                  client-side encryption (CSE) technology,
                  the credit card data is encrypted with the
                  order, with the encryption key being
                  accessible only by the payment service
                  provider.
                </td>
                <td>
                  Data processor <br />
                  NDRU safeguards for data transfers outside
                  the EEA: general data protection clauses
                </td>
              </tr>
            </tbody>
          </table>
        </p>

        <h3>
          2.5 Newsletters and direct marketing messages
        </h3>

        <p>
          <em>Purpose of processing of Personal Data:</em>{' '}
          sending marketing messages in the form of direct
          marketing enquiries (e.g. newsletter, push
          message).{' '}
        </p>

        <p>
          <em>Legal basis for processing:</em> your consent.
        </p>

        <p>
          <em>Categories of Personal Data processed:</em>{' '}
          data provided by you during registration (see 2.1
          and 2.2), vehicle data, service data/service
          status, service history.
        </p>

        <p>
          <em>Duration of storage of Personal Data:</em> the
          relevant Personal Data will be stored until
          consent is withdrawn (e.g. unsubscribing from a
          newsletter), but for a maximum of 10 years from
          the date of consent.
        </p>

        <p>
          <em>
            Recipients of personal data, data processors:
          </em>
          <table>
            <thead>
              <tr>
                <th>Name/Category</th>
                <th>Description</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  MailChimp c/o The Rocket Science Group,
                  LLC., Headquarters: 675 Ponce De Leon Ave
                  NE Suite 5000 Atlanta, GA 30308 USA
                </td>
                <td>
                  Providing e-mail addresses and other
                  contact details for the purpose of sending
                  newsletters
                </td>
                <td>
                  Data processor <br />
                  NDRU safeguards for data transfers outside
                  the EEA: general data protection clauses
                </td>
              </tr>
              <tr>
                <td>
                  Buffer Inc., San Francisco, CA, USA 2243
                  Fillmore St #380-7163
                </td>
                <td>
                  Providing e-mail addresses and other
                  contact details for the purpose of sending
                  newsletters
                </td>
                <td>
                  Data processor <br />
                  NDRU safeguards for data transfers outside
                  the EEA: general data protection clauses
                </td>
              </tr>
            </tbody>
          </table>
        </p>

        <h3>2.6 Rating (rating)</h3>

        <p>
          <em>Purpose of processing Personal Data:</em> to
          improve the Service, to measure and increase user
          satisfaction based on information about the
          quality of the Service.
        </p>

        <p>
          <em>Legal basis for processing:</em> your Personal
          Data is processed by NDRU based on its legitimate
          interest. NDRU's legitimate interest is to provide
          a high-quality service.
        </p>

        <p>
          <em>Categories of Personal Data processed:</em>{' '}
          data provided by you during registration (see
          points 2.1 and 2.2), ratings, comments, completion
          times, satisfaction data, vehicle data, services
          used or ordered.
        </p>

        <p>
          <em>Duration of storage of Personal Data:</em> the
          NDRU will retain your Personal Data relating to
          your assessment for 5 years from the date of
          receipt of the assessment.
        </p>

        <p>
          <em>
            Recipients of personal data, data processors:
          </em>
          <table>
            <thead>
              <tr>
                <th>Name/Category</th>
                <th>Description</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Service concerned</td>
                <td>Evaluation of services</td>
                <td>Shared data controller</td>
              </tr>
              <tr>
                <td>Courier service providers</td>
                <td>Evaluation of services</td>
                <td>Shared data controller</td>
              </tr>
              <tr>
                <td>Add-on partner service providers</td>
                <td>Evaluation of services</td>
                <td>Shared data controller</td>
              </tr>
            </tbody>
          </table>
        </p>

        <h3>2.7 Enforcement of legal claims</h3>

        <p>
          <em>
            Purpose of the processing of Personal Data:
          </em>{' '}
          to assert, exercise and defend NDRU's legal
          claims.
        </p>

        <p>
          <em>Legal basis for processing:</em> NDRU
          processes your personal data on the basis of its
          legitimate interest. NDRU has a legitimate
          interest in pursuing NDRU's legal claims.
        </p>

        <p>
          <em>Categories of Personal Data processed:</em>{' '}
          data provided by you during registration (see 2.1
          and 2.2), vehicle data, service data, service
          history data, data relating to your complaints and
          their handling, data relating to the billing and
          collection of fees, other Personal Data necessary
          to enforce a legal claim.
        </p>

        <p>
          <em>Duration of storage of Personal Data:</em> the
          relevant Personal Data will be kept for 5 years
          after the deletion of the user account.
        </p>

        <p>
          <em>
            Recipients of personal data, data processors:
          </em>
          <table>
            <thead>
              <tr>
                <th>Name/Category</th>
                <th>Description</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Legal representatives, advisers,
                  authorities, courts
                </td>
                <td>
                  In connection with the enforcement of a
                  legal claim, the data will be transmitted
                </td>
                <td>Data controllers</td>
              </tr>
            </tbody>
          </table>
        </p>

        <h3>
          2.8 Measuring business performance, making
          management decisions
        </h3>

        <p>
          <em>Purpose of processing your Personal Data:</em>{' '}
          the NDRU keeps statistics on which service you
          have been to, how many times, how much you have
          paid (labour or parts and how much of that was the
          NDRU commission), what parts you have replaced,
          what parts were not replaced in order to
          accurately measure your business performance and
          make appropriate management decisions based on
          this, and thus provide a higher quality service.
        </p>

        <p>
          <em>Legal basis for processing:</em> your Personal
          Data is processed by NDRU on the basis of its
          legitimate interest. NDRU has a legitimate
          interest in accurately measuring your business
          performance and making appropriate management
          decisions based on that performance, so as to
          provide you with the highest quality of service.
        </p>

        <p>
          <em>Categories of Personal Data processed:</em>{' '}
          information on which service you have been to, how
          many times, how much you have paid (labour or
          parts and how much of that was the NDRU
          commission), what parts you have replaced, what
          parts were not replaced.
        </p>

        <p>
          <em>Duration of storage of Personal Data:</em> the
          NDRU will keep your Personal Data relating to the
          above statistics for 3 years from the date of the
          service.
        </p>

        <p>
          <em>
            Recipients of personal data, data processors:
          </em>
          <table>
            <thead>
              <tr>
                <th>Name/Category</th>
                <th>Description</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>NDRU representatives, Advisers</td>
                <td>Evaluation of service</td>
                <td>Data processor</td>
              </tr>
            </tbody>
          </table>
        </p>

        <h2>
          3. Your rights in relation to data processing by
          NDRU
        </h2>

        <p>
          You have the following rights in relation to the
          processing activities carried out by NDRU:
        </p>

        <ul>
          <li>
            <em style={{ fontWeight: 'normal' }}>
              Right of access
            </em>
            <p>
              You may request access from the NDRU to your
              Personal Data processed by the NDRU, as well
              as information about the purposes of the
              processing, the categories of data processed,
              the recipients of the transfers, the duration
              of the processing, your rights with regard to
              the processing of your Personal Data by the
              NDRU and your right to apply to the data
              protection authority. In addition, where your
              Personal Data is collected by NDRU from a
              source other than you, you have the right to
              be informed of the source of the data and
              whether NDRU uses automated decision-making or
              similar procedures, including the logic of
              their operation and the significance of such
              processing and the likely consequences for
              you. You may also request information on
              whether and under what conditions NDRU
              transfers your Personal Data outside the EEA.
            </p>
            <p>
              If you request it, we will provide you with a
              copy of your Personal Data processed by NDRU.
            </p>
          </li>
          <li>
            <em style={{ fontWeight: 'normal' }}>
              right to rectification
            </em>
            <p>
              You have the right to have inaccurate personal
              data concerning you corrected by NDRU upon
              your request. You have the right to request
              that incomplete personal data be completed by
              means of a supplementary declaration.
            </p>
          </li>
          <li>
            <em style={{ fontWeight: 'normal' }}>
              right to erasure
            </em>
            <p>
              At your request, NDRU will delete your
              Personal Data immediately if one of the
              following grounds applies:
            </p>
            <ol>
              <li>
                the personal data are no longer necessary
                for the purposes for which they were
                collected;
              </li>
              <li>
                You withdraw your consent on which the
                processing is based and there is no other
                legal basis for the processing;
              </li>
              <li>
                You object to the processing and there are
                no overriding legitimate grounds for the
                processing;
              </li>
              <li>
                the processing of your Personal Data is
                unlawful;
              </li>
              <li>
                your Personal Data must be deleted in
                accordance with applicable law.
              </li>
            </ol>
            <p>
              Please note that NDRU has the right to refuse
              to delete your Personal Data if it is
              necessary, inter alia, to ensure freedom of
              expression and information, to comply with a
              legal obligation or to assert, exercise or
              defend a legal claim.
            </p>
          </li>
          <li>
            <em style={{ fontWeight: 'normal' }}>
              Right to restriction of processing
            </em>
            <p>
              You have the right to request restriction of
              processing if one of the following conditions
              is met:
            </p>
            <ol>
              <li>
                You contest the accuracy of the personal
                data, in which case the restriction applies
                for the period of time that allows the
                accuracy of the personal data to be
                verified;
              </li>
              <li>
                the processing is unlawful, and you oppose
                the erasure of the data and instead request
                the restriction of their use;
              </li>
              <li>
                NDRU no longer needs the Personal Data for
                the purposes of processing, but you require
                it for the establishment, exercise or
                defence of legal claims;
              </li>
              <li>
                if you have objected to the processing; in
                this case, the restriction applies for the
                period until it is established whether
                NDRU's legitimate grounds prevail over your
                legitimate grounds.
              </li>
            </ol>
            <p>
              If the processing is restricted, such personal
              data, except for storage, may only be
              processed with your consent or for the
              establishment, exercise or defence of legal
              claims or for the protection of the rights of
              another natural or legal person or for
              important public interests of the European
              Union or of a Member State.{' '}
            </p>
          </li>
          <li>
            <em style={{ fontWeight: 'normal' }}>
              right to protest
            </em>
            <p>
              Where NDRU processes your Personal Data for
              direct marketing purposes, you have the right
              to object to the processing of your Personal
              Data for such purposes, in which case NDRU may
              no longer process your Personal Data for such
              purposes.
            </p>
            <p>
              In addition, you have a general right to
              object to processing where the legal basis for
              the processing activity is the legitimate
              interest of NDRU or a third party. In such a
              case, the NDRU may no longer process the
              Personal Data unless the NDRU can demonstrate
              compelling legitimate grounds for the
              processing which override your interests,
              rights and freedoms or for the establishment,
              exercise or defence of legal claims.
            </p>
          </li>
          <li style={{ marginBottom: '1rem' }}>
            <em style={{ fontWeight: 'normal' }}>
              right to data portability
            </em>
            <p>
              You have the right to receive the Personal
              Data that you provide to NDRU in a structured,
              commonly used, machine-readable format, and
              the right to have NDRU transfer this data
              directly to another data controller, provided
              that the following conditions are met:
            </p>
            <ul>
              <li>
                the processing is based on consent or is
                necessary for the performance of a contract
                to which you are a party or for taking steps
                at your request prior to entering into that
                contract; and
              </li>
              <li>
                the processing is carried out by automated
                means.
              </li>
            </ul>
          </li>

          <li>
            <em style={{ fontWeight: 'normal' }}>
              right to withdraw consent
            </em>
            <p>
              You have the right to withdraw your consent at
              any time, which does not affect the lawfulness
              of the processing based on consent prior to
              the withdrawal.
            </p>
          </li>
        </ul>

        <p style={{ marginTop: '1.5rem' }}>
          The NDRU shall take the measures necessary to
          exercise the above rights without delay, within
          one month of receipt of the request. If necessary,
          and taking into account the complexity of the
          request and the number of requests, this period
          may be extended by a further two months, of which
          the NDRU will inform you, stating the reasons.
        </p>

        <p>
          In the event that you submit such a request
          electronically, the NDRU will inform you
          electronically where possible, unless you request
          otherwise.
        </p>

        <p>
          In the event that the NDRU does not take the
          necessary action on your request, it will inform
          you of the reasons within one month of receipt of
          the request.
        </p>

        <p>
          If the NDRU has reasonable doubts as to the
          identity of the natural person submitting the
          request, it may request additional information
          necessary to confirm the identity of the data
          subject.
        </p>

        <h2>4. Remedies</h2>
        <p>
          If you do not agree with the response or action of
          the NDRU, or if you feel that your rights have
          been violated, you may lodge a complaint with the
          National Authority for Data Protection and Freedom
          of Information (address: H-1055 Budapest, Falk
          Miksa utca 9-11, web: http://naih.hu/, postal
          address: 1363 Budapest, Pf. 9.; phone:
          +36-1-391-1400; e-mail: ugyfelszolgalat@naih.hu).
          In case of violation of your personal rights, you
          may apply to the Metropolitan Court of Budapest or
          to the competent court in your place of residence
          (for more information:
          https://birosag.hu/birosag-kereso).
        </p>

        <h2>5. Miscellaneous provisions</h2>
        <p>
          Please note that the NDRU may periodically review
          this Privacy Notice and amend it as necessary. In
          the event that NDRU makes changes to this Privacy
          Notice, it will notify and publish them in the
          usual way (for example, by e-mail or on the
          website). Please review the NDRU Privacy Notice
          from time to time.
        </p>
      </div>
    </Page>
  );
}
export default NdruPrivacyPolicy;
