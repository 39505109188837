import { useEffect, useContext } from 'react';

import Context from '../context/Context';

function HubSpotFormSection() {
  const { usedLanguage } = useContext(Context);

  useEffect(() => {
    const script_hun = document.createElement('script');
    script_hun.src='//js-eu1.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script_hun);

    script_hun.addEventListener('load', () => {
        // @TS-ignore
        if (window.hbspt) {
            // @TS-ignore
            window.hbspt.forms.create({
                region: "eu1",
                portalId: '26992808',
                formId: '68285f92-0b76-4074-808d-245b350914d0',
                target: '#hubspotForm_hun'
            })
        }
    });

    const script_en = document.createElement('script');
    script_en.src='//js-eu1.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script_en);

    script_en.addEventListener('load', () => {
        // @TS-ignore
        if (window.hbspt) {
            // @TS-ignore
            window.hbspt.forms.create({
                region: "eu1",
                portalId: '26992808',
                formId: 'f432e990-b73e-4e2a-b8ec-97e774ac4c08',
                target: '#hubspotForm_en'
            })
        }
    });
  }, []);

  return (
    <section className='hubspot_form'>
      <div className="section_title">
        <h2>
          {usedLanguage === 'en'
            ? 'Get in touch with us'
            : 'Kérdésed van? Lépj velünk kapcsolatba!'}
        </h2>
      </div>
      <div id="hubspotForm_en" className={usedLanguage === 'en' ? 'form_container' : 'form-hidden form_container'}></div>
      <div id="hubspotForm_hun" className={usedLanguage === 'en' ? 'form-hidden form_container' : 'form_container'}></div>
    </section>
  );
}
export default HubSpotFormSection;
