import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Page from '../components/Page';

import RepairShopSingleComponent from '../components/RepairShopSingleComponent';
import LoaderAsync from '../components/LoaderAsync';

function RepairShopSinglePage() {
  const navigate = useNavigate();
  const params = useParams();
  const [title, setTitle] = useState('');
  const [endpoint, setEndpoint] = useState('');
  const [service, setService] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [categories, setCategories] = useState([]);

  const getData = async (id) => {
    setShowLoader(true);
    const response = await fetch(
      `https://corsproxy.io/?https://server.drivendru.com/api/services/${id}/info/aPRh6KcTHTuM7JEjLtp+TgYjpb1kW2yd3OT0oKI5uQ7LYMo4zCxsEc7nhu6LNdYbo2farn2o9fE28L5MMuijQ==`
    );

    if (!response.ok) {
      console.log('fetching failed');
      return [];
    }

    return await response.json();
  };

  const takeOutCategories = (facilities) => {
    const categories = new Set();
    facilities.forEach((facility) => {
      if (facility.type && facility.type.name) {
        categories.add(facility.type.name);
      }
    });
    setCategories([...categories]);
  };

  useEffect(() => {
    const { id } = params;
    setTitle(`Repair shop - ${id}`);
    setEndpoint(`repair-shops/list/${id}`);

    const fetchService = async () => {
      const service = await getData(id);

      if (!service) {
        return navigate('/notFound');
      }

      setShowLoader(false);

      takeOutCategories(service.facilities);

      setService(service);
    };

    fetchService();
  }, [params]);

  return (
    <Page title={title} endpoint={endpoint}>
      {service && (
        <RepairShopSingleComponent
          shop={service}
          categories={categories}
        />
      )}
      {!service && showLoader && <LoaderAsync />}
    </Page>
  );
}
export default RepairShopSinglePage;
